import {
  Box,
  Dialog,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import ImageIcon from "@material-ui/icons/Image"
import SaveIcon from "@mui/icons-material/Save"
import { generateClient } from "aws-amplify/api"
import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@material-ui/icons/Delete"
import RemoveIcon from "@mui/icons-material/Remove"
import { makeStyles } from "@material-ui/core/styles"
import * as subscriptions from "graphql/subscriptions"
import VisibilityIcon from "@mui/icons-material/Visibility"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import React, { useRef, useState, useEffect, useCallback } from "react"

import { mapToChapterObject, mapMimeTypeToFileType } from "util/helper"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import DocumentViewer from "./DocumentViewer"
import { checkEnvironment } from "util/environment"
import { OnUpdateChapterObjectSubscription } from "API"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import useSaveAttachmentsDialogV1 from "hooksV1/useSaveAttachmentsDialogV1"
import { ChapterObject, ChapterSection, UploadedFile } from "shared/types-exp"
import useDeleteAttachmentsDialogV1 from "hooksV1/useDeleteAttachmentsDialogV1"
import { AuthHelper } from "util/authHelper"

type AttachmentSectionV1Props = {
  sectionId: string
  chapterObject: ChapterObject
  chapterSection: ChapterSection
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
  },
  headerContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  attachmentInput: {
    width: "100%",
    height: "15vh",
    border: "2px dashed #bdbdbd",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  attachmentInputDisabled: {
    width: "100%",
    height: 150,
    border: "2px dashed #bdbdbd",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadIcon: {
    color: theme.palette.text.secondary,
    fontSize: "3rem",
  },
  fileInput: {
    display: "none",
  },
  buttonsContainer: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    color: "white",
    backgroundColor: "red",
  },
  removeButton: {
    color: "red",
  },
  uploadedFile: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  fileDetails: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  titleMargin: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  dialogTitleContainer: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  dialogTitleMargin: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}))

const client = generateClient()

const AttachmentSection: React.FC<AttachmentSectionV1Props> = ({
  sectionId,
  chapterSection,
  chapterObject,
}) => {
  const {
    activeCountry,
    activeProjectUser,
    activeDocumentVersion,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const saveAttachments = useSaveAttachmentsDialogV1()
  const deleteAttachments = useDeleteAttachmentsDialogV1()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const canEditDocument = AuthHelper.canEditDocument(
    chapterObject?.assignedProjectUser,
    activeCountry?.id,
    activeDocumentVersion.access,
    isProjectEnvironment,
    activeProjectUser
  )

  const MAX_FILES = 20
  const latestChapterObject = useRef(chapterObject)

  const [error, setError] = useState("")
  const [maxFilesReached, setMaxFilesReached] = useState(false)

  const [localFiles, setLocalFiles] = useState<{
    [key: string]: UploadedFile[]
  }>({})

  const [deletedFiles, setDeletedFiles] = useState<{
    [key: string]: UploadedFile[]
  }>({})

  const [uploadedFiles, setUploadedFiles] = useState<{
    [key: string]: UploadedFile[]
  }>({})

  const [selectedFile, setSelectedFile] = useState<{
    [key: string]: UploadedFile
  }>({})

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (!files || files.length === 0) {
      setError("")

      return
    }

    // Check if maximum files limit has been reached
    if (
      (uploadedFiles[sectionId]?.length ?? 0) +
        (localFiles[sectionId]?.length ?? 0) +
        files.length >
      MAX_FILES
    ) {
      setError("Maximum number of files reached")

      return
    }

    const newFiles: UploadedFile[] = []

    // Asynchronously process each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      // Check if file already exists in the uploaded files
      if (
        (uploadedFiles[sectionId]?.some(
          (uploadedFile) => uploadedFile.name === file.name
        ) ??
          false) ||
        (localFiles[sectionId]?.some(
          (uploadedFile) => uploadedFile.name === file.name
        ) ??
          false)
      ) {
        setError("File already exists")

        return
      }

      // Check file size limit (4MB)
      const fileSize = file.size / (1024 * 1024) // Convert to MB

      if (fileSize > 25) {
        setError("File size exceeds the limit of 25MB")

        return
      }

      // Check file type
      const allowedTypes = [
        "text/csv",
        "image/png",
        "image/jpeg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]

      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type")

        return
      }

      // Read file content asynchronously using FileReader
      const fileContent = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          const base64String = reader.result?.toString().split(",")[1] || ""
          resolve(base64String)
        }

        reader.onerror = (error) => {
          reject(error)
        }

        reader.readAsDataURL(file) // Read file as Data URL
      })

      // Push the file details to newFiles array
      newFiles.push({
        id: uuidv4(),
        name: file.name,
        size: file.size,
        content: fileContent,
        type: mapMimeTypeToFileType(file.type),
      })
    }

    setLocalFiles((prevFiles) => {
      const currentLocalFiles = {
        ...prevFiles,
        [sectionId]: [...(prevFiles[sectionId] || []), ...newFiles].slice(
          0,
          MAX_FILES
        ),
      }
      const totalFileSize = getTotalFileSize(currentLocalFiles)

      if (totalFileSize > 25) setError("Total file size cannot exceed 25MB")

      return currentLocalFiles
    })

    if (error !== "") setError("")
  }

  const handleRestoreFile = (fileToRestore: UploadedFile) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [sectionId]: [...(prevFiles[sectionId] || []), fileToRestore],
    }))
    setDeletedFiles((prevFiles) => ({
      ...prevFiles,
      [sectionId]: prevFiles[sectionId].filter(
        (file) => file.id !== fileToRestore.id
      ),
    }))
  }

  const handleDeleteFile = (fileToDelete: UploadedFile) => {
    setDeletedFiles((prevFiles) => ({
      ...prevFiles,
      [sectionId]: [...(prevFiles[sectionId] || []), fileToDelete],
    }))
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [sectionId]: prevFiles[sectionId].filter(
        (file) => file.id !== fileToDelete.id
      ),
    }))
  }

  const handleRemoveFile = (fileToRemove: UploadedFile) => {
    setLocalFiles((prevFiles) => {
      const currentLocalFiles = {
        ...prevFiles,
        [sectionId]: prevFiles[sectionId].filter(
          (file) => file.id !== fileToRemove.id
        ),
      }

      const totalFileSize = getTotalFileSize(currentLocalFiles)

      if (totalFileSize > 25) setError("Total file size cannot exceed 25MB")
      else setError("")

      return currentLocalFiles
    })
    setMaxFilesReached(false)
  }

  const getFileIcon = useCallback((fileType: string) => {
    switch (fileType) {
      case "png":
      case "jpg":
      case "image/jpeg":
      case "image/png":
        return <ImageIcon />
      case "application/pdf":
        return <PictureAsPdfIcon />
      default:
        return <InsertDriveFileIcon />
    }
  }, [])

  const handleClose = () => {
    setSelectedFile((prevSelected) => ({
      ...prevSelected,
      [sectionId]: null,
    }))
  }

  const getTotalFileSize = (files: { [key: string]: UploadedFile[] }) => {
    const flatFiles = Object.values(files).flat()

    return (
      flatFiles.reduce((total, file) => total + file.size, 0) / (1024 * 1024)
    )
  }

  const isSaveChangesDisabled = () => {
    return (
      (localFiles[sectionId]?.length ?? 0) === 0 ||
      isEditingDisabled() ||
      getTotalFileSize(localFiles) > 25
    )
  }

  const isDeleteFilesDisabled = () => {
    return (deletedFiles[sectionId]?.length ?? 0) === 0 || isEditingDisabled()
  }

  const isAbleToEdit = () => {
    // Checking if the chapter is a global
    // chapter and in global country
    const isTemplateGlobal =
      isTemplateEnvironment && chapterSection.isGlobal && activeCountry?.global

    // Checking if the chapter is a non-global
    // chapter and in a country
    const isTemplateNonGlobal =
      isTemplateEnvironment &&
      !chapterSection.isGlobal &&
      !activeCountry?.global

    // Checking if the chapter is a project
    // chapter and in project environment
    const isProjectClientEditable =
      isProjectEnvironment && chapterSection.isClientEditable

    return isTemplateGlobal || isTemplateNonGlobal || isProjectClientEditable
  }

  const isEditingDisabled = () => {
    if (isProjectEnvironment) {
      const { access } = chapterObject
      const [{ id }] = activeProjectUser.role
      const isProjectEditDisabled = access[id] !== "maintain"

      if (isProjectEditDisabled) return true
    }

    const isEditingByAnotherUser =
      chapterObject?.editing?.isEditing &&
      chapterObject?.editing.email &&
      chapterObject.editing.email.trim() !== localStorage.getItem("email")

    return isEditingByAnotherUser
  }

  const openSaveAttachmentDialog = () => {
    saveAttachments.setChapterSection(chapterSection)
    saveAttachments.setFilesToUpload([...localFiles[sectionId]])
    saveAttachments.setLocalFiles(setLocalFiles)
    saveAttachments.setUploadedFiles(setUploadedFiles)
    saveAttachments.onOpen()
  }

  const openDeleteAttachmentDialog = () => {
    deleteAttachments.setChapterSection(chapterSection)
    deleteAttachments.setFilesToDelete([...deletedFiles[sectionId]])
    deleteAttachments.setDeletedFiles(setDeletedFiles)
    deleteAttachments.setUploadedFiles(setUploadedFiles)
    deleteAttachments.onOpen()
  }

  const updateSectionInformation = (
    data: OnUpdateChapterObjectSubscription
  ) => {
    const updatedChapterObject = data.onUpdateChapterObject

    if (chapterObject.id !== updatedChapterObject.id) return

    const { editing } = updatedChapterObject

    const userEmail = localStorage.getItem("email")

    if (editing.email === userEmail) return

    const newChapter = mapToChapterObject(updatedChapterObject)

    const section = newChapter.sections.find(
      (section) => section.id === chapterSection.id
    )

    if (!section) return

    const localUploadedFiles = uploadedFiles[sectionId] || []

    if (
      section?.content?.uploadedFiles?.length === localUploadedFiles.length ||
      JSON.stringify(section?.content?.uploadedFiles) ===
        JSON.stringify(localUploadedFiles)
    )
      return

    setUploadedFiles((prev) => ({
      ...prev,
      [sectionId]: section.content.uploadedFiles,
    }))
    setActiveChapterObject(newChapter)
  }

  const isAbleToDelete = () => {
    return !isEditingDisabled() && isAbleToEdit() && canEditDocument
  }

  useEffect(() => {
    latestChapterObject.current = chapterObject
    const localUploadedFiles = uploadedFiles[sectionId] || []
    const section = chapterObject.sections.find(
      (section) => section.id === chapterSection.id
    )

    if (!section) return

    if (
      section.content &&
      (section.content.uploadedFiles.length !== localUploadedFiles.length ||
        JSON.stringify(section.content.uploadedFiles) !==
          JSON.stringify(localUploadedFiles))
    ) {
      const updatedFiles = section.content.uploadedFiles

      setUploadedFiles((prev) => ({
        ...prev,
        [sectionId]: updatedFiles,
      }))

      setMaxFilesReached(updatedFiles.length === MAX_FILES)
    }
  }, [chapterObject])

  useEffect(() => {
    const updateSub = client
      .graphql({
        query: subscriptions.onUpdateChapterObject,
        variables: {
          refId: chapterObject?.refId,
        },
      })
      .subscribe({
        next: ({ data }) => updateSectionInformation(data),
        error: (error) =>
          logger("TextEditorSection", "useEffect (Subscribe)", error),
      })

    return () => {
      updateSub.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (chapterSection?.content?.uploadedFiles.length > 0) {
      const updatedFiles = chapterSection.content.uploadedFiles

      setUploadedFiles((prev) => ({
        ...prev,
        [sectionId]: updatedFiles,
      }))

      setMaxFilesReached(updatedFiles.length === MAX_FILES)
    } else {
      setUploadedFiles((prev) => ({
        ...prev,
        [sectionId]: [],
      }))
    }

    setDeletedFiles((prev) => ({
      ...prev,
      [sectionId]: [],
    }))
    setLocalFiles((prev) => ({
      ...prev,
      [sectionId]: [],
    }))
  }, [])

  const renderFileList = (
    files: UploadedFile[],
    type: "local" | "upload" | "deleted",
    isUploadedFiles = false,
    isDeletedFiles = false
  ) => (
    <div>
      {files.length > 0 && (
        <div
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.25rem",
          }}
        >
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {type === "upload"
              ? "Uploaded Files"
              : type === "local"
              ? "Local Files"
              : "Deleted Files"}
          </Typography>
        </div>
      )}
      {files.map((file) => (
        <div key={file.id} className={classes.uploadedFile}>
          <Avatar>{getFileIcon(file.type)}</Avatar>
          <div className={classes.fileDetails}>
            <Typography variant="body1">{file.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {(file.size / (1024 * 1024)).toFixed(2)} MB
            </Typography>
          </div>
          <>
            {isUploadedFiles && (
              <Tooltip title="View Attachment">
                <IconButton
                  color="primary"
                  onClick={() =>
                    setSelectedFile((prevSelected) => ({
                      ...prevSelected,
                      [sectionId]: file,
                    }))
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}

            {isAbleToDelete() && (
              <Tooltip
                title={isDeletedFiles ? "Remove from file to delete" : "Delete"}
              >
                <IconButton
                  className={classes.removeButton}
                  onClick={() =>
                    type === "local"
                      ? handleRemoveFile(file)
                      : isDeletedFiles
                      ? handleRestoreFile(file)
                      : handleDeleteFile(file)
                  }
                >
                  {isDeletedFiles ? <RemoveIcon /> : <DeleteIcon />}
                </IconButton>
              </Tooltip>
            )}
          </>
        </div>
      ))}
      {files.length > 0 && <Divider style={{ marginTop: "0.75rem" }} />}
    </div>
  )

  const displayUploadBox = () => {
    if (maxFilesReached) {
      return (
        <>
          <label
            htmlFor={`attachment-input-${sectionId}-${chapterObject?.id}-${chapterObject?.refId}`}
          >
            <input
              disabled
              type="file"
              id={`attachment-input-${sectionId}-${chapterObject?.id}-${chapterObject?.refId}`}
              className={classes.fileInput}
              accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
            />
            <Box className={classes.attachmentInputDisabled}>
              <Typography variant="body1" color="textSecondary">
                Max Number of Attachment Reached
              </Typography>
            </Box>
          </label>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </>
      )
    }

    if (!isAbleToEdit() || !canEditDocument || isEditingDisabled()) {
      return (
        <>
          <label
            htmlFor={`attachment-input-${sectionId}-${chapterObject?.id}-${chapterObject?.refId}`}
          >
            <input
              disabled
              type="file"
              id={`attachment-input-${sectionId}-${chapterObject?.id}-${chapterObject?.refId}`}
              className={classes.fileInput}
              accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
            />
            <Box className={classes.attachmentInputDisabled}>
              <Typography variant="body1" color="textSecondary">
                Attachments Section
              </Typography>
            </Box>
          </label>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </>
      )
    }

    return (
      <>
        <label
          htmlFor={`attachment-input-${sectionId}-${chapterObject?.id}-${chapterObject?.refId}`}
        >
          <input
            multiple
            type="file"
            id={`attachment-input-${sectionId}-${chapterObject?.id}-${chapterObject?.refId}`}
            className={classes.fileInput}
            onChange={handleFileChange}
            accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
          />
          <Box className={classes.attachmentInput}>
            <CloudUploadIcon className={classes.uploadIcon} />
            <Typography variant="body1" color="textSecondary">
              Add Attachment
            </Typography>
          </Box>
        </label>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h2>{chapterSection.name}</h2>

        {isAbleToEdit() && canEditDocument && (
          <div className={classes.buttonsContainer}>
            <Button
              color="primary"
              variant="contained"
              disabled={isSaveChangesDisabled()}
              onClick={openSaveAttachmentDialog}
              style={{ height: "2.5rem" }}
            >
              <SaveIcon /> Save Changes
            </Button>
            <Button
              variant="contained"
              className={classes.deleteButton}
              disabled={isDeleteFilesDisabled()}
              onClick={openDeleteAttachmentDialog}
              style={{ height: "2.5rem" }}
            >
              <DeleteIcon /> Delete Files
            </Button>
          </div>
        )}
      </div>
      <Box>
        {displayUploadBox()}
        {renderFileList(uploadedFiles[sectionId] || [], "upload", true)}
        {renderFileList(localFiles[sectionId] || [], "local")}
        {renderFileList(deletedFiles[sectionId] || [], "deleted", false, true)}
        {selectedFile[sectionId] && (
          <Dialog fullScreen open={true} scroll="paper" onClose={handleClose}>
            <DialogTitle className={classes.dialogTitleContainer}>
              <div className={classes.headerContainer}>
                <h4 className={classes.dialogTitleMargin}>
                  {selectedFile[sectionId].name}
                </h4>

                <Tooltip title="Close Document Viewer" placement="left">
                  <IconButton color="primary" onClick={handleClose}>
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </div>
            </DialogTitle>
            <DialogContent>
              <DocumentViewer
                url={
                  typeof selectedFile[sectionId].content === "string"
                    ? (selectedFile[sectionId].content as string)
                    : ""
                }
                mimeType={selectedFile[sectionId].type}
              />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </div>
  )
}
export default AttachmentSection
