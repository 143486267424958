import React from "react"
import { Skeleton } from "@material-ui/lab"

import Loader from "components/Loading/Loader"

const Loading: React.FC = () => {
  return (
    <>
      <Loader open={true} />
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "0.1em",
        }}
      >
        <Skeleton
          style={{
            margin: 0,
            borderRadius: "8px",
          }}
          variant="rect"
          width={"100%"}
          height={"100%"}
        />
      </div>
    </>
  )
}

export default Loading
