import React from "react"

const UneditableEditor: React.FC<{ content: string }> = ({ content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      style={{
        width: "100%",
        height: "auto",
        minHeight: 150,
        padding: "0.75rem",
        backgroundColor: "white",
      }}
    />
  )
}

export default UneditableEditor
