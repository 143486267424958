import {
  Grid,
  Button,
  Dialog,
  Checkbox,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@material-ui/core"
import { z } from "zod"
import { useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"
import { useDispatch } from "react-redux"
import useAppState from "hooksV1/useAppState"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { makeStyles, Theme } from "@material-ui/core/styles"
import React, { FormEvent, useCallback, useEffect, useState } from "react"

import {
  invalidateAll,
  transformArray,
  createAuditTrailObject,
} from "util/helper"
import {
  Document,
  Operations,
  ChapterObject,
  ChapterSection,
  PartialAuditTrail,
  AuditTrailOperations,
  DocumentVersion,
  CountryResponse,
} from "shared/types-exp"
import {
  useGetFullCountriesQuery,
  useCreateDocumentMutation,
  useCreateAuditTrailMutation,
  useCreateChapterObjectMutation,
  useCreateDocumentVersionMutation,
} from "redux/services"
import { logger } from "util/logger"
import { defaultAccess } from "util/constants"
import Loader from "../components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useCreateDocumentDialogV1 from "../hooksV1/useCreateDocumentDialogV1"
import { checkIfError, createAuditTrail } from "util/batchHook"

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5em",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  dialogContent: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
  },
  dialogActions: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "1rem",
  },
}))

const CreateDocumentDialogV1: React.FC = () => {
  const classes = useStyles()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const { countries, activeCountry, setDocuments } = useAppState()
  const createDocumentDialog = useCreateDocumentDialogV1()

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createDocumentAPI] = useCreateDocumentMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [createChapterObjectAPI] = useCreateChapterObjectMutation()
  const [createDocumentVersionAPI] = useCreateDocumentVersionMutation()

  // Will need a way to get only the business blueprint
  const { data: countriesListAll, isLoading: isCountriesLoading } =
    useGetFullCountriesQuery("")

  const [isLoading, setIsLoading] = useState(false)
  const [countriesList, setCountriesList] = useState<any[]>([])

  const shiftGlobal = (array: CountryResponse[]) => {
    let globalIndex = array.findIndex((obj) => obj.global)

    if (globalIndex !== -1) {
      // Remove the global object from its current position
      let [globalObject] = array.splice(globalIndex, 1)
      // Add the global object to the beginning of the array

      array.unshift(globalObject)
    }
  }

  useEffect(() => {
    if (!countriesListAll) return

    const countriesWithBusinessBlueprint =
      countriesListAll.data.listCountries?.items?.filter((country) => {
        const businessBlueprints = country.documents?.items?.filter(
          (doc) =>
            doc.name.toLowerCase() === "business blueprint" && doc.enabled
        )

        return businessBlueprints.length > 0
      })

    shiftGlobal(countriesWithBusinessBlueprint)
    setCountriesList(countriesWithBusinessBlueprint)
  }, [countriesListAll])

  const documentNameExists = (name) => {
    return countriesList.some((country) =>
      country.documents.items.some(
        (document) => document.name.trim().toLowerCase() === name.toLowerCase()
      )
    )
  }

  const createDocumentSchema = z.object({
    documentName: z
      .string()
      .min(2, { message: "Document name has to be at least 3 characters long" })
      .refine((value) => value.toLowerCase() !== "business blueprint", {
        message: "Business Blueprint is a reserved document name",
      })
      .refine((value) => !documentNameExists(value), {
        message: "Document Name already exists",
      }),
    author: z.string().min(1, { message: "Author cannot be empty" }),
    agree: z.boolean(),
  })

  const formik = useFormik({
    initialValues: {
      documentName: "",
      author: "",
      agree: false,
    },
    validationSchema: toFormikValidationSchema(createDocumentSchema),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)

        const refId = uuidv4()
        const { author, documentName, agree } = values

        if (!agree) {
          const activeCountry = countries.find(
            (country) =>
              country.country_name.toLocaleLowerCase() === "global" ||
              country.global
          )

          if (!activeCountry) {
            logger("CreateDocumentDialog", "useFormik", activeCountry)

            snackBar.setMessage(
              "Document Creation Failed. Could not find global country."
            )
            snackBar.setMessageSeverity(SnackType.SnackError)
            snackBar.onOpen()

            return
          }

          const doc: Partial<Document> = {
            refId,
            author,
            enabled: true,
            projectType: "",
            activeVersion: 1,
            isProject: false,
            name: documentName,
            openForChanges: true,
            documentVersions: [],
            countryId: activeCountry?.id,
            isIncludedInWorkflow: false,
            lastUpdated: new Date().toISOString(),
          }

          const createdDocumentResult: any = await createDocumentAPI(doc)

          checkIfError(createdDocumentResult, doc, "[Create Document]")

          const createdDocument = createdDocumentResult.data.data.createDocument

          const docVersion: Partial<DocumentVersion> = {
            users: [],
            chapters: [],
            sections: [],
            isCountry: false,
            isProject: false,
            refId: uuidv4(),
            latestVersion: 1,
            access: defaultAccess,
            isGlobal: activeCountry.global,
            documentId: createdDocument.id,
            documentRefId: createdDocument.refId,
          }

          const createDocumentVersionResult: any =
            await createDocumentVersionAPI(docVersion)

          checkIfError(
            createDocumentVersionResult,
            docVersion,
            "[Create Document Version]"
          )
        } else {
          const newRefIdMap = new Map()

          for (const country of countriesList) {
            const businessBlueprintDocument = country.documents["items"].find(
              (doc) => doc.name.toLowerCase() === "business blueprint"
            )

            if (!businessBlueprintDocument) continue

            const doc: Partial<Document> = {
              refId,
              author,
              enabled: true,
              projectType: "",
              activeVersion: 1,
              isProject: false,
              name: documentName,
              openForChanges: true,
              documentVersions: [],
              countryId: country.id,
              isIncludedInWorkflow: false,
              lastUpdated: new Date().toISOString(),
            }

            const createdDocumentResult: any = await createDocumentAPI(doc)

            checkIfError(createdDocumentResult, doc, "[Create Document]")

            const createdDocument =
              createdDocumentResult.data.data.createDocument

            const businessBlueprintActiveVersion =
              businessBlueprintDocument.documentVersions["items"][0]

            const newSectionsList: ChapterSection[] = []
            businessBlueprintActiveVersion.sections.forEach((section) => {
              newSectionsList.push({
                ...section,
                refId: uuidv4(),
                content: {
                  uploadedFiles: [],
                  plainText: "",
                },
              })
            })

            const docVersion: Partial<DocumentVersion> = {
              users: [],
              chapters: [],
              latestVersion: 1,
              isProject: false,
              refId: uuidv4(),
              access: defaultAccess,
              sections: newSectionsList,
              documentId: createdDocument.id,
              documentRefId: createdDocument.refId,
              isGlobal: businessBlueprintActiveVersion.isGlobal,
              isCountry: businessBlueprintActiveVersion.isCountry,
            }

            const createDocumentVersionResult: any =
              await createDocumentVersionAPI(docVersion)

            checkIfError(
              createDocumentVersionResult,
              docVersion,
              "[Create Document Version]"
            )

            const chapters =
              businessBlueprintActiveVersion?.chapters["items"] || []

            if (country.global) {
              chapters.forEach((chap) => newRefIdMap.set(chap.refId, uuidv4()))
            }

            const newDocVersionId =
              createDocumentVersionResult.data.data.createDocumentVersion.id

            if (newDocVersionId && chapters.length > 0) {
              const transformedArray = transformArray(chapters)
              await traverseChapters(
                newDocVersionId,
                createdDocument.refId,
                transformedArray,
                newSectionsList,
                newRefIdMap
              )
            }
          }
        }

        const auditTrail: PartialAuditTrail = createAuditTrailObject(
          AuditTrailOperations.CREATE,
          Operations.DOCUMENT,
          `A document with the name ${documentName}, was created in ${activeCountry?.country_name}, in the ${environment} environment.`
        )

        await createAuditTrail(createAuditTrailAPI, auditTrail)
        invalidateAll(dispatch)

        setDocuments([])

        snackBar.setMessage("Document Created Successfully")
        snackBar.setMessageSeverity(SnackType.SnackSuccess)
        snackBar.onOpen()

        handleClose()
      } catch (error) {
        logger("CreateDocumentDialog", "useFormik", error)

        snackBar.setMessage("Document Creation Failed. Please try agin.")
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        setIsLoading(false)
      }
    },
  })

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  const handleClose = useCallback(() => {
    formik.resetForm()
    createDocumentDialog.onClose()
  }, [formik, createDocumentDialog])

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const traverseChapters = async (
    docVerId: string,
    documentRefId: string,
    chapters: ChapterObject[],
    sectionsList: ChapterSection[],
    newRefIdMap: Map<string, string>,
    parentId = "0"
  ) => {
    for (const chapter of chapters) {
      const newChapter: Partial<ChapterObject> = {
        // refId: we need to create it's own the reflects across
        parentId,
        documentRefId,
        subchapters: [],
        sharedId: uuidv4(),
        name: chapter.name,
        refId: newRefIdMap.get(chapter.refId) ?? uuidv4(),
        assignedProjectUser: "",
        documentVersionId: docVerId,
        isIncludedInWorkflow: false,
        isGlobal: chapter.isGlobal,
        isProject: chapter.isProject,
        isCountry: chapter.isCountry,
        treeIndex: chapter.treeIndex,
        chapterOrder: chapter.chapterOrder,
        subchapterCreateAllowed: chapter.subchapterCreateAllowed,
        access:
          chapter.access && chapter.access.length > 0
            ? chapter.access
            : defaultAccess,
        sections: sectionsList.map((section) => {
          return {
            ...section,
            content: {
              uploadedFiles: [],
              plainText: "",
            },
          }
        }),
        editing: {
          email: "",
          name: "",
          surname: "",
          isEditing: false,
        },
        epi_status: {
          status: "",
          lastUpdated: "",
          userLastUpdated: "",
          comments: [],
        },
        client_status: {
          status: "",
          lastUpdated: "",
          userLastUpdated: "",
          comments: [],
        },
      }

      const createChapterObjectResult: any = await createChapterObjectAPI(
        newChapter
      )

      checkIfError(createChapterObjectResult, newChapter, "[Create Chapter]")

      if (chapter.subchapters && chapter.subchapters.length > 0) {
        await traverseChapters(
          docVerId,
          documentRefId,
          chapter.subchapters,
          sectionsList,
          newRefIdMap,
          createChapterObjectResult?.data.data?.createChapterObject.id
        )
      }
    }
  }

  return (
    <>
      <Dialog
        open={createDocumentDialog.isOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        onKeyDown={handleKeyDown}
      >
        <DialogTitle className={classes.dialogTitle}>
          Create Document
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <form noValidate>
            <Grid container spacing={0}>
              <Grid xs={12} item>
                <TextField
                  autoComplete="DocumentName"
                  id="DocumentName"
                  label="Document Name"
                  margin="normal"
                  name="documentName"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.documentName}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.documentName &&
                    Boolean(formik.errors.documentName)
                  }
                  helperText={
                    formik.touched.documentName && formik.errors.documentName
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  autoComplete="author"
                  id="author"
                  label="Author"
                  margin="normal"
                  name="author"
                  variant="outlined"
                  fullWidth
                  required
                  onBlur={formik.handleBlur}
                  value={formik.values.author}
                  error={formik.touched.author && Boolean(formik.errors.author)}
                  helperText={formik.touched.author && formik.errors.author}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="agree"
                      name="agree"
                      color="primary"
                      checked={formik.values.agree}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Create a document based of Business Blueprint"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="text"
            color="primary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) =>
              formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)
            }
            disabled={isLoading || !formik.isValid || isCountriesLoading}
          >
            Create
          </Button>
        </DialogActions>

        {(isLoading || isCountriesLoading) && <Loader open={true} />}
      </Dialog>
    </>
  )
}

export default CreateDocumentDialogV1
