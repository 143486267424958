import axios from "axios"
import jwtDecode from "jwt-decode"
import {
  removeUserInfo,
  setForgotPasswordResponseErrorMessage,
  setForgotPasswordStatus,
  setLoginResponseErrorMessage,
  setLoginStatus,
  setResetPasswordResponseErrorMessage,
  setResetPasswordStatus,
  setUserInfo,
} from "redux/slices/authorization"
import { Status } from "shared/types/status"
import { User } from "shared/types/user"
import config from "../../config"
import type { EPIDispatch, RootState } from "../store"

export function login(email: string, password: string, rememberMe: boolean) {
  return async function login(dispatch: EPIDispatch, getState: RootState) {
    dispatch({ type: setLoginStatus.type, payload: Status.loading })
    axios
      .post(`${config.apiGateway.URL}/auth/login`, {
        email,
        password,
      })
      .then(function (response) {
        const token: string = response.headers["x-amzn-remapped-authorization"]
        const finalToken = token.slice(
          token.indexOf("=") + 1,
          token.indexOf(";")
        )
        const user: User = jwtDecode(finalToken)

        if (rememberMe) {
          localStorage.setItem("token", finalToken)
          localStorage.setItem("firstname", user.firstname)
          localStorage.setItem("surname", user.surname)
          localStorage.setItem("email", user.email)
          localStorage.setItem("role_name", user.role_id.role_name)
          localStorage.setItem(
            "can_create",
            user.role_id.can_create as unknown as string
          )
        }
        sessionStorage.setItem("id", user.id)
        sessionStorage.setItem("token", finalToken)
        sessionStorage.setItem("firstname", user.firstname)
        sessionStorage.setItem("surname", user.surname)
        sessionStorage.setItem("email", user.email)
        sessionStorage.setItem("role_name", user.role_id.role_name)
        sessionStorage.setItem(
          "can_create",
          user.role_id.can_create as unknown as string
        )
        axios.defaults.headers.common["authorization"] = finalToken
        user.token = finalToken
        dispatch({ type: setUserInfo.type, payload: user })
        dispatch({ type: setLoginStatus.type, payload: Status.success })
      })
      .catch(function (error) {
        dispatch({ type: setLoginStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({
              type: setLoginResponseErrorMessage.type,
              payload: error.response.data.message,
            })
          }
        } else dispatch({ type: setLoginResponseErrorMessage.type, payload: "Unknown error" })
      })
  }
}

export function logout() {
  return (dispatch: EPIDispatch) => {
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("firstname")
    sessionStorage.removeItem("surname")
    sessionStorage.removeItem("email")
    sessionStorage.removeItem("role_name")
    sessionStorage.removeItem("can_create")
    sessionStorage.removeItem("project_roles")
    sessionStorage.removeItem("id")
    localStorage.removeItem("token")
    localStorage.removeItem("firstname")
    localStorage.removeItem("surname")
    localStorage.removeItem("email")
    localStorage.removeItem("role_name")
    localStorage.removeItem("can_create")
    localStorage.removeItem("project_roles")
    localStorage.removeItem("id")
    dispatch({ type: removeUserInfo.type })
    dispatch({ type: setLoginStatus.type, payload: Status.failed })
    axios.defaults.headers.common["authorization"] = "allow"
  }
}

export function forgotPassword(email: string) {
  return async function forgotPassword(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    //This url should be hidden in an env variable
    axios
      .post(`${config.apiGateway.URL}/email/reset`, {
        email,
      })
      .then(function (response) {
        //response can be used to set user. Using dummy user for now
        // const attributes = JSON.parse(response.data.message)
        // const attributes = response.data.message
        // dispatch({ type: setUserInfo.type, payload: attributes.Attributes })
        dispatch({
          type: setForgotPasswordStatus.type,
          payload: Status.success,
        })
      })
      .catch(function (error) {
        dispatch({ type: setForgotPasswordStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({
              type: setForgotPasswordResponseErrorMessage.type,
              payload: error.response.data.message,
            })
          }
        } else dispatch({ type: setForgotPasswordResponseErrorMessage.type, payload: "Unknown error" })
      })
  }
}

export function resetPassword(email: string, password: string) {
  return async function resetPassword(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    //This url should be hidden in an env variable
    //reset token needs to be passed in here once that's added into the API
    axios
      .put(`${config.apiGateway.URL}/auth/password`, {
        email,
        password,
      })
      .then(function (response) {
        //response can be used to set user. Using dummy user for now
        // const attributes = response.data.message
        // dispatch({ type: setUserInfo.type, payload: attributes.Attributes })
        dispatch({ type: setResetPasswordStatus.type, payload: Status.success })
      })
      .catch(function (error) {
        dispatch({ type: setResetPasswordStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({
              type: setResetPasswordResponseErrorMessage.type,
              payload: error.response.data.message,
            })
          }
        } else dispatch({ type: setResetPasswordResponseErrorMessage.type, payload: "Unknown error" })
      })
  }
}

export function refreshToken(token: string) {
  return async function refreshToken(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    axios
      .post(
        `${config.apiGateway.URL}/auth/refresh`,
        {
          token,
        },
        {
          headers: {
            authorization: "allow",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const token: string =
            response.headers["x-amzn-remapped-authorization"]
          const finalToken = token.slice(
            token.indexOf("=") + 1,
            token.indexOf(";")
          )
          const user: User = jwtDecode(finalToken)
          localStorage.setItem("token", finalToken)
          sessionStorage.setItem("token", finalToken)
          sessionStorage.setItem("id", user.id)
          sessionStorage.setItem("firstname", user.firstname)
          sessionStorage.setItem("surname", user.surname)
          sessionStorage.setItem("email", user.email)
          sessionStorage.setItem("role_name", user.role_id.role_name)
          sessionStorage.setItem(
            "can_create",
            user.role_id.can_create as unknown as string
          )
          axios.defaults.headers.common["authorization"] = finalToken
          user.token = finalToken
          dispatch({ type: setUserInfo.type, payload: user })
        }
      })
      .catch((error) => {
        // error
        /* const logoutThunk = logout()
                dispatch(logoutThunk) */
      })
  }
}
