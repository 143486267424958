import logo from "../media/logo.svg"
import { Link } from "react-router-dom"
import React from "react"

const styles = {
  container: {
    marginRight: 10,
    backgroundColor: "white",
    padding: 7,
    paddingLeft: 10,
    paddingRight: 10,
  },
  logo: {
    width: "100px",
  },
}

const Logo: React.FC = () => {
  return (
    <div style={styles.container}>
      <Link to="/">
        <img alt="EPI-USE Logo" src={logo} style={styles.logo} />
      </Link>
    </div>
  )
}

export default Logo
