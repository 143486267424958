import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  MenuItem,
  Select,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"

import {
  PartialAuditTrail,
  AuditTrailOperations,
  Operations,
} from "shared/types-exp"
import useAppState from "hooksV1/useAppState"
import { createAuditTrail, updateChapterObjectFunc } from "util/batchHook"
import {
  useCreateAuditTrailMutation,
  useUpdateChapterObjectMutation,
} from "redux/services"
import {
  invalidateAll,
  getUpdatedRoles,
  createAuditTrailObject,
} from "util/helper"
import { logger } from "util/logger"
import { ProjectUserRole } from "shared/types/project"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import useAssignUserDialogV1 from "hooksV1/useAssignUserDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  dialogMinWidth: {
    minWidth: "20rem",
    paddingLeft: "1rem",
  },
  dropDown: {
    width: "100%",
    padding: 0,
  },
}))

const AssignUserDialogV1: React.FC = () => {
  const {
    activeCountry,
    activeProject,
    activeDocument,
    // chapterObjects,
    activeChapterObject,
    // setChapterObjects,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const assignUserDialog = useAssignUserDialogV1()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [updateChapterObjectAPI] = useUpdateChapterObjectMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [assignedUser, setAssignedUser] = useState("None")
  const [projectUsers, setProjectUsers] = useState<ProjectUserRole[]>([])

  useEffect(() => {
    if (activeChapterObject && activeProject) {
      setAssignedUser(activeChapterObject.assignedProjectUser || "None")
      setProjectUsers(activeProject.users || [])
    }
  }, [activeChapterObject, activeProject])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleOnSave()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    assignUserDialog.onClose()
  }

  const handleOnSave = async () => {
    try {
      setIsLoading(true)

      const chapterObjectCopy = cloneDeep({
        ...activeChapterObject,
        assignedProjectUser: assignedUser === "None" ? "" : assignedUser,
        access: getUpdatedRoles(activeChapterObject.access),
      })

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.CHAPTER,
        `Updated the assigned user for the chapter ${activeChapterObject?.name}, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the Project environment.`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        updateChapterObjectFunc(updateChapterObjectAPI, {
          id: chapterObjectCopy.id,
          documentVersionId: chapterObjectCopy.documentVersionId,
          isIncludedInWorkflow: chapterObjectCopy.isIncludedInWorkflow,
          assignedProjectUser: chapterObjectCopy.assignedProjectUser,
          access: chapterObjectCopy.access,
        }),
      ])

      setActiveChapterObject(chapterObjectCopy)
      invalidateAll(dispatch)

      handleClose()
    } catch (error) {
      logger("AssignUserDialogV1", "handleOnSave", error)

      snackBar.setMessage(
        "An error occurred updating the Responsible Consultant. Please try again."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setAssignedUser(event.target.value as string)
  }

  const isSaveDisabled = () => {
    return (
      assignedUser === activeChapterObject?.assignedProjectUser ||
      (activeChapterObject?.assignedProjectUser === "" &&
        assignedUser === "None") ||
      isLoading
    )
  }

  return (
    <Dialog
      open={assignUserDialog.isOpen}
      maxWidth="sm"
      onClose={handleClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Assign Responsible Consultant</DialogTitle>
      <DialogContent dividers>
        <Select
          value={assignedUser}
          onChange={handleOnChange}
          className={classes.dropDown}
        >
          <MenuItem key="None" value="None">
            None
          </MenuItem>
          {projectUsers.map((projectUser) => {
            return (
              <MenuItem key={projectUser.user.id} value={projectUser.user.id}>
                {`${projectUser.user.firstname} ${projectUser.user.surname}`}
              </MenuItem>
            )
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          disabled={isLoading}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isSaveDisabled()}
          onClick={handleOnSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignUserDialogV1
