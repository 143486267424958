import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import image from "media/banner.jpg"
import React from "react"
import { useNavigate } from "react-router-dom"
import useToken from "shared/auth/useToken"
import type { ContentInfo } from "shared/types/introduction"

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundImage: `linear-gradient(269.91deg, #EEF2F6 0.07%, #EEF2F6 36.22%, #EEF2F6 46.21%, rgba(238, 242, 246, 0.95) 50.74%, rgba(238, 242, 246, 0.789081) 72%, rgba(238, 242, 246, 0) 100.36%), url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "left bottom",
    backgroundSize: "cover",
    height: "95vh",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "60vh",
    },
    textAlign: "center",
    alignItems: "center",
    display: "flex",
  },
  divContainer1: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    maxWidth: "50%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  divContainer2: {
    paddingTop: theme.spacing(8),
  },
  signInButton: {
    width: "12rem",
    padding: theme.spacing(1, 0, 1, 0),
    fontSize: 16,
  },
  goToProjectButton: {
    width: "17rem",
    padding: theme.spacing(1, 0, 1, 0),
    fontSize: 16,
  },
}))

const Banner: React.FC<ContentInfo> = ({ content }) => {
  const classes = useStyles()
  let navigate = useNavigate()
  const { token } = useToken()

  return (
    <Grid xs={12} item>
      <Paper className={classes.banner} square>
        <Grid
          alignItems="center"
          direction="column"
          justifyContent="center"
          container
        >
          <Grid className={classes.divContainer1} md={6} item>
            <Typography variant="h3">{content?.title}</Typography>
          </Grid>
          {!token && (
            <Grid className={classes.divContainer2} item>
              <Button
                className={classes.signInButton}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#CE1443", color: "white" }}
                onClick={() => {
                  navigate("/signin")
                }}
              >
                Sign In
              </Button>
            </Grid>
          )}
          {token && (
            <Grid className={classes.divContainer2} item>
              <span>
                <Button
                  className={classes.goToProjectButton}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    navigate("/project")
                  }}
                >
                  Go to Project Environment
                </Button>
              </span>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Banner
