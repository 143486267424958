import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { generateClient } from "aws-amplify/api"

import { logger } from "util/logger"
import { invalidateAll } from "util/helper"
import useBooleanState from "./useBooleanStates"
import { getGlobalCountry } from "graphql/queries"
import { useCreateCountryMutation } from "redux/services"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

const client = generateClient()

export const useInitializeGlobalCountry = (): void => {
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const { setIsCountriesLoading } = useBooleanState()
  const [createCountryAPI, { isLoading }] = useCreateCountryMutation()

  useEffect(() => {
    const fetchGlobalCountry = async () => {
      try {
        const result: any = await client.graphql({
          query: getGlobalCountry,
          variables: {
            filter: {
              projectRefId: {
                eq: "",
              },
              global: {
                eq: true,
              },
            },
          },
        })

        if (result.errors) {
          throw Error(result)
        }

        if (result?.data?.listCountries?.items?.length === 0) {
          await createCountryAPI({
            country_name: "Global",
            active_status: true,
            global: true,
            projectRefId: "",
          })

          invalidateAll(dispatch)
        }
      } catch (error) {
        logger(
          "useInitializeGlobalCountry",
          "useEffect (Fetching global country)",
          error
        )

        const errorMessage =
          error.errors[0].message === "Unauthorized"
            ? "User is not authorized to access this information. Please try a different user."
            : "An unexpected error occurred while fetching documents. Please try again."

        snackBar.setMessage(errorMessage)
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      }
    }

    fetchGlobalCountry()
  }, [])

  useEffect(() => {
    setIsCountriesLoading(isLoading)
  }, [isLoading])
}
