import { create } from "zustand"

type EditProjectDialogState = {
  isOpen: boolean
}

type EditProjectDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useEditProjectDialogV1 = create<
  EditProjectDialogState & EditProjectDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useEditProjectDialogV1
