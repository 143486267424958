// ==================== External Imports ==================== //
import React from "react"
import {
  Container,
  CssBaseline,
  Grid,
  Paper,
  makeStyles,
  Theme,
  Typography,
  Button
} from "@material-ui/core"
import {useNavigate} from "react-router-dom"

// ==================== Local Imports ==================== //
import image from "media/logo.svg"
import styled from "styled-components"

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 a100%",
  },
  mainDiv: {
    display: "flex",
    flex: 1,
    padding: "2em",
  },
  paperContainer: {
    display: "flex",
    padding: "5em 2em 5em 2em",
    justifyContent: "center",
  },
  centeredFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "1rem",
  },
  submit: {
    marginTop: "1rem",
  },
  gridItemPaperContainer: {
    maxWidth: 600,
  },
}))

const ForgotPasswordEmailSent: React.FC = () => {
  const classes = useStyles()
  let navigate = useNavigate()

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="xs" component="main">
              <CssBaseline />
              <div className={classes.centeredFlex}>
                <div className={classes.logoContainer}>
                  <LogoImg src={image} alt="logo" />
                </div>
                <Typography
                  style={{ textAlign: "center" }}
                  component="h1"
                  variant="h5"
                  color="textPrimary"
                >
                  Thank you! An email has been sent to you.
                </Typography>
                <Typography
                  style={{ textAlign: "center", justifyContent: "stretch" }}
                  variant="subtitle2"
                  color="textPrimary"
                >
                  Please check your email to complete the process
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => { navigate('/signin') }}
                >
                  <Typography
                    style={{ textAlign: "center", justifyContent: "stretch"}}
                    variant="subtitle2"
                  >
                    Back to Sign In
                  </Typography>
                </Button>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgotPasswordEmailSent

const LogoImg = styled.img<{ src: string; alt: string }>`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`
