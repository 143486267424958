import React from "react"
import { Skeleton } from "@material-ui/lab"
import { ListItem, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: "1em",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  skeleton: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
}))

const ManageCountriesSkeletonItem: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <ListItem className={classes.listItem}>
        <Skeleton
          width="100%"
          height={30}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
          className={classes.skeleton}
        />
      </ListItem>
    </>
  )
}

export default ManageCountriesSkeletonItem
