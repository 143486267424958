// ==================== External Imports ==================== //
import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"

// ==================== Local Imports ==================== //
import NoDataMessage from "../NoDataMessage"
import useAppState from "hooksV1/useAppState"
import { List, Paper } from "@material-ui/core"
import useBooleanState from "hooksV1/useBooleanStates"
import ManageCountriesListItem from "./ManageCountriesListItem"
import DeleteCountryDialogV1 from "dialogsV1/DeleteCountryDialog"
import useDeleteCountryDialogV1 from "hooksV1/useDeleteCountryDialogV1"
import ManageCountriesSkeletonItem from "./ManageCountriesSkeletonItem"
import { useGetAllCountriesWithProjectRefIdV1Query } from "redux/services"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0.5rem",
    margin: theme.spacing(1, 0, 0, 0),
  },
  paperCard: {
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
  },
}))

const ManageCountries: React.FC = () => {
  const classes = useStyles()
  const useDeleteCountry = useDeleteCountryDialogV1()
  const { setIsChaptersLoading, isChaptersLoading } = useBooleanState()
  const { isAdding, isDeleting, activeDocument, setIsAdding, setIsDeleting } =
    useAppState()

  const { data: countriesListAll } =
    useGetAllCountriesWithProjectRefIdV1Query("")

  const pathArray = location.pathname.split("/")

  // ==================== Use Hooks ==================== //
  const [countries, setCountries] = useState([])
  const [stillFetching, setStillFetching] = useState(false)

  useEffect(() => {
    if (isChaptersLoading) setIsChaptersLoading(false)
  }, [isChaptersLoading])

  useEffect(() => {
    if (!countriesListAll) return

    const activeDocumentId = activeDocument?.refId || pathArray[3]

    const property = activeDocument?.refId ? "refId" : "id"

    const countriesListData = countriesListAll.data.listCountries.items.filter(
      (country) =>
        !!country.documents.items.find(
          (document) => document[property] === activeDocumentId
        )
    )

    if (countriesListData.length > 0) {
      const countriesList: any[] = countriesListData ?? []

      const sortedCountriesList = [...countriesList].sort((a, b) => {
        const nameA = a.country_name.toUpperCase()
        const nameB = b.country_name.toUpperCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      })

      setCountries(sortedCountriesList.filter((country) => !country.global))
      setIsAdding(false)
      setIsDeleting(null)
    }
  }, [countriesListAll, activeDocument])

  useEffect(() => {
    setTimeout(() => {
      setStillFetching(!activeDocument)
    }, 10)
  }, [activeDocument])

  return (
    <div className={classes.container}>
      {countries?.length > 0 || stillFetching || !activeDocument ? (
        <Paper className={classes.paperCard}>
          <List
            dense
            style={{
              width: "100%",
            }}
          >
            {!stillFetching && activeDocument
              ? countries?.map((country, index) => {
                  return (
                    // Skeleton for when item is being deleted (Perhaps manage cleaner)
                    isDeleting && isDeleting.id === country.id ? (
                      <ManageCountriesSkeletonItem key={`skeleton-${index}`} />
                    ) : (
                      <ManageCountriesListItem
                        key={country.id}
                        country={country}
                      />
                    )
                  )
                })
              : Array.from({ length: 3 }).map((_, index) => (
                  <ManageCountriesSkeletonItem key={index} />
                ))}

            {isAdding && <ManageCountriesSkeletonItem />}
          </List>
        </Paper>
      ) : (
        <NoDataMessage text={"No Countries Found"} />
      )}
      {useDeleteCountry.isOpen && <DeleteCountryDialogV1 />}
    </div>
  )
}

export default ManageCountries
