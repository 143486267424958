// ==================== External Imports ==================== //
import React from "react"
import { cloneDeep } from "lodash"
import {
  Theme,
  Switch,
  ListItem,
  makeStyles,
  ListItemText,
  FormControlLabel,
  ListItemSecondaryAction,
} from "@material-ui/core"

// ==================== Local Imports ==================== //
import { Country } from "shared/types-exp"
import * as lookup from "country-code-lookup"
import ReactCountryFlag from "react-country-flag"
import useAppState from "../../hooksV1/useAppState"

type ProjectManageCountriesItemProps = {
  country: Country
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: "1.5rem",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  button: {
    backgroundColor: "#f44336",
    color: "white",
    marginLeft: "0.25em",
  },
}))

const ProjectManageCountriesItem: React.FC<ProjectManageCountriesItemProps> = ({
  country,
}) => {
  const classes = useStyles()
  const { countries, setCountries } = useAppState()

  let countryCode = ""

  const onActiveToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempCountries = cloneDeep(countries)
    const index = tempCountries.findIndex(
      (currentCountry) => currentCountry.id === country.id
    )

    tempCountries[index].active_status = !country.active_status
    setCountries(tempCountries)
  }

  if (country.country_name !== "Global") {
    countryCode = lookup.byCountry(country.country_name)?.iso2 ?? ""
  }

  return (
    <ListItem key={country.id} className={classes.listItem}>
      <ListItemText
        primary={
          <>
            <ReactCountryFlag countryCode={countryCode} svg />
            &nbsp;{country.country_name}
          </>
        }
      />
      <ListItemSecondaryAction>
        <FormControlLabel
          control={
            <Switch
              checked={country.active_status}
              name={country.country_name}
              id={country.id}
              color="primary"
              onChange={onActiveToggle}
            />
          }
          label={
            <span style={{ fontSize: "14px" }}>
              {country.active_status ? "Active" : "Deactivated"}
            </span>
          }
          style={{ flexDirection: "row-reverse" }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ProjectManageCountriesItem
