import { create } from "zustand"

type AddCountryDialogState = {
    isOpen: boolean
}

type AddCountryDialogActions = {
    onOpen: () => void
    onClose: () => void
}

const useAddCountryDialogV1 = create<
    AddCountryDialogState & AddCountryDialogActions
>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
}))

export default useAddCountryDialogV1
