import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"

import {
  createAuditTrailObject,
  getImageTagsFromPlainText,
  invalidateAll,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useListFullDocumentsByRefIdQuery,
  useDeleteDocumentOperationMutation,
} from "redux/services"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import { createAuditTrail } from "util/batchHook"
import Loader from "../components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import { DeleteDocumentModel } from "shared/types-exp/delete"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useDeleteDocumentDialogV1 from "hooksV1/useDeleteDocumentV1"
import { Operations, AuditTrailOperations } from "shared/types-exp"

const DeleteDocumentDialogV1: React.FC = () => {
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const { documents, setDocuments } = useAppState()
  const deleteDocumentDialog = useDeleteDocumentDialogV1()

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [deleteDocumentApi] = useDeleteDocumentOperationMutation()
  const { data: documentsList, isLoading: isLoadingFullDocuments } =
    useListFullDocumentsByRefIdQuery(
      deleteDocumentDialog?.activeDocument?.refId
    )

  const [isLoading, setIsLoading] = useState(false)
  const [deleteDocumentModel, setDeleteDocumentModel] =
    useState<DeleteDocumentModel>(null)

  useEffect(() => {
    if (!documentsList) return
    const data: DeleteDocumentModel = populateDeleteDocumentModel(documentsList)
    setDeleteDocumentModel(data)
  }, [documentsList])

  const populateDeleteDocumentModel = (response: any): DeleteDocumentModel => {
    const documents: { id: string; countryId: string }[] = []
    const documentVersions: { id: string; documentId: string }[] = []
    const chapters: { id: string; documentVersionId: string }[] = []
    const uploadedFiles: { id: string; content: string }[] = []

    // Using Map for faster lookups to avoid duplicates
    const uploadedFilesMap = new Map<string, string>()

    response.data.listDocuments.items?.forEach((document: any) => {
      const { id, countryId } = document
      documents.push({ id, countryId })

      document.documentVersions.items?.forEach((version: any) => {
        const { id: versionId, documentId } = version
        documentVersions.push({ id: versionId, documentId })

        version.chapters.items?.forEach((chapter: any) => {
          const { id: chapterId, documentVersionId: versionIdRef } = chapter
          chapters.push({ id: chapterId, documentVersionId: versionIdRef })

          for (const section of chapter.sections) {
            if (
              section.type === "attachment" &&
              section.content.uploadedFiles.length > 0
            ) {
              section.content.uploadedFiles.forEach((file: any) => {
                if (!uploadedFilesMap.has(file.id)) {
                  uploadedFilesMap.set(file.id, file.content)
                  uploadedFiles.push({ id: file.id, content: file.content })
                }
              })

              continue
            }

            const templateImgTags = getImageTagsFromPlainText(
              section.content.plainText
            )

            if (section.type === "text" && templateImgTags.length > 0) {
              templateImgTags.forEach((img) => {
                if (img.src.includes("uploaded-files-s3") && img.id) {
                  const file = {
                    id: img.id,
                    content: img.src,
                  }

                  if (!uploadedFilesMap.has(file.id)) {
                    uploadedFilesMap.set(file.id, file.content)
                    uploadedFiles.push({ id: file.id, content: file.content })
                  }
                }
              })
            }
          }
        })
      })
    })

    return { documents, documentVersions, chapters, uploadedFiles }
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleConfirmDeleteDocument()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    deleteDocumentDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleConfirmDeleteDocument = async () => {
    if (!documentsList) return

    try {
      setIsLoading(true)

      await deleteDocumentApi(deleteDocumentModel)

      const auditTrail = createAuditTrailObject(
        AuditTrailOperations.DELETE,
        Operations.DOCUMENT,
        `The document ${deleteDocumentDialog?.activeDocument?.name} was deleted in the ${environment} environment.`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)

      const newDocuments = documents.filter(
        (doc) => doc.id !== deleteDocumentDialog.activeDocument?.id
      )

      invalidateAll(dispatch)
      setDocuments(newDocuments)

      snackBar.setMessage("Document Deleted Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("DeleteDocumentDialogV1", "handleConfirmDeleteDocument", error)

      snackBar.setMessage("Document Delete Failed. Please try again later.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleCloseDialog}
      open={deleteDocumentDialog.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to delete this document? <br />
          <span>
            <b>(This action is permanent)</b>
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="text"
          color="primary"
          disabled={isLoading}
          onClick={deleteDocumentDialog.onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading || isLoadingFullDocuments}
          onClick={handleConfirmDeleteDocument}
        >
          Delete
        </Button>
      </DialogActions>

      {(isLoading || isLoadingFullDocuments) && <Loader open={true} />}
    </Dialog>
  )
}

export default DeleteDocumentDialogV1
