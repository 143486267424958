import Grid from "@material-ui/core/Grid"
import React from "react"
import { useDispatch } from "react-redux"
import { refreshToken } from "redux/thunks/authorizationThunk"
import useToken from "shared/auth/useToken"
import { ContentInfo } from "shared/types/introduction"
import Banner from "./Banner"
import {
  default as HomeCards,
  default as NavigationCards,
} from "./DisplayCards"
import InformationField from "./InformationField"

const LandingPage: React.FC = () => {
  const token = useToken().token

  const catchyHeading: ContentInfo = {
    content: {
      title: "EPI-Center is designed to provide insights into your organization's payroll-related processes",
    },
  }

  const info: ContentInfo = {
    content: {
      title:
        "Welcome to EPI-Center, your one-stop source for SAP payroll management!",
      body: "EPI-Center is a comprehensive documentation website designed to provide users with in-depth information and guidance on payroll management in SAP. Our aim is to assist SAP payroll administrators, HR professionals, and business analysts in their day-to-day operations by providing step-by-step instructions, best practices, and expert advice. Whether you are a beginner or an experienced professional, EPI-Center is your one-stop source to give insight in your own processes.",
    },
  }

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      const refreshThunk = refreshToken(localStorage.getItem("token"))
      dispatch(refreshThunk)
    }
  }, [])

  return (
    <>
      {!token && (
        <>
          <Grid spacing={0} container>
            <Banner content={catchyHeading.content} />
          </Grid>

          <Grid container>
            <InformationField {...info} />
          </Grid>
          
          <HomeCards token={undefined} />
        </>
      )}
      {token && (
        <>
          <Grid spacing={0} container>
            <Banner content={catchyHeading.content} />
          </Grid>
          <Grid container>
            <InformationField {...info} />
          </Grid>
          <NavigationCards token={token} />
        </>
      )}
    </>
  )
}

export default LandingPage
