import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import { useLocation, Link } from "react-router-dom"
import { Paper, Tab, Tabs, makeStyles, Theme } from "@material-ui/core"

import {
  a11yProps,
  containsPath,
  sortDocumentsOrder,
  containsPathProject,
  invalidateAll,
} from "util/helper"
import useAppState from "hooksV1/useAppState"
import useBooleanState from "hooksV1/useBooleanStates"
import { checkEnvironment } from "util/environment"

const useStyles = makeStyles((theme: Theme) => ({
  paperCard: {
    display: "flex",
    alignItems: "center",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },
  skeletonContainer: {
    width: "100%",
    padding: "1rem",
    display: "flex",
    fontSize: 16,
  },
  tabsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "0.5rem",
  },
  tab: {
    minWidth: 175,
    height: 40,
    color: "black",
    borderRadius: 50,
    textAlign: "center",
    marginRight: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  activeTab: {
    minWidth: 175,
    height: 40,
    color: "white",
    borderRadius: 50,
    textAlign: "center",
    marginRight: "0.5rem",
    backgroundColor: "#001744",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const DocumentsToolBar: React.FC = () => {
  const {
    documents,
    activeCountry,
    activeDocument,
    setActiveDocument,
    setChapterObjects,
    setChapterObjectsList,
    setActiveChapterObject,
    setActiveDocumentVersion,
  } = useAppState()
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const booleanState = useBooleanState()

  const paths = location.pathname.split("/")
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const [activeDocumentId, setActiveDocumentId] = useState(null)

  useEffect(() => {
    if (!activeDocument) return

    if (isTemplateEnvironment && paths[paths.length - 1] !== "template") {
      setActiveDocumentId(activeDocument?.id || "")
    }

    if (isProjectEnvironment && paths[paths.length - 2] !== "project") {
      setActiveDocumentId(activeDocument?.id || "")
    }

    setActiveDocumentId(null)
  }, [activeDocument])

  const onSetActiveDocument = (docId: string) => {
    const activeDocument = documents?.find((doc) => doc.id === docId)

    if (!activeDocument) return

    setChapterObjects(null)
    setChapterObjectsList(null)
    setActiveChapterObject(null)
    setActiveDocumentVersion(null)
    setActiveDocument(activeDocument)

    const documentVersion = activeDocument?.documentVersions

    if (documentVersion) {
      setActiveDocumentVersion(documentVersion["items"][0])
    } else {
      setActiveDocumentVersion(null)
    }
  }

  const navigateInTemplate = (id: string): string => {
    const paths = location.pathname.split("/")
    const currentPath = paths[paths.length - 1]

    let activeCountryName = activeCountry?.country_name ?? "Global"

    if (!containsPath(currentPath)) {
      return `/template/document/${id}/country/${activeCountryName}`
    }

    return `/template/document/${id}/country/${activeCountryName}/${currentPath}`
  }

  const navigateInProject = (id: string): string => {
    const pathsArray = location.pathname.split("/")
    const currentPath = pathsArray[pathsArray.length - 1]
    const projectId = pathsArray[2]

    let activeCountryName = activeCountry?.country_name ?? ""

    if (!containsPathProject(currentPath)) {
      return `/project/${projectId}/document/${id}/country/${activeCountryName}`
    }

    return `/project/${projectId}/document/${id}/country/${activeCountryName}/${currentPath}`
  }

  const getNavigationLink = (id: string): string => {
    if (isProjectEnvironment) return navigateInProject(id)

    return navigateInTemplate(id)
  }

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    if (booleanState?.isEditingBlocked) {
      e.preventDefault() // Prevents navigation
    } else {
      invalidateAll(dispatch)
      onSetActiveDocument(id)
    }
  }

  const isLoading = () => {
    return (
      booleanState.isCountriesLoading || booleanState.isDocumentVersionLoading
    )
  }

  if (isLoading()) {
    return (
      <Skeleton
        style={{
          margin: 0,
          borderRadius: "8px",
        }}
        height={50}
        variant="rect"
        width={"100%"}
        animation="wave"
      />
    )
  }

  if (!documents || documents?.length === 0) {
    return (
      <Paper className={classes.paperCard}>
        <div className={classes.skeletonContainer}>
          No documents available for the selected country.
        </div>
      </Paper>
    )
  }

  const sortedDocuments = sortDocumentsOrder(
    documents?.filter((document) => document.enabled)
  )

  return (
    <Paper className={classes.paperCard}>
      <Tabs
        scrollButtons="auto"
        textColor="primary"
        variant="scrollable"
        indicatorColor="primary"
        value={activeDocumentId}
        className={classes.tabsContainer}
        aria-label="scrollable auto tabs example"
      >
        {sortedDocuments?.map((document, index) => (
          <Link
            key={document.id}
            to={getNavigationLink(document.id)}
            className={
              activeDocument?.id === document.id
                ? classes.activeTab
                : classes.tab
            }
            onClick={(e) => {
              if (activeDocument?.id !== document.id) {
                handleClick(e, document.id)
              }
            }}
          >
            <Tab
              key={document.id}
              value={document.id}
              label={document.name}
              {...a11yProps(index)}
              textColor={
                activeDocument?.id !== document.id ? "primary" : "white"
              }
            />
          </Link>
        ))}
      </Tabs>
    </Paper>
  )
}

export default DocumentsToolBar
