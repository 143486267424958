import CssBaseline from "@material-ui/core/CssBaseline"
import { Suspense, useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import authInterceptors from "shared/auth/authInterceptors"
import FooterNav from "components/MainNav/FooterNav"
import styled from "styled-components"
import Registration from "views/Registration/Registration"
import Guide from "views/Home/Guide"
import Loader from "./components/Loading/Loader"
import Copyright from "./views/Disclaimers/Copyright"
import Disclaimer from "./views/Disclaimers/Disclaimer"
import PrivacyPolicy from "./views/Disclaimers/PrivacyPolicy"
import TermsAndConditions from "./views/Disclaimers/TermsAndConditions"
import { DBConfig } from "./DBConfig"
import { initDB } from "react-indexed-db"
import TemplateHomeV1 from "views/Template/TemplateHomeV1"
import UserManagement from "components/UserManagement/UserManagement"
import ProjectManagement from "components/ProjectManagementV1/ProjectManagementV1"
import DocumentManagement from "components/DocumentManagement/DocumentManagement"
import ManageContent from "components/ManageContent/ManageContent"
import ManageCountries from "components/ManageCountries/ManageCountries"
import ManageRoles from "components/ManageRoles/ManageRoles"
import ManageSettings from "components/ManageSettings/ManageSettings"
import ManageSections from "components/ManageSection/ManageSections"
import TemplateDocumentV1 from "views/Template/TemplateDocumentV1"
import MainNavV1 from "./components/MainNav/MainNavV1"
import ProjectsV1 from "./views/Project/ProjectsV1"
import ProjectHomeV1 from "./views/Project/ProjectHomeV1"
import ProjectDocumentManagement from "./components/ProjectDocumentManagement/ProjectDocumentManagement"
import ProjectUserManagement from "./components/UserManagement/ProjectUserManagement"
import ProjectCountryManagement from "./components/ProjectManageCountries/ProjectCountryManagement"
import ProjectManageRoles from "./components/ProjectManageRoles/ProjectManageRoles"
import ProjectDocumentV1 from "./views/Project/ProjectDocumentV1"
import ProjectManageContent from "./components/ProjectManageContent/ProjectManageContent"
import ProjectManageSettings from "./components/ProjectManageSettings/ProjectManageSettings"
import RestrictedRoute from "shared/auth/RestrictedRoute"
import LandingPage from "views/Home/Home"
import Login from "views/Login/Login"
import ForgotPassword from "views/Login/ForgotPassword"
import ForgotPasswordEmailSent from "views/Login/ForgotPasswordEmailSent"
import PasswordReset from "views/Login/PasswordReset"
import RegistrationCompletion from "views/Registration/RegistrationCompletion"
import PrivateRoute from "shared/auth/PrivateRoute"
import ProjectManageVersion from "components/ProjectManageVersion/ProjectManageVersion"
import { setLoggerEnabled } from "util/logger"
import PublicRoute from "shared/auth/PublicRoute"
import NotFound from "views/Error/404"

initDB(DBConfig)

const App: React.FC = () => {
  authInterceptors()

  useEffect(() => {
    // Enable the logger during development
    setLoggerEnabled(process.env.NODE_ENV === "development")
  }, [])

  return (
    <EPIContainer>
      <CssBaseline />
      <Suspense fallback={<Loader open={true} />}>
        <MainNavV1 />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route element={<PublicRoute />}>
            <Route path="/signin" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password-email-sent"
              element={<ForgotPasswordEmailSent />}
            />
            <Route path="/resetPassword" element={<PasswordReset />} />
            <Route path="/register" element={<Registration />} />
          </Route>
          <Route path="/completion" element={<RegistrationCompletion />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/copyright" element={<Copyright />} />

          <Route element={<RestrictedRoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/template" element={<TemplateHomeV1 />}>
                <Route index element={<DocumentManagement />} />
                <Route path="users" element={<UserManagement />} />
                <Route path="projects" element={<ProjectManagement />} />
              </Route>

              <Route
                path="/template/document/:documentId/country/:countryId"
                element={<TemplateDocumentV1 />}
              >
                <Route index element={<Navigate to="content" replace />} />
                <Route path="content" element={<ManageContent />} />
                <Route path="sections" element={<ManageSections />} />
                <Route path="settings" element={<ManageSettings />} />
                <Route path="countries" element={<ManageCountries />} />
                <Route path="roles" element={<ManageRoles />} />
              </Route>
            </Route>

            <Route path="/project" element={<ProjectsV1 />} />

            <Route path="/project/:project" element={<ProjectHomeV1 />}>
              <Route index element={<Navigate to="home" replace />} />
              <Route path="home" element={<ProjectDocumentManagement />} />
              <Route path="users" element={<ProjectUserManagement />} />
              <Route path="countries" element={<ProjectCountryManagement />} />
            </Route>

            <Route
              path="/project/:project/country/:countryId"
              element={<ProjectHomeV1 />}
            >
              <Route index element={<Navigate to="home" replace />} />
              <Route path="home" element={<ProjectDocumentManagement />} />
              <Route path="users" element={<ProjectUserManagement />} />
              <Route path="countries" element={<ProjectCountryManagement />} />
            </Route>

            <Route
              path="/project/:project/document/:documentId/country/:countryId"
              element={<ProjectDocumentV1 />}
            >
              <Route index element={<Navigate to="content" replace />} />
              <Route path="content" element={<ProjectManageContent />} />
              <Route path="sections" element={<ManageSections />} />
              <Route path="settings" element={<ProjectManageSettings />} />
              <Route path="versions" element={<ProjectManageVersion />} />
              <Route path="roles" element={<ProjectManageRoles />} />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>

        <FooterNav />
      </Suspense>
    </EPIContainer>
  )
}

export default App

const EPIContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
`
