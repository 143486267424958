import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme, Paper, TablePagination } from "@material-ui/core"

import { isMatch } from "util/helper"
import { RootState } from "redux/store"
import { User } from "shared/types/user"
import UserTableBody from "./UserTableBody"
import { Status } from "shared/types/status"
import useAppState from "hooksV1/useAppState"
import { checkEnvironment } from "util/environment"
import NoDataMessage from "components/NoDataMessage"
import { ProjectUserRole } from "shared/types/project"
import { fetchUsers } from "redux/thunks/usersSliceThunk"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import Loading from "components/Loading/Loading"

type UserTableProps = {
  searchText: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: "0.5rem",
  },
  paginationContainer: {
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  skeleton: {
    width: "99%",
    height: "100%",
    marginLeft: "0.5rem",
    marginTop: "0.5rem",
  },
}))

const UserTable: React.FC<UserTableProps> = ({ searchText }) => {
  const classes = useStyles()
  const snackBar = useSnackBar()
  const { activeRole, activeProject, activeCountry, activeProjectUser } = useAppState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const role = activeRole

  const dispatch = useDispatch()
  const users = useSelector((state: RootState) => state.users.users)
  const fetchingUsersStatus = useSelector(
    (state: RootState) => state.users.getUserStatus
  )

  const deleteUserStatus = useSelector(
    (state: RootState) => state.users.deleteUserStatus
  )

  const updateUserStatus = useSelector(
    (state: RootState) => state.users.updateUserStatus
  )

  useEffect(() => {
    if (isProjectEnvironment) setProjectUsers()
    else setUsers()
  }, [users, role, searchText, isProjectEnvironment, isTemplateEnvironment])

  useEffect(() => {
    if (users.length === 0 && isTemplateEnvironment) {
      fetchUsersFromDB()
    }
  }, [])

  useEffect(() => {
    if (deleteUserStatus === Status.success) {
      fetchUsersFromDB()
    } else if (deleteUserStatus === Status.failed) {
      snackBar.setMessage("User deletion failed")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [deleteUserStatus])

  useEffect(() => {
    if (updateUserStatus === Status.success) {
      fetchUsersFromDB()
    } else if (updateUserStatus === Status.failed) {
      snackBar.setMessage("User update failed")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [updateUserStatus])

  useEffect(() => {
    if (!activeProject) return
    setProjectUsers()
  }, [activeProject])

  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [filteredProjectUsers, setFilteredProjectUsers] = useState<
    ProjectUserRole[]
  >([])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const setUsers = () => {
    let rows = users

    if (searchText !== "") {
      rows = rows.filter((row, key) => {
        if (
          isMatch(row.firstname, searchText) ||
          isMatch(row.surname, searchText) ||
          isMatch(row.email, searchText) ||
          isMatch(row.role_id.role_name, searchText)
        )
          return row
      })
    }

    if (role !== "All Roles") {
      if (rows.length > 0) {
        rows = rows.filter((row) => row.role_id.role_name === role)
      }
    }

    setFilteredUsers(rows)
  }

  const setProjectUsers = () => {
    const isGlobalUser = activeProjectUser.role.find((role) => role.countryId === 'global')
    let rows = activeProject?.users.filter((row) => {
      const assignedCountry = row.role?.filter(
        (role) =>
          role.countryId === "global" || role.countryId === activeCountry?.id || isGlobalUser
      )

      if ((assignedCountry?.length || 0) === 0) return false

      return true
    })

    if (searchText !== "") {
      rows = rows.filter((row, key) => {
        if (
          isMatch(row.user.firstname, searchText) ||
          isMatch(row.user.surname, searchText) ||
          isMatch(row.user.email, searchText) ||
          isMatch(row.user.role_id.role_name, searchText)
        )
          return row
      })
    }

    if (role !== "All Roles") {
      if (rows.length > 0) {
        rows = rows.filter((row) => {
          const assignedCountry = row.role?.filter(
            (role) =>
              role.countryId === "global" ||
              role.countryId === activeCountry?.id
          )

          if ((assignedCountry?.length || 0) === 0) return false

          if (assignedCountry[0].role_name === role) return true

          return false
        })
      }
    }

    setFilteredProjectUsers(rows)
  }

  const fetchUsersFromDB = () => {
    const fetchUsersThunk = fetchUsers()
    dispatch(fetchUsersThunk)
  }

  const isLoading = () => {
    return (
      fetchingUsersStatus === Status.loading ||
      deleteUserStatus === Status.loading ||
      updateUserStatus === Status.loading
    )
  }

  const hasUsers = () => {
    if (isProjectEnvironment) return filteredProjectUsers.length > 0

    return filteredUsers.length > 0
  }

  if (isLoading()) {
    return (
      <div className={classes.skeleton}>
        <Loading />
      </div>
    )
  }

  if (!hasUsers()) {
    return (
      <div className={classes.container}>
        <NoDataMessage text={"No Users found"} />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <>
        <UserTableBody
          page={page}
          rowsPerPage={rowsPerPage}
          users={isProjectEnvironment ? filteredProjectUsers : filteredUsers}
        />
        <Paper className={classes.paginationContainer}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={
              isProjectEnvironment
                ? filteredProjectUsers?.length
                : filteredUsers.length
            }
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </>
    </div>
  )
}

export default UserTable
