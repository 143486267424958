import { create } from "zustand"
import { ChapterSection, UploadedFile } from "shared/types-exp"

type SaveAttachmentsDialogState = {
  isOpen: boolean
  chapterSection: ChapterSection
  filesToUpload: UploadedFile[]
  filesUploaded: UploadedFile[]
  localFilesFunc: React.Dispatch<
    React.SetStateAction<{
      [key: string]: UploadedFile[]
    }>
  >
  uploadedFilesFunc: React.Dispatch<
    React.SetStateAction<{
      [key: string]: UploadedFile[]
    }>
  >
}

type SaveAttachmentsDialogActions = {
  onOpen: () => void
  onClose: () => void
  setFilesToUpload: (filesToUpload: UploadedFile[]) => void
  setFilesUploaded: (filesUploaded: UploadedFile[]) => void
  setChapterSection: (chapterSection: ChapterSection) => void
  setLocalFiles: (
    localFilesFunc: React.Dispatch<
      React.SetStateAction<{
        [key: string]: UploadedFile[]
      }>
    >
  ) => void

  setUploadedFiles: (
    uploadedFilesFunc: React.Dispatch<
      React.SetStateAction<{
        [key: string]: UploadedFile[]
      }>
    >
  ) => void
}

const useSaveAttachmentsDialogV1 = create<
  SaveAttachmentsDialogState & SaveAttachmentsDialogActions
>((set) => ({
  isOpen: false,
  chapterSection: null,
  filesToUpload: [],
  filesUploaded: [],
  filesToDelete: [],
  localFilesFunc: null,
  deleteFilesFunc: null,
  uploadedFilesFunc: null,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setFilesToUpload: (filesToUpload) => set(() => ({ filesToUpload })),
  setFilesUploaded: (filesUploaded) => set(() => ({ filesUploaded })),
  setChapterSection: (chapterSection) => set(() => ({ chapterSection })),
  setLocalFiles: (localFilesFunc) => set(() => ({ localFilesFunc })),
  setUploadedFiles: (uploadedFilesFunc) => set(() => ({ uploadedFilesFunc })),
}))

export default useSaveAttachmentsDialogV1
