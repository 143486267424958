// ==================== External Imports ==================== //
import React from "react"
import { makeStyles, Theme, Paper } from "@material-ui/core"

// ==================== Local Imports ==================== //
import PageTitle from "./PageTitle"
import SearchBarV1 from "./SearchBarV1"
import ToolbarButtons from "./ToolbarButtons"

type PageToolBarProps = {
  isDocumentManagement?: boolean
  isUserManagement?: boolean
  isProjectManagement?: boolean
  search?: string
  setSearch?: (search: string) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: "0.5rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },

  gridItem: {
    flex: 1,
    marginRight: "1rem",
  },
}))

const getToolbarDetails = (
  isDocumentManagement: boolean,
  isUserManagement: boolean,
  isProjectManagement: boolean
) => {
  switch (true) {
    case isDocumentManagement:
      return {
        title: "Document Management",
        subTitle: "Create, edit or remove Documents of EPI-CENTRE",
        buttonDetails: "isDocumentManagement",
      }
    case isUserManagement:
      return {
        title: "User Management",
        subTitle: "Invite, edit or remove Users of EPI-CENTRE",
        buttonDetails: "isUserManagement",
      }
    case isProjectManagement:
      return {
        title: "Project Management",
        subTitle: "Create, edit or remove Projects of EPI-CENTRE",
        buttonDetails: "isProjectManagement",
      }
  }
}

const TemplatePageToolbar: React.FC<PageToolBarProps> = ({
  isDocumentManagement,
  isUserManagement,
  isProjectManagement,
  search,
  setSearch,
}) => {
  const classes = useStyles()

  const {
    title: title,
    subTitle: subTitle,
    buttonDetails: buttonDetails,
  } = getToolbarDetails(
    isDocumentManagement,
    isUserManagement,
    isProjectManagement
  )

  return (
    <Paper className={classes.container}>
      <div style={{ flex: 1 }}>
        <PageTitle title={title} subTitle={subTitle} />
      </div>
      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <SearchBarV1 search={search} setSearch={setSearch} />
      </div>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <ToolbarButtons buttonsDetails={buttonDetails} />
      </div>
    </Paper>
  )
}

export default TemplatePageToolbar
