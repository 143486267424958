import { create } from "zustand"
import { Country } from "shared/types-exp"

export type DeleteCountryState = {
  country: Country
}

export type DeleteCountryActions = {
  setCountry: (country: Country) => void
}

const useDeleteCountryState = create<DeleteCountryState & DeleteCountryActions>(
  (set) => ({
    country: null,
    setCountry: (country) => set(() => ({ country })),
  })
)

export default useDeleteCountryState
