import {
  Paper,
  Theme,
  Button,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import React from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import * as EmailValidator from "email-validator"
import { Link, useNavigate } from "react-router-dom"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import image from "media/logo.svg"
import { Status } from "shared/types/status"
import { useCreateAuditTrailMutation } from "redux/services"
import { forgotPassword } from "redux/thunks/authorizationThunk"
import { setForgotPasswordStatus } from "redux/slices/authorization"
import { createAuditTrailObject } from "util/helper"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: "1 1 100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paperContainer: {
    width: "35vw",
    display: "flex",
    padding: "1rem",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  centeredFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "1rem",
  },
  form: {
    display: "flex",
    width: "85%",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: "1rem",
  },
  formRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: "1rem",
  },
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  link: {
    marginTop: "0.5rem",
    textDecoration: "none !important",
    color: theme.palette.text.primary,
  },
}))

type SupportEmail = {
  email: string
  children: string
  subject: string
  body: string
}

const ForgotPassword: React.FC = () => {
  const [emailInput, setEmailInput] = React.useState<string>("")
  const [emailHelperText, setEmailHelperText] = React.useState<string>("")
  const [emailValidation, setEmailValidation] = React.useState<boolean>(false)

  const dispatch = useDispatch()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const sendDetailsToServer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let formValidation = true

    if (emailInput === "") {
      setEmailHelperText("Email can not be empty")
      setEmailValidation(true)
      formValidation = false
    } else {
      if (EmailValidator.validate(emailInput)) {
        setEmailValidation(false)
      } else {
        setEmailHelperText("Invalid email")
        setEmailValidation(true)
        formValidation = false
      }
    }

    if (formValidation) {
      const forgotPasswordThunk = forgotPassword(emailInput)
      dispatch(forgotPasswordThunk)

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.FORGET,
        Operations.USER,
        `Executed the forget password operation.`
      )

      createAuditTrailAPI(auditTrail)

      dispatch({ type: setForgotPasswordStatus.type, payload: Status.loading })
      navigate("/forgot-password-email-sent")
    }
  }

  const Mailto = ({
    email,
    children,
    subject = "",
    body = "",
  }: SupportEmail) => {
    let params = subject || body ? "?" : ""

    if (subject) params += `subject=${encodeURIComponent(subject)}`

    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`

    return <a href={`mailto:${email}${params}`}>{children}</a>
  }

  const classes = useStyles()
  let navigate = useNavigate()

  return (
    <div className={classes.container}>
      <Paper className={classes.paperContainer}>
        <div className={classes.centeredFlex}>
          <div className={classes.logoContainer}>
            <LogoImg src={image} alt="logo" />
          </div>
          <Typography
            style={{ textAlign: "center" }}
            component="h1"
            variant="h5"
            color="textPrimary"
          >
            Reset your password
          </Typography>
          <form
            noValidate
            className={classes.form}
            onSubmit={sendDetailsToServer}
          >
            <TextField
              required
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
              fullWidth
              variant="outlined"
              margin="normal"
              error={emailValidation}
              helperText={emailHelperText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmailInput(event.target.value)
              }
            />

            <div className={classes.formRow}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Reset password
              </Button>
            </div>

            <p className={classes.link}>
              Have an account already?{" "}
              <span>
                <Link to="/signin">Sign In Instead</Link>
              </span>
            </p>
          </form>
          <Typography
            style={{
              textAlign: "center",
              justifyContent: "normal",
              marginBottom: "1rem",
              marginTop: "0.5rem",
            }}
            variant="subtitle2"
            color="textPrimary"
          >
            You will receive an email with instructions on how to reset your
            password
          </Typography>
          <Typography
            style={{ textAlign: "center", justifyContent: "normal" }}
            variant="subtitle2"
            color="textPrimary"
          >
            If you have forgotten your email address, or no longer have access
            to it, please contact our support team for assistance at
          </Typography>
          <div className={classes.centeredFlex}>
            <Mailto
              email="support@epiuse.com"
              subject="Forgot Email"
              body="Forgot email"
            >
              support@epiuse.com
            </Mailto>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default ForgotPassword

const LogoImg = styled.img<{ src: string; alt: string }>`
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
  width: 125px;
  height: auto;
`
