import {
  Paper,
  Theme,
  Button,
  Checkbox,
  Typography,
  TextField,
  IconButton,
  makeStyles,
  InputAdornment,
  FormControlLabel,
} from "@material-ui/core"

import { z } from "zod"
import { useFormik } from "formik"
import styled from "styled-components"
import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { Visibility, VisibilityOff } from "@material-ui/icons"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import image from "media/logo.svg"
import { RootState } from "redux/store"
import { User } from "shared/types/user"
import { Status } from "shared/types/status"
import Loader from "components/Loading/Loader"
import { login } from "redux/thunks/authorizationThunk"
import { useCreateAuditTrailMutation } from "redux/services"
import { createAuditTrailObject } from "util/helper"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: "1 1 100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "35vw",
    display: "flex",
    padding: "1rem",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    padding: "1rem",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  formFields: {
    paddingTop: "1rem",
  },
  formRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: "1rem",
  },
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  checkbox: {
    paddingTop: "0.5rem",
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    marginTop: "0.5rem",
    textDecoration: "none !important",
    color: theme.palette.text.primary,
  },
  environmentButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const LogoImg = styled.img<{ src: string; alt: string }>`
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
  width: 125px;
  height: auto;
`

const Login: React.FC = () => {
  const dispatch = useDispatch()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const LoginSchema = z.object({
    email: z.string().email({ message: "Invalid email" }),
    password: z.string().min(1, { message: "Password can not be empty" }),
  })

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: toFormikValidationSchema(LoginSchema),
    onSubmit: (values) => {
      const loginThunk = login(values.email, values.password, rememberMe)
      dispatch(loginThunk)
    },
  })

  const [isAdmin, setIsAdmin] = React.useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)

  const errorMessage = useSelector(
    (state: RootState) => state.authorisation.loginResponseErrorMessage
  )
  const loggingInStatus = useSelector(
    (state: RootState) => state.authorisation.loginStatus
  )
  const user: User | any = useSelector(
    (state: RootState) => state.authorisation.user
  )

  useEffect(() => {
    if (user.id !== "") {
      if (user.role_id.role_name !== "Administrator") {
        setIsAdmin(false)
      } else {
        setIsAdmin(true)
      }
    }
  }, [user])

  useEffect(() => {
    if (user.id !== "" && isAdmin === false) {
      navigate("/projectEnvironment")
    }
  }, [isAdmin])

  useEffect(() => {
    if (loggingInStatus === Status.success) {
      let resUser = user as User

      localStorage.setItem("firstname", resUser.firstname)
      localStorage.setItem("surname", resUser.surname)
      localStorage.setItem("email", resUser.email)
      localStorage.setItem("role_name", resUser.role_id.role_name)
      sessionStorage.setItem("project_roles", "")
      localStorage.setItem(
        "can_create",
        resUser.role_id.can_create as unknown as string
      )

      const token = resUser.token // Assuming you get a token
      const expiresIn = 24 * 60 * 60 * 1000 // 1 day in milliseconds
      const expiryTime = new Date().getTime() + expiresIn

      localStorage.setItem("token", token)
      localStorage.setItem("token_expiry", expiryTime.toString())
      sessionStorage.setItem("token", token)

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.LOGIN,
        Operations.USER,
        "Executed the login operation"
      )

      createAuditTrailAPI(auditTrail)
    }
  }, [loggingInStatus])

  const classes = useStyles()
  let navigate = useNavigate()

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Loader open={loggingInStatus === Status.loading}></Loader>
        <div className={classes.logoContainer}>
          <LogoImg alt="" src={image} />
        </div>

        {user.id === "" && (
          <form
            className={classes.form}
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.formFields}>
              <TextField
                autoComplete="email"
                id="email"
                label="Email address"
                name="email"
                variant="outlined"
                size="medium"
                value={formik.values.email}
                autoFocus
                fullWidth
                required
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
              />
            </div>
            <div className={classes.formFields}>
              <TextField
                autoComplete="current-password"
                id="password"
                label="Password"
                size="medium"
                name="password"
                type={passwordShown ? "text" : "password"}
                variant="outlined"
                value={formik.values.password}
                fullWidth
                required
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {passwordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {loggingInStatus === Status.failed && errorMessage && (
                <Typography variant="caption" color="error">
                  <br />*{errorMessage}
                </Typography>
              )}
            </div>

            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  color="primary"
                  value="rememberMe"
                  disabled={loggingInStatus === Status.loading}
                  onClick={() => setRememberMe(!rememberMe)}
                />
              }
              label="Remember me"
            />

            <div className={classes.formRow}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
                disabled={loggingInStatus === Status.loading}
              >
                Sign In
              </Button>
            </div>

            <Link className={classes.link} to="/forgot-password">
              Forgot password?
            </Link>
          </form>
        )}
        {user.id !== "" && (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h3>
              Welcome,{" "}
              <b>
                {user.firstname} {user.surname}!
              </b>
            </h3>
            <p>Choose an environment to log into:</p>
            <br />
            <div className={classes.environmentButtonsContainer}>
              {isAdmin === true && (
                <Button
                  onClick={() => {
                    navigate("/template")
                  }}
                  style={{ marginRight: "1em" }}
                  variant="outlined"
                  color="primary"
                >
                  Template Environment
                </Button>
              )}
              <Button
                onClick={() => {
                  navigate("/project")
                }}
                variant="contained"
                color="primary"
              >
                Project Environment
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </div>
  )
}

export default Login
