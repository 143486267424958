import { create } from "zustand"

type ChapterNavigationState = {
  isOpen: boolean
}

type ChapterNavigationActions = {
  onOpen: () => void
  onClose: () => void
}

const useChapterNavigationV1 = create<
  ChapterNavigationState & ChapterNavigationActions
>((set) => ({
  isOpen: true,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useChapterNavigationV1
