import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import image from "media/logo.svg"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 a100%",
  },
  mainDiv: {
    display: "flex",
    flex: 1,
    padding: "2em",
  },
  paperContainer: {
    display: "flex",
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: "center",
  },
  paper: {
    dispaly: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  formLink: {
    paddingTop: theme.spacing(2),
  },
  signInButton: {
    color: "#111B43",
    fontSize: 18,
  },
}))

const RegistrationCompletion: React.FC = () => {
  const classes = useStyles()
  let navigate = useNavigate()

  return (
    <div className={classes.mainDiv}>
      <Grid
        alignItems="center"
        className={classes.gridContainer}
        justifyContent="center"
        spacing={3}
        container
      >
        <Grid className={classes.gridItemPaperContainer} xs={10} item>
          <Paper className={classes.paperContainer}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.logoContainer}>
                  <LogoImg alt="" src={image} />
                </div>
              </div>
              <Typography
                color="textSecondary"
                component="p"
                style={{ textAlign: "center" }}
                variant="body1"
              >
                You have successfully completed the registration
              </Typography>
              <Typography
                color="textPrimary"
                component="h2"
                style={{ textAlign: "center" }}
                variant="h5"
                className={classes.formLink}
              >
                <Button
                  className={classes.signInButton}
                  onClick={() => navigate("/signin")}
                  variant={"outlined"}
                >
                  Sign In
                </Button>
              </Typography>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default RegistrationCompletion

const LogoImg = styled.img<{ src: string; alt: string }>`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`
