import { ChapterSection } from "shared/types-exp"
import { create } from "zustand"

export type ManageSectionsState = {
  sections: ChapterSection[]
  globalSections: ChapterSection[]
  deletedSections: ChapterSection[]
  isLoadingSections: boolean
}

export type ManageSectionsActions = {
  setSections: (sections: ChapterSection[]) => void
  setGlobalSections: (globalSections: ChapterSection[]) => void
  setDeletedSections: (deletedSections: ChapterSection[]) => void
  setIsLoading: (isLoadingSections: boolean) => void
}

const useManageSectionsState = create<
  ManageSectionsState & ManageSectionsActions
>((set) => ({
  sections: [],
  globalSections: [],
  deletedSections: [],
  isLoadingSections: false,
  setSections: (sections) => set(() => ({ sections })),
  setGlobalSections: (globalSections) => set(() => ({ globalSections })),
  setIsLoading: (isLoadingSections) => set(() => ({ isLoadingSections })),
  setDeletedSections: (deletedSections) => set(() => ({ deletedSections })),
}))

export default useManageSectionsState
