import React from "react"
import { useNavigate } from "react-router-dom"
import { makeStyles, Theme, Paper } from "@material-ui/core"

import useAppState from "hooksV1/useAppState"

type DocumentCardProps = {
  documentId: string
  documentName: string
  documentVersion: number
}

const useStyles = makeStyles((theme: Theme) => ({
  documentPaper: {
    display: "flex",
    height: "10vw",
    width: "10vw",
    borderRadius: "10px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: theme.palette.action.hover,
    },
    cursor: "pointer",
  },
  titleTextTypography: {
    display: "flex",
    alignItems: "center",
    marginTop: "2.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    flexDirection: "column",
    justifyContent: "fixed",
    textAlign: "center",
    flex: 1,
  },
  documentName: {
    margin: 0,
    fontSize: "1rem",
    fontFamily: "Lato",
    letterSpacing: "0.08rem",
    marginTop: "0.2rem",
  },
}))

const DocumentCard: React.FC<DocumentCardProps> = ({
  documentId,
  documentName,
  documentVersion,
}) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { activeCountry, documents, setActiveDocument } = useAppState()

  let navigate = useNavigate()
  let pathArray = location.pathname.split("/")
  const projectId = pathArray[2]

  const country = activeCountry?.country_name ?? "Global"

  // ==================== Functions ==================== //
  const openDocument = (documentId: string) => {
    navigate(`/project/${projectId}/document/${documentId}/country/${country}`)
    const activeDocument = documents?.find((doc) => doc.id === documentId)
    setActiveDocument(activeDocument)
  }

  return (
    <Paper
      elevation={2}
      key={documentId}
      className={classes.documentPaper}
      onClick={() => openDocument(documentId)}
    >
      <div className={classes.titleTextTypography}>
        <p style={{ fontSize: "11px", margin: 0 }}>Document:</p>
        <p className={classes.documentName}>{documentName}</p>
      </div>

      <p style={{ fontSize: "11px", marginBottom: "0.3rem" }}>Working Version: {documentVersion}</p>
    </Paper>
  )
}

export default DocumentCard
