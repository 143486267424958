import { appSyncApiConfig as config } from "./config"
import { QueryResponse } from "shared/types/queryResponse"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { PartialVersion } from "shared/types-exp"

export const versionsApi = createApi({
  reducerPath: "versionsApi",
  tagTypes: ["Versions"],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", config.apiKey)

      return headers
    },
  }),
  endpoints: (builder) => ({
    getVersion: builder.query<
      QueryResponse<"getVersion">,
      { countryId: string; id: string }
    >({
      query: (params) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetVersion($countryId: ID = "", $id: ID = "") {
              getVersion(countryId: $countryId, id: $id) {
                activeVersion
                author
                countryId
                enabled
                id
                lastUpdated
                name
                refId
                chapters {
                  assignedProjectUser
                  chapterOrder
                  documentVersionId
                  id
                  editing {
                    name
                    email
                    surname
                    isEditing
                  }
                  name
                  refId
                  parentId
                  sections {
                    content {
                      plainText
                      uploadedFiles {
                        id
                        name
                        size
                        type
                        content
                      }
                    }
                    id
                    refId
                    isProject
                    isCountry
                    isClientEditable
                    isGlobal
                    name
                    type
                    viewing
                  }
                  sharedId
                  isIncludedInWorkflow
                  subchapterCreateAllowed
                  treeIndex
                }
                documentVersion 
                  documentId
                  id
                  latestVersion
                  users
                  isGlobal
                  isCountry
                  isProject
                  sections {
                    id
                    refId
                    isClientEditable
                    isGlobal
                    isCountry
                    isProject
                    name
                    type
                    viewing
                  }
                }
              }
            }
          `,
          variables: {
            countryId: params.countryId,
            id: params.id,
          },
        },
      }),
      providesTags: ["Versions"],
    }),
    ListVersionsByCountryIdAndRefId: builder.query<
      QueryResponse<"listVersions">,
      { countryId: string; refId: string }
    >({
      query: (params) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListVersionsByCountryIdAndRefId ($countryId: String = "", $refId: String = "") {
              listVersions(filter: { countryId: {eq: $countryId}, refId: {eq: $refId}}) {
                items {
                  id
                  name
                  countryId
                  activeVersion
                  chapters {
                    id
                    name
                    chapterOrder
                    parentId
                    sections {
                      content {
                        plainText
                        uploadedFiles {
                          id
                          name
                          size
                          type
                          content
                        }
                      }
                      id
                      refId
                      isClientEditable
                      isGlobal
                      isCountry
                      isProject
                      name
                      viewing
                      type
                    }
                  }
                }
              }
            }
          `,
          variables: {
            countryId: params.countryId,
            refId: params.refId,
          },
        },
      }),
      providesTags: ["Versions"],
    }),
    CreateVersions: builder.mutation<
      QueryResponse<"getVersion">,
      PartialVersion
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            mutation CreateVersions(
              $refId: ID = "", 
              $name: String = "", 
              $countryId: ID = "", 
              $author: String = "", 
              $activeVersion: Int = 1, 
              $enabled: Boolean = false
              $lastUpdated: String = "",
              $chapters: [PartialChapterObjectInput] = []
              $documentVersion: PartialDocumentVersionInput!
            ) {
              createVersion(
                input: {
                  refId: $refId,
                  name: $name,
                  author: $author,
                  enabled: $enabled,
                  chapters: $chapters,
                  countryId: $countryId, 
                  lastUpdated: $lastUpdated,
                  activeVersion: $activeVersion,
                  documentVersion: $documentVersion
                }
              ) {
                lastUpdated
                refId
                countryId
                author
                id
                name
                activeVersion
                enabled
                chapters {
                  documentVersionId
                  id
                  refId
                }
                documentVersion {
                  id
                  documentId
                  latestVersion
                }
              }
            }
          `,
          variables: {
            name: data.name,
            refId: data.refId,
            author: data.author,
            enabled: data.enabled,
            chapters: data.chapters,
            countryId: data.countryId,
            lastUpdated: data.lastUpdated,
            activeVersion: data.activeVersion,
            documentVersion: data.documentVersion,
          },
        },
      }),
      invalidatesTags: ["Versions"],
    }),
    batchDeleteVersions: builder.mutation<any, any>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            mutation BatchDeleteVersionsInput($objects: [DeleteVersionsInput!]!) {
              batchDeleteVersions(input: {objects: $objects}) {
                id
                countryId
              }
            }
          `,
          variables: {
            objects: data.objects,
          },
        },
      }),
      invalidatesTags: ["Versions"],
    }),
  }),
})

export const {
  useGetVersionQuery,
  useCreateVersionsMutation,
  useListVersionsByCountryIdAndRefIdQuery,
} = versionsApi

export const versionsUtil = versionsApi.util
