import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem, ListItemText, MenuItem, Tooltip,
} from "@material-ui/core"
import {makeStyles, Theme} from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"

import {TableActionMenu} from "../components/TableActionMenu/TableActionMenu"
import useManageProjectTypesDialog from "../hooksV1/useManageProjectTypesDialog"
import useEditProjectTypeDialog from "../hooksV1/useEditProjectTypeDialog"
import EditProjectTypeDialog from "./EditProjectTypeDialog"
import useAddProjectTypeDialog from "../hooksV1/useAddProjectTypeDialog"
import AddProjectTypeDialog from "./AddProjectTypeDialog"
import useDeleteProjectTypeDialog from "../hooksV1/useDeleteProjectTypeDialog"
import DeleteProjectTypeDialog from "./DeleteProjectTypeDialog"

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButton: {
    marginRight: '2rem',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const ManageProjectTypesDialog: React.FC = () => {
  const classes = useStyles()
  const [menuOpen, setMenuOpen] = useState(false)

  const addProjectTypeDialog = useAddProjectTypeDialog()
  const manageProjectTypesDialog = useManageProjectTypesDialog()
  const editProjectTypeDialog = useEditProjectTypeDialog()
  const deleteProjectTypeDialog = useDeleteProjectTypeDialog()

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      handleClose()
    }
  }

  const handleClose = () => {
    manageProjectTypesDialog.onClose()
  }

  const handleCloseDialog = ( reason: "backdropClick" | "escapeKeyDown" ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return

    handleClose()
  }

  const handleAddProjectType = () => {
    addProjectTypeDialog.onOpen()
  }

  const handleEditProjectType = (projectType: string) => {
    editProjectTypeDialog.onOpen()
    setMenuOpen(false)
  }

  const handleDeleteProjectType = (projectType: string) => {
    deleteProjectTypeDialog.onOpen()
    setMenuOpen(false)
  }

  const projectTypes = [
    "Payroll Implementation",
    "Data Integration",
    "EC Implementation",
    "Time Implementation",
    "Workforce  Implementation",
    "People Solutions",
    "PEX Implementation",
  ]

  return (
    <Dialog
      open={manageProjectTypesDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      onKeyDown={handleKeyDown}
    >
      <div className={classes.dialogTitle}>
        <DialogTitle>Manage Project Types</DialogTitle>
        <Tooltip title="Add Project Type">
          <IconButton
            className={classes.addButton}
            onClick={() => handleAddProjectType()}
          >
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </div>

      <DialogContent dividers>
        <List disablePadding>
          {projectTypes.map((projectType) => (
            <ListItem className={classes.listItem}>
              <ListItemText primary={projectType}/>
              <TableActionMenu
                tooltip="Manage Project Type"
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
              >
                <MenuItem onClick={() => handleEditProjectType(projectType)}>
                  <EditIcon /> &nbsp;Edit Project Type
                </MenuItem>
                <MenuItem onClick={() => handleDeleteProjectType(projectType)}>
                  <DeleteIcon /> &nbsp;Delete Project Type
                </MenuItem>
              </TableActionMenu>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>

      {editProjectTypeDialog.isOpen && <EditProjectTypeDialog />}
      {addProjectTypeDialog.isOpen && <AddProjectTypeDialog />}
      {deleteProjectTypeDialog.isOpen && <DeleteProjectTypeDialog />}
    </Dialog>
  )
}

export default ManageProjectTypesDialog
