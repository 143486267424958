import {
  Grid,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"
import { z } from "zod"
import { useFormik } from "formik"
import { RootState } from "redux/store"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { toFormikValidationSchema } from "zod-formik-adapter"
import React, { useState, useEffect, FormEvent } from "react"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { Status } from "shared/types/status"
import Loader from "../components/Loading/Loader"
import { inviteUser } from "redux/thunks/usersSliceThunk"
import { useCreateAuditTrailMutation } from "redux/services"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useInviteUserDialogV1 from "../hooksV1/useInviteUserDialogV1"
import { createAuditTrailObject } from "util/helper"
import { createAuditTrail } from "util/batchHook"

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5em",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  dialogContent: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
  },
  dialogActions: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "1rem",
  },
}))

const InviteUserDialogV1: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const inviteUserDialog = useInviteUserDialogV1()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const [isLoading, setIsLoading] = useState(false)

  const inviteUserSchema = z.object({
    firstName: z.string().min(1, { message: "First name cannot be empty" }),
    lastName: z.string().min(1, { message: "Last name cannot be empty" }),
    email: z.string().email({ message: "Invalid email" }),
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: toFormikValidationSchema(inviteUserSchema),
    onSubmit: (values) => {
      setIsLoading(true)

      const { email } = values
      const inviteThunk = inviteUser(email)
      dispatch(inviteThunk)
    },
  })

  const theInvitationStatus = useSelector(
    (state: RootState) => state.users.invitationStatus
  )

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  const handleClose = () => {
    formik.resetForm()
    setIsLoading(false)
    inviteUserDialog.onClose()
  }

  useEffect(() => {
    formik.resetForm()
  }, [inviteUserDialog.isOpen])

  useEffect(() => {
    const handleAsync = async () => {
      if (theInvitationStatus === Status.success) {
        const auditTrail: PartialAuditTrail = createAuditTrailObject(
          AuditTrailOperations.INVITE,
          Operations.USER,
          `A user with the email ${formik.values.email} was successfully invited to EPI-CENTER.`
        )

        createAuditTrail(createAuditTrailAPI, auditTrail)

        snackBar.setMessage("Invite Sent Successfully")
        snackBar.setMessageSeverity(SnackType.SnackSuccess)
        snackBar.onOpen()

        handleClose()
      } else if (theInvitationStatus === Status.failed) {
        snackBar.setMessage("Failed to Send Invite. Please try again.")
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        setIsLoading(false)
      }
    }

    handleAsync()
  }, [theInvitationStatus])

  return (
    <Dialog
      open={inviteUserDialog.isOpen}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        Invite user
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <DialogContentText>
          After submission of the form, the invitation will be send to email you
          provided.
        </DialogContentText>
        <form noValidate>
          <Grid spacing={1} container>
            <Grid sm={6} xs={12} item>
              <TextField
                autoComplete="fname"
                id="firstName"
                label="First Name"
                name="firstName"
                variant="outlined"
                autoFocus
                fullWidth
                required
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid sm={6} xs={12} item>
              <TextField
                autoComplete="lname"
                id="lastName"
                label="Last Name"
                name="lastName"
                variant="outlined"
                fullWidth
                required
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                autoComplete="email"
                id="email"
                label="Email Address"
                name="email"
                variant="outlined"
                fullWidth
                required
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) =>
            formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)
          }
          disabled={isLoading || !formik.isValid}
        >
          Add
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default InviteUserDialogV1
