import React, { useState } from "react"

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core"

import ChapterItem from "./ChapterItem"
import useAppState from "hooksV1/useAppState"
import { ChapterObject } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

type ChapterListProps = {
  chaptersProp: ChapterObject[]
  chaptersIdMap: Map<string, ChapterObject>
  setChaptersIdMap: React.Dispatch<
    React.SetStateAction<Map<string, ChapterObject>>
  >
}

const ChapterList: React.FC<ChapterListProps> = ({
  chaptersProp,
  chaptersIdMap,
  setChaptersIdMap,
}) => {
  const snackBar = useSnackBar()
  const { activeCountry } = useAppState()
  const [chapters, setChapters] = useState<ChapterObject[]>(chaptersProp)

  const { isTemplateEnvironment } = checkEnvironment()

  const getErrorMessage = () => {
    if (isTemplateEnvironment && !activeCountry?.global)
      return "Cannot swap chapters from country with chapters from global"

    if (isTemplateEnvironment && activeCountry?.global)
      return "Cannot swap non-global chapters with global chapters"

    return "Cannot swap chapters from template with chapters from template"
  }

  const isFromSameEnvironment = (activeId: string, overId: string): boolean => {
    const activeOject = chaptersIdMap.get(activeId)
    const overOject = chaptersIdMap.get(overId)

    if (activeOject.isProject && overOject.isProject) return true

    if (activeOject.isCountry && overOject.isCountry) {
      return true
    }

    if (activeOject.isGlobal && overOject.isGlobal) {
      return true
    }

    snackBar.setMessage(getErrorMessage())
    snackBar.setMessageSeverity(SnackType.SnackError)
    snackBar.onOpen()

    return false
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!activeCountry?.global) {
      if (
        !isFromSameEnvironment(active.id.toString(), over?.id.toString() || "")
      )
        return
    }

    if (active.id !== over?.id) {
      setChapters((prevChapters) => {
        const oldIndex = prevChapters.findIndex(
          (chapter) => chapter.id === active.id
        )

        const newIndex = prevChapters.findIndex(
          (chapter) => chapter.id === over?.id
        )

        const newChapters = arrayMove(prevChapters, oldIndex, newIndex)

        const data = newChapters.map((chapter, index) => {
          const newChapter = {
            ...chapter,
            chapterOrder: index,
          }

          chaptersIdMap.set(newChapter.id, newChapter)

          return newChapter
        })

        setChaptersIdMap(chaptersIdMap)

        return data
      })
    }
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={chapters.map((chapter) => chapter.id)}
        strategy={verticalListSortingStrategy}
      >
        {chapters.map((chapter) => (
          <ChapterItem
            key={chapter.id}
            chapter={chapter}
            chaptersIdMap={chaptersIdMap}
            setChaptersIdMap={setChaptersIdMap}
          />
        ))}
      </SortableContext>
    </DndContext>
  )
}

export default ChapterList
