import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import { checkEnvironment } from "../util/environment"
import useAppState from "../hooksV1/useAppState"
import { invalidateAll } from "util/helper"
import { useDispatch } from "react-redux"

const useStyles = makeStyles((theme: Theme) => ({
  homeNavButton: {
    color: "#111B43",
    marginRight: "0.75rem",
    padding: "0.75rem",
    borderRadius: 22,
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  environmentNavButton: {
    width: 175,
    color: "white",
    borderRadius: 22,
    overflow: "hidden",
    marginRight: "10px",
    textAlign: "center",
    padding: theme.spacing(1.5, 0.5, 1.5, 0.5),
    "&:hover": {
      backgroundColor: "#87a9ce",
    },
  },
  templateEnvironment: {
    background: "#E8EBED",
    color: "#111B43",
  },
  projectEnvironment: {
    background: "#E8EBED",
    color: "#111B43",
  },
}))

type EnvironmentSelectorProps = {
  hasStorageToken: boolean
}

const EnvironmentSelector: React.FC<EnvironmentSelectorProps> = ({
  hasStorageToken,
}) => {
  const classes = useStyles()
  const appState = useAppState()
  const dispatch = useDispatch()
  const { isProjectEnvironment, isTemplateEnvironment, isHome } =
    checkEnvironment()

  const getFromStorage = (storage: Storage, type: string) => {
    return storage.getItem(type)
  }

  const localStorageRole = getFromStorage(localStorage, "role_name")

  const handleEnvironmentChange = () => {
    invalidateAll(dispatch)
    appState.setDocuments([])
    appState.setCountries([])
    appState.setChapterObjects([])
    appState.setChapterObjectsList([])
    appState.setActiveCountry(null)
    appState.setActiveDocument(null)
    appState.setActiveChapterObject(null)
    appState.setActiveDocumentVersion(null)
  }

  return (
    <>
      {hasStorageToken && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {localStorageRole === "Administrator" && (
            <Link to="/template" onClick={handleEnvironmentChange}>
              <div
                className={
                  isHome ? classes.homeNavButton : classes.environmentNavButton
                }
                style={
                  isTemplateEnvironment
                    ? {
                        background: "#E8EBED",
                        color: "#111B43",
                      }
                    : {}
                }
              >
                Template Environment
              </div>
            </Link>
          )}
          <Link to="/project" onClick={handleEnvironmentChange}>
            <div
              className={
                isHome ? classes.homeNavButton : classes.environmentNavButton
              }
              style={
                isProjectEnvironment
                  ? {
                      background: "#E8EBED",
                      color: "#111B43",
                    }
                  : {}
              }
            >
              Project Environment
            </div>
          </Link>
        </div>
      )}
    </>
  )
}

export default EnvironmentSelector
