import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core"

import Loader from "../components/Loading/Loader"
import useEditProjectTypeDialog from "../hooksV1/useEditProjectTypeDialog"

const EditProjectTypeDialog: React.FC = () => {
  const editProjectTypeDialog = useEditProjectTypeDialog()
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setIsLoading(false)
    editProjectTypeDialog.onClose()
  }

  const handleCloseDialog = ( reason: "backdropClick" | "escapeKeyDown" ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown")) return

    handleClose()
  }

  return (
    <Dialog
      open={editProjectTypeDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Edit Project Type</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          id="name"
          label="Project Type"
          type="text"
          fullWidth
          required
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default EditProjectTypeDialog
