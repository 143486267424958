import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"

import {
  Operations,
  ChapterObject,
  ChapterSection,
  DocumentVersion,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  useCreateAuditTrailMutation,
  useBatchUpdateChapterObjectsMutation,
  useBatchUpdateDocumentVersionsMutation,
  useListCountriesWithNoRefIdQuery,
  useGetFullDocumentVersionWithChaptersV1Query,
} from "redux/services"
import {
  createAuditTrail,
  batchUpdateChapters,
  batchUpdateDocumentsVersions,
} from "util/batchHook"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { defaultAccess } from "util/constants"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import useManageSectionsState from "hooksV1/useManageSections"
import SectionList from "components/ReOrderSection/SectionList"
import { createAuditTrailObject, invalidateAll } from "util/helper"
import useReOrderSectionDialogV1 from "hooksV1/useReOrderSectionsDialogV1"

const ReOrderSectionsDialogV1: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    activeDocumentVersion,
    setActiveDocumentVersion,
  } = useAppState()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const reOrderDialog = useReOrderSectionDialogV1()

  const { sections, setSections } = useManageSectionsState()
  const { isProjectEnvironment } = checkEnvironment()

  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()
  const [batchUpdateDocumentVersionsAPI] =
    useBatchUpdateDocumentVersionsMutation()

  const { data: result, isLoading: isFetchingCountries } =
    useListCountriesWithNoRefIdQuery(null, {
      skip:
        !activeDocumentVersion ||
        !activeCountry ||
        !activeDocument ||
        !activeCountry?.global,
    })

  const { data: docVersionResult, isLoading: isFetchingDocVersion } =
    useGetFullDocumentVersionWithChaptersV1Query(
      {
        id: activeDocumentVersion?.id,
        documentId: activeDocument?.id,
      },
      {
        skip:
          !activeDocumentVersion ||
          !activeCountry ||
          !activeDocument ||
          activeCountry?.global,
      }
    )

  const [isLoading, setIsLoading] = useState(false)
  const [activeDocVersion, setActiveDocVersion] = useState(null)
  const [sectionsProp, setSectionsProp] = useState<ChapterSection[]>(null)
  const [documentsFromCountry, setDocumentsFromCountry] = useState([])

  useEffect(() => {
    if (!result) return

    const countriesData = result?.data?.listCountries?.items

    const docs = []
    countriesData.forEach((country) => {
      const result = country.documents.items.filter(
        (document) => document.refId === activeDocument?.refId
      )
      docs.push(...result)
    })

    setDocumentsFromCountry([...docs])
  }, [result])

  useEffect(() => {
    if (!docVersionResult) return

    const docVersion = docVersionResult.data.getDocumentVersion

    setActiveDocVersion({
      ...docVersion,
      chapters: docVersion.chapters["items"],
    })
  }, [docVersionResult])

  useEffect(() => {
    if (!sections) return

    setSectionsProp(sections)
  }, [sections])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSubmit()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    reOrderDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      const chaptersToUpdate: ChapterObject[] = []
      const activeVersionToUpdate: DocumentVersion[] = []
      let newActiveDocumentVersion: DocumentVersion | null = null

      if (activeCountry?.global) {
        newActiveDocumentVersion = handleGlobalCountry(
          chaptersToUpdate,
          activeVersionToUpdate
        )

        activeVersionToUpdate.forEach((version) => delete version.chapters)
      } else {
        newActiveDocumentVersion = handleNonGlobalCountry(
          activeDocVersion,
          chaptersToUpdate
        )

        const tempActiveDocumentVersion: DocumentVersion = cloneDeep(
          newActiveDocumentVersion
        )

        delete tempActiveDocumentVersion.chapters
        activeVersionToUpdate.push(tempActiveDocumentVersion)
      }

      const auditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.SECTION,
        `The sections were reordered, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
      )

      await Promise.all([
        batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
        batchUpdateDocumentsVersions(
          batchUpdateDocumentVersionsAPI,
          activeVersionToUpdate
        ),
        createAuditTrail(createAuditTrailAPI, auditTrail),
      ])

      setActiveDocumentVersion(newActiveDocumentVersion)
      invalidateAll(dispatch)
      setSections(sectionsProp)

      snackBar.setMessage("Sections Saved Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()
      handleClose()
    } catch (error) {
      logger("ManageSectionsButton", "onSaveSections", error)

      snackBar.setMessage(
        "An error occurred while saving sections. Please try again."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      setIsLoading(false)
    }
  }

  const handleGlobalCountry = (
    chaptersToUpdate: ChapterObject[],
    activeVersionToUpdate: DocumentVersion[]
  ): DocumentVersion | null => {
    let updatedActiveDocumentVersion: DocumentVersion | null = null

    documentsFromCountry.forEach((doc) => {
      const docVersions = doc.documentVersions.items

      if (docVersions.length === 0) return

      // Clone the active version and set default access if needed
      const activeVersion = cloneDeep(docVersions[0])

      // Add non-global sections to the new sections list
      const newSections = [
        ...sectionsProp,
        ...activeVersion.sections.filter((section) => section.isCountry),
      ]

      activeVersion.sections = newSections

      // Check if this is the active document version
      if (activeVersion.id === activeDocumentVersion.id) {
        updatedActiveDocumentVersion = activeVersion
      }

      const docVersionChapters = activeVersion.chapters.items

      if (docVersionChapters.length > 0) {
        updateChapters(docVersionChapters, chaptersToUpdate, newSections)
      }

      activeVersionToUpdate.push(activeVersion)
    })

    return updatedActiveDocumentVersion
  }

  const handleNonGlobalCountry = (
    activeDocVersion: DocumentVersion,
    chaptersToUpdate: ChapterObject[]
  ): DocumentVersion => {
    // Clone the active document version and set default access if needed
    const clonedDocVersion: DocumentVersion = cloneDeep(activeDocVersion)
    clonedDocVersion.access =
      clonedDocVersion.access?.length > 0
        ? clonedDocVersion.access
        : defaultAccess

    // Update sections, change history, and timestamp
    clonedDocVersion.sections = sectionsProp

    // Update chapters based on the provided chapters to update and sections
    updateChapters(clonedDocVersion.chapters, chaptersToUpdate, sectionsProp)

    return clonedDocVersion
  }

  const updateChapters = (
    docVersionChapters: ChapterObject[],
    chaptersToUpdate: ChapterObject[],
    sections: ChapterSection[]
  ) => {
    for (const chapter of docVersionChapters) {
      const updatedSections = sections.map((section) => {
        const existingSection = chapter.sections.find(
          (sec) => sec.id === section.id
        )

        return existingSection
          ? {
              ...existingSection,
              name: section.name,
              type: section.type,
              isGlobal: section.isGlobal,
              isClientEditable: section.isClientEditable,
              content: existingSection.content || {
                plainText: "",
                uploadedFiles: [],
              },
            }
          : {
              ...section,
              content: { plainText: "", uploadedFiles: [] },
            }
      })

      const updatedChapter = {
        ...chapter,
        sections: updatedSections,
      }
      delete updatedChapter.subchapters

      chaptersToUpdate.push(updatedChapter)
    }
  }

  return (
    <Dialog
      open={reOrderDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Re-Order Sections</DialogTitle>
      <DialogContent
        dividers
        style={{
          maxHeight: "70vh",
          overflow: "auto",
        }}
      >
        <SectionList
          sectionsProp={sectionsProp}
          setSectionsProp={setSectionsProp}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          disabled={isLoading || isFetchingCountries || isFetchingDocVersion}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isFetchingCountries || isFetchingDocVersion}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>

      {(isLoading || isFetchingCountries || isFetchingDocVersion) && (
        <Loader open={true} />
      )}
    </Dialog>
  )
}

export default ReOrderSectionsDialogV1
