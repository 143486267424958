import {
  Tab,
  Tabs,
  Theme,
  Dialog,
  Button,
  Select,
  Divider,
  MenuItem,
  TableHead,
  TableCell,
  TableBody,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  DialogContentText,
} from "@material-ui/core"
import {
  Table,
  TableRow,
  Avatar,
  TextField,
  CircularProgress,
} from "@mui/material"
import { cloneDeep } from "lodash"
import React, { useState, useEffect } from "react"

import { logger } from "util/logger"
import TabPanel from "components/TabPanel"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { ChapterObject, Comment } from "shared/types-exp"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import { a11yProps, findChapterByIdAndUpdate } from "util/helper"
import useChapterStatusDialogV1 from "hooksV1/useChapterStatusDialog"
import {
  useGetChapterObjectCommentsQuery,
  useUpdateChapterObjectMutation,
} from "redux/services"
import { AuthHelper } from "util/authHelper"
import { ProjectUserRole } from "shared/types/project"

type SelectStatusComponentProps = {
  type: string
  status: string
  canEdit: boolean
  onChange: (value: any) => void
}

type TabBodyProps = {
  name: string
  type: string
  canEdit: boolean
  activeChapterObject: ChapterObject
  updateChapterFunc: (chapterObject: ChapterObject) => void
  updateChapterObject: (activeChapterObject: ChapterObject) => void
}

type StatusType = "EPIUSE" | "CLIENT"

type StatusUpdate = {
  status: string
  lastUpdated: string
  userLastUpdated: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(5, 5, 5, 5),
  },
  tabContainer: {
    width: "100%",
  },
  button: {
    paddingLeft: "1rem",
  },
  commentSaveBox: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2, 0, 2, 0),
  },
  commentBoxContainer: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  saveBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  commentsBox: {
    minHeight: 0,
    maxHeight: 250,
    width: "100%",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  commentName: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "row",
  },
  commentInfoBox: {
    gap: "0.25rem",
    display: "flex",
    flexDirection: "column",
  },
  commentInfoBoxText: {
    padding: 0,
    margin: 0,
    fontSize: "13px",
    fontWeight: "bold",
  },
  commentInfoBoxTextTimeStamp: {
    padding: 0,
    margin: 0,
    fontSize: "13px",
    fontWeight: "bold",
  },
  commentBox: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: theme.spacing(1, 0, 1, 0),
  },
  loader: {
    width: "100%",
    height: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 0),
  },
  dialogContentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(0, 0, 0, 2),
  },
  dropDown: {
    padding: 0,
  },
  bodyContainer: { gap: 8, margin: theme.spacing(0, 0, 2.5, 0) },
  dialogTitle: { padding: theme.spacing(2.5, 0, 0, 5) },
  dialogActions: { padding: theme.spacing(0, 5, 2.5, 5) },
}))

const SelectStatusComponent: React.FC<SelectStatusComponentProps> = ({
  type,
  status,
  canEdit,
  onChange,
}) => {
  if (type === "EPIUSE") {
    return (
      <Select
        value={status}
        disabled={!canEdit}
        onChange={onChange}
        labelId="demo-simple-select-label"
      >
        <MenuItem value="To be started">To be started</MenuItem>
        <MenuItem value="In Progress (being updated)">
          In Progress (being updated)
        </MenuItem>
        <MenuItem value="In Progress (waiting for client information)">
          In Progress (waiting for client information)
        </MenuItem>
        <MenuItem value="Released for Client Review">
          Released for Client Review
        </MenuItem>
        <MenuItem value="Rejected with Comments">
          Rejected with Comments
        </MenuItem>
        <MenuItem value="Approved by Client">Approved by Client</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
      </Select>
    )
  }

  return (
    <Select
      value={status}
      disabled={!canEdit}
      onChange={onChange}
      labelId="demo-simple-select-label"
    >
      <MenuItem value="To be started">To be started</MenuItem>
      <MenuItem value="In Progress (being updated)">
        In Progress (being updated)
      </MenuItem>
      <MenuItem value="In Progress (waiting for client information)">
        In Progress (waiting for client information)
      </MenuItem>
      <MenuItem value="Waiting for Review">Waiting for Review</MenuItem>
      <MenuItem value="Rejected with Comments">Rejected with Comments</MenuItem>
      <MenuItem value="Approved">Approved/Completed</MenuItem>
    </Select>
  )
}

const TabBody: React.FC<TabBodyProps> = ({
  name,
  type,
  canEdit,
  updateChapterFunc,
  activeChapterObject,
  updateChapterObject,
}) => {
  const { activeCountry, activeProjectUser } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()
  const [currentComment, setCurrentComment] = useState("")
  const [isSavingComment, setIsSavingComment] = useState(false)
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false)

  const surname = localStorage.getItem("surname") as string
  const firstName = localStorage.getItem("firstname") as string

  const comments: Comment[] =
    type === "EPIUSE"
      ? sortCommentsByTimestamp([...activeChapterObject.epi_status.comments])
      : sortCommentsByTimestamp([...activeChapterObject.client_status.comments])

  const canViewComments = () => {
    if (type === "EPIUSE") return true

    if (
      type !== "EPIUSE" &&
      AuthHelper.canSeeClientComments(
        activeChapterObject.assignedProjectUser,
        activeCountry?.id,
        activeProjectUser
      )
    )
      return true

    return false
  }

  function sortCommentsByTimestamp(comments: Comment[]): Comment[] {
    return comments.sort((a, b) => {
      const dateA = new Date(a.timeStamp)
      const dateB = new Date(b.timeStamp)

      return dateB.getTime() - dateA.getTime()
    })
  }

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: StatusType
  ) => {
    const newStatus = event.target.value
    const clonedActiveChapterObject = cloneDeep(activeChapterObject)
    const currentTime = new Date().toISOString()
    const userFullName = `${sessionStorage.getItem(
      "firstname"
    )} ${sessionStorage.getItem("surname")}`

    const updateStatus = (statusObj: StatusUpdate, status: string) => {
      statusObj.status = status
      statusObj.lastUpdated = currentTime
      statusObj.userLastUpdated = userFullName
    }

    if (type === "EPIUSE") {
      handleEPIUseStatusChange(
        clonedActiveChapterObject,
        newStatus,
        updateStatus
      )
    } else {
      handleClientStatusChange(
        clonedActiveChapterObject,
        newStatus,
        updateStatus
      )
    }

    updateChapterObject(clonedActiveChapterObject)
  }

  const handleEPIUseStatusChange = (
    chapter: any,
    newStatus: string,
    updateStatus: (statusObj: StatusUpdate, status: string) => void
  ) => {
    if (newStatus === "Approved by Client") return

    if (newStatus === "Rejected with Comments") return

    if (
      newStatus === "Completed" &&
      chapter.client_status.status !== "Approved"
    )
      return

    updateStatus(chapter.epi_status, newStatus)

    const clientStatusMap: { [key: string]: string } = {
      "To be started": "To be started",
      "Released for Client Review": "Waiting for Review",
      "In Progress (being updated)": "In Progress (being updated)",
      "In Progress (waiting for client information)":
        "In Progress (waiting for client information)",
    }

    if (clientStatusMap[newStatus]) {
      updateStatus(chapter.client_status, clientStatusMap[newStatus])
    }
  }

  const handleClientStatusChange = (
    chapter: any,
    newStatus: string,
    updateStatus: (statusObj: StatusUpdate, status: string) => void
  ) => {
    const invalidStatuses = [
      "To be started",
      "Waiting for Review",
      "In Progress (waiting for client information)",
      "In Progress (being updated)",
    ]

    if (invalidStatuses.includes(newStatus)) return

    updateStatus(chapter.client_status, newStatus)

    if (newStatus === "Approved") {
      updateStatus(chapter.epi_status, "Approved by Client")
    } else if (newStatus === "Rejected with Comments") {
      updateStatus(chapter.epi_status, "Rejected with Comments")
    }
  }

  const handleCommentChange = (event: any) => {
    setCurrentComment(event.target.value)
  }

  const formatDateTime = (dateTimeString: string): string => {
    if (!dateTimeString) return "None"

    const dateTime = new Date(dateTimeString)
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    }

    return dateTime.toLocaleDateString(undefined, options)
  }

  const onTextFieldFocus = (event: any) => {
    setIsSaveButtonEnabled(true)
  }

  const onTextFieldBlur = (event: any) => {
    if (!currentComment) return setIsSaveButtonEnabled(false)
  }

  const onHandleCommentSave = async (event: any) => {
    try {
      setIsSavingComment(true)

      const newComment: Comment = {
        email: localStorage.getItem("email"),
        comment: currentComment,
        lastName: localStorage.getItem("surname"),
        timeStamp: new Date().toISOString(),
        firstName: localStorage.getItem("firstname"),
      }

      const newChapterObject = cloneDeep(activeChapterObject)

      if (type === "EPIUSE") {
        newChapterObject.epi_status.comments.push(newComment)
      } else {
        newChapterObject.client_status.comments.push(newComment)
      }

      delete newChapterObject.subchapters

      await updateChapterFunc(newChapterObject)
      setCurrentComment("")
    } catch (error) {
      logger("ChapterStatusDialogV1", "handleCommentSubmit", error)

      snackBar.setMessage("The comment failed to save. Please try agin.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    } finally {
      setIsSavingComment(false)
    }
  }

  return (
    <div className={classes.bodyContainer}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Date last changed</TableCell>
              <TableCell align="center">User last changed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              <TableCell align="center">
                <SelectStatusComponent
                  type={type}
                  canEdit={canEdit}
                  status={
                    type === "EPIUSE"
                      ? activeChapterObject?.epi_status.status
                      : activeChapterObject?.client_status.status
                  }
                  onChange={(event) =>
                    handleStatusChange(event, type as StatusType)
                  }
                />
              </TableCell>
              <TableCell align="center">
                {type === "EPIUSE"
                  ? formatDateTime(activeChapterObject?.epi_status.lastUpdated)
                  : formatDateTime(
                      activeChapterObject?.client_status.lastUpdated
                    )}
              </TableCell>
              <TableCell align="center">
                {type === "EPIUSE"
                  ? activeChapterObject?.epi_status.userLastUpdated || "None"
                  : activeChapterObject?.client_status.userLastUpdated ||
                    "None"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {canViewComments() && (
        <>
          <Divider />

          <div className={classes.commentSaveBox}>
            <div className={classes.commentBoxContainer}>
              <Avatar
                sx={{
                  width: 40, // Set the desired width
                  height: 40, // Set the desired height
                  fontSize: 16, // Adjust the font size if needed
                  padding: "0.5rem",
                  backgroundColor: "#37017e",
                }}
                alt={`${firstName}`}
                src=""
              >
                {firstName ? `${firstName.charAt(0)}${surname.charAt(0)}` : ""}
              </Avatar>
              <TextField
                multiline
                fullWidth
                maxRows={4}
                disabled={!canEdit}
                InputProps={{
                  sx: {
                    padding: "8px", // Add your padding value here
                  },
                }}
                value={currentComment}
                variant="standard"
                placeholder="Add comment..."
                onChange={(event) => handleCommentChange(event)}
                onFocus={onTextFieldFocus}
                onBlur={onTextFieldBlur}
              />
            </div>
            {isSaveButtonEnabled && (
              <div className={classes.saveBox}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={!isSaveButtonEnabled || isSavingComment}
                  onClick={onHandleCommentSave}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          <div className={classes.commentsBox}>
            {comments.map((comment) => {
              return (
                <div className={classes.commentBox}>
                  <Avatar
                    alt={`${firstName}`}
                    src=""
                    sx={{
                      width: 32, // Set the desired width
                      height: 32, // Set the desired height
                      fontSize: 14, // Adjust the font size if needed
                      padding: "0.5rem",
                      backgroundColor: "#37017e",
                    }}
                  >
                    {firstName
                      ? `${firstName.charAt(0)}${surname.charAt(0)}`
                      : ""}
                  </Avatar>
                  <div className={classes.commentInfoBox}>
                    <div className={classes.commentName}>
                      <p className={classes.commentInfoBoxText}>
                        {comment.firstName} {comment.lastName},{" "}
                      </p>
                      <p className={classes.commentInfoBoxTextTimeStamp}>
                        {formatDateTime(comment.timeStamp)}
                      </p>
                    </div>
                    {comment.comment}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

const ChapterStatusDialog: React.FC = () => {
  const {
    activeProject,
    activeCountry,
    chapterObjects,
    setChapterObjects,
    activeProjectUser,
    activeChapterObject,
    activeDocumentVersion,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()

  const useChapterStatus = useChapterStatusDialogV1()

  const [updateChapterObjectAPI] = useUpdateChapterObjectMutation()
  const { data: chapterComments, isLoading: isCommentsLoading } =
    useGetChapterObjectCommentsQuery(
      {
        id: activeChapterObject?.id,
        documentVersionId: activeChapterObject?.documentVersionId,
      },
      {
        skip: !activeChapterObject,
      }
    )

  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [chapterObject, setChapterObject] = useState(null)
  const [assignedUser, setAssignedUser] = useState("None")
  const [projectUsers, setProjectUsers] = useState<ProjectUserRole[]>([])

  const canEditStatus = AuthHelper.canEditStatus(
    activeChapterObject?.assignedProjectUser,
    activeCountry?.id,
    activeDocumentVersion?.access,
    activeProjectUser
  )
  useEffect(() => {
    if (activeChapterObject && activeProject) {
      setAssignedUser(activeChapterObject.assignedProjectUser || "None")
      setProjectUsers(activeProject.users || [])
    }
  }, [activeChapterObject, activeProject])

  useEffect(() => {
    if (!chapterComments) return

    const chapterObject = chapterComments.data.getChapterObject

    const newChapterObject = cloneDeep(activeChapterObject)
    newChapterObject.epi_status.comments =
      chapterObject.epi_status.comments || []
    newChapterObject.client_status.comments =
      chapterObject.client_status.comments || []

    setChapterObject(newChapterObject)
  }, [chapterComments])

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setAssignedUser(event.target.value as string)
    setChapterObject({
      ...chapterObject,
      assignedProjectUser: event.target.value as string,
    })
  }

  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSubmit()
    }
  }

  const handleClose = () => {
    setIsLoading(false)

    useChapterStatus.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const hasBeenUpdated = (): boolean => {
    return (
      activeChapterObject?.epi_status.status !==
        chapterObject?.epi_status.status ||
      activeChapterObject?.client_status.status !==
        chapterObject?.client_status.status ||
      activeChapterObject?.assignedProjectUser !== assignedUser
    )
  }

  const updateChapterFunc = async (chapterObject: ChapterObject) => {
    try {
      setIsLoading(true)

      const response: any = await updateChapterObjectAPI(chapterObject)

      if (response.data?.errors) {
        throw Error(
          `${JSON.stringify(
            response.data.errors
          )}, Operation: [Update Chapter], Data Passed: ${chapterObject}`
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      const clonedChapter = cloneDeep(chapterObject)

      delete clonedChapter.subchapters

      await updateChapterFunc(clonedChapter)

      const clonedChapters = cloneDeep(chapterObjects)

      findChapterByIdAndUpdate(clonedChapters, chapterObject)
      setActiveChapterObject(chapterObject)
      setChapterObjects(clonedChapters)

      snackBar.setMessage("Chapter status successfully updated.")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("ChapterStatusDialogV1", "handleSubmit", error)

      snackBar.setMessage("Chapter status update failed. Please try agin.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const displayBody = () => {
    if (isCommentsLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )
    }

    if (!chapterObject) {
      return (
        <div className={classes.loader}>No Chapter Information Available</div>
      )
    }

    const canEditClientStatus = AuthHelper.canEditClientStatus(
      activeChapterObject?.assignedProjectUser,
      activeCountry?.id,
      activeDocumentVersion?.access,
      activeProjectUser
    )

    return (
      <>
        <TabPanel value={value} index={0} key={0}>
          <TabBody
            key={0}
            type="EPIUSE"
            name="Epi-Use Status"
            canEdit={canEditStatus}
            activeChapterObject={chapterObject}
            updateChapterFunc={updateChapterFunc}
            updateChapterObject={setChapterObject}
          />
        </TabPanel>
        <TabPanel value={value} index={1} key={1}>
          <TabBody
            key={1}
            type="CLIENT"
            name="Client Status"
            canEdit={canEditClientStatus}
            activeChapterObject={chapterObject}
            updateChapterFunc={updateChapterFunc}
            updateChapterObject={setChapterObject}
          />
        </TabPanel>
      </>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title"
      open={useChapterStatus.isOpen}
      onClose={handleCloseDialog}
      className={classes.container}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Chapter Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          <div>View Current Chapter Status</div>

          <div>
            <>Assigned User: </>
            <Select
              value={assignedUser}
              onChange={handleOnChange}
              disabled={!canEditStatus}
              className={classes.dropDown}
            >
              <MenuItem key="None" value="None">
                None
              </MenuItem>
              {projectUsers.map((projectUser) => {
                return (
                  <MenuItem
                    key={projectUser.user.id}
                    value={projectUser.user.id}
                  >
                    {`${projectUser.user.firstname} ${projectUser.user.surname}`}
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        </DialogContentText>
        <div className={classes.tabContainer}>
          <Tabs
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="edit user tab"
            style={{
              marginBottom: "0.25rem",
            }}
          >
            <Tab label="Epi-Use" {...a11yProps(0)} />
            <Tab label="Client" {...a11yProps(1)} />
          </Tabs>
          <Divider />
          {displayBody()}
        </div>
        <Divider />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="text"
          color="primary"
          size="large"
          disabled={isLoading}
          onClick={useChapterStatus.onClose}
        >
          Close
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isCommentsLoading || !hasBeenUpdated()}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>

      {(isLoading || isCommentsLoading) && <Loader open={true} />}
    </Dialog>
  )
}

export default ChapterStatusDialog
