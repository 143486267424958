import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"

import {
  Operations,
  ChapterObject,
  DocumentVersion,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  useCreateAuditTrailMutation,
  useDeleteSectionAttachmentsMutation,
  useBatchUpdateChapterObjectsMutation,
  useListCachedCountriesWithNoRefIdQuery,
  useBatchUpdateDocumentVersionsMutation,
  useGetFullDocumentVersionWithChaptersV1Query,
} from "redux/services"
import {
  createAuditTrail,
  batchUpdateChapters,
  batchUpdateDocumentsVersions,
} from "util/batchHook"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { defaultAccess } from "util/constants"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import useManageSectionsState from "hooksV1/useManageSections"
import useEditSectionDialogV1 from "hooksV1/useEditSectionDialogV1"
import {
  createAuditTrailObject,
  getImageTagsFromPlainText,
  invalidateAll,
} from "util/helper"

const EditSectionDialog: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    activeDocumentVersion,
    setActiveDocumentVersion,
  } = useAppState()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const editSection = useEditSectionDialogV1()
  const { sections } = useManageSectionsState()
  const { isProjectEnvironment } = checkEnvironment()

  const chaptersVisited = new Set<string>()
  const filesMap = new Map<string, string>()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [deleteSectionAttachments] = useDeleteSectionAttachmentsMutation()

  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()
  const [batchUpdateDocumentVersionsAPI] =
    useBatchUpdateDocumentVersionsMutation()
  const { data: countriesListAll, isLoading: isCountriesLoading } =
    useListCachedCountriesWithNoRefIdQuery(null, {
      skip: !activeCountry?.global,
    })

  const { data: documentVersionData, isLoading: isFetchingDocVersionData } =
    useGetFullDocumentVersionWithChaptersV1Query(
      { documentId: activeDocument?.id, id: activeDocumentVersion?.id },
      {
        skip:
          !activeDocument || !activeDocumentVersion || activeCountry?.global,
      }
    )

  const [isLoading, setIsLoading] = useState(false)
  const [activeDocVersion, setActiveDocVersion] = useState(null)
  const [documentsFromCountry, setDocumentsFromCountry] = useState([])

  useEffect(() => {
    if (!countriesListAll) return

    const countriesData = countriesListAll?.data?.listCountries?.items

    const docs = []

    countriesData.forEach((country) => {
      const result = country.documents.items.find(
        (document) => document.refId === activeDocument?.refId
      )

      if (result) docs.push(result)
    })

    setDocumentsFromCountry(docs)
  }, [countriesListAll])

  useEffect(() => {
    if (!documentVersionData) return

    const docVersion = documentVersionData.data.getDocumentVersion

    setActiveDocVersion({
      ...docVersion,
      chapters: docVersion.chapters["items"],
    })
  }, [documentVersionData])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await onSaveSections()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    editSection.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (
      (isLoading || isCountriesLoading) &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    )
      return

    handleClose()
  }

  const onSaveSections = async () => {
    try {
      setIsLoading(true)

      const chaptersToUpdate: ChapterObject[] = []
      const documentVersionsToUpdate: DocumentVersion[] = []
      let updatedDocumentVersion: DocumentVersion | null = null

      if (activeCountry?.global) {
        updatedDocumentVersion = handleGlobalCountry(
          chaptersToUpdate,
          documentVersionsToUpdate
        )
      } else {
        updatedDocumentVersion = handleNonGlobalCountry(
          activeDocVersion,
          chaptersToUpdate
        )

        const tempVersion: DocumentVersion = cloneDeep(updatedDocumentVersion)
        delete tempVersion.chapters
        documentVersionsToUpdate.push(tempVersion)
      }

      const auditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.SECTION,
        `The section information was updated in the ${activeDocument?.name} document in ${activeCountry?.country_name} in the ${environment} environment.`
      )

      const deleteAttachmentsPostBody = Array.from(filesMap.entries()).map(
        ([key, value]) => ({
          id: key,
          content: value,
        })
      )

      if (deleteAttachmentsPostBody.length > 0) {
        const result: any = await deleteSectionAttachments({
          files: deleteAttachmentsPostBody,
        })

        // TODO: Error handle
        console.log(result)
      }

      await Promise.all([
        batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
        batchUpdateDocumentsVersions(
          batchUpdateDocumentVersionsAPI,
          documentVersionsToUpdate
        ),
        createAuditTrail(createAuditTrailAPI, auditTrail),
      ])

      setActiveDocumentVersion(updatedDocumentVersion)
      invalidateAll(dispatch)

      snackBar.setMessage("Sections Saved Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("EditSectionDialog", "onSaveSections", error)

      snackBar.setMessage(
        "An error occurred while saving sections. Please try again."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const handleGlobalCountry = (
    chaptersToUpdate: ChapterObject[],
    activeVersionsToUpdate: DocumentVersion[]
  ): DocumentVersion | null => {
    let updatedActiveDocumentVersion: DocumentVersion | null = null

    documentsFromCountry.forEach((doc) => {
      const docVersions = doc.documentVersions.items

      if (docVersions.length === 0) return

      const activeVersion = cloneDeep(docVersions[0])

      // review this function
      const newSections = [
        ...cloneDeep(sections),
        ...activeVersion.sections.filter((section) => section.isCountry),
      ]

      activeVersion.sections = newSections

      if (activeVersion.id === activeDocumentVersion.id) {
        updatedActiveDocumentVersion = activeVersion
      }
      // review this function

      updateChapters(
        activeVersion,
        activeVersion.chapters.items,
        chaptersToUpdate,
        newSections
      )

      delete activeVersion.chapters
      activeVersionsToUpdate.push(activeVersion)
    })

    return updatedActiveDocumentVersion
  }

  const handleNonGlobalCountry = (
    activeDocVersion: DocumentVersion,
    chaptersToUpdate: ChapterObject[]
  ): DocumentVersion => {
    const updatedDocumentVersion = cloneDeep({
      ...activeDocVersion,
      access:
        activeDocumentVersion.access?.length > 0
          ? activeDocumentVersion.access
          : defaultAccess,
    })

    updatedDocumentVersion.sections = sections

    updateChapters(
      activeDocVersion,
      updatedDocumentVersion.chapters,
      chaptersToUpdate,
      sections
    )

    return updatedDocumentVersion
  }

  const updateChapters = (
    activeVersion,
    docVersionChapters,
    chaptersToUpdate,
    sections
  ) => {
    const sectionMap = new Map(sections.map((section) => [section.id, section]))

    for (const chapter of docVersionChapters) {
      const newSectionsList = []
      const newChapter = cloneDeep(chapter)

      for (const section of newChapter.sections) {
        if (sectionMap.has(section.id)) continue // checks which section is being removed

        if (!activeCountry?.global && section.isGlobal) continue

        if (
          !section.isGlobal &&
          activeCountry?.global &&
          activeVersion.documentId === activeDocument.id
        )
          continue

        if (
          section.isGlobal &&
          activeCountry?.global &&
          activeVersion.documentId !== activeDocument.id
        )
          continue

        if (
          section.type === "attachment" &&
          section.content.uploadedFiles.length > 0
        ) {
          section.content.uploadedFiles.map((file) => {
            if (!filesMap.has(file.id)) filesMap.set(file.id, file.content)
          })

          continue
        }

        const templateImgTags = getImageTagsFromPlainText(
          section.content.plainText
        )

        if (section.type === "text" && templateImgTags.length > 0) {
          templateImgTags.forEach((img) => {
            if (img.src.includes("uploaded-files-s3") && img.id) {
              const file = {
                id: img.id,
                content: img.src,
              }

              // Add to filesMap if it doesn't already contain the id
              if (!filesMap.has(file.id)) {
                filesMap.set(file.id, file.content)
              }
            }
          })
        }
      }

      for (const section of sections) {
        const index = newChapter.sections.findIndex((x) => x.id === section.id)

        if (index !== -1) {
          newSectionsList.push({
            ...newChapter.sections[index],
            name: section.name,
            type: section.type,
            isGlobal: section.isGlobal,
            isClientEditable: section.isClientEditable,
            content: newChapter.sections[index].content || {
              plainText: "",
              uploadedFiles: [],
            },
          })
        } else {
          newSectionsList.push({
            ...section,
            content: {
              plainText: "",
              uploadedFiles: [],
            },
          })
        }
      }

      newChapter.sections = newSectionsList
      delete newChapter.subchapters
      chaptersToUpdate.push(newChapter)

      chaptersVisited.add(chapter.refId)
    }
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={editSection.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Save Sections</DialogTitle>
      <DialogContent dividers>
        <div style={{ textAlign: "center", fontSize: "16px" }}>
          Are you sure you want to save the sections?
          <br />
          <br />
          <b> Warning:</b>{" "}
          <i style={{ color: "orange" }}>
            <b>
              {" "}
              Saving these changes will affect all the chapters of this
              document. Please ensure that no one is editing this or subsequent
              documents, before making these changes.
            </b>
          </i>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isLoading || isCountriesLoading || isFetchingDocVersionData}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSaveSections}
          disabled={isLoading || isCountriesLoading || isFetchingDocVersionData}
        >
          Save
        </Button>
      </DialogActions>

      {(isLoading || isCountriesLoading || isFetchingDocVersionData) && (
        <Loader open={true} />
      )}
    </Dialog>
  )
}

export default EditSectionDialog
