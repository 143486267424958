import React, { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { isExpired } from "react-jwt"
import { useDispatch } from "react-redux"
import { useNavigate, Navigate, Outlet } from "react-router-dom"
import { logout, refreshToken } from "redux/thunks/authorizationThunk"

const RestrictedRoute: React.FC = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [token, setToken] = useState(localStorage.getItem("token"))

  const handleOnIdle = () => {
    dispatch(logout())
    sessionStorage.removeItem("token")
    localStorage.removeItem("token")
    localStorage.removeItem("token_expiry")
    sessionStorage.clear()
    localStorage.clear()
    setToken(null)
    navigate("/")
  }

  const handleOnActive = () => {
    getRemainingTime()
    getLastActiveTime()
  }

  const handleOnAction = () => {
    //
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      type: "localStorage",
      channelName: "idle-timer",
      fallbackInterval: 2000,
      responseTime: 100,
      removeTimeout: 1000 * 60,
      emitOnAllTabs: true,
    },
  })

  useEffect(() => {
    const checkTokenExpiry = () => {
      const token = localStorage.getItem("token")
      const tokenExpiry = localStorage.getItem("token_expiry")

      if (token && tokenExpiry) {
        const now = new Date().getTime()

        if (now >= parseInt(tokenExpiry)) {
          dispatch(logout())
          sessionStorage.removeItem("token")
          localStorage.removeItem("token")
          localStorage.removeItem("token_expiry")
          setToken(null)
          navigate("/signin")
        } else if (isExpired(token)) {
          dispatch(refreshToken(token))
        }
      }
    }

    const syncLogoutAcrossTabs = (event) => {
      if (event.key === "token" && event.newValue === null) {
        dispatch(logout())
        sessionStorage.clear()
        setToken(null)
        navigate("/signin")
      }
    }

    window.addEventListener("storage", syncLogoutAcrossTabs)
    checkTokenExpiry()

    return () => {
      window.removeEventListener("storage", syncLogoutAcrossTabs)
    }
  }, [dispatch, navigate])

  return token ? <Outlet /> : <Navigate to="/signin" replace />
}

export default RestrictedRoute
