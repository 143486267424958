import {
  Theme,
  Dialog,
  Button,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core"
import { RootState } from "redux/store"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { Status } from "shared/types/status"
import { createAuditTrailObject } from "util/helper"
import { useCreateAuditTrailMutation } from "redux/services"
import useEditProjectState from "hooksV1/useEditProjectState"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import { editProjectInfoOnDB } from "redux/thunks/projectsThunk"
import useEditProjectDialogV1 from "hooksV1/useEditProjectDialogV1"
import { Project, ProjectUserRole } from "shared/types/project"
import EditProjectDialogBody from "components/EditProjectDialog/EditProjectDialogBody"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(5, 5, 5, 5),
  },
  button: {
    paddingLeft: "1rem",
  },
  dialogTitle: { padding: theme.spacing(2.5, 0, 0, 5) },
  dialogContentText: { padding: theme.spacing(0, 0, 0, 2) },
  dialogActions: { padding: theme.spacing(0, 5, 2.5, 5) },
}))

export type EditProjectDialogProps = {
  project: Project
}

const EditProjectDialogV1: React.FC = () => {
  const classes = useStyles()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const useEditProject = useEditProjectDialogV1()
  const useEditProjectStateHook = useEditProjectState()

  let auditTrail: PartialAuditTrail = null
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const updateProjectStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )

  useEffect(() => {
    if (updateProjectStatus === Status.success) {
      snackBar.setMessage("Project Updated Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } else if (updateProjectStatus === Status.failed) {
      snackBar.setMessage("Project Update Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }, [updateProjectStatus])

  const handleSubmit = async () => {
    let isValid = true
    let newProjectName = useEditProjectStateHook.newProjectName
      .trim()
      .toLowerCase()
    let newAuthorName = useEditProjectStateHook.newAuthorName
      .trim()
      .toLowerCase()

    if (newAuthorName === "") {
      useEditProjectStateHook.setAuthorError(true)
      useEditProjectStateHook.setAuthorHelperText(
        "Project Author can not be empty"
      )
      isValid = false
    }

    if (newProjectName.length < 3) {
      useEditProjectStateHook.setProjectNameError(true)
      useEditProjectStateHook.setProjectNameHelperText(
        "Project name has to be at least 3 characters long"
      )
      isValid = false
    }

    if (!isValid) return

    setIsLoading(true)
    useEditProjectStateHook.setAuthorError(false)
    useEditProjectStateHook.setAuthorHelperText("")
    useEditProjectStateHook.setProjectNameError(false)
    useEditProjectStateHook.setProjectNameHelperText("")

    let newProjectUsers: ProjectUserRole[] =
      useEditProjectStateHook.projectUsers.filter(
        (user) => user.role.length > 0
      )

    let newProject: Project = {
      ...useEditProjectStateHook.activeProject,
      author: useEditProjectStateHook.newAuthorName,
      name: useEditProjectStateHook.newProjectName,
      lastUpdated: new Date().toISOString(),
      value: useEditProjectStateHook.newProjectName,
      users: newProjectUsers,
      countries: [],
      documents: [],
    }

    auditTrail = createAuditTrailObject(
      AuditTrailOperations.UPDATE,
      Operations.PROJECT,
      `The project ${newProject.name} was edited`
    )

    const editProjectThunk = editProjectInfoOnDB(newProject)
    dispatch(editProjectThunk)

    createAuditTrailAPI(auditTrail)
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSubmit()
    }
  }

  const handleClose = () => {
    useEditProject.onClose()
    setIsLoading(false)
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={useEditProject.isOpen}
      onClose={handleCloseDialog}
      className={classes.container}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle className={classes.dialogTitle}>Edit Project</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          View/Change Project Details and Users
        </DialogContentText>
        <EditProjectDialogBody />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="text"
          color="primary"
          size="large"
          disabled={updateProjectStatus === Status.loading || isLoading}
          onClick={useEditProject.onClose}
        >
          Cancel
        </Button>
        <span className={classes.button} />
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={updateProjectStatus === Status.loading || isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProjectDialogV1
