import { cloneDeep } from "lodash"
import React, { useState, useEffect, useRef } from "react"
import {
  Radio,
  Button,
  Dialog,
  FormLabel,
  RadioGroup,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import ReactDiffViewer from "react-diff-viewer"
import { makeStyles, Theme } from "@material-ui/core/styles"

import { listChaptersByProjectRefId } from "graphql/queries"
import {
  useCreateAuditTrailMutation,
  useSyncContentOperationMutation,
} from "redux/services"
import {
  Operations,
  ChapterObject,
  ChapterSection,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { generateClient } from "aws-amplify/api"
import { createAuditTrail } from "util/batchHook"
import { SyncContent } from "shared/types-exp/sync"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useSyncContentDialogV1 from "hooksV1/useSyncContentDialog"
import { createAuditTrailObject, invalidateAll } from "util/helper"

const client = generateClient()

type SyncType = "Global" | "Country"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "40vw",
    maxHeight: "60vh",
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  contentCountry: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}))

const SyncContentDialog: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    activeChapterObject,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const syncContentDialog = useSyncContentDialogV1()

  const fetchInitiated = useRef(false)
  const [showNext, setShowNext] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState<SyncType>("Global")

  const [chapterObjectsToUpdate, setChapterObjectsToUpdate] =
    useState<ChapterObject>(null)
  const [syncContentModel, setSyncContentModel] = useState<SyncContent>(null)

  const [syncContentApi] = useSyncContentOperationMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  useEffect(() => {
    if (!activeCountry || !activeDocument || fetchInitiated.current) return

    const fetchCountryByProjectRef = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: listChaptersByProjectRefId,
          variables: {
            filter: {
              projectRefId: {
                eq: "",
              },
              country_name: {
                eq: activeCountry?.country_name,
              },
            },
          },
        })

        parseCountriesList(result)
      } catch (error) {
        logger(
          "SyncContentDialog",
          "handleNext (fetchCountryByProjectRef)",
          error
        )

        snackBar.setMessage(
          "An error occurred fetching the list of countries. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    fetchCountryByProjectRef()
  }, [activeCountry, activeDocument])

  const parseCountriesList = (countriesList: any) => {
    const countries = countriesList?.data?.listCountries?.items

    const country = countries[0]
    const documents = country.documents.items

    const document = documents.find(
      (doc) => activeDocument?.refId === doc.refId
    )

    if (!document) {
      snackBar.setMessage(
        `${activeDocument?.name} not found in template. Please sync documents.`
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      handleClose()

      return
    }

    const activeVersion = document?.documentVersions.items[0]
    const chapters = activeVersion.chapters.items as ChapterObject[]

    const updatableChapter = chapters.find(
      (chapter) => chapter.refId === activeChapterObject.refId
    )

    if (!updatableChapter) {
      snackBar.setMessage(
        `Error occurred while syncing ${value} content. Chapter was not found`
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      return
    }

    const syncContentModel: SyncContent = {
      type: "Global",
      templateChapterDocV: updatableChapter?.documentVersionId,
      templateChapterId: updatableChapter?.id,
      projectChapterDocV: activeChapterObject.documentVersionId,
      projectChapterId: activeChapterObject?.id,
    }

    setSyncContentModel(syncContentModel)
    setChapterObjectsToUpdate(updatableChapter)
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleNext()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    syncContentDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as SyncType)
  }

  const handleBack = () => {
    setShowNext(false)
  }

  const handleNext = async () => {
    if (!showNext) setShowNext(true)
    else {
      try {
        setIsLoading(true)

        const tempSyncModel: SyncContent = { ...syncContentModel }
        tempSyncModel.type = value

        const response: any = await syncContentApi(tempSyncModel)

        if (
          response.error ||
          (response.error?.status && response.error.status.toString() !== "200")
        ) {
          throw Error(
            `${JSON.stringify(
              response.error.data
            )}, Operation: [SyncContentDialog], Data Passed: ${JSON.stringify(
              tempSyncModel
            )}`
          )
        }

        const auditTrail: PartialAuditTrail = createAuditTrailObject(
          AuditTrailOperations.SYNC,
          Operations.SYNC_CONTENT,
          `Executed the Sync Content operation in ${activeCountry?.country_name}, in the ${activeDocument?.name} document, in the ${activeChapterObject?.name} chapter`
        )

        await createAuditTrail(createAuditTrailAPI, auditTrail)
        invalidateAll(dispatch)
        setActiveChapterObject(response.data?.body?.chapter)

        snackBar.setMessage(`${value} Content Sync Successful`)
        snackBar.setMessageSeverity(SnackType.SnackSuccess)
        snackBar.onOpen()

        handleClose()
      } catch (error) {
        logger("SyncContentDialog", "handleSync", error)

        snackBar.setMessage(`Error occurred while syncing ${value} content`)
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
        setIsLoading(false)
      }
    }
  }

  const fileDiff = (filesA, filesB, color) =>
    filesA
      .filter((fileA) => !filesB.some((fileB) => fileB.id === fileA.id))
      .map((file) => (
        <p key={file.id} style={{ color }}>
          {file.name}
        </p>
      ))

  const getContentDifference = (sectionType) => {
    const isGlobalSection = sectionType === "Global"
    const newActiveChapter = cloneDeep(activeChapterObject)
    let filteredSections: ChapterSection[] = []

    if (chapterObjectsToUpdate?.sections) {
      if (isGlobalSection) {
        filteredSections = chapterObjectsToUpdate.sections.filter(
          (section) => section.isGlobal
        )
      } else {
        filteredSections = chapterObjectsToUpdate.sections.filter(
          (section) => !section.isGlobal
        )
      }
    }

    return filteredSections.map((section) => {
      const oldSection = newActiveChapter.sections.find(
        (sec) => sec.id === section.id
      )

      if (section.type === "attachment" && !oldSection) {
        return (
          <div key={section.id}>
            <h3>Section: {section.name}</h3>

            <>
              {section.content.uploadedFiles.length === 0 ? (
                <p>No attachments present</p>
              ) : (
                section.content.uploadedFiles.map((file) => (
                  <p key={file.id} style={{ color: "green" }}>
                    {file.name}
                  </p>
                ))
              )}
            </>
          </div>
        )
      }

      if (section.type === "attachment" && oldSection) {
        const filesToDelete = fileDiff(
          oldSection.content.uploadedFiles,
          section.content.uploadedFiles,
          "red"
        )

        const filesToUpdate = fileDiff(
          section.content.uploadedFiles,
          oldSection.content.uploadedFiles,
          "green"
        )

        if (filesToDelete.length === 0 && filesToUpdate.length === 0) {
          return (
            <div key={section.id}>
              <h3>Section: {section.name}</h3>
              <p>No attachments present</p>
            </div>
          )
        }

        return (
          <div key={section.id}>
            <h3>Section: {section.name}</h3>

            <>{filesToDelete}</>
            <>{filesToUpdate}</>
          </div>
        )
      }

      const oldContent = oldSection?.content.plainText || ""
      const newContent = section?.content.plainText || ""

      return (
        <div key={section.id}>
          <h3>Section: {section.name}</h3>
          {filteredSections.length !== 0 && (
            <ReactDiffViewer
              oldValue={oldContent}
              newValue={newContent}
              splitView={true}
            />
          )}
          {oldContent === newContent && <div>No new content available.</div>}
        </div>
      )
    })
  }

  return (
    <Dialog
      open={syncContentDialog.isOpen}
      onClose={handleCloseDialog}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Sync Selection</DialogTitle>
      <DialogContent className={classes.container} dividers>
        {showNext ? (
          <div className={classes.contentCountry}>
            <h3>
              Syncing: {activeDocument?.name} - {activeChapterObject?.name}{" "}
              {value} Content with Templates
            </h3>
            {getContentDifference(value)}
          </div>
        ) : (
          <FormControl>
            <FormLabel>Please select a sync type:</FormLabel>
            <RadioGroup value={value} onChange={handleChange}>
              <FormControlLabel
                value="Global"
                control={<Radio />}
                label="Sync Global Content"
              />
              <FormControlLabel
                value="Country"
                control={<Radio />}
                label="Sync Country Content"
              />
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        {showNext ? (
          <>
            <Button onClick={handleBack} color="primary" disabled={isLoading}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isLoading}
            >
              Sync
            </Button>
          </>
        ) : (
          <>
            <Button onClick={syncContentDialog.onClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleNext}
              disabled={isLoading}
            >
              Next
            </Button>
          </>
        )}
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default SyncContentDialog
