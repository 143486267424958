import {
  Theme,
  Table,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import React, { useRef, useState } from "react"
import FileDownloadIcon from "@mui/icons-material/FileDownload"

import { PartialVersion, Version } from "shared/types-exp"
import ExportVersionDialogV1 from "dialogsV1/ExportVersionDialogV1"
import useExportVersionDialogV1 from "hooksV1/useExportVersionDialogV1"

type VersionsTableBodyV1Props = {
  page: number
  currentVersion: PartialVersion
  versions: Version[]
  rowsPerPage: number
}

type VersionsTableRowProps = {
  index: number
  version: Version
  openExport: (version: Version, fileType: string ) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: "auto",
  },
  currentVersionContainer: {
    backgroundColor: "#f5f5f5",
  },
  exportContainer: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  versionNumber: {
    paddingLeft: "2rem",
  },
}))

const VersionsTable: React.FC<VersionsTableBodyV1Props> = ({
  page,
  currentVersion,
  versions,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const exportVersion = useExportVersionDialogV1()
  const currentFileType = useRef("docx")
  const currentVersionRef = useRef(null)

  const onOpenExport = (version: Version, fileType: string) => {
    currentFileType.current = fileType
    currentVersionRef.current = version
    exportVersion.onOpen()
  }

  const convertCurrentVersion = (partialVersion: PartialVersion): Version => {
    return {
      activeVersion: partialVersion?.activeVersion,
      chapters: partialVersion?.chapters?.map(chapter => ({
        id: chapter.id,
        name: chapter.name,
        chapterOrder: chapter.chapterOrder,
        parentId: chapter.parentId,
        sections: chapter.sections,
      })),
      countryId: partialVersion?.countryId,
      id: partialVersion?.documentVersion.id || "",
      name: partialVersion?.name,
    } as Version;
  }

  const downloadableCurrentVersion = convertCurrentVersion(currentVersion)
  const updatedVersions = [downloadableCurrentVersion, ...versions]

  return (
  <>
    <Table aria-label="collapsible table" className={classes.table}>
      <TableBody>
        {updatedVersions.length > 0 &&
          updatedVersions
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((version, i) => {

              return (
                <VersionsTableRow
                  version={version}
                  index={i}
                  key={version.id}
                  openExport={onOpenExport}
                />
              )
            })}
      </TableBody>
    </Table>

    {exportVersion.isOpen && (
      <ExportVersionDialogV1 
        version={currentVersionRef.current} 
        fileType={currentFileType.current} 
      />
    )}
  </>
  )
}

const VersionsTableRow: React.FC<VersionsTableRowProps> = ({
  index,
  version,
  openExport,
}) => {
  const classes = useStyles()
  const [fileType, setFileType] = useState<string>("docx")

  return (
    <TableRow key={`${version.id}-${index}`} className={index===0 ? classes.currentVersionContainer : ""}>
      <TableCell component="th">
        <Typography className={classes.versionNumber}>Version: {version.activeVersion} {index === 0 ? <i>(current)</i> : ""}</Typography>
      </TableCell>
      <TableCell component="th">
        <div className={classes.exportContainer}>
          <Select
            value={fileType}
            onChange={(event) => {
              setFileType(event.target.value as string)
            }}
            style={{ width: "5rem" }}
          >
            {/* <MenuItem value={"pdf"}>PDF</MenuItem> */}
            <MenuItem value={"docx"}>DOCX</MenuItem>
            <MenuItem value={"xlsx"}>XLSX</MenuItem>
          </Select>
          <Tooltip title="Export">
            <IconButton
              color="primary"
              onClick={() => {
                openExport(version, fileType)
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default VersionsTable
