import React, { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme } from "@material-ui/core/styles"

import useAppState from "hooksV1/useAppState"
import { Project } from "shared/types/project"
import { getProjects } from "redux/slices/projects"
import { containsPathProjectHome } from "util/helper"
import SideNaveV1 from "components/SideNav/SideNaveV1"
import DocumentsToolBar from "components/DocumentsToolBar"
import { fetchProjectsByUserV2 } from "redux/thunks/projectsThunk"

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flexGrow: 1,
    padding: "0.75rem",
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "0.75rem",
  },
  sideNav: {
    width: "15%",
    height: "100%",
  },
  body: {
    flex: 1,
    height: "100%",
  },
}))

const ProjectHomeV1: React.FC = () => {
  const classes = useStyles()
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    activeCountry,
    activeProject,
    setActiveProject,
    setActiveProjectUser,
  } = useAppState()

  const projects: Project[] = useSelector(getProjects)

  const pathArray = location.pathname.split("/")
  const projectId = pathArray[2]

  useEffect(() => {
    if (projects.length > 0) {
      const activeProject = projects.find((project) => project.id === projectId)

      setActiveProject(activeProject)

      const activeUser = activeProject?.users.find(
        (user) => user.user.id === sessionStorage.getItem("id")
      )

      setActiveProjectUser(activeUser)
    }
  }, [projects])

  useEffect(() => {
    if (projects.length === 0) onFetchProjects()

    const currentPath = pathArray[pathArray.length - 1]

    if (containsPathProjectHome(currentPath) && activeCountry !== null) {
      navigate(
        `/project/${activeProject?.id}/country/${activeCountry?.country_name}/${currentPath}`
      )
    } else if (containsPathProjectHome(currentPath) && activeCountry === null) {
      navigate(`/project/${projectId}/${currentPath}`)
    } else {
      navigate(`/project/${projectId}/home`)
    }
  }, [])

  const onFetchProjects = () => {
    let fetchProjThunk = fetchProjectsByUserV2(sessionStorage.getItem("id"))
    dispatch(fetchProjThunk)
  }

  return (
    <main className={classes.main}>
      <div className={classes.root}>
        <DocumentsToolBar />
        <div className={classes.container}>
          <div className={classes.sideNav}>
            <SideNaveV1 isProjectHome />
          </div>
          <div className={classes.body}>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProjectHomeV1
