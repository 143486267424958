import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme, Paper, TablePagination } from "@material-ui/core"

import {
  setDeleteProjectStatus,
  setUpdateProjectStatus,
} from "redux/slices/projects"
import { RootState } from "redux/store"
import NoDataMessage from "../NoDataMessage"
import { Status } from "shared/types/status"
import { Project } from "shared/types/project"
import ProjectTableBody from "./ProjectTableBody"
import { fetchProjects } from "redux/thunks/projectsThunk"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import Loading from "components/Loading/Loading"

type ProjectTableProps = {
  searchText: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: "0.5rem",
  },
  paginationContainer: {
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  skeleton: {
    width: "99%",
    height: "100%",
    marginLeft: "0.5rem",
    marginTop: "0.5rem",
  },
}))

const ProjectTable: React.FC<ProjectTableProps> = ({ searchText }) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()

  // ==================== Variables ==================== //
  const rowsPerPage = 10
  const projects = useSelector((state: RootState) => state.projects.allProjects)

  const fetchingProjectsStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  )

  const deleteProjectStatus = useSelector(
    (state: RootState) => state.projects.deleteProjectStatus
  )

  const updateProjectStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )

  // ==================== Use Hooks ==================== //
  const [page, setPage] = useState<number>(0)
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([])

  useEffect(() => {
    if (projects.length === 0) {
      fetchProjectsFromDB()
    }
  }, [])

  useEffect(() => {
    if (deleteProjectStatus === Status.success) {
      fetchProjectsFromDB()
      dispatch({ type: setDeleteProjectStatus.type, payload: Status.idle })
    } else if (deleteProjectStatus === Status.failed) {
      snackBar.setMessage("Project deletion failed")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      dispatch({ type: setDeleteProjectStatus.type, payload: Status.idle })
    }
  }, [deleteProjectStatus])

  useEffect(() => {
    if (updateProjectStatus === Status.success) {
      fetchProjectsFromDB()
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    } else if (updateProjectStatus === Status.failed) {
      snackBar.setMessage("Project update failed")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    }
  }, [updateProjectStatus])

  useEffect(() => {
    searchTable()
  }, [projects, searchText])

  const fetchProjectsFromDB = () => {
    const fetchProjectsThunk = fetchProjects()
    dispatch(fetchProjectsThunk)
  }

  // ==================== Functions ==================== //
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const searchTable = () => {
    if (searchText !== "") {
      const filteredRows = projects.filter((row, key) => {
        if (
          row.name.toLowerCase().includes(searchText.toLowerCase()) ||
          row.author.toLowerCase().includes(searchText.toLowerCase())
        )
          return row
      })
      setFilteredProjects(filteredRows)
    } else {
      setFilteredProjects(projects)
    }
  }

  if (
    fetchingProjectsStatus === Status.loading ||
    deleteProjectStatus === Status.loading ||
    updateProjectStatus === Status.loading
  ) {
    return (
      <div className={classes.skeleton}>
        <Loading />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {filteredProjects.length > 0 ? (
        <>
          <ProjectTableBody
            page={page}
            rowsPerPage={rowsPerPage}
            projects={filteredProjects}
            isLoading={false}
          />
          <Paper className={classes.paginationContainer}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={filteredProjects.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </Paper>
        </>
      ) : (
        <NoDataMessage text={"No Projects Found"} />
      )}
    </div>
  )
}

export default ProjectTable
