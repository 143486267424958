import { create } from "zustand"
import { Document } from "shared/types-exp"

type DeleteDocumentDialogState = {
  isOpen: boolean
  activeDocument: Document
}

type DeleteDocumentDialogActions = {
  onOpen: () => void
  onClose: () => void
  setActiveDocument: (activeDocument: Document) => void
}

const useDeleteDocumentDialogV1 = create<
  DeleteDocumentDialogState & DeleteDocumentDialogActions
>((set) => ({
  isOpen: false,
  activeDocument: null,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setActiveDocument: (activeDocument) => set(() => ({ activeDocument })),
}))

export default useDeleteDocumentDialogV1
