import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"

import {
  useCreateAuditTrailMutation,
  useBatchDeleteChapterObjectsMutation,
  useBatchUpdateChapterObjectsMutation,
  useGetFullCountriesByProjectRefIdQuery,
} from "redux/services"
import {
  transformArray,
  getSubChapters,
  removeObjectById,
  findObjectByRefId,
  reorderChapterObjects,
  invalidateAll,
  createAuditTrailObject,
} from "util/helper"
import {
  batchDeleteChapters,
  batchUpdateChapters,
  createAuditTrail,
} from "util/batchHook"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "../components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import { Operations, AuditTrailOperations } from "shared/types-exp"
import useRemoveChapterDialogV1 from "../hooksV1/useRemoveChapterDialogV1"

const DeleteChapterDialogV1: React.FC = () => {
  const {
    activeChapterObject,
    chapterObjects,
    chapterObjectsList,
    activeDocument,
    activeCountry,
    setChapterObjects,
    setChapterObjectsList,
    setActiveChapterObject,
  } = useAppState()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const removeChapterDialog = useRemoveChapterDialogV1()

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchDeleteChapterObjectsAPI] = useBatchDeleteChapterObjectsMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()

  const { data: countriesList, isLoading: isFetchingCountries } =
    useGetFullCountriesByProjectRefIdQuery("", {
      skip: !activeCountry?.global,
    })

  const [isLoading, setIsLoading] = useState(false)
  const [refIdDocuments, setRefIdDocuments] = useState<any[]>([])

  useEffect(() => {
    if (!countriesList) return

    const refIdDocuments = []

    countriesList.data.listCountries?.items?.forEach((country) => {
      const result = country.documents.items.filter(
        (doc) => doc.refId === activeDocument.refId
      )

      if (result.length > 0) refIdDocuments.push(...result)
    })

    setRefIdDocuments(refIdDocuments)
  }, [countriesList])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleOnSubmit()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    removeChapterDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleOnSubmit = async () => {
    try {
      setIsLoading(true)

      if (activeCountry?.global) {
        if (!countriesList) return
        await handleGlobalChapterRemove()
      } else {
        await handleLocalChapterRemove()
      }

      invalidateAll(dispatch)

      snackBar.setMessage("Chapter Removed Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()
      handleClose()
    } catch (error) {
      logger("DeleteChapterDialogV1", "handleOnSubmit", error)

      setIsLoading(false)
      snackBar.setMessage("Chapter Remove Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }

  const handleLocalChapterRemove = async () => {
    const chaptersToUpdate = []
    const tempChapterObjects = cloneDeep(chapterObjects)
    const tempChapterObjectsList = cloneDeep(chapterObjectsList)
    const subChapters = getSubChapters(activeChapterObject)

    subChapters.push({
      id: activeChapterObject.id,
      documentVersionId: activeChapterObject.documentVersionId,
    })

    removeObjectById(tempChapterObjects, activeChapterObject?.id)
    removeObjectById(tempChapterObjectsList, activeChapterObject?.id)

    const updatedChapters = reorderChapterObjects(
      tempChapterObjects,
      activeChapterObject
    )

    updatedChapters.forEach((chapter) => {
      delete chapter.subchapters
      chaptersToUpdate.push(chapter)
    })

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.DELETE,
      Operations.CHAPTER,
      `A chapter with the name ${activeChapterObject?.name}, was deleted in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      batchDeleteChapters(batchDeleteChapterObjectsAPI, subChapters),
      batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
    ])

    setChapterObjects(tempChapterObjects)
    setChapterObjectsList(tempChapterObjectsList)
    setActiveChapterObject(tempChapterObjects[0] || null)
  }

  const handleGlobalChapterRemove = async () => {
    if (refIdDocuments.length === 0) return

    const chaptersToUpdate = []
    const chaptersToDelete = []
    let activeChapters = []

    for (const doc of refIdDocuments) {
      const activeVersion = doc.documentVersions.items[0]
      const chapters = activeVersion.chapters.items
      const transformedChapters = transformArray(chapters)

      const tempActiveChapter = findObjectByRefId(
        transformedChapters,
        activeChapterObject.refId
      )

      if (tempActiveChapter) {
        const subChapters = getSubChapters(tempActiveChapter)

        subChapters.push({
          id: tempActiveChapter.id,
          documentVersionId: tempActiveChapter.documentVersionId,
        })

        chaptersToDelete.push(...subChapters)

        removeObjectById(transformedChapters, tempActiveChapter.id)

        const updatedChapters = cloneDeep(
          reorderChapterObjects(transformedChapters, tempActiveChapter)
        )

        updatedChapters.forEach((chapter) => {
          delete chapter.subchapters
          chaptersToUpdate.push(chapter)
        })
      }

      if (doc.id === activeDocument.id) {
        activeChapters = transformedChapters
      }
    }

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.DELETE,
      Operations.CHAPTER,
      `A chapter with the name ${activeChapterObject?.name}, was deleted in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      batchDeleteChapters(batchDeleteChapterObjectsAPI, chaptersToDelete),
      batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
    ])

    setChapterObjects(activeChapters)
    setActiveChapterObject(activeChapters[0] || null)
  }

  return (
    <Dialog
      open={removeChapterDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="xs"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Remove {activeChapterObject?.name}</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to remove this chapter?
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
          disabled={isLoading || isFetchingCountries}
        >
          Remove
        </Button>
      </DialogActions>

      {(isLoading || isFetchingCountries) && <Loader open={true} />}
    </Dialog>
  )
}

export default DeleteChapterDialogV1
