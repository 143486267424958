import { create } from "zustand"

type ChapterStatusInfoDialogState = {
  isOpen: boolean
}

type ChapterStatusInfoDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useChapterStatusInfoDialogV1 = create<
  ChapterStatusInfoDialogState & ChapterStatusInfoDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useChapterStatusInfoDialogV1
