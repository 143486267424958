import {
  Avatar,
  Fade,
  Card,
  CardHeader,
  Tooltip,
  makeStyles,
  Theme,
  MenuItem,
  Menu,
  IconButton,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { useCreateAuditTrailMutation } from "redux/services"
import { logout } from "redux/thunks/authorizationThunk"
import { createAuditTrailObject } from "util/helper"

type ProfileMenuProps = {
  hasStorageToken: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: "#37017e",
    color: theme.palette.getContrastText("#37017e"),
  },
  avatarName: {
    color: "white",
    minWidth: "105px",
  },
  menuItem: {
    "&:hover": {
      color: theme.palette.error.main,
    },
    justifyContent: "center",
    color: "red",
  },
  showMoreMenu: {
    justifyContent: "flex-end",
    display: "flex",
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))

const ProfileMenu: React.FC<ProfileMenuProps> = ({ hasStorageToken }) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  // ==================== Use Hooks ==================== //
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openProfileMenu = Boolean(anchorEl)

  useEffect(() => {
    if (!hasStorageToken) {
      navigate("/signin")
    }
  }, [hasStorageToken])

  // ==================== Functions ==================== //
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    const auditTrail: PartialAuditTrail = createAuditTrailObject(
      AuditTrailOperations.LOGOUT,
      Operations.USER,
      `Executed the logout operation.`
    )

    const logoutAction = logout()
    dispatch(logoutAction)

    createAuditTrailAPI(auditTrail)

    navigate("/signin")
    handleClose()
  }

  const getDetails = (details: string) => {
    return localStorage.getItem(details) as string
  }

  // ==================== Variables ==================== //
  const email: string = getDetails("email") || "No email provided"
  const name: string = getDetails("firstname")
  const role: string = getDetails("role_name")
  const surname: string = getDetails("surname")
  const subtitle = (
    <>
      {role}
      <br />
      {email}
    </>
  )

  return (
    <>
      {name && surname && email && (
        <div style={{ display: "flex" }}>
          <Tooltip title={email}>
            <IconButton
              aria-label="logout"
              className={classes.showMoreMenu}
              onClick={handleClick}
              disableRipple
              disableFocusRipple
            >
              <Avatar
                className={classes.avatar}
                alt={`${name} ${surname}`}
                src=""
              >
                {name ? name.charAt(0) : ""}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openProfileMenu}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <Card style={{ boxShadow: "none" }}>
              <CardHeader
                avatar={
                  <Avatar
                    className={classes.avatar}
                    alt={`${name} ${surname}`}
                    src=""
                  >
                    {name ? name.charAt(0) : ""}
                  </Avatar>
                }
                title={`${name} ${surname}`}
                subheader={subtitle}
              ></CardHeader>
            </Card>
            <MenuItem onClick={handleLogout} className={classes.menuItem}>
              <ExitToAppIcon style={{ marginRight: "5px" }} fontSize="small" />
              Logout
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  )
}

export default ProfileMenu
