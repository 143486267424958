import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"

import UserTable from "./UserTable"
import { checkEnvironment } from "../../util/environment"
import ProjectPageToolbar from "../PageToolBar/ProjectPageToolbar"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}))

const ProjectUserManagement: React.FC = () => {
  const classes = useStyles()

  const [role, setRole] = React.useState("AllRoles")
  const [search, setSearch] = React.useState<string>("")
  const { isProjectEnvironment } = checkEnvironment()

  return (
    <div className={classes.container}>
      <ProjectPageToolbar
        isUserManagement={isProjectEnvironment}
        role={role}
        setRole={setRole}
        search={search}
        setSearch={setSearch}
      />
      <UserTable searchText={search} />
    </div>
  )
}

export default ProjectUserManagement
