import React, { useEffect } from "react"
import MenuIcon from "@material-ui/icons/MoreVert"
import { Tooltip, Button, Menu } from "@material-ui/core"

type TableActionMenu = {
  children: React.ReactNode
  menuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  tooltip: string
}

export const TableActionMenu: React.FC<TableActionMenu> = ({
  children,
  menuOpen,
  setMenuOpen,
  tooltip,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const handleClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  // ==================== Use Hooks ==================== //
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  useEffect(() => {
    if (menuOpen === false) {
      setAnchorEl(null)
    }
  }, [menuOpen])

  return (
    <div>
      <Tooltip title={tooltip} placement="right-end">
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && menuOpen}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  )
}
