import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import { generateClient } from "aws-amplify/api"
import useBooleanState from "hooksV1/useBooleanStates"
import SideNaveV1 from "components/SideNav/SideNaveV1"
import { listDocumentsByCountryId } from "graphql/queries"
import { containsPath, sortDocuments } from "util/helper"
import DocumentsToolBar from "components/DocumentsToolBar"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import ManageDocumentsToolBar from "components/ManageDocumentsToolBar/ManageDocumentsToolBar"

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flexGrow: 1,
    padding: "0.75rem",
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "0.5rem",
  },
  sideNav: {
    width: "12.5%",
    height: "100%",
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
}))

const client = generateClient()

const TemplateDocumentV1: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    setDocuments,
    setActiveDocument,
    setActiveDocumentVersion,
  } = useAppState()
  const classes = useStyles()
  let navigate = useNavigate()
  const location = useLocation()

  const snackBar = useSnackBar()
  const booleanState = useBooleanState()

  const pathArray = location.pathname.split("/")
  const [currentPath, setCurrentPath] = useState<string[]>([])

  useEffect(() => {
    if (!activeCountry || !hasCountryOrDocumentChanged()) return

    const fetchCountryData = async () => {
      try {
        booleanState.setIsDocumentVersionLoading(true)

        const result = await client.graphql({
          query: listDocumentsByCountryId,
          variables: {
            filter: {
              countryId: {
                eq: activeCountry?.id,
              },
              enabled: {
                eq: true,
              },
            },
          },
        })

        let documents: any[] = result?.data?.listDocuments?.items ?? []

        documents = sortDocuments(documents)

        const activeDocumentId = pathArray[3]

        const activeTempDocument = documents.find(
          (doc) => doc.id === activeDocumentId
        )

        if (activeTempDocument) {
          setDocuments(documents)
          setActiveDocument(activeTempDocument)
          let documentVersions =
            activeTempDocument?.documentVersions["items"][0]
          setActiveDocumentVersion(documentVersions)
        } else {
          const activeDocumentViaRefId = documents.find(
            (doc) => doc.refId === activeDocument?.refId
          )

          setDocuments(documents)
          setActiveDocument(activeDocumentViaRefId)
          let documentVersions =
            activeDocumentViaRefId?.documentVersions["items"][0]
          setActiveDocumentVersion(documentVersions)

          const activeCountryName = pathArray[5]?.replace(/%20/g, " ")
          const currentPath = pathArray[pathArray.length - 1]

          if (!containsPath(currentPath)) {
            navigate(
              `/template/document/${activeDocumentViaRefId?.id}/country/${activeCountryName}`
            )
          } else {
            navigate(
              `/template/document/${activeDocumentViaRefId?.id}/country/${activeCountryName}/${currentPath}`
            )
          }
        }
      } catch (error) {
        console.log(error)
        logger("TemplateDocumentV1", "useEffect (fetchCountryData)", error)

        snackBar.setMessage(
          "An error occurred fetching the countries information. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setCurrentPath(pathArray)
        booleanState.setIsDocumentVersionLoading(false)
      }
    }

    fetchCountryData()
  }, [location.pathname, activeCountry])

  useEffect(() => {
    localStorage.setItem("chapterIndex", "1.")
  }, [])

  const hasCountryOrDocumentChanged = (): boolean => {
    const pathArray = location.pathname.split("/")

    return pathArray[3] !== currentPath[3] || pathArray[5] !== currentPath[5]
  }

  return (
    <main className={classes.main}>
      <div className={classes.root}>
        <DocumentsToolBar />
        <div className={classes.container}>
          <div className={classes.sideNav}>
            <SideNaveV1 isTemplateDocuments />
          </div>
          <div className={classes.body}>
            <div
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              <ManageDocumentsToolBar />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default TemplateDocumentV1
