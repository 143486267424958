import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@material-ui/core"
import { RootState } from "redux/store"
import React, { useState, useEffect } from "react"
import * as EmailValidator from "email-validator"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme } from "@material-ui/core/styles"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { User } from "shared/types/user"
import Loader from "../components/Loading/Loader"
import useEditUserState from "hooksV1/useEditUserState"
import { editUserOnDB } from "redux/thunks/usersSliceThunk"
import { useCreateAuditTrailMutation } from "redux/services"
import useEditUserDialogV1 from "hooksV1/useEditUserDialogV1"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import EditUserDialogBody from "components/EditUserDialog/EditUserDialogBody"
import { createAuditTrailObject } from "util/helper"
import { Status } from "shared/types/status"
import { createAuditTrail } from "util/batchHook"
import { logger } from "util/logger"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(5, 5, 5, 5),
  },
  button: {
    paddingLeft: "1rem",
  },
  dialogTitle: { padding: theme.spacing(2.5, 0, 0, 5) },
  dialogContentText: { padding: theme.spacing(0, 0, 0, 2) },
  dialogActions: { padding: theme.spacing(0, 5, 2.5, 5) },
}))

const EditUserDialogV1: React.FC = () => {
  const {
    newRole,
    activeUser,
    newFirstName,
    newLastName,
    newEmailAddress,
    setFirstNameError,
    setLastNameError,
    setEmailAddressError,
    setFirstNameHelperText,
    setLastNameHelperText,
    setEmailAddressHelperText,
  } = useEditUserState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const useEditUser = useEditUserDialogV1()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const editUserStatus = useSelector(
    (state: RootState) => state.users.updateUserStatus
  )

  const editUserMessage = useSelector(
    (state: RootState) => state.users.updateUserMessage
  )

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (editUserStatus === Status.success) {
      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.USER,
        `The user ${newFirstName} ${newLastName} was updated.`
      )

      createAuditTrail(createAuditTrailAPI, auditTrail)

      snackBar.setMessage("User Updated Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } else if (editUserStatus === Status.failed) {
      logger("EditUserDialog", "useEffect", editUserMessage)

      snackBar.setMessage("User Update Failed. Please try again")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }, [editUserStatus])

  const handleSubmit = () => {
    setIsLoading(true)

    let isValid = true

    if (
      newFirstName === activeUser.firstname &&
      newLastName === activeUser.surname &&
      newEmailAddress === activeUser.email &&
      newRole.role_name === activeUser.role_id.role_name
    ) {
      setFirstNameError(false)
      setFirstNameHelperText("")
      setLastNameError(false)
      setLastNameHelperText("")
      setEmailAddressHelperText("")
      setEmailAddressError(false)

      return
    }

    if (newFirstName === "") {
      setFirstNameError(true)
      setFirstNameHelperText("First Name can not be empty")
      isValid = false
    } else {
      setFirstNameError(false)
      setFirstNameHelperText("")
    }

    if (newLastName === "") {
      setLastNameError(true)
      setLastNameHelperText("Last Name can not be empty")
      isValid = false
    } else {
      setLastNameError(false)
      setLastNameHelperText("")
    }

    if (newEmailAddress === "") {
      setEmailAddressHelperText("Email can not be empty")
      setEmailAddressError(true)
      isValid = false
    } else {
      if (EmailValidator.validate(newEmailAddress)) {
        setEmailAddressHelperText("")
        setEmailAddressError(false)
      } else {
        setEmailAddressHelperText("Invalid email")
        setEmailAddressError(true)
        isValid = false
      }
    }

    if (!isValid) return

    const user = {
      ...activeUser,
      firstname: newFirstName,
      surname: newLastName,
      email: newEmailAddress,
      role_id: newRole,
    } as User

    const editUserOnDBThunk = editUserOnDB(user)
    dispatch(editUserOnDBThunk)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleClose = () => {
    setIsLoading(false)
    useEditUser.onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={useEditUser.isOpen}
      className={classes.container}
      onKeyDown={handleKeyDown}
      onClose={handleCloseDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        Edit User
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          View/Change User’s Personal Information, Role or Projects
        </DialogContentText>
        <EditUserDialogBody />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          size="large"
          onClick={useEditUser.onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <span className={classes.button} />
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default EditUserDialogV1
