import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import {
  useUpdateDocumentMutation,
  useCreateVersionsMutation,
  useCreateAuditTrailMutation,
  useUpdateDocumentVersionMutation,
} from "redux/services"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { createAuditTrailObject, invalidateAll } from "util/helper"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useSaveWorkingVersionDialogV1 from "hooksV1/useSaveWorkingVersionDialogV1"
import {
  Document,
  Operations,
  PartialVersion,
  DocumentVersion,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import {
  updateDocument,
  createAuditTrail,
  updateDocumentVersion,
} from "util/batchHook"
import { defaultAccess } from "util/constants"

type SaveWorkingVersionsProps = {
  version: PartialVersion
  activeDocument: Document
  activeDocumentVersion: DocumentVersion
}

const SaveWorkingVersionDialogV1: React.FC<SaveWorkingVersionsProps> = ({
  version,
  activeDocument,
  activeDocumentVersion,
}) => {
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const { activeCountry } = useAppState()
  const saveWorkingVersion = useSaveWorkingVersionDialogV1()

  const [createVersionAPI] = useCreateVersionsMutation()
  const [updateDocumentAPI] = useUpdateDocumentMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [updateDocumentVersionAPI] = useUpdateDocumentVersionMutation()

  const [isLoading, setIsLoading] = useState(false)

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSave()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    saveWorkingVersion.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)

      await createVersionAPI(version)

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.SAVE,
        Operations.VERSION,
        `A new version (${version?.activeVersion}) was created , in the ${activeDocument?.name} document, in ${activeCountry?.country_name}.`
      )

      const activeVersion = activeDocumentVersion.latestVersion + 1
      const newLatestVersion = activeDocumentVersion.latestVersion + 1

      let updateActiveDocumentVersion: DocumentVersion = {
        ...activeDocumentVersion,
        access: activeDocumentVersion?.access || defaultAccess,
        latestVersion: newLatestVersion,
      }

      let updatedDocument: Document = {
        ...activeDocument,
        activeVersion,
        lastUpdated: new Date().toISOString(),
      }

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        updateDocument(updateDocumentAPI, updatedDocument),
        updateDocumentVersion(
          updateDocumentVersionAPI,
          updateActiveDocumentVersion
        ),
      ])

      invalidateAll(dispatch)

      snackBar.setMessage("Working version successfully saved")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("SaveWorkingVersionDialogV1", "handleSave", error)

      snackBar.setMessage(
        "Working version save operation failed. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={saveWorkingVersion.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Save Working Version</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to save this current version?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default SaveWorkingVersionDialogV1
