import React from "react"
import {
  Theme,
  Dialog,
  Button,
  Divider,
  TableHead,
  TableCell,
  TableBody,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  DialogContentText,
} from "@material-ui/core"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"

import { statusAndColor } from "util/constants"
import useChapterStatusInfoDialogV1 from "hooksV1/useChapterStatusInfoDialog"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: "1rem",
  },
  status: {
    width: "0.75rem",
    height: "0.75rem",
  },
  dialogTitle: { padding: theme.spacing(2.5, 0, 0, 5) },
  dialogContentText: { padding: theme.spacing(0, 0, 0, 2) },
  dialogActions: { padding: theme.spacing(0, 5, 2.5, 5) },
}))

const ChapterStatusInfoDialog: React.FC = () => {
  const classes = useStyles()
  const useChapterStatusInfo = useChapterStatusInfoDialogV1()

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      handleClose()
    }
  }

  const handleClose = () => {
    useChapterStatusInfo.onClose()
  }

  const displayTableBody = () => {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Associated Color</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...statusAndColor].map(([status, color]) => (
              <TableRow
                key={status}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  {status}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  <div
                    className={classes.status}
                    style={{ backgroundColor: `${color}` }}
                  ></div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={useChapterStatusInfo.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle className={classes.dialogTitle}>
        Chapter Status Information
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          View chapter statuses and their associated colors
        </DialogContentText>
        <Divider />
        {displayTableBody()}
        <Divider />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="text"
          color="primary"
          size="large"
          onClick={useChapterStatusInfo.onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChapterStatusInfoDialog
