import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, makeStyles, Theme, Typography } from "@material-ui/core"

import image from "media/banner.jpg"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundImage: `linear-gradient(269.91deg, #EEF2F6 0.07%, #EEF2F6 36.22%, #EEF2F6 46.21%, rgba(238, 242, 246, 0.95) 50.74%, rgba(238, 242, 246, 0.789081) 72%, rgba(238, 242, 246, 0) 100.36%), url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "left bottom",
    backgroundSize: "cover",
    textAlign: "center",
  },

  button1: {
    width: "12em",
    padding: theme.spacing(1, 0),
    fontSize: 16,
  },
}))

const NotFound: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.container}>
      <Typography variant="h3">
        {"The page you're looking for was not found"}
      </Typography>

      <Button
        className={classes.button1}
        variant="contained"
        fullWidth
        style={{ backgroundColor: "#CE1443", color: "white", marginTop: "2rem" }}
        onClick={() => navigate("/")}
      >
        Home
      </Button>
    </div>
  )
}

export default NotFound