import { create } from "zustand"
import { ChapterSection, UploadedFile } from "shared/types-exp"

type DeleteAttachmentsDialogState = {
  isOpen: boolean
  chapterSection: ChapterSection
  filesToDelete: UploadedFile[]
  filesUploaded: UploadedFile[]
  deleteFilesFunc: React.Dispatch<
    React.SetStateAction<{
      [key: string]: UploadedFile[]
    }>
  >
  uploadedFilesFunc: React.Dispatch<
    React.SetStateAction<{
      [key: string]: UploadedFile[]
    }>
  >
}

type DeleteAttachmentsDialogActions = {
  onOpen: () => void
  onClose: () => void
  setFilesUploaded: (filesUploaded: UploadedFile[]) => void
  setFilesToDelete: (filesToDelete: UploadedFile[]) => void
  setChapterSection: (chapterSection: ChapterSection) => void
  setDeletedFiles: (
    deleteFilesFunc: React.Dispatch<
      React.SetStateAction<{
        [key: string]: UploadedFile[]
      }>
    >
  ) => void
  setUploadedFiles: (
    uploadedFilesFunc: React.Dispatch<
      React.SetStateAction<{
        [key: string]: UploadedFile[]
      }>
    >
  ) => void
}

const useDeleteAttachmentsDialogV1 = create<
  DeleteAttachmentsDialogState & DeleteAttachmentsDialogActions
>((set) => ({
  isOpen: false,
  chapterSection: null,
  filesUploaded: [],
  filesToDelete: [],
  localFilesFunc: null,
  deleteFilesFunc: null,
  uploadedFilesFunc: null,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setFilesUploaded: (filesUploaded) => set(() => ({ filesUploaded })),
  setFilesToDelete: (filesToDelete) => set(() => ({ filesToDelete })),
  setChapterSection: (chapterSection) => set(() => ({ chapterSection })),
  setDeletedFiles: (deleteFilesFunc) => set(() => ({ deleteFilesFunc })),
  setUploadedFiles: (uploadedFilesFunc) => set(() => ({ uploadedFilesFunc })),
}))

export default useDeleteAttachmentsDialogV1
