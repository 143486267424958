import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import useEditProjectState from "hooksV1/useEditProjectState"
import useDeleteProjectDialogV1 from "hooksV1/useDeleteProjectDialogV1"

import {
  useCreateAuditTrailMutation,
  useDeleteProjectOperationMutation,
  useGetFullCountriesByProjectRefIdQuery,
} from "redux/services"
import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import Loader from "../components/Loading/Loader"
import { createAuditTrail } from "util/batchHook"
import { createAuditTrailObject, getImageTagsFromPlainText } from "util/helper"
import { fetchProjects } from "redux/thunks/projectsThunk"
import { DeleteProjectModel } from "shared/types-exp/delete"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"

const DeleteProjectDialogV1: React.FC = () => {
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const { activeProject } = useEditProjectState()
  const useDeleteProject = useDeleteProjectDialogV1()

  const [isLoading, setIsLoading] = useState(false)
  const [deleteProjectModel, setDeleteProjectModel] =
    useState<DeleteProjectModel>(null)

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [deleteProjectApi] = useDeleteProjectOperationMutation()

  const {
    data: getFullCountriesByProjectRefIdData,
    isLoading: isLoadingDataFromCountries,
  } = useGetFullCountriesByProjectRefIdQuery(activeProject?.id, {
    skip: !activeProject,
  })

  useEffect(() => {
    if (!getFullCountriesByProjectRefIdData) return

    const deleteModel: DeleteProjectModel = populateDeleteDocumentModel(
      getFullCountriesByProjectRefIdData,
      activeProject?.id
    )

    setDeleteProjectModel(deleteModel)
  }, [getFullCountriesByProjectRefIdData])

  const populateDeleteDocumentModel = (
    response: any,
    projectId: string
  ): DeleteProjectModel => {
    const documents: { id: string; countryId: string }[] = []
    const documentVersions: { id: string; documentId: string }[] = []
    const chapters: { id: string; documentVersionId: string }[] = []
    const uploadedFiles: { id: string; content: string }[] = []
    const countriesIds: string[] = []

    // Using Map for faster lookups to avoid duplicates
    const uploadedFilesMap = new Map<string, string>()

    response.data.listCountries.items.forEach((country: any) => {
      const { id: countryId } = country
      countriesIds.push(countryId)

      country.documents.items.forEach((document: any) => {
        const { id, countryId } = document
        documents.push({ id, countryId })

        document.documentVersions.items.forEach((version: any) => {
          const { id: versionId, documentId } = version
          documentVersions.push({ id: versionId, documentId })

          version.chapters.items.forEach((chapter: any) => {
            const { id: chapterId, documentVersionId: versionIdRef } = chapter
            chapters.push({ id: chapterId, documentVersionId: versionIdRef })

            for (const section of chapter.sections) {
              if (
                section.type === "attachment" &&
                section.content.uploadedFiles.length > 0
              ) {
                section.content.uploadedFiles.forEach((file: any) => {
                  if (!uploadedFilesMap.has(file.id)) {
                    uploadedFilesMap.set(file.id, file.content)
                    uploadedFiles.push({ id: file.id, content: file.content })
                  }
                })

                continue
              }

              const templateImgTags = getImageTagsFromPlainText(
                section.content.plainText
              )

              if (section.type === "text" && templateImgTags.length > 0) {
                templateImgTags.forEach((img) => {
                  if (img.src.includes("uploaded-files-s3") && img.id) {
                    const file = {
                      id: img.id,
                      content: img.src,
                    }

                    if (!uploadedFilesMap.has(file.id)) {
                      uploadedFilesMap.set(file.id, file.content)
                      uploadedFiles.push({ id: file.id, content: file.content })
                    }
                  }
                })
              }
            }
          })
        })
      })
    })

    return {
      documents,
      documentVersions,
      chapters,
      uploadedFiles,
      countriesIds,
      projectId,
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleDelete()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    useDeleteProject.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const fetchProjectsFromDB = () => {
    const fetchProjectsThunk = fetchProjects()
    dispatch(fetchProjectsThunk)
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)

      await deleteProjectApi(deleteProjectModel)

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.DELETE,
        Operations.PROJECT,
        `A project with the name ${activeProject.name} was deleted`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)

      fetchProjectsFromDB()

      snackBar.setMessage("Project Deleted Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("DeleteProjectDialogV1", "handleDelete", error)

      snackBar.setMessage("Project Delete Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleCloseDialog}
      open={useDeleteProject.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to delete this project? <br />
          <span>
            <b>(This action is permanent)</b>
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          variant="text"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          color="primary"
          variant="contained"
          disabled={isLoading || isLoadingDataFromCountries}
        >
          Delete
        </Button>
      </DialogActions>

      {(isLoading || isLoadingDataFromCountries) && <Loader open={true} />}
    </Dialog>
  )
}

export default DeleteProjectDialogV1
