import Snackbar from "@material-ui/core/Snackbar"
import { Alert } from "@material-ui/lab"
import React from "react"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

const SnackBar: React.FC = () => {
  const vertical = "top"
  const horizontal = "right"

  const snackBar = useSnackBar()

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    snackBar.onClose()
  }

  if (
    snackBar.messageSeverity !== SnackType.SnackInfo &&
    snackBar.messageSeverity !== SnackType.SnackError &&
    snackBar.messageSeverity !== SnackType.SnackSuccess &&
    snackBar.messageSeverity !== SnackType.SnackWarning
  ) {
    return
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleClose}
      open={snackBar.isOpen}
      autoHideDuration={4000}
      key={vertical + horizontal}
      style={{ marginTop: "2rem" }}
    >
      <Alert
        elevation={10}
        variant="filled"
        onClose={handleClose}
        style={{ width: "100%" }}
        severity={snackBar.messageSeverity}
      >
        {snackBar.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar
