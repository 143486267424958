import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@material-ui/core"
import { z } from "zod"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import React, { FormEvent, useEffect, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import CountryRegionData from "country-region-data"
import { toFormikValidationSchema } from "zod-formik-adapter"

import Loader from "components/Loading/Loader"
import useAppState from "../hooksV1/useAppState"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useAddCountryDialogV1 from "hooksV1/useAddCountryDialogV1"
import {
  invalidateAll,
  transformArray,
  getUpdatedRoles,
  createAuditTrailObject,
} from "util/helper"
import {
  useCreateCountryMutation,
  useCreateDocumentMutation,
  useCreateAuditTrailMutation,
  useCreateDocumentVersionMutation,
  useCreateChapterObjectMutation,
  useListCountriesByCountryNameQuery,
  useGetFullDocumentVersionWithChaptersV1Query,
} from "redux/services"
import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import { createAuditTrail, traverseAndCreateChapters } from "util/batchHook"

const AddCountryDialogV1: React.FC = () => {
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const addCountryDialog = useAddCountryDialogV1()

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const { countries, activeDocument, activeDocumentVersion, setIsAdding } =
    useAppState()

  const selectCountrySchema = z
    .object({
      selectedCountry: z.string().min(1, { message: "Select a country" }),
    })
    .refine((data) => data.selectedCountry !== "none", {
      message: "Select a country", // Custom error message
      path: ["selectedCountry"], // Path of the field that failed the validation
    })

  const formik = useFormik({
    initialValues: {
      selectedCountry: "none",
    },
    validationSchema: toFormikValidationSchema(selectCountrySchema),
    onSubmit: async (values) => {
      try {
        await handleAddCountry()
      } catch (error) {
        logger("AddCountryDialogV1", "useFormik", error)

        snackBar.setMessage("Country Add Failed. Please try again.")
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        setIsLoading(false)
      }
    },
  })

  const [isLoading, setIsLoading] = useState(false)
  const [chosenCountry, setChosenCountry] = useState(
    formik.values.selectedCountry
  )

  const { data: documentVersionData, isLoading: isFetchingDocVersionData } =
    useGetFullDocumentVersionWithChaptersV1Query(
      { documentId: activeDocument?.id, id: activeDocumentVersion?.id },
      {
        skip: !activeDocument || !activeDocumentVersion,
      }
    )

  const { data: countryFound, isLoading: isFetchingCountry } =
    useListCountriesByCountryNameQuery(
      { countryName: chosenCountry, projectRefId: "" },
      { skip: chosenCountry === "none" }
    )

  const [createCountryAPI] = useCreateCountryMutation()
  const [createDocumentAPI] = useCreateDocumentMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [createChapterObjectAPI] = useCreateChapterObjectMutation()
  const [createDocumentVersionAPI] = useCreateDocumentVersionMutation()

  useEffect(() => {
    setIsLoading(isFetchingCountry || isFetchingDocVersionData)
  }, [isFetchingCountry, isFetchingDocVersionData])

  async function handleAddCountry() {
    try {
      if (!activeDocument) return

      if (!formik.isValid) return

      setIsLoading(true)

      let tempCountry = null

      if (countryFound.data.listCountries.items.length === 0) {
        const newCountry = {
          global: false,
          projectRefId: "",
          active_status: true,
          country_name: formik.values.selectedCountry,
        }

        const response: any = await createCountryAPI(newCountry)

        if (response.data?.errors) {
          throw Error(
            `${JSON.stringify(
              response.data.errors
            )}, Operation: [Create Country], Data Passed: ${newCountry}`
          )
        }

        tempCountry = response.data.data.createCountry
      } else {
        tempCountry = countryFound.data.listCountries.items[0]
      }

      const newDocument = {
        ...activeDocument,
        isProject: false,
        countryId: tempCountry.id,
      }

      const createDocumentResult: any = await createDocumentAPI(newDocument)

      if (
        !createDocumentResult.data?.data &&
        createDocumentResult.data?.errors
      ) {
        throw Error(
          `${JSON.stringify(
            createDocumentResult.data.errors
          )}, Operation: [Create Document], Data Passed: ${newDocument}`
        )
      }

      const newDocumentId = createDocumentResult.data.data.createDocument.id
      const activeVersion = documentVersionData.data.getDocumentVersion
      const newActiveVersion = {
        ...activeVersion,
        isProject: false,
        isCountry: true,
        isGlobal: false,
        documentId: newDocumentId,
        access: getUpdatedRoles(activeVersion.access),
      }

      const createDocumentVersionResult: any = await createDocumentVersionAPI(
        newActiveVersion
      )

      if (
        !createDocumentVersionResult.data?.data &&
        createDocumentVersionResult.data?.errors
      ) {
        throw Error(
          `${JSON.stringify(
            createDocumentVersionResult.data.errors
          )}, Operation: [Create Document Version], Data Passed: ${newActiveVersion}`
        )
      }

      const chapters = activeVersion.chapters["items"]
      const newDocVersionId =
        createDocumentVersionResult.data.data.createDocumentVersion.id

      const transformedArray = transformArray(chapters)

      await traverseAndCreateChapters(
        newDocVersionId,
        transformedArray,
        createChapterObjectAPI,
        activeDocument.refId
      )

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.CREATE,
        Operations.COUNTRY,
        `${formik.values.selectedCountry} was added to the document ${activeDocument.name}, in the ${environment} environment.`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)
      invalidateAll(dispatch)

      // Trigger add skeleton
      setIsAdding(true)

      snackBar.setMessage("Country Added Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("AddCountryDialogV1", "useFormik", error)

      snackBar.setMessage("Country Creation Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const filteredCountries = CountryRegionData.filter((country) => {
    const existing = countries.find((activeCountry) => {
      return activeCountry.country_name === country.countryName
    })

    return existing === undefined
  })

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  const handleClose = () => {
    formik.resetForm()
    setIsLoading(false)
    addCountryDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      open={addCountryDialog.isOpen}
      onClose={handleCloseDialog}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Add Country</DialogTitle>
      <DialogContent style={{ minWidth: 500 }} dividers>
        <Select
          fullWidth
          value={formik.values.selectedCountry}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            setChosenCountry(e.target.value as string)
            formik.setValues({ selectedCountry: e.target.value as string })
          }}
        >
          <MenuItem key="none" value={"none"}>
            <em>Select Country</em>
          </MenuItem>
          {filteredCountries.map((country) => (
            <MenuItem
              key={country.countryShortCode}
              value={country.countryName}
            >
              <ReactCountryFlag countryCode={country.countryShortCode} svg />
              &nbsp; {country.countryName}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          size="large"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleAddCountry}
          disabled={!formik.isValid || isLoading}
        >
          Add Country
        </Button>
      </DialogActions>
      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default AddCountryDialogV1
