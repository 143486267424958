import { create } from "zustand"

type EditUserDialogState = {
  isOpen: boolean
}

type EditUserDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useEditUserDialogV1 = create<EditUserDialogState & EditUserDialogActions>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export default useEditUserDialogV1
