import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core"
import ErrorIcon from "@mui/icons-material/Error"
import LayersClearIcon from "@mui/icons-material/LayersClear"

import useFetchCSV from "hooksV1/useFetchCsv"

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
    overflow: "auto",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
    gap: "0.5rem",
  },

  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
}))

type DocumentViewerProps = {
  url: string
  mimeType: string
}

const PngViewer = ({ url }) => {
  const classes = useStyles()

  const isBase64 = (str) => {
    try {
      return btoa(atob(str))
    } catch (err) {
      return false
    }
  }

  const isBase64String = isBase64(url)

  return (
    <div className={classes.container}>
      <img
        className={classes.image}
        src={isBase64String ? `data:image/png;base64,${url}` : url}
        alt="PNG Document"
      />
    </div>
  )
}

const JpegViewer = ({ url }) => {
  const classes = useStyles()

  const isBase64 = (str) => {
    try {
      return btoa(atob(str))
    } catch (err) {
      return false
    }
  }

  const isBase64String = isBase64(url)

  return (
    <div className={classes.container}>
      <img
        className={classes.image}
        src={isBase64String ? `data:image/png;base64,${url}` : url}
        alt="JPEG Document"
      />
    </div>
  )
}

const PdfViewer = ({ url }) => {
  return <iframe src={url} title="PDF Document" width="100%" height="99%" />
}

const WordViewer = ({ url }) => {
  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
      title="Word Document"
      width="100%"
      height="99%"
    />
  )
}

const ExcelViewer = ({ url }) => {
  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
      title="Excel Document"
      width="100%"
      height="99%"
    />
  )
}

const CsvViewer = ({ url }) => {
  const classes = useStyles()
  const { data, loading, error } = useFetchCSV(url)

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
        <Typography variant="h5">Fetching CSV Data</Typography>
      </div>
    )
  }

  if (error) {
    return (
      <div className={classes.loading}>
        <ErrorIcon fontSize="large" htmlColor="red" />
        <Typography color="error" variant="h5">
          {error}
        </Typography>
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <div className={classes.loading}>
        <LayersClearIcon fontSize="large" />
        <Typography color="primary" variant="h5">
          No Data Available
        </Typography>
      </div>
    )
  }

  return (
    <>
      {data.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Object.keys(data[0]).map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, valueIndex) => (
                    <TableCell key={valueIndex}>
                      {(value as string) || ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ mimeType, url }) => {
  switch (mimeType) {
    case "png":
      return <PngViewer url={url} />
    case "jpeg":
    case "jpg":
      return <JpegViewer url={url} />
    case "pdf":
      return <PdfViewer url={url} />
    case "doc":
    case "docx":
      return <WordViewer url={url} />
    case "xlsx":
      return <ExcelViewer url={url} />
    case "csv":
      return <CsvViewer url={url} />
    default:
      return <div>Unsupported document type</div>
  }
}

export default DocumentViewer
