import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import SaveIcon from "@material-ui/icons/Save"
import React, { useEffect, useState } from "react"
import { IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
  Document,
} from "shared/types-exp"
import {
  useCreateAuditTrailMutation,
  useListDocumentsByRefIdQuery,
  useBatchUpdateDocumentsMutation,
} from "redux/services"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import { createAuditTrailObject, invalidateAll } from "util/helper"
import useManageSettingsState from "hooksV1/useManageSettingsState"
import { batchUpdateDocuments, createAuditTrail } from "util/batchHook"
import ManageProjectTypesDialog from "../../dialogsV1/ManageProjectTypesDialog"
import useManageProjectTypesDialog from "../../hooksV1/useManageProjectTypesDialog"

const useStyles = makeStyles((theme: Theme) => ({
  manageSettingsButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
}))

const ManageSettingsButton: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const {
    newAuthorName,
    newDocumentName,
    newIsIncludedInWorkflow,
    // newOpenForChanges,
    // newProjectType,
  } = useManageSettingsState()
  const manageProjectTypesDialog = useManageProjectTypesDialog()
  const { documents, setDocuments, activeDocument, setActiveDocument } =
    useAppState()

  const [isLoading, setIsLoading] = useState(false)
  const [documentsToUpdate, setDocumentsToUpdate] = useState<Document[]>([])

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchDocumentMutationAPI] = useBatchUpdateDocumentsMutation()
  const { data: listDocumentsByRefIdData, isLoading: isFetching } =
    useListDocumentsByRefIdQuery(activeDocument?.refId)

  // ==================== Use Hooks ==================== //
  useEffect(() => {
    const documentsToUpdate = []
    const documents = listDocumentsByRefIdData?.data?.listDocuments?.items

    if (documents !== undefined && documents.length > 0) {
      documents.forEach((document) => {
        if (!document.isProject && document.id !== activeDocument?.id)
          documentsToUpdate.push(document)
      })
    }

    setDocumentsToUpdate(documentsToUpdate)
  }, [listDocumentsByRefIdData])

  // ==================== Functions ==================== //
  const isDisabled = () => {
    return (
      isFetching ||
      isLoading ||
      newAuthorName?.trim() === "" ||
      newDocumentName?.trim() === "" ||
      (newAuthorName?.trim() === activeDocument?.author?.trim() &&
        newDocumentName?.trim() === activeDocument?.name?.trim())
    )
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      const batchDocs: Document[] = []
      let activeDoc: Document = cloneDeep({
        ...activeDocument,
        lastUpdated: new Date().toISOString(),
        name: newDocumentName.trim(),
        author: newAuthorName.trim(),
        isIncludedInWorkflow: newIsIncludedInWorkflow,
        // openForChanges: newOpenForChanges,
        // projectType: newProjectType,
      })

      delete activeDoc.documentVersions

      batchDocs.push(activeDoc)

      for (const doc of documentsToUpdate) {
        const newDoc = {
          ...doc,
          lastUpdated: new Date().toISOString(),
          name: newDocumentName.trim(),
          author: newAuthorName.trim(),
          // isIncludedInWorkflow: newIsIncludedInWorkflow,
          // openForChanges: newOpenForChanges,
          // projectType: newProjectType,
        }

        batchDocs.push(newDoc)
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.DOCUMENT,
        `The document settings in ${activeDocument?.name} document, were updated.`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        batchUpdateDocuments(batchDocumentMutationAPI, batchDocs),
      ])

      const updatedData = cloneDeep(documents)

      const index = updatedData.findIndex(
        (document) => document.id === activeDocument.id
      )

      updatedData[index] = {
        ...activeDocument,
        name: newDocumentName.trim(),
        author: newAuthorName.trim(),
        lastUpdated: new Date().toISOString(),
        isIncludedInWorkflow: newIsIncludedInWorkflow,
      }

      setActiveDocument(activeDoc)
      setDocuments(updatedData)

      invalidateAll(dispatch)

      snackBar.setMessage("Document Settings updated successfully.")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()
    } catch (error) {
      logger("ManageSettingsButton", "onSubmit", error)

      snackBar.setMessage("Document Settings updated failed.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    } finally {
      setIsLoading(false)
    }
  }

  //PHASE 2
  //-----------------------------

  // const handleManageProjectTypesClick = () => {
  //   manageProjectTypesDialog.onOpen()
  // }

  return (
    <>
      {(isFetching || isLoading) && <Loader open={true} />}
      <div className={classes.manageSettingsButtons}>
        {/*PHASE 2*/}
        {/*----------------------*/}

        {/*{ isProjectEnvironment ? (*/}
        {/*  <Tooltip title="Chapter Overview">*/}
        {/*    <IconButton*/}
        {/*      color="primary"*/}
        {/*    >*/}
        {/*      <ViewListIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*) : (*/}
        {/*  <Tooltip title="Manage Project Types">*/}
        {/*    <IconButton*/}
        {/*      color="primary"*/}
        {/*      onClick={handleManageProjectTypesClick}*/}
        {/*    >*/}
        {/*      <SettingsIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*)}*/}

        <Tooltip title="Save">
          <IconButton
            color="primary"
            onClick={onSubmit}
            disabled={isDisabled()}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </div>

      {manageProjectTypesDialog.isOpen && <ManageProjectTypesDialog />}
    </>
  )
}

export default ManageSettingsButton
