import React from "react"
import { useLocation } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

import ProjectTable from "./ProjectTable"
import DeleteProjectDialogV1 from "dialogsV1/DeleteProjectDialogV1"
import TemplatePageToolbar from "../PageToolBar/TemplatePageToolbar"
import useDeleteProjectDialogV1 from "hooksV1/useDeleteProjectDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}))

const ProjectManagement: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const [search, setSearch] = React.useState<string>("")

  const useDeleteProject = useDeleteProjectDialogV1()

  let pathArray = location.pathname.split("/")
  const managementPage =
    pathArray[pathArray.length - 1] === "projects"

  return (
    <div className={classes.container}>
      <TemplatePageToolbar
        isProjectManagement={managementPage}
        search={search}
        setSearch={setSearch}
      />
      <ProjectTable searchText={search} />
      {useDeleteProject.isOpen && <DeleteProjectDialogV1 />}
    </div>
  )
}

export default ProjectManagement
