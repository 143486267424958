import {
  Grid,
  Theme,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  InputLabel,
  makeStyles,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import React, { useState } from "react"
import ReactDiffViewer from "react-diff-viewer"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import {
  Version,
  ChapterObject,
  ChapterSection,
  PartialChapterObject,
} from "shared/types-exp"
import { transformArray } from "util/helper"
import CompareIcon from "@mui/icons-material/CompareArrows"
import useCompareVersionDialog from "../hooksV1/useCompareVersionDialog"

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    gap: 8,
    display: "flex",
    alignItems: "center",
  },
  container: {
    minWidth: "30vw",
    maxHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  chaptersContainer: {
    width: "100%",
    listStyle: "none",
  },
  chapterStyle: {
    backgroundColor: "grey",
    color: "white",
    paddingTop: "0.4rem",
    paddingBottom: "0.4rem",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    borderRadius: "1rem",
    marginLeft: "0.25rem",
  },
  subChapterItem: {
    paddingTop: "1rem",
    borderLeft: "1px dashed #ddd",
    margin: "0 !important",
  },
  versionSelects: {
    display: "flex",
    flexDirection: "row",
  },
  compareIcon: {
    margin: "1.5rem",
  },
}))

type CompareVersionDialogProps = {
  versions: Version[]
}

const CompareVersionDialog: React.FC<CompareVersionDialogProps> = ({
  versions,
}) => {
  const classes = useStyles()
  const compareVersionDialog = useCompareVersionDialog()

  const [versionX, setVersionX] = useState(0)
  const [versionY, setVersionY] = useState(0)
  const [showNext, setShowNext] = useState(false)

  const isDisabled = () => {
    return versionX === 0 || versionY === 0
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit(event)
    }
  }

  const handleClose = () => {
    compareVersionDialog.onClose()
  }

  const handleBack = () => {
    setVersionX(0)
    setVersionY(0)
    setShowNext(false)
  }

  const handleOnSubmit = (event) => {
    if (!showNext) {
      setShowNext(true)
    } else {
      handleClose()
    }
  }

  const handleSelectVersionX = (event) => {
    setVersionX(event.target.value)
  }

  const handleSelectVersionY = (event) => {
    setVersionY(event.target.value)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={compareVersionDialog.isOpen}
      style={{ maxHeight: "90vh", overflow: "auto" }}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>
        <div className={classes.titleContainer}>
          <h4 style={{ margin: 0 }}>Compare Versions</h4>
          <Tooltip
            title={
              "The version on  the left has to be an earlier version than the version on the right"
            }
            placement="bottom"
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent className={classes.container} dividers>
        {showNext ? (
          <VersionStructureComparison
            versions={versions}
            versionX={versionX}
            versionY={versionY}
          />
        ) : (
          <div className={classes.versionSelects}>
            <FormControl fullWidth>
              <InputLabel>Select Version</InputLabel>
              <Select value={versionX} onChange={handleSelectVersionX}>
                {versions.map((version, index) => {
                  if (versionY === 0 || version.activeVersion < versionY) {
                    return (
                      <MenuItem key={index} value={version.activeVersion}>
                        Version {version.activeVersion}
                      </MenuItem>
                    )
                  }

                  return null
                })}
              </Select>
            </FormControl>

            <CompareIcon className={classes.compareIcon} />

            <FormControl fullWidth>
              <InputLabel>Select Version</InputLabel>
              <Select value={versionY} onChange={handleSelectVersionY}>
                {versions.map((version, index) => {
                  if (version.activeVersion > versionX) {
                    return (
                      <MenuItem key={index} value={version.activeVersion}>
                        Version {version.activeVersion}
                      </MenuItem>
                    )
                  }

                  return null
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={showNext ? handleBack : handleClose}>
          {showNext ? "Back" : "Cancel"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
          disabled={showNext ? false : isDisabled()}
        >
          {showNext ? "Done" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type VersionStructureComparisonProps = {
  versionX: number
  versionY: number
  versions: Version[]
}

const VersionStructureComparison: React.FC<VersionStructureComparisonProps> = ({
  versionX,
  versionY,
  versions,
}) => {
  const classes = useStyles()

  const vX = versions.find((version) => version.activeVersion === versionX)
  const vY = versions.find((version) => version.activeVersion === versionY)

  const versionChaptersMapX: Map<string, PartialChapterObject> = new Map(
    vX.chapters.map((chapter) => [chapter.id, chapter])
  )
  const versionChaptersMapY: Map<string, PartialChapterObject> = new Map(
    vY.chapters.map((chapter) => [chapter.id, chapter])
  )

  const versionChaptersX = transformArray(
    vX.chapters.map((ch) => ({
      ...ch,
      access: [],
      subchapters: [], // Ensure subchapters is included
    }))
  )

  const versionChaptersY = transformArray(
    vY.chapters.map((ch) => ({
      ...ch,
      access: [],
      subchapters: [], // Ensure subchapters is included
    }))
  )

  const hasChapterChanged = (
    chapterX: ChapterObject | PartialChapterObject,
    chapterY: ChapterObject | PartialChapterObject
  ): boolean => {
    return (
      chapterX.chapterOrder !== chapterY.chapterOrder ||
      chapterX.name !== chapterY.name
    )
  }

  const getToolTipMessage = (
    chapterX: ChapterObject | PartialChapterObject,
    chapterY: ChapterObject | PartialChapterObject,
    isCurrent: boolean
  ): string => {
    if (isCurrent && !chapterY) return "This chapter has been deleted."

    if (isCurrent && chapterY) return "This chapter is still present"

    if (!chapterX && chapterY && !isCurrent)
      return "This is a newly created chapter"

    if (chapterX.name !== chapterY.name && !isCurrent)
      return `The chapter name has changed from ${chapterX.name} to ${chapterY.name}`

    if (chapterX.chapterOrder !== chapterY.chapterOrder && !isCurrent)
      return `The chapter order has changed from ${chapterX.chapterOrder} to ${chapterY.chapterOrder}`

    return "This chapter has remained the same"
  }

  function renderChapters(
    chapters: ChapterObject[],
    parentIndex = "",
    isCurrent = false,
    depth = 0
  ) {
    return chapters?.map((chapter, index) => {
      const currentChapterNumber =
        depth === 0 ? `${index + 1}.` : `${parentIndex}${index + 1}.`

      let chapterStyle = null
      let toolTipMessage = ""
      let chapterFound: PartialChapterObject = null

      if (isCurrent) {
        chapterFound = versionChaptersMapY.get(chapter.id)
        toolTipMessage = getToolTipMessage(chapter, chapterFound, isCurrent)

        chapterStyle = {
          width: "100%",
          display: "flex",
          backgroundColor: chapterFound ? "lightgrey" : "#FA5F55",
        }
      } else {
        chapterFound = versionChaptersMapX.get(chapter.id)
        toolTipMessage = getToolTipMessage(chapterFound, chapter, isCurrent)

        chapterStyle = {
          width: "100%",
          display: "flex",

          backgroundColor: !chapterFound
            ? "lightgreen"
            : hasChapterChanged(chapter, chapterFound)
            ? "#FFBF00"
            : "lightgrey",
        }
      }

      return (
        <li key={chapter.id} className={classes.chaptersContainer}>
          <Tooltip
            placement="bottom"
            style={chapterStyle}
            title={toolTipMessage}
          >
            <span>{`${currentChapterNumber} ${chapter.name}`}</span>
          </Tooltip>
          <div
            style={{
              width: "100%",
              padding: "0.25rem",
              border: "0.5px dashed lightgrey",
            }}
          >
            <RenderSection
              chapterX={chapter}
              chapterY={chapterFound}
              isCurrent={isCurrent}
            />
          </div>
          {chapter.subchapters.length > 0 && (
            <ul className={chapter.subchapters ? classes.subChapterItem : ""}>
              {renderChapters(
                chapter.subchapters,
                `${currentChapterNumber}`,
                isCurrent,
                depth + 1
              )}
            </ul>
          )}
        </li>
      )
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <h3>Version {versionX}</h3>
        <ul>{renderChapters(versionChaptersX, "1", true)}</ul>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3>Version {versionY}</h3>
        <ul>{renderChapters(versionChaptersY, "1")}</ul>
      </Grid>
    </Grid>
  )
}

type RenderSectionProps = {
  chapterX: ChapterObject | PartialChapterObject
  chapterY: ChapterObject | PartialChapterObject
  isCurrent: boolean
}

const RenderSection: React.FC<RenderSectionProps> = ({
  chapterX,
  chapterY,
  isCurrent,
}) => {
  if (!chapterX.sections.length) return <></>

  const renderSections = chapterX.sections.map((section: ChapterSection) => {
    const { id, type, content, name } = section

    const counterpartSection = chapterY?.sections.find((sec) => sec.id === id)

    // Handle case where counterpart section does not exist
    if (!counterpartSection) {
      const sectionStyle = {
        borderBottom: "0.1rem dotted lightgrey",
        backgroundColor: isCurrent ? "#FA5F55" : "lightgreen",
      }

      return (
        <Tooltip
          placement="bottom"
          style={sectionStyle}
          title={
            isCurrent ? "This section was deleted" : "This is a new section "
          }
        >
          <div key={`${chapterX.id}-${id}`}>
            <h4>Section: {name}</h4>
          </div>
        </Tooltip>
      )
    }

    // Handle text differences
    if (type === "text") {
      const oldValue = isCurrent ? content : counterpartSection.content
      const newValue = isCurrent ? counterpartSection?.content : content

      return (
        <div
          key={`${chapterX.id}-${id}`}
          style={{
            marginBottom: "0.5rem",
            borderBottom: "0.1rem dotted lightgrey",
          }}
        >
          <Tooltip placement="bottom" title="This section still exists">
            <div key={`${chapterX.id}-${id}`}>
              <h4>Section: {name}</h4>
            </div>
          </Tooltip>

          <ReactDiffViewer
            oldValue={oldValue.plainText ?? ""}
            newValue={newValue.plainText ?? ""}
            splitView={true}
          />
        </div>
      )
    }

    // Handle attachment differences
    if (type === "attachment") {
      const currentFiles = content?.uploadedFiles || []
      const counterpartFiles = counterpartSection.content?.uploadedFiles || []

      const fileDiff = (filesA, filesB, color) =>
        filesA
          .filter((fileA) => !filesB.some((fileB) => fileB.id === fileA.id))
          .map((file) => (
            <p key={file.id} style={{ color }}>
              {file.name}
            </p>
          ))

      const unchangedFiles = currentFiles.filter((fileA) =>
        counterpartFiles.some((fileB) => fileB.id === fileA.id)
      )

      return (
        <div
          key={`${chapterX.id}-${id}`}
          style={{
            marginBottom: "0.5rem",
            borderBottom: "0.1rem dotted lightgrey",
          }}
        >
          <Tooltip placement="bottom" title="This section still exists">
            <div key={`${chapterX.id}-${id}`}>
              <h4>Section: {name}</h4>
            </div>
          </Tooltip>
          {unchangedFiles.map((file) => (
            <p key={file.id}>{file.name}</p>
          ))}
          {isCurrent ? (
            <>{fileDiff(currentFiles, counterpartFiles, "red")}</>
          ) : (
            <>{fileDiff(currentFiles, counterpartFiles, "green")}</>
          )}
        </div>
      )
    }

    return null
  })

  return <>{renderSections}</>
}

export default CompareVersionDialog
