import React from "react"
import { makeStyles, Theme } from "@material-ui/core"

import DocumentCard from "./DocumentCard"
import useAppState from "hooksV1/useAppState"
import NoDataMessage from "components/NoDataMessage"
import useBooleanState from "hooksV1/useBooleanStates"
import { checkEnvironment } from "../../util/environment"
import ProjectPageToolbar from "../PageToolBar/ProjectPageToolbar"
import Loading from "components/Loading/Loading"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  documentCards: {
    gap: "1rem",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: "1rem",
    alignItems: "center",
    justifyContent: "start",
  },

  skeletonContainer: {
    width: "99%",
    height: "100%",
    margin: theme.spacing(1, 0, 0, 2),
  },
}))

const ProjectDocumentManagement: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { documents } = useAppState()
  const { isDocumentsLoading, isCountriesLoading } = useBooleanState()

  const { isProjectEnvironment } = checkEnvironment()

  const displayBody = () => {
    if (isDocumentsLoading || isCountriesLoading) {
      return (
        <div className={classes.skeletonContainer}>
          <Loading />
        </div>
      )
    }

    if (documents.length === 0) {
      return <NoDataMessage text={"No Documents Found"} />
    }

    return (
      <div className={classes.documentCards}>
        {documents?.map((document) => {
          const latestVersion =
            document?.activeVersion ||
            document?.documentVersions["items"][0]?.latestVersion ||
            1

          return (
            <DocumentCard
              key={document.id}
              documentId={document.id}
              documentName={document.name}
              documentVersion={latestVersion}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <ProjectPageToolbar isProjectDocuments={isProjectEnvironment} />
      {displayBody()}
    </div>
  )
}

export default ProjectDocumentManagement
