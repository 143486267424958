// ==================== External Imports ==================== //
import {
  Theme,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import * as lookup from "country-code-lookup"
import ReactCountryFlag from "react-country-flag"
import React, { useEffect, useState } from "react"
import GlobalIcon from "@material-ui/icons/Language"
import { useLocation, useNavigate } from "react-router-dom"

// ==================== Local Imports ==================== //
import { logger } from "util/logger"
import { invalidateAll } from "util/helper"
import { containsPath } from "util/helper"
import useAppState from "hooksV1/useAppState"
import useBooleanState from "hooksV1/useBooleanStates"
import useSnackBar, { SnackType } from "../../hooksV1/useSnackBar"
import { useGetAllCountriesWithProjectRefIdV1Query } from "redux/services"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  skeletonContainer: {
    width: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "1rem",
    margin: theme.spacing(1, 0, 1, 0),
  },
}))

const CountrySelectorV1: React.FC = () => {
  // ==================== Hooks ==================== //
  const {
    activeCountry,
    activeDocument,
    setActiveCountry,
    setChapterObjects,
    setChapterObjectsList,
    setActiveChapterObject,
    setActiveDocumentVersion,
  } = useAppState()
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const snackBar = useSnackBar()
  const booleanState = useBooleanState()

  const { data: response, isLoading } =
    useGetAllCountriesWithProjectRefIdV1Query("")

  // ==================== Variables ==================== //
  let globalCountry = null
  const countriesList = []
  const pathArray = location.pathname.split("/")

  // ==================== Use Hooks ==================== //
  const [countries, setCountries] = useState([])

  useEffect(() => {
    if (!response) return

    try {
      if (response.errors) {
        throw Error(
          `Operation: [Get All Countries With No Project Ref Id]`,
          response.errors
        )
      }

      const activeDocumentId = activeDocument?.refId || pathArray[3]
      const property = activeDocument?.refId ? "refId" : "id"

      if (!response.data || response.data.listCountries.items.length === 0) {
        logger(
          "CountrySelectorV1",
          "useEffect",
          "No countries were found in template"
        )

        snackBar.setMessage(
          "No countries were found. Please try agin or contact administrator."
        )
        snackBar.setMessageSeverity(SnackType.SnackInfo)
        snackBar.onOpen()

        return
      }

      const countriesListData = response.data.listCountries.items.filter(
        (country) =>
          country.documents.items.some(
            (document) => document[property] === activeDocumentId
          )
      )

      if (countriesListData.length > 0) {
        const activeCountryName = pathArray[5]?.replace(/%20/g, " ") || "Global"

        const countriesList: any[] = countriesListData ?? []

        const sortedCountriesList = [...countriesList].sort((a, b) => {
          const nameA = a.country_name.toUpperCase()
          const nameB = b.country_name.toUpperCase()

          if (nameA < nameB) {
            return -1
          }

          if (nameA > nameB) {
            return 1
          }

          return 0
        })

        const activeCountry = sortedCountriesList.find(
          (country) =>
            country.country_name.toLocaleLowerCase() ===
            activeCountryName.toLocaleLowerCase()
        )

        setActiveCountry(activeCountry)
        setCountries(sortedCountriesList)
      }
    } catch (error) {
      console.log(error)
      logger("CountrySelectorV1", "useEffect", error)

      snackBar.setMessage(
        "An error occurred while fetching countries. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [response, activeDocument])

  useEffect(() => {
    booleanState.setIsDocumentVersionLoading(isLoading)
  }, [isLoading])

  // ==================== Functions ==================== //
  const handleCountryChange = (event: any) => {
    clearState()

    const newActiveCountry = countries.find(
      (country) => country.id === event.target.value
    )

    let activeCountryName = newActiveCountry.country_name
    const currentPath = pathArray[pathArray.length - 1]
    const activeDocumentId = pathArray[3]

    booleanState.setIsDocumentsLoading(true)
    setActiveCountry(newActiveCountry)

    if (currentPath === "Global" || !containsPath(currentPath)) {
      navigate(
        `/template/document/${activeDocumentId}/country/${activeCountryName}`
      )
    } else {
      navigate(
        `/template/document/${activeDocumentId}/country/${activeCountryName}/${currentPath}`
      )
    }
  }

  const clearState = () => {
    invalidateAll(dispatch)

    setChapterObjects([])
    setChapterObjectsList([])
    setActiveChapterObject(null)
    setActiveDocumentVersion(null)
  }

  countries?.forEach((country) => {
    if (country.global) globalCountry = country
    else countriesList.push(country)
  })

  if (globalCountry) countriesList.unshift(globalCountry)

  if (!isLoading && countries.length > 0 && activeCountry !== null) {
    return (
      <div className={classes.container}>
        <FormControl>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={activeCountry?.id}
            onChange={handleCountryChange}
            displayEmpty
          >
            {countriesList.map((country) => {
              const countryCode = country?.global
                ? ""
                : lookup.byCountry(country?.country_name)?.iso2 ?? ""

              return (
                <MenuItem
                  key={country.id}
                  value={country.id}
                  disabled={
                    !country.active_status || booleanState?.isEditingBlocked
                  }
                >
                  {country.global ? (
                    <GlobalIcon
                      fontSize="small"
                      style={{
                        verticalAlign: "middle",
                      }}
                    />
                  ) : (
                    <ReactCountryFlag countryCode={countryCode} svg />
                  )}
                  &nbsp;
                  {country.global ? "Global" : country.country_name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  return (
    <div className={classes.skeletonContainer}>
      <Skeleton
        width={125}
        height={30}
        variant="rect"
        animation="wave"
        style={{ borderRadius: "8px" }}
      />
    </div>
  )
}

export default CountrySelectorV1
