import { create } from "zustand"

type DeleteProjectTypeDialogState = {
  isOpen: boolean
}

type DeleteProjectTypeDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useDeleteProjectTypeDialog = create<
  DeleteProjectTypeDialogState & DeleteProjectTypeDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useDeleteProjectTypeDialog
