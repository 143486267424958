export enum AuditTrailOperations {
  ADD = "ADD",
  SYNC = "SYNC",
  SAVE = "SAVE",
  LOGIN = "LOGIN",
  EXPORT = "EXPORT",
  INVITE = "INVITE",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  LOGOUT = "LOGOUT",
  FORGET = "FORGET",
}

export enum Operations {
  USER = "USER",
  ROLES = "ROLES",
  VERSION = "VERSION",
  SECTION = "SECTION",
  CHAPTER = "CHAPTER",
  COUNTRY = "COUNTRY",
  PROJECT = "PROJECT",
  DOCUMENT = "DOCUMENT",
  SYNC_ALL = "SYNC_ALL",
  SUBCHAPTER = "SUBCHAPTER",
  SYNC_CONTENT = "SYNC_CONTENT",
  SYNC_COUNTRIES = "SYNC_COUNTRIES",
  SYNC_STRUCTURE = "SYNC_STRUCTURE",
  SYNC_GLOBAL_DOCUMENT = "SYNC_GLOBAL_DOCUMENT",
  SYNC_COUNTRY_DOCUMENT = "SYNC_COUNTRY_DOCUMENT",
}

export const getEventDescription = (
  operation: AuditTrailOperations,
  entity: Operations
): string => {
  switch (operation) {
    case AuditTrailOperations.CREATE:
      switch (entity) {
        case Operations.USER:
          return "A new user was created"
        case Operations.ROLES:
          return "A new role was created"
        case Operations.CHAPTER:
          return "A new chapter was created"
        case Operations.COUNTRY:
          return "A new country was created"
        case Operations.PROJECT:
          return "A new project was created"
        case Operations.DOCUMENT:
          return "A new document was created"
        case Operations.SUBCHAPTER:
          return "A new subchapter was created"
        default:
          return "An unknown entity was created"
      }
    case AuditTrailOperations.UPDATE:
      switch (entity) {
        case Operations.USER:
          return "A user was updated"
        case Operations.ROLES:
          return "A role was updated"
        case Operations.SECTION:
          return "A document's section information was updated"
        case Operations.CHAPTER:
          return "A chapter was updated"
        case Operations.COUNTRY:
          return "A country was updated"
        case Operations.PROJECT:
          return "A project was updated"
        case Operations.DOCUMENT:
          return "A document was updated"
        case Operations.SUBCHAPTER:
          return "A subchapter was updated"
        default:
          return "An unknown entity was updated"
      }
    case AuditTrailOperations.DELETE:
      switch (entity) {
        case Operations.USER:
          return "A user was deleted"
        case Operations.ROLES:
          return "A role was deleted"
        case Operations.CHAPTER:
          return "A chapter was deleted"
        case Operations.COUNTRY:
          return "A country was deleted"
        case Operations.PROJECT:
          return "A project was deleted"
        case Operations.DOCUMENT:
          return "A document was deleted"
        case Operations.SUBCHAPTER:
          return "A subchapter was deleted"
        default:
          return "An unknown entity was deleted"
      }
    case AuditTrailOperations.SYNC:
      switch (entity) {
        case Operations.SYNC_ALL:
          return "Synchronization of all data occurred"
        case Operations.SYNC_CONTENT:
          return "Content data synchronization occurred"
        case Operations.SYNC_COUNTRIES:
          return "Country data synchronization occurred"
        case Operations.SYNC_GLOBAL_DOCUMENT:
          return "Global document data synchronization occurred"
        case Operations.SYNC_COUNTRY_DOCUMENT:
          return "Country-specific document data synchronization occurred"
        case Operations.SYNC_STRUCTURE:
          return "Structure data synchronization occurred"
        default:
          return "Data synchronization occurred"
      }
    case AuditTrailOperations.SAVE:
      return "A user saved the working version"
    case AuditTrailOperations.EXPORT:
      return "A user exported a version"
    case AuditTrailOperations.LOGIN:
      return "A user logged in"
    case AuditTrailOperations.LOGOUT:
      return "A user logged out"
    case AuditTrailOperations.INVITE:
      return "A user was invited"
    case AuditTrailOperations.FORGET:
      return "A user initiated forget password sequence"

    default:
      return "An unknown event occurred"
  }
}
