import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import { generateClient } from "aws-amplify/api"
import React, { useState, useEffect } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"

import {
  Document,
  Operations,
  ChapterSection,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  useSyncAllOperationMutation,
  useCreateAuditTrailMutation,
  useListDocumentsByCountryNameAndNoProjectIdForSyncQuery,
  useGetDocumentForSyncAllAndStructureQuery,
} from "redux/services"
import { logger } from "util/logger"
import { ChapterSectionInput } from "API"
import { transformArray } from "util/helper"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { createAuditTrail } from "util/batchHook"
import { updateDocumentVersion } from "graphql/mutations"
import { SyncStructureModel } from "shared/types-exp/sync"
import useSyncAllDialogV1 from "hooksV1/useSyncAllDialogV1"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import { createAuditTrailObject, invalidateAll } from "util/helper"

const client = generateClient()

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "40vw",
    maxHeight: "60vh",
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}))

const SyncAllDialogV1: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    chapterObjects,
    setChapterObjects,
    activeDocumentVersion,
    setChapterObjectsList,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const syncAllDialog = useSyncAllDialogV1()

  const [isLoading, setIsLoading] = useState(false)
  const [partialDoc, setPartialDoc] = useState<Partial<Document>>(null)
  const [newSectionsList, setNewSectionList] = useState<ChapterSection[]>([])

  const [syncAllModel, setSyncStructureModel] =
    useState<SyncStructureModel>(null)

  const [syncAllApi] = useSyncAllOperationMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const { data: result, isLoading: isFetchingCountries } =
    useListDocumentsByCountryNameAndNoProjectIdForSyncQuery(
      {
        countryName: activeCountry?.country_name,
        projectRefId: "",
      },
      {
        skip: !chapterObjects || !activeCountry || !activeDocumentVersion,
      }
    )

  const { data: response, isLoading: isFetchingDocument } =
    useGetDocumentForSyncAllAndStructureQuery(
      {
        id: partialDoc?.id,
        countryId: partialDoc?.countryId,
      },
      {
        skip: !partialDoc,
      }
    )

  useEffect(() => {
    if (!result || partialDoc) return

    const documents = result?.data?.listCountries?.items[0].documents.items

    const filteredDocument = documents.find(
      (doc) => activeDocument?.refId === doc.refId
    )

    if (!filteredDocument) {
      snackBar.setMessage(
        `${activeDocument.name} not found in template. Please sync documents.`
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      handleClose()

      return
    }

    setPartialDoc(filteredDocument)
  }, [result])

  useEffect(() => {
    if (!result || !response) return

    mapQueryResultObject(response.data.getDocument)
  }, [response])

  const mapQueryResultObject = (filteredDocument: any) => {
    const activeVersion = filteredDocument.documentVersions.items[0]
    const newSectionsList = cloneDeep(activeVersion.sections)

    activeDocumentVersion?.sections?.forEach((section) => {
      if (section.isProject) newSectionsList.push(section)
    })

    if (activeVersion.chapters.items.length === 0) {
      snackBar.setMessage(
        `The ${activeDocument.name} does not have any chapters from template.`
      )
      snackBar.setMessageSeverity(SnackType.SnackInfo)
      snackBar.onOpen()
      handleClose()

      return
    }

    const syncStructureModel: SyncStructureModel = {
      sections: newSectionsList,
      templateDocumentVersionId: activeVersion.id,
      projectDocumentVersionId: activeDocumentVersion.id,
      documentId: activeDocument.id,
      isIncludedInWorkflow: activeDocument?.isIncludedInWorkflow,
    }

    setNewSectionList(newSectionsList)
    setSyncStructureModel(syncStructureModel)
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSync()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    syncAllDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleSync = async () => {
    try {
      setIsLoading(true)

      const response: any = await syncAllApi(syncAllModel)

      if (
        response.error ||
        (response.error?.status && response.error.status.toString() !== "200")
      ) {
        throw Error(
          `${JSON.stringify(
            response.error.data
          )}, Operation: [Sync All], Data Passed: ${JSON.stringify(
            syncAllModel
          )}`
        )
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.SYNC,
        Operations.SYNC_STRUCTURE,
        `Executed the Sync Structure operation in ${activeCountry?.country_name}, in the ${activeDocument?.name} document`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        client.graphql({
          query: updateDocumentVersion,
          variables: {
            input: {
              id: activeDocumentVersion.id,
              documentId: activeDocumentVersion.documentId,
              sections: newSectionsList as ChapterSectionInput[],
            },
          },
        }),
      ])

      const responseChapters = response.data?.body?.chapters
      const transformedChapters = transformArray(responseChapters)

      setChapterObjects(transformedChapters)
      setChapterObjectsList(responseChapters)
      setActiveChapterObject(transformedChapters[0])

      invalidateAll(dispatch)

      snackBar.setMessage("Sync Structure and Content Completed Successful")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("SyncAllDialog", "handleSync", error)

      snackBar.setMessage("Error occurred while syncing structure")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      open={syncAllDialog.isOpen}
      onClose={handleCloseDialog}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Sync Selection</DialogTitle>
      <DialogContent className={classes.container} dividers>
        <h3>
          Syncing: {activeDocument?.name} - Structure and Content (Global and
          Country)
        </h3>
        <p>
          This will Sync the structure with the Template document and Sync the
          Global and Country content for all Chapters. Are you sure you want to
          continue with the Sync All of {activeDocument?.name}?
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={syncAllDialog.onClose}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSync}
          disabled={isLoading || isFetchingCountries || isFetchingDocument}
        >
          Sync
        </Button>
      </DialogActions>

      {(isLoading || isFetchingCountries || isFetchingDocument) && (
        <Loader open={true} />
      )}
    </Dialog>
  )
}

export default SyncAllDialogV1
