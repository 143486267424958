import { create } from "zustand"

type ManageSettingsState = {
  newAuthorName: string
  newDocumentName: string
  newIsIncludedInWorkflow: boolean
  newOpenForChanges: boolean
  newProjectType: string
}

type ManageSettingsActions = {
  setNewAuthorName: (newAuthorName: string) => void
  setNewDocumentName: (newDocumentName: string) => void
  setNewIsIncludedInWorkflow: (newIsIncludedInWorkflow: boolean) => void
  setNewOpenForChanges: (newOpenForChanges: boolean) => void
  setNewProjectType: (newProjectType: string) => void
}

const useManageSettingsState = create<
  ManageSettingsState & ManageSettingsActions
>((set) => ({
  newAuthorName: "",
  newDocumentName: "",
  newIsIncludedInWorkflow: false,
  newOpenForChanges: true,
  newProjectType: "",

  setNewAuthorName: (newAuthorName) => set(() => ({ newAuthorName })),
  setNewDocumentName: (newDocumentName) => set(() => ({ newDocumentName })),
  setNewIsIncludedInWorkflow: (newIsIncludedInWorkflow) => set(() => ({ newIsIncludedInWorkflow })),
  setNewOpenForChanges: (newOpenForChanges) => set(() => ({ newOpenForChanges })),
  setNewProjectType: (newProjectType) => set(() => ({ newProjectType })),
}))

export default useManageSettingsState
