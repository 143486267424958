import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import React, { useState } from "react"

import {
  Operations,
  ChapterObject,
  PartialAuditTrail,
  AttachmentsPostBody,
  AuditTrailOperations,
  AttachmentsPostBodyChapters,
} from "shared/types-exp"
import {
  useCreateAuditTrailMutation,
  useDeleteAttachmentsMutation,
  useUpdateChapterObjectMutation,
} from "redux/services"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import { invalidateAll, createAuditTrailObject } from "util/helper"
import { createAuditTrail, updateChapterObjectFunc } from "util/batchHook"
import useDeleteAttachmentsDialogV1 from "hooksV1/useDeleteAttachmentsDialogV1"
import { cloneDeep } from "lodash"

type DeleteAttachmentsDialogV1Props = {
  chapterObjectsToUpdate: ChapterObject[]
}

const DeleteAttachmentsDialogV1: React.FC<DeleteAttachmentsDialogV1Props> = ({
  chapterObjectsToUpdate,
}) => {
  const {
    activeCountry,
    activeDocument,
    activeChapterObject,
    setActiveChapterObject,
  } = useAppState()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const { isProjectEnvironment } = checkEnvironment()

  const {
    isOpen,
    onClose,
    filesToDelete,
    chapterSection,
    deleteFilesFunc,
    uploadedFilesFunc,
  } = useDeleteAttachmentsDialogV1()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [deleteAttachmentsAPI] = useDeleteAttachmentsMutation()
  const [updateChapterObjectApi] = useUpdateChapterObjectMutation()

  const [isLoading, setIsLoading] = useState(false)
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") await handleDelete()
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)

      const allChapters = [...chapterObjectsToUpdate, activeChapterObject]

      const postBody: AttachmentsPostBody = {
        files: filesToDelete,
        sectionId: chapterSection.id,
        chapters: allChapters.map((chapterObject) => {
          const attachmentsChapter: AttachmentsPostBodyChapters = {
            chapterObjectId: chapterObject.id,
            documentVersionId: chapterObject.documentVersionId,
          }

          return attachmentsChapter
        }),
      }

      const result: any = await deleteAttachmentsAPI(postBody)

      if (result.errors) {
        throw Error(
          `${JSON.stringify(
            result.data.body
          )}, Operation: [Upload Attachments], Data Passed: ${JSON.stringify(
            postBody
          )}`
        )
      }

      const updatedSection = activeChapterObject.sections.map((section) => {
        if (section.id === chapterSection.id) {
          return {
            ...section,
            content: {
              plainText: section.content.plainText,
              uploadedFiles: section.content.uploadedFiles.filter(
                (file) =>
                  !filesToDelete.some(
                    (currentFile) => file.id === currentFile.id
                  )
              ),
            },
          }
        }

        return section
      })

      let newActiveObject: ChapterObject = {
        ...activeChapterObject,
        sections: updatedSection,
      }

      delete newActiveObject.subchapters

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.SECTION,
        `Deleted attachments om the section ${chapterSection.name}, in the chapter ${activeChapterObject?.name}, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        updateChapterObjectFunc(updateChapterObjectApi, newActiveObject),
      ])

      uploadedFilesFunc((prevFiles) => ({
        ...prevFiles,
        [chapterSection.id]: prevFiles[chapterSection.id].filter(
          (file) =>
            !filesToDelete.some((attachment) => attachment.id === file.id)
        ),
      }))

      deleteFilesFunc((prevFiles) => ({
        ...prevFiles,
        [chapterSection.id]: [],
      }))

      setActiveChapterObject(cloneDeep(newActiveObject))
      invalidateAll(dispatch)

      snackBar.setMessage("Attachments successfully deleted")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("DeleteAttachmentsDialog", "handleDelete", error)

      snackBar.setMessage(
        "An error occurred whilst deleting attachments. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog onClose={handleCloseDialog} open={isOpen} onKeyDown={handleKeyDown}>
      <DialogTitle>Delete Attachments</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to delete the selected attachments?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDelete}
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default DeleteAttachmentsDialogV1
