// ==================== External Imports ==================== //
import React from "react"
import { Paper, makeStyles, Theme, ListItem } from "@material-ui/core"

// ==================== Local Imports ==================== //
import {
  templateHomeLinks,
  projectHomeLinks,
  templateDocumentLinks,
  projectDocumentLinks,
} from "util/constants"
import SideNavButton from "./SideNavButton"
import useAppState from "hooksV1/useAppState"
import { checkEnvironment } from "../../util/environment"
import { Skeleton } from "@material-ui/lab"
import { AuthHelper } from "util/authHelper"

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },
  skeletonContainer: {
    width: "100%",
  },
  skeleton: {
    width: "100%",
    height: "40px",
  },
}))

type SideNaveV1 = {
  isTemplateHome?: boolean
  isTemplateDocuments?: boolean
  isProjectHome?: boolean
  isProjectDocuments?: boolean
  documentAccess?: string[]
}

const SideNaveV1: React.FC<SideNaveV1> = ({
  isTemplateHome,
  isTemplateDocuments,
  isProjectHome,
  isProjectDocuments,
  documentAccess,
}) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const appState = useAppState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()
  const activeProjectName = appState.activeProject?.name
  // ==================== Functions ==================== //
  const showTemplateHomeLinks = () => {
    return templateHomeLinks.map((linkItem) => (
      <SideNavButton key={linkItem.id} {...linkItem} Icon={linkItem.icon} />
    ))
  }

  const showProjectHomeLinks = () => {
    return projectHomeLinks
      .filter((project) =>
        AuthHelper.canViewProjectHomeTab(
          project.canAccess,
          appState?.activeCountry?.id,
          appState?.activeProjectUser
        )
      )
      .map((linkItem) => (
        <SideNavButton key={linkItem.id} {...linkItem} Icon={linkItem.icon} />
      ))
  }

  const showTemplateDocumentLinks = () => {
    return templateDocumentLinks.map((linkItem) => (
      <SideNavButton key={linkItem.id} {...linkItem} Icon={linkItem.icon} />
    ))
  }

  const showProjectDocumentLinks = () => {
    return projectDocumentLinks
      .filter((project) =>
        AuthHelper.canViewProjectDocumentTab(
          project.title,
          appState?.activeCountry?.id,
          documentAccess,
          appState?.activeProjectUser
        )
      )
      .map((linkItem) => (
        <SideNavButton key={linkItem.id} {...linkItem} Icon={linkItem.icon} />
      ))
  }

  const showSkeleton = () => {
    return (
      <>
        {Array.from({ length: 6 }, (_, index) => (
          <ListItem button className={classes.skeletonContainer} key={index}>
            <Skeleton
              variant="text"
              width={"100%"}
              animation="wave"
              style={{ borderRadius: "8px" }}
              className={classes.skeleton}
            />
          </ListItem>
        ))}
      </>
    )
  }

  return (
    <Paper className={classes.body}>
      {isProjectEnvironment && activeProjectName !== null && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: "1rem",
            paddingBottom: "1rem",
            borderBottom: "1px solid #f2f2f2",
          }}
        >
          Active Project: <b>{activeProjectName}</b>
        </div>
      )}
      {documentAccess?.length === 0 ? (
        showSkeleton()
      ) : (
        <>
          {isTemplateHome && isTemplateEnvironment && showTemplateHomeLinks()}
          {isTemplateDocuments &&
            isTemplateEnvironment &&
            showTemplateDocumentLinks()}
          {isProjectHome && isProjectEnvironment && showProjectHomeLinks()}
          {isProjectDocuments &&
            isProjectEnvironment &&
            showProjectDocumentLinks()}
        </>
      )}
    </Paper>
  )
}

export default SideNaveV1
