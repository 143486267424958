/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { appSyncApiConfig as config } from "./config"
import { QueryResponse } from "shared/types/queryResponse"

// Define a service using a base URL and expected endpoints
export const countriesApi = createApi({
  reducerPath: "countriesApi",
  tagTypes: ["Countries"], // Tags for invalidation
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", config.apiKey)

      return headers
    },
  }),
  endpoints: (builder) => ({
    listCountriesByCountryName: builder.query<
      any,
      { countryName: string; projectRefId: string }
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListCountriesByCountryName ($countryName: String = "", $projectRefId: String = "") {
              listCountries(filter: { country_name: {eq: $countryName}, projectRefId: {eq: $projectRefId}}) {
                items {
                  id
                  global
                  country_name
                  projectRefId
                  active_status
                }
              }
            }
          `,
          variables: {
            countryName: data.countryName,
            projectRefId: data.projectRefId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    listDocumentsByCountryNameAndNoProjectIdForSync: builder.query<
      any,
      { countryName: string; projectRefId: string }
    >({
      keepUnusedDataFor: 0,
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query listDocumentsByCountryNameAndNoProjectIdForSync ($countryName: String = "", $projectRefId: String = "") {
              listCountries(filter: { country_name: {eq: $countryName}, projectRefId: {eq: $projectRefId}}) {
                items {
                  documents {
                    items {
                      id
                      refId
                      countryId
                    }
                  }
                } 
              }
            }
          `,
          variables: {
            countryName: data.countryName,
            projectRefId: data.projectRefId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    getFullCountries: builder.query<QueryResponse<"listCountries">, string>({
      keepUnusedDataFor: 0,
      query: (refId) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetFullCountries($eq: String = "") {
              listCountries(filter: {projectRefId: {eq: $eq}}) {
                items {
                  active_status
                  country_name
                  global
                  id
                  projectRefId
                  documents {
                    items {
                      activeVersion
                      author
                      countryId
                      enabled
                      id
                      lastUpdated
                      name
                      refId
                      isIncludedInWorkflow
                      openForChanges
                      projectType
                      documentVersions {
                        items {
                          documentId
                          isGlobal
                          isCountry
                          isProject
                          documentRefId
                          refId
                          id
                          latestVersion
                          users
                          sections {
                            content {
                              plainText
                              uploadedFiles {
                                id
                                name
                                size
                                type
                                content
                              }
                            }
                            id
                            name
                            refId
                            type
                            isProject
                            isClientEditable
                            isCountry
                            isGlobal
                            viewing
                          }
                          chapters {
                             items {
                                id
                                name
                                refId
                                access
                                sharedId
                                parentId
                                isGlobal
                                isProject
                                isCountry
                                treeIndex
                                chapterOrder
                                documentRefId
                                documentVersionId
                                assignedProjectUser
                                isIncludedInWorkflow
                                subchapterCreateAllowed
                                editing {
                                  name
                                  email
                                  surname
                                  isEditing
                                }
                                epi_status {
                                  status
                                  lastUpdated
                                  userLastUpdated
                                }
                                client_status {
                                  status
                                  lastUpdated
                                  userLastUpdated
                                }
                                sections {
                                  content {
                                    plainText
                                    uploadedFiles {
                                      id
                                      name
                                      size
                                      type
                                      content
                                    }
                                  }
                                  id
                                  name
                                  type
                                  refId
                                  viewing
                                  isProject
                                  isCountry
                                  isGlobal
                                  isClientEditable
                                }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            eq: refId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    GetCountriesWithNoProjectId: builder.query<any, void>({
      query: () => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetCountriesWithNoProjectId {
              listCountries(filter: {projectRefId: {eq: ""}}) {
                items {
                  active_status
                  country_name
                  global
                  id
                  projectRefId
                  documents {
                    items {
                      activeVersion
                      author
                      countryId
                      enabled
                      id
                      lastUpdated
                      name
                      refId
                      isIncludedInWorkflow
                      openForChanges
                      projectType
                    }
                  }
                }
              }
            }

          `,
        },
      }),
    }),
    getFullCountriesByProjectRefId: builder.query<any, string>({
      query: (refId) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetFullCountriesByProjectRefId($eq: String = "") {
              listCountries(filter: {projectRefId: {eq: $eq}}) {
                items {
                  id
                  global
                  country_name
                  active_status
                  projectRefId
                  documents {
                    items {
                      activeVersion
                      author
                      countryId
                      enabled
                      id
                      lastUpdated
                      name
                      refId
                      isIncludedInWorkflow
                      openForChanges
                      projectType
                      documentVersions {
                        items {
                          documentId
                          isGlobal
                          isCountry
                          isProject
                          documentRefId
                          id
                          refId
                          latestVersion
                          users
                          access
                          chapters {
                            items {
                                id
                                name
                                refId
                                access
                                sharedId
                                parentId
                                isGlobal
                                isProject
                                isCountry
                                treeIndex
                                chapterOrder
                                documentRefId
                                documentVersionId
                                assignedProjectUser
                                isIncludedInWorkflow
                                subchapterCreateAllowed
                                editing {
                                  name
                                  email
                                  surname
                                  isEditing
                                }
                                epi_status {
                                  status
                                  lastUpdated
                                  userLastUpdated
                                }
                                client_status {
                                  status
                                  lastUpdated
                                  userLastUpdated
                                }
                                sections {
                                  content {
                                    plainText
                                    uploadedFiles {
                                      id
                                      name
                                      size
                                      type
                                      content
                                    }
                                  }
                                  id
                                  name
                                  type
                                  refId
                                  viewing
                                  isProject
                                  isCountry
                                  isGlobal
                                  isClientEditable
                                }
                            }
                          }
                          sections {
                            content {
                              plainText
                              uploadedFiles {
                                id
                                name
                                size
                                type
                                content
                              }
                            }
                            type
                            id
                            refId
                            isGlobal
                            isCountry
                            isProject
                            isClientEditable
                            name
                            viewing
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            eq: refId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    getFullCountriesByProjectRefIdForSync: builder.query<any, string>({
      keepUnusedDataFor: 0,
      query: (refId) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetFullCountriesByProjectRefIdForSync($eq: String = "") {
              listCountries(filter: {projectRefId: {eq: $eq}}) {
                items {
                  active_status
                  country_name
                  global
                  id
                  projectRefId
                  documents {
                    items {
                      id
                      countryId
                      documentVersions {
                        items {
                          documentId
                          id
                          chapters {
                            items {
                              documentVersionId
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            eq: refId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    createCountry: builder.mutation<any, any>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            mutation CreateCountry(
              $active_status: Boolean = false, 
              $country_name: String = "", 
              $global: Boolean = false
              $projectRefId: String = ""
            ) {
              createCountry(
                input: {
                  active_status: $active_status, 
                  country_name: $country_name, 
                  global: $global
                  projectRefId: $projectRefId
                }
              ) {
                active_status
                country_name
                global
                id
                projectRefId
              }
            }
          `,
          variables: {
            active_status: data.active_status,
            country_name: data.country_name,
            global: data.global,
            projectRefId: data.projectRefId,
          },
        },
      }),
    }),
    getFullCountryIdsToDelete: builder.query<any, string>({
      keepUnusedDataFor: 0,
      query: (id) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query getFullCountryIdsToDelete($id: ID = "") {
              getCountry(id: $id) {
                id
                documents {
                  items {
                    countryId
                    id
                    refId
                    documentVersions {
                      items {
                        id
                        documentId
                        chapters {
                          items {
                            id
                            documentVersionId
                            sections {
                              content {
                                uploadedFiles {
                                  id
                                  content
                                }
                                plainText
                              }
                              type
                              isGlobal
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            id,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    updateCountry: builder.mutation<any, any>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            mutation UpdateCountry(
              $active_status: Boolean = false
              $country_name: String = ""
              $global: Boolean = false
              $id: ID = ""
              $projectRefId: String = ""
            ) {
              updateCountry(
                input: {
                  active_status: $active_status
                  country_name: $country_name
                  global: $global
                  id: $id
                  projectRefId: $projectRefId
                }
              ) {
                active_status
                country_name
                global
                id
                projectRefId
              }
            }

          `,
          variables: {
            active_status: data.active_status,
            country_name: data.country_name,
            global: data.global,
            id: data.id,
            projectRefId: data.projectRefId,
          },
        },
      }),
    }),

    // ===================================== V1 queries =====================================
    GetGlobalCountryWithDocumentsV1: builder.query<
      QueryResponse<"listCountries">,
      void
    >({
      query: () => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetGlobalCountryWithDocumentsV1 {
              listCountries(filter: { global: { eq: true } }) {
                items {
                  id
                  active_status
                  country_name
                  projectRefId
                  global
                  documents {
                    items {
                      id
                      name
                      refId
                      author
                      enabled
                      countryId
                      lastUpdated
                      isIncludedInWorkflow
                    }
                  }
                }
              }
            }
          `,
        },
      }),
      providesTags: ["Countries"],
    }),
    ListCountriesWithDocumentsWithProjectRefIdV1Delete: builder.query<
      QueryResponse<"listCountries">,
      { countryName: string; projectRefId: string }
    >({
      query: (params) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListCountriesWithDocumentsWithProjectRefIdV1Delete($countryName: String = "", $projectRefId: String = "") {
              listCountries(filter: { country_name: { eq: $countryName }, projectRefId: {eq: $projectRefId}}) {
                items {
                  id
                  projectRefId
                  documents {
                    items {
                      activeVersion
                      author
                      countryId
                      enabled
                      id
                      lastUpdated
                      name
                      refId
                      isIncludedInWorkflow
                      openForChanges
                      projectType
                    }
                  }
                }
              }
            }
          `,

          variables: {
            countryName: params.countryName,
            projectRefId: params.projectRefId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    ListCountriesWithProjectRefIdV1: builder.query<any, string>({
      query: (projectRefId) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListCountriesWithProjectRefIdV1($eq: String = "") {
              listCountries(filter: { projectRefId: {eq: $eq}, global: { eq: false } }) {
                items {
                  id
                  global
                  active_status
                  country_name
                  projectRefId
                }
              }
            }
          `,
          variables: {
            eq: projectRefId,
          },
        },
      }),
    }),
    GetAllCountriesWithProjectRefIdV1: builder.query<any, string>({
      query: (projectRefId) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetAllCountriesWithProjectRefIdV1($eq: String = "") {
              listCountries(filter: {projectRefId: {eq: $eq}}) {
                  items {
                    id
                    global
                    country_name
                    projectRefId
                    active_status
                    documents {
                      items {
                        id
                        refId
                        name
                        author
                        enabled
                        countryId
                        isProject
                        activeVersion
                        isIncludedInWorkflow
                        documentVersions {
                          items {
                            access
                            latestVersion
                          }
                        }
                      }
                    }
                  }
              }
            }
          `,
          variables: {
            eq: projectRefId,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    GetAllocatedCountries: builder.query<any, string[]>({
      query: (countryIds) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query GetAllocatedCountries($eq: [String] = []) {
              listCountriesMultiple(filter: {ids: $eq}) {
                  items {
                    id
                    global
                    country_name
                    projectRefId
                    active_status
                    documents {
                      items {
                        id
                        refId
                        name
                        author
                        enabled
                        countryId
                        isProject
                        activeVersion
                        isIncludedInWorkflow
                        documentVersions {
                          items {
                            access
                            latestVersion
                          }
                        }
                      }
                    }
                  }
              }
            }
          `,
          variables: {
            eq: countryIds,
          },
        },
      }),
      providesTags: ["Countries"],
    }),
    ListCountriesWithNoRefId: builder.query<any, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListCountriesWithNoRefId {
              listCountries(filter: {projectRefId: {eq: ""}}) {
                items {
                  documents {
                    items {
                      refId
                      documentVersions {
                        items {
                          documentId
                          isGlobal
                          isCountry
                          isProject
                          documentRefId
                          id
                          refId
                          latestVersion
                          users
                          sections {
                            content {
                              plainText
                              uploadedFiles {
                                id
                                name
                                size
                                type
                                content
                              }
                            }
                            type
                            id
                            refId
                            isGlobal
                            isCountry
                            isProject
                            isClientEditable
                            name
                            viewing
                          }
                          chapters {
                            items {
                              documentVersionId
                              id
                              refId
                              parentId
                              sharedId
                              name
                              isGlobal
                              isCountry
                              isProject
                              documentRefId
                              sections {
                                id
                                content {
                                  uploadedFiles {
                                    id
                                    name
                                    type
                                    size
                                    content
                                  }
                                  plainText
                                }
                                isGlobal
                                isCountry
                                isProject
                                isClientEditable
                                name
                                type
                                viewing
                                refId
                              }
                              access
                              editing {
                                email
                                name
                                surname
                                isEditing
                              }
                              epi_status {
                                status
                                lastUpdated
                                userLastUpdated
                              }
                              client_status {
                                status
                                lastUpdated
                                userLastUpdated
                              }
                              treeIndex
                              assignedProjectUser
                              chapterOrder
                              isIncludedInWorkflow
                              subchapterCreateAllowed
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

          `,
        },
      }),
    }),
    ListCachedCountriesWithNoRefId: builder.query<any, void>({
      query: () => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListCachedCountriesWithNoRefId {
              listCountries(filter: {projectRefId: {eq: ""}}) {
                items {
                  documents {
                    items {
                      refId
                      documentVersions {
                        items {
                          documentId
                          isGlobal
                          isCountry
                          isProject
                          documentRefId
                          id
                          refId
                          latestVersion
                          users
                          sections {
                            content {
                              plainText
                              uploadedFiles {
                                id
                                name
                                size
                                type
                                content
                              }
                            }
                            type
                            id
                            refId
                            isGlobal
                            isCountry
                            isProject
                            isClientEditable
                            name
                            viewing
                          }
                          chapters {
                            items {
                              documentVersionId
                              id
                              refId
                              parentId
                              sharedId
                              name
                              isGlobal
                              isCountry
                              isProject
                              documentRefId
                              sections {
                                id
                                content {
                                  uploadedFiles {
                                    id
                                    name
                                    type
                                    size
                                    content
                                  }
                                  plainText
                                }
                                isGlobal
                                isCountry
                                isProject
                                isClientEditable
                                name
                                type
                                viewing
                                refId
                              }
                              access
                              editing {
                                email
                                name
                                surname
                                isEditing
                              }
                              epi_status {
                                status
                                lastUpdated
                                userLastUpdated
                              }
                              client_status {
                                status
                                lastUpdated
                                userLastUpdated
                              }
                              treeIndex
                              assignedProjectUser
                              chapterOrder
                              isIncludedInWorkflow
                              subchapterCreateAllowed
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

          `,
        },
      }),
    }),
    ListChaptersByProjectRefId: builder.query<any, void>({
      query: () => ({
        url: "",
        method: "POST",
        body: {
          query: `
            query ListChaptersByProjectRefId {
              listCountries(filter: {projectRefId: {eq: ""}}) {
                items {
                  documents {
                    items {
                      id
                      refId
                      documentVersions {
                        items {
                          documentId
                          isGlobal
                          isCountry
                          isProject
                          documentRefId
                          id
                          refId
                          latestVersion
                          users
                          sections {
                            type
                            id
                            refId
                            isGlobal
                            isCountry
                            isProject
                            isClientEditable
                            name
                            viewing
                          }
                          chapters {
                            items {
                              documentVersionId
                              id
                              refId
                              parentId
                              sharedId
                              name
                              isGlobal
                              isCountry
                              isProject
                              documentRefId
                              sections {
                                id
                                content {
                                  uploadedFiles {
                                    id
                                    name
                                    type
                                    size
                                    content
                                  }
                                  plainText
                                }
                                isGlobal
                                isCountry
                                isProject
                                isClientEditable
                                name
                                type
                                viewing
                                refId
                              }
                              access
                              editing {
                                email
                                name
                                surname
                                isEditing
                              }
                              epi_status {
                                status
                                lastUpdated
                                userLastUpdated
                              }
                              client_status {
                                status
                                lastUpdated
                                userLastUpdated
                              }
                              treeIndex
                              assignedProjectUser
                              chapterOrder
                              isIncludedInWorkflow
                              subchapterCreateAllowed
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

          `,
        },
      }),
    }),
  }),
})

export const {
  useGetFullCountriesQuery,
  useGetFullCountryIdsToDeleteQuery,
  useListCountriesByCountryNameQuery,
  useGetFullCountriesByProjectRefIdQuery,
  useGetFullCountriesByProjectRefIdForSyncQuery,
  useGetCountriesWithNoProjectIdQuery,
  useCreateCountryMutation,
  useUpdateCountryMutation,
  // ================ V1 ================
  useGetAllocatedCountriesQuery,
  useListChaptersByProjectRefIdQuery,
  useListCountriesWithNoRefIdQuery,
  useListCachedCountriesWithNoRefIdQuery,
  useGetGlobalCountryWithDocumentsV1Query,
  useListCountriesWithProjectRefIdV1Query,
  useGetAllCountriesWithProjectRefIdV1Query,
  useListDocumentsByCountryNameAndNoProjectIdForSyncQuery,
} = countriesApi

export const countriesUtil = countriesApi.util
