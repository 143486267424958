import { Link } from "react-router-dom"
import { Button, Container, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    bottom: 0,
    width: "100vw",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "50px",
  },
  links: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& > *": {},
  },
  button: {
    fontSize: "12px",
    fontFamily: "Open Sans",
    letterSpacing: 0.1,
    color: theme.palette.text.secondary,
  },
}))

const FooterNav: React.FC = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container className={classes.footerContainer} maxWidth="sm">
        <div className={classes.links}>
          <Link to="/privacy-policy">
            <Button className={classes.button}>Privacy Policy</Button>
          </Link>
          <span> |</span>
          {/*todo: need content for these*/}
          {/*<Link to="/terms-and-conditions">*/}
          {/*  <Button className={classes.button}> Terms and Conditions</Button>*/}
          {/*</Link>*/}
          {/*<span> |</span>*/}
          {/*<Link to="/copyright">*/}
          {/*  <Button className={classes.button}> Copyright </Button>*/}
          {/*</Link>*/}
          {/*<span> |</span>*/}
          <Link to="/disclaimer">
            <Button className={classes.button}>Disclaimer </Button>
          </Link>
        </div>
      </Container>
    </footer>
  )
}

export default FooterNav
