import React, { useEffect, useState } from "react"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { ListItemButton } from "@mui/material"
import {
  makeStyles,
  Theme,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Collapse,
} from "@material-ui/core"

import useAppState from "hooksV1/useAppState"
import useBooleanState from "hooksV1/useBooleanStates"
import useManageSettingsState from "hooksV1/useManageSettingsState"
import AddToWorkflowDialogV1 from "dialogsV1/AddToWorkflowDialogV1"
import useAddToWorkflowDialogV1 from "hooksV1/useAddToWorkflowDialogV1"
import RemoveFromWorkflowDialogV1 from "dialogsV1/RemoveFromWorkflowDialogV1"
import useRemoveFromWorkflowDialogV1 from "hooksV1/useRemoveFromWorkflowDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0.5rem",
  },
  form: {
    width: "100%",
    padding: theme.spacing(0, 0, 0, 0),
  },
  textField: {
    marginBottom: "1.2rem",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const ManageSettings: React.FC = () => {
  const classes = useStyles()
  const { activeCountry } = useAppState()
  const addToWorkflowDialog = useAddToWorkflowDialogV1()
  const removeFromWorkflowDialog = useRemoveFromWorkflowDialogV1()
  const { setIsChaptersLoading, isChaptersLoading } = useBooleanState()

  const {
    newAuthorName,
    newDocumentName,
    newIsIncludedInWorkflow,
    // newOpenForChanges,
    // newProjectType,
    setNewAuthorName,
    setNewDocumentName,
    setNewIsIncludedInWorkflow,
  } = useManageSettingsState()

  const { activeDocument } = useAppState()
  const [collapseOpen, setCollapseOpen] = useState(false)

  //PHASE 2
  //-----------------------------

  // const projectTypes = [
  //   "Payroll Implementation",
  //   "Data Integration",
  //   "EC Implementation",
  //   "Time Implementation",
  //   "Workforce  Implementation",
  //   "People Solutions",
  //   "PEX Implementation",
  // ]

  const checkIsBusinessBlueprint = (name: string) =>
    name.toLowerCase() === "business blueprint"

  useEffect(() => {
    if (activeDocument) {
      setNewAuthorName(activeDocument?.author)
      setNewDocumentName(activeDocument?.name)
      setNewIsIncludedInWorkflow(activeDocument?.isIncludedInWorkflow)
    }
  }, [activeDocument])

  useEffect(() => {
    if (isChaptersLoading) setIsChaptersLoading(false)
  }, [isChaptersLoading])

  // Function to handle changes
  const handleChangeDocumentName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewDocumentName(event.target.value)
  }

  const handleChangeAuthor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAuthorName(event.target.value)
  }

  const handleCollapseOpen = () => {
    setCollapseOpen(!collapseOpen)
  }

  const handleChangeIncludedInWorkflow = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      //
      removeFromWorkflowDialog.onOpen()
    } else {
      addToWorkflowDialog.onOpen()
    }
  }

  //PHASE 2
  //-----------------------------

  // const handleChangeOpenForChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewOpenForChanges(event.target.checked)
  // }
  //
  // const handleChangeProjectType = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setNewProjectType(event.target.value as string)
  // }

  return (
    <>
      <Paper
        style={{
          padding: "2em",
          marginTop: "0.5rem",
          marginLeft: "0.5rem",
        }}
      >
        <form className={classes.form} noValidate>
          <TextField
            className={classes.textField}
            autoComplete="DocumentName"
            id="DocumentName"
            label="Document Name"
            name="DocumentName"
            variant="outlined"
            disabled={
              checkIsBusinessBlueprint(newDocumentName) ||
              !activeCountry?.global
            }
            fullWidth
            focused={false}
            value={newDocumentName}
            onChange={handleChangeDocumentName}
            inputProps={
              checkIsBusinessBlueprint(newDocumentName) ||
              !activeCountry?.global
                ? { style: { color: "black", opacity: 0.7 } }
                : {}
            }
            InputLabelProps={
              checkIsBusinessBlueprint(newDocumentName) ||
              !activeCountry?.global
                ? { style: { color: "black", opacity: 0.7 } }
                : {}
            }
          />
          <TextField
            className={classes.textField}
            focused={false}
            id="author"
            label="Author"
            name="author"
            variant="outlined"
            disabled={
              checkIsBusinessBlueprint(newDocumentName) ||
              !activeCountry?.global
            }
            fullWidth
            value={newAuthorName}
            onChange={handleChangeAuthor}
            inputProps={
              checkIsBusinessBlueprint(newDocumentName) ||
              !activeCountry?.global
                ? { style: { color: "black", opacity: 0.7 } }
                : {}
            }
            InputLabelProps={
              checkIsBusinessBlueprint(newDocumentName) ||
              !activeCountry?.global
                ? { style: { color: "black", opacity: 0.7 } }
                : {}
            }
          />

          <ListItemButton onClick={handleCollapseOpen}>
            <ListItemText primary="Advanced Settings" />
            {collapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newIsIncludedInWorkflow}
                      onChange={handleChangeIncludedInWorkflow}
                    />
                  }
                  label={
                    newIsIncludedInWorkflow
                      ? "Included in Workflow"
                      : "Not Included in Workflow"
                  }
                />
              </ListItem>

              {/*PHASE 2*/}
              {/*-----------------------------*/}

              {/*<ListItem>*/}
              {/*  <FormControlLabel*/}
              {/*    control={*/}
              {/*      <Switch*/}
              {/*        checked={newOpenForChanges}*/}
              {/*        onChange={handleChangeOpenForChanges}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label={newOpenForChanges ? "Open for Changes" : "Closed for Changes"}*/}
              {/*  />*/}
              {/*</ListItem>*/}

              {/*<ListItem>*/}
              {/*  <FormControl>*/}
              {/*    <InputLabel>Project Type</InputLabel>*/}
              {/*    <Select*/}
              {/*      style={{width: 300}}*/}
              {/*      value={newProjectType}*/}
              {/*      onChange={handleChangeProjectType}*/}
              {/*    >*/}
              {/*      {projectTypes.map((type) => (*/}
              {/*        <MenuItem key={type} value={type} className={classes.menuItem}>*/}
              {/*          {type}*/}
              {/*        </MenuItem>*/}
              {/*      ))}*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}
              {/*</ListItem>*/}

              {/*<ListItem>*/}
              {/*  <FormControl>*/}
              {/*    <InputLabel>Link Document Structure</InputLabel>*/}
              {/*    <Select*/}
              {/*      style={{width: 300}}*/}
              {/*    >*/}
              {/*      /!*todo: add options*!/*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}
              {/*</ListItem>*/}
            </List>
          </Collapse>
        </form>
      </Paper>
      {addToWorkflowDialog.isOpen && (
        <AddToWorkflowDialogV1 setWorkflow={setNewIsIncludedInWorkflow} />
      )}
      {removeFromWorkflowDialog.isOpen && (
        <RemoveFromWorkflowDialogV1 setWorkflow={setNewIsIncludedInWorkflow} />
      )}
    </>
  )
}

export default ManageSettings
