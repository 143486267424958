// Need to use the React-specific entry point to allow generating React hooks
import config from "config"
import { SyncModel } from "shared/types-exp/sync"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
// import config from "config"

const baseUrl =
  "SYNC_URL" in config.apiGateway
    ? `${config.apiGateway.SYNC_URL}`
    : `${config.apiGateway.URL}/sync/`

// Define a service using a base URL and expected endpoints
export const syncApi = createApi({
  reducerPath: "syncApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")

      if (token) {
        headers.set("Authorization", `${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    syncCountryOperation: builder.mutation<any, SyncModel>({
      query: (syncModel) => ({
        url: "sync-country",
        method: "POST",
        body: syncModel,
      }),
    }),
    syncDocumentOperation: builder.mutation<any, SyncModel>({
      query: (syncModel) => ({
        url: "sync-documents",
        method: "POST",
        body: syncModel,
      }),
    }),
    syncStructureOperation: builder.mutation<any, SyncModel>({
      query: (syncModel) => ({
        url: "sync-structure",
        method: "POST",
        body: syncModel,
      }),
    }),
    syncAllOperation: builder.mutation<any, SyncModel>({
      query: (syncModel) => ({
        url: "sync-all",
        method: "POST",
        body: syncModel,
      }),
    }),
    syncContentOperation: builder.mutation<any, SyncModel>({
      query: (syncModel) => ({
        url: "sync-content",
        method: "POST",
        body: syncModel,
      }),
    }),
  }),
})

export const {
  useSyncAllOperationMutation,
  useSyncContentOperationMutation,
  useSyncCountryOperationMutation,
  useSyncDocumentOperationMutation,
  useSyncStructureOperationMutation,
} = syncApi
