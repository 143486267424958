import { create } from "zustand"

type AddSubChapterDialogState = {
    isOpen: boolean
}

type AddSubChapterDialogActions = {
    onOpen: () => void
    onClose: () => void
}

const useAddSubChapterDialogV1 = create<
    AddSubChapterDialogState & AddSubChapterDialogActions
>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
}))

export default useAddSubChapterDialogV1
