/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Document,
  ChapterObject,
  DocumentVersion,
  PartialAuditTrail,
  ImageData,
} from "shared/types-exp"
import { defaultAccess } from "./constants"
import { generateClient } from "aws-amplify/api"
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import {
  batchCreateImageData,
  batchUpdateChapterObjects,
  createImageData,
  updateChapterObject,
  updateImageData,
} from "graphql/mutations"
import { UpdateChapterObjectInput } from "API"

const client = generateClient()

export const checkIfError = (
  response: any,
  data: any,
  operation: string
): void => {
  if (response.data?.errors || response.data?.errors?.length > 0) {
    throw Error(
      `${JSON.stringify(
        response.data.errors
      )}, Operation: ${operation}, Data Passed: ${JSON.stringify(data)}`
    )
  }
}

const processBatchUpdate = async (
  batchOperation: MutationTrigger<any>,
  batch: any[],
  operation: string
) => {
  const response: any = await batchOperation({ objects: batch })

  checkIfError(response, batch, operation)
}

// ================= Batch Create ================= //

export const batchCreateImageDataAmplify = async (
  imageData: ImageData[]
): Promise<void> => {
  const response: any = await client.graphql({
    query: batchCreateImageData,
    variables: {
      input: {
        objects: imageData,
      },
    },
  })

  checkIfError(response, imageData, "[Batch Create Image Data Amplify]")
}

// ================= Batch Updates ================= //
export const batchUpdateDocumentsVersions = async (
  batchUpdateDocumentVersionsAPI: MutationTrigger<any>,
  documentVersionsToUpdate: DocumentVersion[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < documentVersionsToUpdate.length) {
    const batchUpdateArray = documentVersionsToUpdate.slice(
      index,
      index + batchSize
    )

    await processBatchUpdate(
      batchUpdateDocumentVersionsAPI,
      batchUpdateArray,
      "[Batch Update Document Version]"
    )

    index += batchSize
  }
}

export const batchUpdateChapters = async (
  batchUpdateChapterObjectsAPI: MutationTrigger<any>,
  chaptersToUpdate: ChapterObject[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < chaptersToUpdate.length) {
    const batchUpdateArray = chaptersToUpdate.slice(index, index + batchSize)

    await processBatchUpdate(
      batchUpdateChapterObjectsAPI,
      batchUpdateArray,
      "[Batch Update Chapters]"
    )

    index += batchSize
  }
}

export const batchUpdateChapterSection = async (
  batchUpdateChapterSectionApi: MutationTrigger<any>,
  chaptersToUpdate: ChapterObject[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < chaptersToUpdate.length) {
    const batchUpdateArray = chaptersToUpdate.slice(index, index + batchSize)

    await processBatchUpdate(
      batchUpdateChapterSectionApi,
      batchUpdateArray,
      "[Batch Update Chapter Sections]"
    )

    index += batchSize
  }
}

export const batchUpdateDocuments = async (
  batchUpdateDocumentsAPI: MutationTrigger<any>,
  documentsToUpdate: Document[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < documentsToUpdate.length) {
    const batchUpdateArray = documentsToUpdate.slice(index, index + batchSize)

    await processBatchUpdate(
      batchUpdateDocumentsAPI,
      batchUpdateArray,
      "[Batch Update Document]"
    )

    index += batchSize
  }
}

export const batchUpdateChapterObjectsAmplify = async (
  updatableChapters: any[]
): Promise<void> => {
  const response: any = await client.graphql({
    query: batchUpdateChapterObjects,
    variables: {
      input: {
        objects: updatableChapters,
      },
    },
  })

  checkIfError(response, updatableChapters, "[Batch Update Chapter Amplify]")
}

// ================= Batch Deletes ================= //
export const batchDeleteChapters = async (
  batchDeleteChapterObjectsAPI: MutationTrigger<any>,
  chaptersToDelete: { id: string; documentVersionId: string }[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < chaptersToDelete.length) {
    const batchDeleteArray = chaptersToDelete.slice(index, index + batchSize)

    await processBatchUpdate(
      batchDeleteChapterObjectsAPI,
      batchDeleteArray,
      "[Batch Delete Chapters]"
    )

    index += batchSize
  }
}

export const batchDeleteDocuments = async (
  batchDeleteDocumentsAPI: MutationTrigger<any>,
  documentsToDelete: Document[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < documentsToDelete.length) {
    const batchDeleteArray = documentsToDelete.slice(index, index + batchSize)

    await processBatchUpdate(
      batchDeleteDocumentsAPI,
      batchDeleteArray,
      "[Batch Delete Documents]"
    )

    index += batchSize
  }
}

export const batchDeleteDocumentVersions = async (
  batchDeleteDocumentVersionsAPI: MutationTrigger<any>,
  documentVersionsToDelete: Document[]
): Promise<void> => {
  const batchSize = 25
  let index = 0

  while (index < documentVersionsToDelete.length) {
    const batchDeleteArray = documentVersionsToDelete.slice(
      index,
      index + batchSize
    )

    await processBatchUpdate(
      batchDeleteDocumentVersionsAPI,
      batchDeleteArray,
      "[Batch Delete Document Versions]"
    )

    index += batchSize
  }
}

// ================= Create ================= //
export const createImageDataAmplify = async (
  imageData: ImageData
): Promise<void> => {
  const response: any = await client.graphql({
    query: createImageData,
    variables: {
      input: imageData,
    },
  })

  checkIfError(response, imageData, "[Batch Create Image Data Amplify]")
}

export const createAuditTrail = async (
  createOperation: MutationTrigger<any>,
  auditTrail: PartialAuditTrail
): Promise<void> => {
  const response: any = await createOperation(auditTrail)

  checkIfError(response, auditTrail, "[Create Audit Trail]")
}

export const createChapterObject = async (
  createOperation: MutationTrigger<any>,
  chapter: Partial<ChapterObject>
): Promise<any> => {
  const response: any = await createOperation(chapter)

  checkIfError(response, chapter, "[Create Chapter]")

  return response
}

export const traverseAndCreateChapters = async (
  docVerId: string,
  chapters: ChapterObject[],
  createChapterObjectAPI: MutationTrigger<any>,
  documentRefId: string,
  parentId = "0"
): Promise<void> => {
  if (!docVerId || !chapters || chapters.length === 0) {
    return
  }

  for (const chapter of chapters) {
    const newChapter: ChapterObject = {
      ...chapter,
      documentRefId,
      parentId,
      documentVersionId: docVerId,
      access:
        chapter.access || chapter.access.length > 0
          ? chapter.access
          : defaultAccess,
      epi_status: {
        status: "",
        lastUpdated: "",
        userLastUpdated: "",
        comments: [],
      },
      client_status: {
        status: "",
        lastUpdated: "",
        userLastUpdated: "",
        comments: [],
      },
      editing: {
        email: "",
        name: "",
        surname: "",
        isEditing: false,
      },
    }

    delete newChapter.id
    delete newChapter.subchapters

    const createChapterObjectResult: any = await createChapterObjectAPI(
      newChapter
    )

    checkIfError(createChapterObjectResult, newChapter, "[Create Chapter]")

    await traverseAndCreateChapters(
      docVerId,
      chapter.subchapters,
      createChapterObjectAPI,
      documentRefId,
      createChapterObjectResult.data.data.createChapterObject.id
    )
  }
}

// ================= Updates ================= //
export const updateDocument = async (
  updateDocumentAPI: MutationTrigger<any>,
  document: Document
): Promise<void> => {
  const response: any = await updateDocumentAPI(document)

  checkIfError(response, document, "[Update Document]")
}

export const updateDocumentVersion = async (
  updateDocumentVersionAPI: MutationTrigger<any>,
  documentVersion: DocumentVersion
): Promise<void> => {
  const response: any = await updateDocumentVersionAPI(documentVersion)

  checkIfError(response, documentVersion, "[Update Document Version]")
}

export const updateChapterObjectFunc = async (
  updateChapterObjectAPI: MutationTrigger<any>,
  chapter: Partial<ChapterObject>
): Promise<void> => {
  const response: any = await updateChapterObjectAPI(chapter)

  checkIfError(response, chapter, "[Update Chapter]")
}

export const updateImageDataAmplify = async (imageData: any): Promise<void> => {
  const response: any = await client.graphql({
    query: updateImageData,
    variables: {
      input: imageData,
    },
  })

  checkIfError(response, imageData, "[Batch Create Image Data Amplify]")
}

export const updateChapterObjectAmplify = async (
  chapter: ChapterObject,
  input: UpdateChapterObjectInput
): Promise<void> => {
  const response: any = await client.graphql({
    query: updateChapterObject,
    variables: {
      input,
    },
  })

  checkIfError(response, chapter, "[Update Chapter Amplify]")
}

// ================= Deletes ================= //
export const deleteCountry = async (
  deleteCountryAPI: MutationTrigger<any>,
  id: string
): Promise<void> => {
  const response: any = await deleteCountryAPI(id)

  checkIfError(response, `Country Id: ${id}`, "[Delete Document]")
}

export const deleteDocument = async (
  deleteDocumentAPI: MutationTrigger<any>,
  data: {
    countryId: string
    id: string
  }
): Promise<void> => {
  const response: any = await deleteDocumentAPI(data)

  checkIfError(response, data, "[Delete Document]")
}

export const deleteDocumentVersion = async (
  deleteDocumentVersionAPI: MutationTrigger<any>,
  data: {
    documentId: string
    id: string
  }
): Promise<void> => {
  const response: any = await deleteDocumentVersionAPI(data)

  checkIfError(response, data, "[Delete Document Version]")
}
