// ==================== External Imports ==================== //
import React from "react"
import { Divider, Tab, Tabs, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import { a11yProps } from "util/helper"
import UserProjects from "./UserProjects"
import TabPanel from "components/TabPanel"
import AccessManagement from "./AccessManagement"
import PersonalInformation from "./PersonalInformation"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    padding: theme.spacing(0, 0, 2.5, 2.5),
  },
}))

const EditUserDialogBody: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  // ==================== Functions ==================== //
  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  return (
    <div className={classes.container}>
      <Tabs
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        aria-label="edit user tab"
        style={{
          marginBottom: "0.25rem",
        }}
      >
        <Tab label="Personal Information" {...a11yProps(0)} />
        <Tab label="Access Management" {...a11yProps(1)} />
        <Tab label="User Projects" {...a11yProps(2)} />
      </Tabs>
      <Divider />
      <TabPanel value={value} index={0}>
        <PersonalInformation />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AccessManagement />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserProjects />
      </TabPanel>
    </div>
  )
}

export default EditUserDialogBody
