import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import RefreshIcon from "@material-ui/icons/Refresh"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Paper, Tooltip, Typography, Grid } from "@material-ui/core"

import imageLogo from "../../media/logo.svg"
import { RootState } from "../../redux/store"
import { Status } from "../../shared/types/status"
import { Project } from "../../shared/types/project"
import IconButton from "@material-ui/core/IconButton"
import { getProjects } from "../../redux/slices/projects"
import Loader from "../../components/Loading/Loader"
import { fetchProjectsByUserV2 } from "../../redux/thunks/projectsThunk"
import useAppState from "../../hooksV1/useAppState"
import NoDataMessage from "../../components/NoDataMessage"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  iconButton: {
    color: "#111B43",
    marginLeft: "0.5rem",
    backgroundColor: "#D1D7DB",
  },
  projectsContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem",
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    borderBottom: "1px solid rgba(0,0,0,0.5)",
  },
  paperContainer: {
    padding: "1em",
    height: "11vw",
    width: "11vw",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: "1rem",
    marginBottom: "2rem",
    textAlign: "center",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: theme.palette.action.hover,
    },
  },
  projectTitleContainer: {
    wordWrap: "break-word",
    width: "100%",
  },
}))

const ProjectsV1: React.FC = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    setActiveProjectUser,
    setActiveProject,
    setDocuments,
    setActiveCountry,
    setActiveDocument,
    setCountries,
  } = useAppState()

  const projects: Project[] = useSelector(getProjects) || []
  const fetchingProjectsStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  )

  useEffect(() => {
    setDocuments([])
    setActiveDocument(null)

    setCountries([])
    setActiveCountry(null)
  }, [])

  const openProject = (project_id: string) => {
    const selectedProject = projects.find(
      (project) => project.id === project_id
    )
    setActiveProject(selectedProject)
    const activeUser = selectedProject?.users.find(
      (user) => user.user.id === sessionStorage.getItem("id")
    )
    setActiveProjectUser(activeUser)
    navigate(`/project/${project_id}`)
  }

  const onFetchProjects = () => {
    let fetchProjThunk = fetchProjectsByUserV2(sessionStorage.getItem("id"))
    dispatch(fetchProjThunk)
  }

  useEffect(() => {
    onFetchProjects()
  }, [])

  const classes = useStyles()

  const user =
    localStorage.getItem("firstname") + " " + localStorage.getItem("surname")

  return (
    <div className={classes.container}>
      <h2>Welcome to EPI-Centre, {user}!</h2>
      <div className={classes.pageTitleContainer}>
        <h3>Select a Project to begin</h3>
        <Tooltip title="Refresh">
          <IconButton
            size={"small"}
            color="primary"
            onClick={onFetchProjects}
            className={classes.iconButton}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </div>

      <Grid container className={classes.projectsContainer}>
        {projects.length > 0 ? (
          projects.map((project) => (
            <Paper
              elevation={2}
              key={project.id}
              className={classes.paperContainer}
              onClick={() => openProject(project.id)}
            >
              <img
                src={imageLogo}
                alt=""
                style={{
                  width: "6vw",
                  height: "6vw",
                }}
              />
              <div className={classes.projectTitleContainer}>
                <Typography variant="h6" color="primary">
                  {project.name}
                </Typography>
              </div>
            </Paper>
          ))
        ) : (
          <NoDataMessage text={"No assigned projects at the moment"} />
        )}
      </Grid>
      <Loader open={fetchingProjectsStatus === Status.loading} />
    </div>
  )
}

export default ProjectsV1
