import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import React, { useState, useEffect } from "react"

import {
  AuditTrailOperations,
  ChapterObject,
  Operations,
  PartialAuditTrail,
} from "shared/types-exp"
import useAddToWorkflowDialogV1 from "hooksV1/useAddToWorkflowDialogV1"
import {
  useUpdateDocumentMutation,
  useCreateAuditTrailMutation,
  useBatchUpdateChapterObjectsMutation,
  useGetDocumentVersionWithChaptersV1Query,
} from "redux/services"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import { createAuditTrailObject, transformArray } from "util/helper"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import { logger } from "util/logger"
import {
  createAuditTrail,
  batchUpdateChapters,
  updateDocument,
} from "util/batchHook"

type AddToWorkflowDialogV1Props = {
  setWorkflow: (isEnabled: boolean) => void
}

const AddToWorkflowDialogV1: React.FC<AddToWorkflowDialogV1Props> = ({
  setWorkflow,
}) => {
  const {
    activeCountry,
    activeDocument,
    setActiveDocument,
    activeDocumentVersion,
    setChapterObjects,
    setChapterObjectsList,
  } = useAppState()
  const snackBar = useSnackBar()
  const { isProjectEnvironment } = checkEnvironment()
  const addToWorkflowDialog = useAddToWorkflowDialogV1()

  const [isLoading, setIsLoading] = useState(false)
  const [chapters, setChapters] = useState<ChapterObject[]>([])
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [updateDocumentAPI] = useUpdateDocumentMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchUpdateChapterObjectAPI] = useBatchUpdateChapterObjectsMutation()

  const { data: documentVersionData, isLoading: isLoadingDocumentsVersion } =
    useGetDocumentVersionWithChaptersV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  useEffect(() => {
    if (!documentVersionData) return

    const documentVersion = documentVersionData.data.getDocumentVersion

    if (!documentVersion) return

    const chapters = documentVersion.chapters["items"] as ChapterObject[]
    setChapters(chapters)
  }, [documentVersionData])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleAddToWorkFlow()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    addToWorkflowDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleAddToWorkFlow = async () => {
    try {
      if (activeCountry?.global) {
        snackBar.setMessage(
          "Can't add a global document to a workflow. Please try a different document."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        return
      }

      setIsLoading(true)
      let clonedChapters = cloneDeep(chapters)

      clonedChapters = clonedChapters.map((chapter) => {
        const chapterCopy = {
          ...chapter,
          isIncludedInWorkflow: true,
          epi_status: {
            comments: [],
            lastUpdated: new Date().toISOString(),
            status: "To be started",
            userLastUpdated: "",
          },
          client_status: {
            comments: [],
            lastUpdated: new Date().toISOString(),
            status: "To be started",
            userLastUpdated: "",
          },
        }

        delete chapterCopy.subchapters

        return chapterCopy
      })

      const updatedDocument = {
        ...activeDocument,
        isIncludedInWorkflow: true,
        lastUpdated: new Date().toISOString(),
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.DOCUMENT,
        `Added the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment, into a workflow. `
      )

      await Promise.all([
        updateDocument(updateDocumentAPI, updatedDocument),
        createAuditTrail(createAuditTrailAPI, auditTrail),
        batchUpdateChapters(batchUpdateChapterObjectAPI, clonedChapters),
      ])

      setWorkflow(true)
      setActiveDocument(updatedDocument)
      setChapterObjectsList(clonedChapters)
      setChapterObjects(transformArray(clonedChapters))

      snackBar.setMessage("The document is successfully added to a workflow.")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("AddToWorkflowDialog", "handleAddToWorkFlow", error)

      snackBar.setMessage(
        "An error occurred while adding the document to a workflow. Please try again."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleCloseDialog}
      open={addToWorkflowDialog.isOpen}
      aria-labelledby="confirmation-dialog-title"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle id="confirmation-dialog-title">
        Confirm Add To Workflow
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to add this document to a workflow?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="text"
          color="primary"
          disabled={isLoading}
          onClick={addToWorkflowDialog.onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading || isLoadingDocumentsVersion}
          onClick={handleAddToWorkFlow}
        >
          Confirm
        </Button>
      </DialogActions>

      {(isLoading || isLoadingDocumentsVersion) && <Loader open={true} />}
    </Dialog>
  )
}

export default AddToWorkflowDialogV1
