// ==================== External Imports ==================== //
import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Theme,
  ListItem,
  makeStyles,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"

// ==================== Local Imports ==================== //
import useAppState from "../../hooksV1/useAppState"
import { checkEnvironment } from "../../util/environment"
import useBooleanState from "hooksV1/useBooleanStates"

type SideNavButtonProps = {
  title: string
  path: string
  Icon: React.ElementType
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonColor: {
    color: "red",
  },
}))

const isSelected = (path: string, currentPath: string) => {
  if (currentPath === path) return true

  const currentPathArray = currentPath.split("/")

  if (
    (currentPathArray[currentPathArray.length - 1] === "Global" ||
      currentPathArray[currentPathArray.length - 2] === "country") &&
    path === "content"
  )
    return true

  return currentPathArray[currentPathArray.length - 1] === path
}

const SideNavButton: React.FC<SideNavButtonProps> = ({ title, path, Icon }) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  let navigate = useNavigate()
  let location = useLocation()
  const { isProjectEnvironment } = checkEnvironment()

  const {
    setActiveDocument,
    setChapterObjects,
    setChapterObjectsList,
    setActiveChapterObject,
    setActiveDocumentVersion,
  } = useAppState()
  const { isEditingBlocked } = useBooleanState()

  // ==================== Variables ==================== //
  const isLeaveDocumentButton = title === "Leave Document"
  const isLeaveProjectButton = title === "Leave Project"

  // ==================== Functions ==================== //
  const handleClick = () => {
    if (isLeaveDocumentButton) {
      const pathArray = location.pathname.split("/")
      const newPath = `/project/${pathArray[2]}/country/${pathArray[6]}/home`

      navigate(isProjectEnvironment ? newPath : path)
      setChapterObjects(null)
      setChapterObjectsList(null)
      setActiveDocument(null)
      setActiveChapterObject(null)
      setActiveDocumentVersion(null)
    } else {
      navigate(path)
    }
  }

  return (
    <ListItem
      button
      style={{
        overflow: "hidden",
      }}
      disabled={isEditingBlocked}
      onClick={handleClick}
      selected={isSelected(path, location.pathname)}
      dense
    >
      <ListItemIcon>
        <Icon
          className={
            isLeaveDocumentButton || isLeaveProjectButton
              ? classes.buttonColor
              : ""
          }
          style={{ fontSize: "20px" }}
        />
      </ListItemIcon>
      <ListItemText
        className={
          isLeaveDocumentButton || isLeaveProjectButton
            ? classes.buttonColor
            : ""
        }
        primary={title}
      />
    </ListItem>
  )
}

export default SideNavButton
