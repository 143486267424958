import React, { useState, useEffect } from "react"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core"

import { cloneDeep } from "lodash"
import SectionItem from "./SectionItem"
import useAppState from "hooksV1/useAppState"
import { ChapterSection } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

type SectionListProps = {
  sectionsProp: ChapterSection[]
  setSectionsProp: React.Dispatch<React.SetStateAction<ChapterSection[]>>
}

const SectionList: React.FC<SectionListProps> = ({
  sectionsProp,
  setSectionsProp,
}) => {
  const snackBar = useSnackBar()
  const { activeCountry } = useAppState()

  const { isTemplateEnvironment, isProjectEnvironment } = checkEnvironment()

  const [sectionsIdMap, setSectionsIdMap] =
    useState<Map<string, ChapterSection>>()

  useEffect(() => {
    if (!sectionsProp) return

    const sectionMap: Map<string, ChapterSection> = new Map(
      sectionsProp.map((section) => [section.id, section])
    )

    setSectionsIdMap(sectionMap)
  }, [sectionsProp])

  const getErrorMessage = () => {
    if (isTemplateEnvironment && !activeCountry?.global)
      return "Cannot swap sections from country with sections from global"

    if (isTemplateEnvironment && activeCountry?.global)
      return "Cannot swap non-global sections with global sections"

    return "Cannot swap sections from template with sections from project"
  }

  const isFromSameEnvironment = (activeId: string, overId: string): boolean => {
    const activeOject = sectionsIdMap.get(activeId)
    const overOject = sectionsIdMap.get(overId)

    if (isProjectEnvironment && activeOject.isProject && overOject.isProject)
      return true

    if (isTemplateEnvironment && activeOject.isCountry && overOject.isCountry)
      return true

    if (isTemplateEnvironment && activeOject.isGlobal && overOject.isGlobal)
      return true

    snackBar.setMessage(getErrorMessage())
    snackBar.setMessageSeverity(SnackType.SnackError)
    snackBar.onOpen()

    return false
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!activeCountry?.global) {
      if (
        !isFromSameEnvironment(active.id.toString(), over?.id.toString() || "")
      )
        return
    }

    if (active.id !== over?.id) {
      const prevSections = cloneDeep(sectionsProp)

      const oldIndex = prevSections.findIndex(
        (section) => section.id === active.id
      )

      const newIndex = prevSections.findIndex(
        (section) => section.id === over?.id
      )

      const newSections = arrayMove(prevSections, oldIndex, newIndex)

      setSectionsProp(newSections)
    }
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={sectionsProp.map((sections) => sections.id)}
        strategy={verticalListSortingStrategy}
      >
        {sectionsProp.map((section) => (
          <SectionItem section={section} key={section.id} />
        ))}
      </SortableContext>
    </DndContext>
  )
}

export default SectionList
