import {
  useCreateDocumentMutation,
  useCreateDocumentVersionMutation,
  useGetGlobalCountryWithDocumentsV1Query,
} from "redux/services"
import { useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { useDispatch } from "react-redux"
import { defaultAccess } from "util/constants"
import { DocumentVersion } from "shared/types-exp"
import { invalidateAll } from "util/helper"

const useInitializeBusinessBlueprint = (): void => {
  const dispatch = useDispatch()

  const [createDocumentAPI] = useCreateDocumentMutation()
  const [createDocumentVersionAPI] = useCreateDocumentVersionMutation()
  const { data: globalCountry } = useGetGlobalCountryWithDocumentsV1Query()

  const createBusinessBlueprint = (countryId: string) => {
    const refId = uuidv4()

    createDocumentAPI({
      countryId,
      refId,
      name: "Business Blueprint",
      author: "System",
      lastUpdated: new Date().toISOString(),
      activeVersion: 1,
      enabled: true,
      isProject: false,
      isIncludedInWorkflow: false,
      openForChanges: false,
      projectType: "",
    }).then(async (createDocumentResult) => {
      if ("data" in createDocumentResult) {
        const createdDocument = createDocumentResult.data.data.createDocument
        const docVersion: Partial<DocumentVersion> = {
          documentId: createdDocument.id,
          documentRefId: createdDocument.refId,
          isCountry: false,
          isGlobal: true,
          isProject: false,
          refId: uuidv4(),
          latestVersion: 1,
          users: [],
          access: defaultAccess,
          sections: [],
        }

        await createDocumentVersionAPI(docVersion)

        invalidateAll(dispatch)
      }
    })
  }

  useEffect(() => {
    if (!globalCountry) return

    const countries = globalCountry?.data?.listCountries?.items

    if (countries?.length === 0) return

    const country = countries[0]

    const businessBlueprint = country.documents?.items?.find(
      (doc) => doc.name.toLowerCase() === "business blueprint"
    )

    if (businessBlueprint) return

    createBusinessBlueprint(country.id)
  }, [globalCountry])
}

export default useInitializeBusinessBlueprint
