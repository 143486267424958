/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  ListItem,
  Typography,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
} from "@material-ui/core"
import { Skeleton } from "@mui/material"
import { useDispatch } from "react-redux"
import * as lookup from "country-code-lookup"
import ReactCountryFlag from "react-country-flag"
import React, { useEffect, useState, useRef } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"

import {
  Document,
  Operations,
  ChapterObject,
  DocumentVersion,
  PartialAuditTrail,
  AuditTrailOperations,
  Country,
} from "shared/types-exp"
import {
  useCreateAuditTrailMutation,
  useSyncCountryOperationMutation,
  useGetFullCountriesByProjectRefIdForSyncQuery,
} from "redux/services"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { generateClient } from "aws-amplify/api"
import { createAuditTrail } from "util/batchHook"
import { getGlobalCountry } from "graphql/queries"
import { SyncCountriesModel } from "shared/types-exp/sync"
import useSyncCountriesV1 from "hooksV1/useSyncCountriesV1"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import { createAuditTrailObject, invalidateAll } from "util/helper"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "30vw",
    maxHeight: "60vh",
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  listItem: {
    padding: "1em",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  listItemText: {
    display: "inline-block",
  },
  typographInline: {
    display: "inline",
  },
}))

const client = generateClient()

export type DeleteCountriesModel = {
  documents: { id: string; countryId: string }[]
  documentVersions: { id: string; documentId: string }[]
  chapters: { id: string; documentVersionId: string }[]
  countryIds: string[]
}

const SyncCountriesDialogV1: React.FC = () => {
  const { activeProject } = useAppState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const syncDocDialog = useSyncCountriesV1()

  const fetchInitiated = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [countriesToPull, setCountriesToPull] = useState([])
  const [countriesListAll, setCountriesListAll] = useState(null)
  const [countriesToRemove, setCountriesToRemove] = useState([])
  const [syncCountriesModel, setSyncCountriesModel] =
    useState<SyncCountriesModel | null>(null)
  const [syncCountriesApi] = useSyncCountryOperationMutation()

  const { data: projectCountries, isLoading: isProjectCountriesLoading } =
    useGetFullCountriesByProjectRefIdForSyncQuery(activeProject?.id, {
      skip: !activeProject,
    })

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  useEffect(() => {
    if (!countriesListAll || !projectCountries) return

    const templateCountries = countriesListAll.map((country) => {
      return {
        ...country,
        documents: [],
      } as Country
    })

    const projectCountriesWithDocuments =
      projectCountries?.data?.listCountries.items.map((country) => {
        return {
          ...country,
          documents: country.documents?.items || [],
        } as Country
      })

    const { countriesToPull, countriesToDelete, countriesToAdd } =
      findCountriesToDeleteAndPull(
        templateCountries,
        projectCountriesWithDocuments
      )

    const request = extractCountryInformation(countriesToDelete)

    const syncCountriesModel: SyncCountriesModel = {
      ...request,
      countryNames: countriesToAdd,
      projectRefId: activeProject?.id,
    }

    setSyncCountriesModel(syncCountriesModel)
    setCountriesToPull(countriesToPull ?? [])
    setCountriesToRemove(countriesToDelete ?? [])
  }, [countriesListAll, projectCountries])

  useEffect(() => {
    if (fetchInitiated.current) return

    const fetchNonGlobalAndNonProjectCountries = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: getGlobalCountry,
          variables: {
            filter: {
              projectRefId: {
                eq: "",
              },
              global: {
                eq: false,
              },
            },
          },
        })

        const countries = [...(result?.data?.listCountries?.items || [])].sort(
          (a, b) => {
            const nameA = a.country_name.toUpperCase()
            const nameB = b.country_name.toUpperCase()

            if (nameA < nameB) {
              return -1
            }

            if (nameA > nameB) {
              return 1
            }

            return 0
          }
        )

        setCountriesListAll(countries)
      } catch (error) {
        logger(
          "CreateProjectDialog",
          "useEffect (fetchNonGlobalAndNonProjectCountries)",
          error
        )

        snackBar.setMessage(
          "An error occurred fetching the list of countries. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    fetchNonGlobalAndNonProjectCountries()
  }, [])

  useEffect(() => {
    setIsLoading(isProjectCountriesLoading)
  }, [isProjectCountriesLoading])

  const findCountriesToDeleteAndPull = (
    templateCountries: Country[],
    projectCountriesWithDocuments: Country[]
  ): {
    countriesToPull: {
      country_name: string
      code: string
    }[]
    countriesToDelete: Country[]
    countriesToAdd: string[]
  } => {
    const countriesToPull: {
      country_name: string
      code: string
    }[] = []
    const countriesToAdd: string[] = []
    const countriesToDelete: Country[] = []

    // Find countries in the first list that are not in the second list
    for (const country of templateCountries) {
      if (
        !projectCountriesWithDocuments.some(
          (item) => item.country_name === country.country_name
        )
      ) {
        countriesToPull.push({
          country_name: country.country_name,
          code: lookup.byCountry(country.country_name)?.iso2 ?? "",
        })
        countriesToAdd.push(country.country_name)
      }
    }

    // Find countries in the second list that are not in the first list
    for (const country of projectCountriesWithDocuments) {
      if (
        !templateCountries.some(
          (item) => item.country_name === country.country_name
        )
      ) {
        countriesToDelete.push(country)
      }
    }

    return { countriesToDelete, countriesToPull, countriesToAdd }
  }

  const extractCountryInformation = (
    countriesToDelete: Country[]
  ): DeleteCountriesModel => {
    const countryIds = []
    const documentIds = []
    const chapterObjectIds = []
    const documentVersionIds = []

    const chapterObjects = []
    const documentVersions = []

    // Function to extract documentVersionIds and documentVersions
    const extractDocumentData = (document: Document) => {
      documentIds.push({
        id: document.id,
        countryId: document.countryId,
      })
      documentVersions.push(...(document.documentVersions["items"] || []))
    }

    // Function to extract chapterObjectIds and chapterObjects
    const extractDocumentVersionData = (documentVersion: DocumentVersion) => {
      documentVersionIds.push({
        id: documentVersion.id,
        documentId: documentVersion.documentId,
      })
      chapterObjects.push(...(documentVersion.chapters["items"] || []))
    }

    // Function to extract chapterObjectIds from chapterObjects
    const extractChapterObjectData = (chapterObject: ChapterObject) => {
      chapterObjectIds.push({
        id: chapterObject.id,
        documentVersionId: chapterObject.documentVersionId,
      })
    }

    for (const country of countriesToDelete) {
      const documents = country.documents || []
      // Extract data from documents
      documents.forEach(extractDocumentData)

      // Extract data from documentVersions
      documentVersions.forEach(extractDocumentVersionData)

      // Extract data from chapterObjects
      chapterObjects.forEach(extractChapterObjectData)
      countryIds.push(country.id)
    }

    return {
      documents: documentIds,
      chapters: chapterObjectIds,
      documentVersions: documentVersionIds,
      countryIds,
    }
  }

  const handleOnSubmit = async () => {
    try {
      setIsLoading(true)

      const response: any = await syncCountriesApi(syncCountriesModel)

      if (
        response.error ||
        (response.error?.status && response.error.status.toString() !== "200")
      ) {
        throw Error(
          `${JSON.stringify(
            response.error.data
          )}, Operation: [Sync Country], Data Passed: ${JSON.stringify(
            syncCountriesModel
          )}`
        )
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.SYNC,
        Operations.SYNC_COUNTRIES,
        `Executed the Sync Countries operation`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)
      invalidateAll(dispatch)

      snackBar.setMessage("Sync Countries Completed Successful")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("SyncCountriesDialog", "handleSync", error)

      snackBar.setMessage(
        "An error occurred while syncing countries. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleOnSubmit()
    }
  }

  const handleClose = () => {
    setIsLoading(false)

    syncDocDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const isSubmitDisabled = () => {
    return (
      isLoading ||
      (countriesToPull.length === 0 && countriesToRemove.length === 0)
    )
  }

  return (
    <Dialog
      open={syncDocDialog.isOpen}
      onClose={handleCloseDialog}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Sync Countries</DialogTitle>
      <DialogContent className={classes.container} dividers>
        {isLoading ? (
          <Skeleton
            style={{
              margin: 0,
              borderRadius: "8px",
            }}
            height={40}
            variant="rounded"
            width={"100%"}
            animation="wave"
          />
        ) : countriesToPull.length === 0 && countriesToRemove.length === 0 ? (
          <Typography> No countries to sync</Typography>
        ) : (
          <>
            {countriesToPull.map((country, i) => {
              const countryCode = country?.global
                ? ""
                : lookup.byCountry(country?.country_name)?.iso2 ?? ""

              return (
                <ListItem className={classes.listItem} key={`Adding-${i}`}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <>
                        <ReactCountryFlag countryCode={countryCode} svg />
                        &nbsp;{country.country_name}
                        &nbsp;
                        <Typography
                          className={classes.typographInline}
                          style={{ color: "green" }}
                        >
                          (Adding)
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              )
            })}
            {countriesToRemove.map((country, i) => {
              const countryCode = country?.global
                ? ""
                : lookup.byCountry(country?.country_name)?.iso2 ?? ""

              return (
                <ListItem className={classes.listItem} key={`Removing-${i}`}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <>
                        <ReactCountryFlag countryCode={countryCode} svg />
                        &nbsp;{country.country_name}
                        &nbsp;
                        <Typography
                          className={classes.typographInline}
                          color="error"
                        >
                          (Deleting)
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              )
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={syncDocDialog.onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
          disabled={isSubmitDisabled()}
        >
          Sync
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default SyncCountriesDialogV1
