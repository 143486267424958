/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API"
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType
  __generatedQueryOutput: OutputType
}

export const getCountry = /* GraphQL */ `query GetCountry($id: ID!) {
  getCountry(id: $id) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCountryQueryVariables, APITypes.GetCountryQuery>
export const listCountries = /* GraphQL */ `query ListCountries(
  $filter: TableCountryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      documents {
        items {
          countryId
          id
          refId
          name
          author
          activeVersion
          documentVersions {
            items {
              documentId
              id
              latestVersion
              users
              access
              isProject
              isCountry
              isGlobal
              documentRefId
              refId
              __typename
            }
            nextToken
            __typename
          }
          lastUpdated
          enabled
          isProject
          isIncludedInWorkflow
          openForChanges
          projectType
          __typename
        }
        nextToken
        __typename
      }
      country_name
      active_status
      global
      projectRefId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>
export const listCountriesMultiple = /* GraphQL */ `query ListCountriesMultiple(
  $filter: TableCountryMultipleFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountriesMultiple(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      documents {
        items {
          countryId
          id
          refId
          name
          author
          activeVersion
          documentVersions {
            items {
              documentId
              id
              latestVersion
              users
              access
              isProject
              isCountry
              isGlobal
              documentRefId
              refId
              __typename
            }
            nextToken
            __typename
          }
          lastUpdated
          enabled
          isProject
          isIncludedInWorkflow
          openForChanges
          projectType
          __typename
        }
        nextToken
        __typename
      }
      country_name
      active_status
      global
      projectRefId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesMultipleQueryVariables,
  APITypes.ListCountriesMultipleQuery
>
export const getDocument =
  /* GraphQL */ `query GetDocument($countryId: ID!, $id: ID!) {
  getDocument(countryId: $countryId, id: $id) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetDocumentQueryVariables,
    APITypes.GetDocumentQuery
  >
export const listDocuments = /* GraphQL */ `query ListDocuments(
  $filter: TableDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      countryId
      id
      refId
      name
      author
      activeVersion
      documentVersions {
        items {
          documentId
          id
          latestVersion
          users
          sections {
            id
            content {
              plainText
              __typename
            }
            isGlobal
            isCountry
            isProject
            isClientEditable
            name
            type
            viewing
            refId
            __typename
          }
          access
          chapters {
            items {
              documentVersionId
              id
              refId
              parentId
              sharedId
              name
              isGlobal
              isCountry
              isProject
              documentRefId
              access
              treeIndex
              assignedProjectUser
              chapterOrder
              isIncludedInWorkflow
              subchapterCreateAllowed
              __typename
            }
            nextToken
            __typename
          }
          isProject
          isCountry
          isGlobal
          documentRefId
          refId
          __typename
        }
        nextToken
        __typename
      }
      lastUpdated
      enabled
      isProject
      isIncludedInWorkflow
      openForChanges
      projectType
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>
export const getVersion =
  /* GraphQL */ `query GetVersion($countryId: ID!, $id: ID!) {
  getVersion(countryId: $countryId, id: $id) {
    countryId
    id
    refId
    name
    author
    activeVersion
    lastUpdated
    enabled
    chapters {
      documentVersionId
      id
      refId
      parentId
      sharedId
      isGlobal
      isCountry
      isProject
      documentRefId
      name
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      editing {
        email
        name
        surname
        isEditing
        __typename
      }
      epi_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      client_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      treeIndex
      assignedProjectUser
      chapterOrder
      isIncludedInWorkflow
      subchapterCreateAllowed
      __typename
    }
    documentVersion {
      documentId
      id
      latestVersion
      users
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      isProject
      isCountry
      isGlobal
      documentRefId
      refId
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVersionQueryVariables, APITypes.GetVersionQuery>
export const listVersions = /* GraphQL */ `query ListVersions(
  $filter: TableVersionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      countryId
      id
      refId
      name
      author
      activeVersion
      lastUpdated
      enabled
      chapters {
        documentVersionId
        id
        refId
        parentId
        sharedId
        isGlobal
        isCountry
        isProject
        documentRefId
        name
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      documentVersion {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVersionsQueryVariables,
  APITypes.ListVersionsQuery
>
export const getDocumentVersion =
  /* GraphQL */ `query GetDocumentVersion($documentId: ID!, $id: ID!) {
  getDocumentVersion(documentId: $documentId, id: $id) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetDocumentVersionQueryVariables,
    APITypes.GetDocumentVersionQuery
  >
export const listDocumentVersions = /* GraphQL */ `query ListDocumentVersions(
  $filter: TableDocumentVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      documentId
      id
      latestVersion
      users
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      chapters {
        items {
          documentVersionId
          id
          refId
          parentId
          sharedId
          name
          isGlobal
          isCountry
          isProject
          documentRefId
          subchapters {
            items {
              documentVersionId
              id
              refId
              parentId
              sharedId
              name
              isGlobal
              isCountry
              isProject
              documentRefId
              access
              treeIndex
              assignedProjectUser
              chapterOrder
              isIncludedInWorkflow
              subchapterCreateAllowed
              __typename
            }
            nextToken
            __typename
          }
          sections {
            id
            content {
              plainText
              __typename
            }
            isGlobal
            isCountry
            isProject
            isClientEditable
            name
            type
            viewing
            refId
            __typename
          }
          access
          editing {
            email
            name
            surname
            isEditing
            __typename
          }
          epi_status {
            status
            lastUpdated
            userLastUpdated
            comments {
              email
              comment
              lastName
              timeStamp
              firstName
              metadata
              __typename
            }
            __typename
          }
          client_status {
            status
            lastUpdated
            userLastUpdated
            comments {
              email
              comment
              lastName
              timeStamp
              firstName
              metadata
              __typename
            }
            __typename
          }
          treeIndex
          assignedProjectUser
          chapterOrder
          isIncludedInWorkflow
          subchapterCreateAllowed
          __typename
        }
        nextToken
        __typename
      }
      isProject
      isCountry
      isGlobal
      documentRefId
      refId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentVersionsQueryVariables,
  APITypes.ListDocumentVersionsQuery
>
export const getChapterObject =
  /* GraphQL */ `query GetChapterObject($documentVersionId: ID!, $id: ID!) {
  getChapterObject(documentVersionId: $documentVersionId, id: $id) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetChapterObjectQueryVariables,
    APITypes.GetChapterObjectQuery
  >
export const listChapterObjects = /* GraphQL */ `query ListChapterObjects(
  $filter: TableChapterObjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listChapterObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      documentVersionId
      id
      refId
      parentId
      sharedId
      name
      isGlobal
      isCountry
      isProject
      documentRefId
      subchapters {
        items {
          documentVersionId
          id
          refId
          parentId
          sharedId
          name
          isGlobal
          isCountry
          isProject
          documentRefId
          subchapters {
            items {
              documentVersionId
              id
              refId
              parentId
              sharedId
              name
              isGlobal
              isCountry
              isProject
              documentRefId
              access
              treeIndex
              assignedProjectUser
              chapterOrder
              isIncludedInWorkflow
              subchapterCreateAllowed
              __typename
            }
            nextToken
            __typename
          }
          sections {
            id
            content {
              plainText
              __typename
            }
            isGlobal
            isCountry
            isProject
            isClientEditable
            name
            type
            viewing
            refId
            __typename
          }
          access
          editing {
            email
            name
            surname
            isEditing
            __typename
          }
          epi_status {
            status
            lastUpdated
            userLastUpdated
            comments {
              email
              comment
              lastName
              timeStamp
              firstName
              metadata
              __typename
            }
            __typename
          }
          client_status {
            status
            lastUpdated
            userLastUpdated
            comments {
              email
              comment
              lastName
              timeStamp
              firstName
              metadata
              __typename
            }
            __typename
          }
          treeIndex
          assignedProjectUser
          chapterOrder
          isIncludedInWorkflow
          subchapterCreateAllowed
          __typename
        }
        nextToken
        __typename
      }
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      editing {
        email
        name
        surname
        isEditing
        __typename
      }
      epi_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      client_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      treeIndex
      assignedProjectUser
      chapterOrder
      isIncludedInWorkflow
      subchapterCreateAllowed
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChapterObjectsQueryVariables,
  APITypes.ListChapterObjectsQuery
>
export const getAuditTrail =
  /* GraphQL */ `query GetAuditTrail($email: String!, $id: ID!) {
  getAuditTrail(email: $email, id: $id) {
    email
    id
    name
    surname
    event
    details
    type
    createdAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetAuditTrailQueryVariables,
    APITypes.GetAuditTrailQuery
  >
export const listAuditTrail = /* GraphQL */ `query ListAuditTrail(
  $filter: TableAuditTrailFilterInput
  $limit: Int
  $nextToken: String
) {
  listAuditTrail(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      email
      id
      name
      surname
      event
      details
      type
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuditTrailQueryVariables,
  APITypes.ListAuditTrailQuery
>

// ============================ New Queries ============================
export const listDocumentsByCountryId = /* GraphQL */ `query ListDocuments(
  $filter: TableDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      refId
      author
      enabled
      countryId
      isProject
      lastUpdated
      activeVersion
      isIncludedInWorkflow
      documentVersions {
        items {
          id
          refId
          access
          isGlobal
          isProject
          isCountry
          documentId
          latestVersion
          documentRefId
          sections {
            id
            name
            type
            refId
            viewing
            isGlobal
            isCountry
            isProject
            isClientEditable
          }
        }
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>

export const getGlobalCountry = /* GraphQL */ `query ListCountries(
  $filter: TableCountryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      global
      country_name
      active_status
    } 
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>

export const listCountriesWithNoRefId = /* GraphQL */ `query ListCountries(
  $filter: TableCountryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      documents {
        items {
          refId
          documentVersions {
            items {
              chapters {
                items {
                  documentVersionId
                  id
                  refId
                  parentId
                  sharedId
                  name
                  isGlobal
                  isCountry
                  isProject
                  documentRefId
                  sections {
                    id
                    content {
                      uploadedFiles {
                        id
                        name
                        type
                        size
                        content
                      }
                      plainText
                    }
                    isGlobal
                    isCountry
                    isProject
                    isClientEditable
                    name
                    type
                    viewing
                    refId
                  }
                  access
                  editing {
                    email
                    name
                    surname
                    isEditing
                  }
                  epi_status {
                    status
                    lastUpdated
                    userLastUpdated
                  }
                  client_status {
                    status
                    lastUpdated
                    userLastUpdated
                  }
                  treeIndex
                  assignedProjectUser
                  chapterOrder
                  isIncludedInWorkflow
                  subchapterCreateAllowed
                }
              }
            }
          }
        }
      }
    } 
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>

export const listChaptersByProjectRefId = /* GraphQL */ `query ListCountries(
  $filter: TableCountryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      documents {
        items {
          name
          refId
          documentVersions {
            items {
              chapters {
                items {
                  id
                  name
                  refId
                  access
                  sharedId
                  parentId
                  treeIndex
                  isGlobal
                  isCountry
                  isProject
                  chapterOrder
                  documentRefId
                  documentVersionId
                  assignedProjectUser
                  isIncludedInWorkflow
                  subchapterCreateAllowed
                  editing {
                    name
                    email
                    surname
                    isEditing
                  }
                   epi_status {
                    status
                    lastUpdated
                    userLastUpdated
                  }
                  client_status {
                    status
                    lastUpdated
                    userLastUpdated
                  }
                  sections {
                    content {
                      plainText
                      uploadedFiles {
                        id
                        name
                        size
                        type
                        content
                      }
                    }
                    id
                    name
                    type
                    refId
                    viewing
                    isGlobal
                    isCountry
                    isProject
                    isClientEditable
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>

export const listCountriesAndDocuments = /* GraphQL */ `query ListCountries(
  $filter: TableCountryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      global
      country_name
      active_status
      documents {
        items {
          id
          name
          refId
          author
          enabled
          countryId
          isProject
          projectType
          activeVersion
          openForChanges
          isIncludedInWorkflow
        }
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>

export const getDocumentVersionLimited =
  /* GraphQL */ `query GetDocumentVersion($documentId: ID!, $id: ID!) {
  getDocumentVersion(documentId: $documentId, id: $id) {
    id
    refId
    access
    isGlobal
    isProject
    isCountry
    documentId
    latestVersion
    documentRefId
    sections {
      id
      name
      type
      refId
      viewing
      isGlobal
      isCountry
      isProject
      isClientEditable
    }
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        access
        editing {
          email
          name
          surname
          isEditing
        }
        epi_status {
          status
        }
        client_status {
          status
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
      }
    }
  }
}
` as GeneratedQuery<
    APITypes.GetDocumentVersionQueryVariables,
    APITypes.GetDocumentVersionQuery
  >

export const getChapterObjectForSections =
  /* GraphQL */ `query GetChapterObject($documentVersionId: ID!, $id: ID!) {
  getChapterObject(documentVersionId: $documentVersionId, id: $id) {
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
        }
        plainText
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
    }
  }
}
` as GeneratedQuery<
    APITypes.GetChapterObjectQueryVariables,
    APITypes.GetChapterObjectQuery
  >
