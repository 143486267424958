import { makeStyles, Theme } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"

import epiUseLogo from "../../media/epi-use-white.webp"
import erpLogo from "../../media/erp-revenue-stamp.webp"

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  card: {
    width: '30%',
    marginTop: '3rem',
    marginBottom: '3rem',
    backgroundColor: '#262F3E',
  },
  text: {
    color: '#C2C2C2',
    textAlign: 'center',
  },
  heading: {
    color: '#E0E0E0',
    textAlign: 'center',
    marginTop: '1rem',
  },
  link: {
    color: '#E0E0E0',
    textDecoration: 'none',
  },
  logo: {
    width: '30%',
    height: 'auto',
    marginTop: '1rem',
  },
  erpLogo: {
    width: '10%',
    height: 'auto',
    marginTop: '0.5rem',
  },
}))

const HomeCards: React.FC<{ token: string | undefined | null }> = ({
  token,
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.cardContainer}>
        <Card className={classes.card} color="textPrimary">
            <CardContent>
              <Typography
                className={classes.text}
                variant="h5"
                component="h2"
              >
                <img src={epiUseLogo} alt="EPI-USE Logo" className={classes.logo}/>
              </Typography>
              <br/>
              <Typography
                className={classes.text}
                gutterBottom
              >
                EPI-USE is part of Group Elephant, which employs more than 3,700 people worldwide. EPI-USE is the world's largest and most experienced independent SAP HR/Payroll specialist and has emerged as a leader in designing, building, and implementing Cloud-based, hybrid and on-premises HR/Payroll systems for large, complex multinational corporations and public sector agencies.
              </Typography>
            </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.heading}
              variant="h5"
              component="h2"
            >
              All office locations
            </Typography>
            <br/>
            <Typography
              className={classes.text}
              style={{ marginTop: '0.7rem' }}
              gutterBottom
            >
              EPI-USE is a global organization with offices worldwide. View our regional offices <strong>
              <a
                href="https://www.epiuse.com/contact-us"
                className={classes.link}>
                here
              </a>
            </strong>.
            </Typography>
            <br/>
            <Typography
              className={classes.text}
              gutterBottom
            >
              Please contact us at <strong>
                <a
                  href="https://info@epiuse.com"
                  className={classes.link}>
                  info@epiuse.com
                </a>
              </strong>.
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.text}
              variant="h5"
              component="h2"
            >
              <img src={erpLogo} alt="ERP Logo" className={classes.erpLogo}/>
            </Typography>
            <br/>
            <Typography
              className={classes.text}
              gutterBottom
            >
              1% of our revenue is applied to the protection and preservation of at-risk elephants and rhinos, through a strategy that is based on poverty alleviation.
            </Typography>
            <br/>
            <Typography
              className={classes.text}
              gutterBottom
            >
              <strong>
              <a
                href="https://www.erp.ngo/"
                className={classes.link}>
                www.erp.ngo
              </a>
            </strong>.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default HomeCards
