import { create } from "zustand"
import { ProjectUserRole } from "shared/types/project"

type useEditProjectCountryRolesDialogV1State = {
  isOpen: boolean
  activeUser: ProjectUserRole
}

type useEditProjectCountryRolesDialogV1Actions = {
  onOpen: () => void
  onClose: () => void
  setActiveUser: (activeUser: ProjectUserRole) => void
}

const useEditProjectCountryRolesDialogV1 = create<
  useEditProjectCountryRolesDialogV1State &
    useEditProjectCountryRolesDialogV1Actions
>((set) => ({
  isOpen: false,
  activeUser: null,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setActiveUser: (activeUser) => set(() => ({ activeUser })),
}))

export default useEditProjectCountryRolesDialogV1
