// ==================== External Imports ==================== //
import { Theme, makeStyles } from "@material-ui/core"
import React, { useState } from "react"

// ==================== Local Imports ==================== //
import SettingsIcon from "@material-ui/icons/Settings"
import { ChapterSettingsV1 } from "./ChapterSettingsV1"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}))

const ChapterSettingsButton: React.FC = () => {
  // ==================== UseStates ==================== //
  const classes = useStyles()
  const [chapterSettingsMenu, setChapterSettingsMenu] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  // ==================== Functions ==================== //
  const handleSettingsIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setChapterSettingsMenu(!chapterSettingsMenu)
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <div onClick={handleSettingsIconClick} className={classes.container}>
        <SettingsIcon style={{ color: "white", fontSize: "17.5px" }} />
      </div>
      <ChapterSettingsV1
        chapterSettingsMenu={chapterSettingsMenu}
        setChapterSettingsMenu={setChapterSettingsMenu}
        anchorEl={anchorEl}
      />
    </>
  )
}

export default ChapterSettingsButton
