import { ChapterObject } from "shared/types-exp"

export function flattenChapters(chapters: ChapterObject[]): ChapterObject[] {
  // Initialize an array to hold the flattened chapters
  let flattenedChapters: ChapterObject[] = []

  // Helper function to recursively flatten the chapters
  function flatten(chapter: ChapterObject) {
    // Add the current chapter to the array
    flattenedChapters.push(chapter)

    // If the chapter has subChapters, recursively flatten them
    if (chapter.subchapters && chapter.subchapters.length > 0) {
      chapter.subchapters.forEach((subChapter) => flatten(subChapter))
    }
  }

  // Start the flattening process for each chapter in the array
  chapters.forEach((chapter) => flatten(chapter))

  return flattenedChapters
}

export function findChapterById(
  chapters: ChapterObject[],
  id: string
): ChapterObject | null {
  // Helper function to recursively search for the chapter by id
  function find(chapter: ChapterObject): ChapterObject | null {
    // If the current chapter has the matching id, return it
    if (chapter.id === id) {
      return chapter
    }

    // If the chapter has subChapters, recursively search them.
    if (chapter.subchapters && chapter.subchapters.length > 0) {
      for (let subChapter of chapter.subchapters) {
        const found = find(subChapter)

        if (found) {
          return found
        }
      }
    }

    // If the chapter is not found, return null
    return null
  }

  // Start the search process for each chapter in the array
  for (let chapter of chapters) {
    const result = find(chapter)

    if (result) {
      return result
    }
  }

  // If the chapter is not found in any of the provided chapters, return null
  return null
}
