import { create } from "zustand"

type ReOrderDialogState = {
  isOpen: boolean
}

type ReOrderDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useReOrderDialogV1 = create<ReOrderDialogState & ReOrderDialogActions>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export default useReOrderDialogV1
