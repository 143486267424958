import React from 'react';
import { ErrorOutline } from "@material-ui/icons";

const styles = {
    container: {
        display: "flex",
        padding: "1rem",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "1rem"
    },
    icon: {
        marginRight: "0.5rem"
    },
    text: {
        fontWeight: 600,
        marginTop: "0.1rem"
    }
};

type NoDateMessageProps = {
    text: string
}

const NoDataMessage: React.FC<NoDateMessageProps> = ({
    text
}) => {
    return (
        <div style={styles.container}>
            <ErrorOutline style={styles.icon}/>
            <span style={styles.text}>{text}</span>
        </div>
    )
}

export default NoDataMessage

