import { useEffect, useState } from "react"
import { logger } from "util/logger"

const useFetchCSV = (url, maxRetries = 3, retryDelay = 1000) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async (retryCount = 0) => {
      try {
        const response = await fetch(url)

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const text = await response.text()
        const rows = text.trim().split("\n")
        const headers = rows[0].split(",")
        const data = rows.slice(1).map((row) => {
          const values = row.split(",")
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index]
            return obj
          }, {})
        })

        setData(data)
        setLoading(false)
      } catch (error) {
        if (retryCount < maxRetries) {
          setTimeout(() => fetchData(retryCount + 1), retryDelay)
        } else {
          logger("DocumentViewer", "useEffect", error)
          setError(
            "An Error Occurred while Fetching CSV Data. Please try again later."
          )
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [url, maxRetries, retryDelay])

  return { data, loading, error }
}

export default useFetchCSV
