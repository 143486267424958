import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import _ from "lodash"
import { z } from "zod"
import { v4 as uuidv4 } from "uuid"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { toFormikValidationSchema } from "zod-formik-adapter"
import React, { FormEvent, useState, useEffect } from "react"

import {
  checkIfError,
  createAuditTrail,
  batchUpdateChapters,
} from "util/batchHook"
import {
  Operations,
  ChapterObject,
  DocumentVersion,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  transformArray,
  updateChapterOrders,
  newChapterSectionsMap,
  createAuditTrailObject,
  invalidateAll,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useCreateChapterObjectMutation,
  useBatchUpdateChapterObjectsMutation,
  useListChaptersByProjectRefIdQuery,
} from "redux/services"
import { logger } from "util/logger"
import { defaultAccess } from "util/constants"
import useAppState from "hooksV1/useAppState"
import { checkEnvironment } from "util/environment"
import Loader from "../components/Loading/Loader"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useAddChapterDialogV1 from "../hooksV1/useAddChapterDialogV1"

const AddChapterDialogV1: React.FC = () => {
  const {
    chapterObjects,
    activeCountry,
    activeDocument,
    setChapterObjects,
    chapterObjectsList,
    activeDocumentVersion,
    setChapterObjectsList,
    setActiveChapterObject,
  } = useAppState()

  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const addChapterDialog = useAddChapterDialogV1()

  const [isLoading, setIsLoading] = useState(false)
  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [createChapterObjectAPI] = useCreateChapterObjectMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()

  const { data: countriesList, refetch } = useListChaptersByProjectRefIdQuery(
    null,
    {
      skip: !activeCountry?.global,
    }
  )

  useEffect(() => {
    if (countriesList) refetch()
  }, [])

  const chapterNameExistsOnLevel = (name) => {
    return chapterObjects.some(
      (chapter) => chapter.name.trim().toLowerCase() === name.toLowerCase()
    )
  }

  const addChapterSchema = z.object({
    name: z
      .string()
      .min(3, { message: "Chapter name has to be at least 3 characters long" })
      .refine((value) => !chapterNameExistsOnLevel(value), {
        message: "Chapter name already exists on this level",
      }),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: toFormikValidationSchema(addChapterSchema),
    onSubmit: async () => handleChapterAdd(),
  })

  const handleChapterAdd = async () => {
    setIsLoading(true)

    try {
      if (activeCountry?.global) {
        await handleGlobalChapterAdd()
      } else {
        await handleNonGlobalChapterAdd()
      }

      invalidateAll(dispatch)

      snackBar.setMessage("Chapter Created Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("AddChapterDialogV1", "useFormik", error)

      snackBar.setMessage("Chapter Creation Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const handleNonGlobalChapterAdd = async () => {
    if (!chapterObjects) return

    const lastChapterObject = chapterObjects[chapterObjects.length - 1]
    const treeIndex = lastChapterObject ? lastChapterObject.treeIndex + 1 : 0
    const chapterOrder = lastChapterObject
      ? lastChapterObject.chapterOrder + 1
      : 0

    const newChapter: Partial<ChapterObject> = {
      documentVersionId: activeDocumentVersion.id,
      refId: uuidv4(),
      isProject: isProjectEnvironment,
      documentRefId: activeDocument.refId,
      isGlobal: false,
      isCountry: true,
      parentId: "0",
      sharedId: uuidv4(),
      name: formik.values.name,
      editing: {
        email: "",
        name: "",
        surname: "",
        isEditing: false,
      },
      epi_status: {
        status: activeDocument?.isIncludedInWorkflow ? "To be started" : "",
        lastUpdated: new Date().toISOString(),
        userLastUpdated: "",
        comments: [],
      },
      client_status: {
        status: activeDocument?.isIncludedInWorkflow ? "To be started" : "",
        lastUpdated: new Date().toISOString(),
        userLastUpdated: "",
        comments: [],
      },
      sections: newChapterSectionsMap(activeDocumentVersion.sections),
      access:
        activeDocumentVersion?.access &&
        activeDocumentVersion?.access?.length > 0
          ? activeDocumentVersion?.access
          : defaultAccess,
      treeIndex,
      chapterOrder,
      assignedProjectUser: "",
      isIncludedInWorkflow: activeDocument?.isIncludedInWorkflow || false,
      subchapterCreateAllowed: true,
    }

    const response: any = await createChapterObjectAPI(newChapter)

    checkIfError(response, newChapter, "[Create Chapter]")

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.CREATE,
      Operations.CHAPTER,
      `A chapter with the name ${formik.values.name}, was created in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await createAuditTrail(createAuditTrailAPI, auditTrail)

    const createdChapterObject = response.data.data.createChapterObject
    const createdChapterObjectWithSubchapters = {
      ...createdChapterObject,
      subchapters: [],
    }

    const tempChapterObjects = [
      ...chapterObjects,
      createdChapterObjectWithSubchapters,
    ]

    setChapterObjects(tempChapterObjects)
    setChapterObjectsList([
      ...chapterObjectsList,
      createdChapterObjectWithSubchapters,
    ])

    setActiveChapterObject(createdChapterObjectWithSubchapters)
  }

  const handleGlobalChapterAdd = async () => {
    if (!countriesList) return

    const refId = uuidv4()
    let newActiveChapter = null
    const chaptersToUpdate = []
    const countries = countriesList.data.listCountries.items

    for (const country of countries) {
      const documents = country.documents.items

      for (const doc of documents) {
        if (doc.refId !== activeDocument.refId) continue

        const activeVersion = doc.documentVersions.items[0]
        const chapters = activeVersion.chapters.items || ([] as ChapterObject[])
        const transformedChapters = transformArray(chapters)

        let newChapter: Partial<ChapterObject> | null = null

        if (activeDocument.id === doc.id) {
          newChapter = createNewChapterObject(
            activeVersion,
            transformedChapters,
            formik.values.name,
            refId
          )
        } else {
          const { newTreeIndex, newChapterOrder, updatedChapters } =
            updateChapterOrders(transformedChapters)

          chaptersToUpdate.push(...updatedChapters)

          newChapter = createNewChapterObject(
            activeVersion,
            transformedChapters,
            formik.values.name,
            refId,
            newTreeIndex,
            newChapterOrder
          )
        }

        const response: any = await createChapterObjectAPI(newChapter)

        checkIfError(response, newChapter, "[Create Chapter]")

        if (activeDocument.id === doc.id) {
          newActiveChapter = response.data.data.createChapterObject
        }
      }
    }

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.CREATE,
      Operations.CHAPTER,
      `A chapter with the name ${formik.values.name}, was created in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
    ])

    const createdChapterObjectWithSubchapters = {
      ...newActiveChapter,
      subchapters: [],
    }

    const tempChapterObjects = [
      ...chapterObjects,
      createdChapterObjectWithSubchapters,
    ]

    setChapterObjects(tempChapterObjects)
    setChapterObjectsList([
      ...chapterObjectsList,
      createdChapterObjectWithSubchapters,
    ])

    setActiveChapterObject(createdChapterObjectWithSubchapters)
  }

  const createNewChapterObject = (
    activeVersion: DocumentVersion,
    transformedChapters: ChapterObject[],
    chapterName: string,
    refId: string,
    newTreeIndex?: number,
    newChapterOrder?: number
  ): Partial<ChapterObject> => {
    const lastChapterObject =
      transformedChapters[transformedChapters.length - 1]
    const treeIndex =
      newTreeIndex !== undefined
        ? newTreeIndex
        : lastChapterObject
        ? lastChapterObject.treeIndex + 1
        : 0
    const chapterOrder =
      newChapterOrder !== undefined
        ? newChapterOrder
        : lastChapterObject
        ? lastChapterObject.chapterOrder + 1
        : 0

    return {
      isProject: false,
      isCountry: false,
      isGlobal: true,
      documentVersionId: activeVersion.id,
      documentRefId: activeDocument.refId,
      refId,
      parentId: "0",
      sharedId: uuidv4(),
      name: chapterName,
      epi_status: {
        status: "",
        lastUpdated: "",
        userLastUpdated: "",
        comments: [],
      },
      client_status: {
        status: "",
        lastUpdated: "",
        userLastUpdated: "",
        comments: [],
      },
      editing: {
        email: "",
        name: "",
        surname: "",
        isEditing: false,
      },
      access:
        activeVersion?.access || activeVersion?.access?.length > 0
          ? activeVersion?.access
          : defaultAccess,
      sections: newChapterSectionsMap(activeVersion.sections),
      treeIndex,
      chapterOrder,
      assignedProjectUser: "",
      subchapterCreateAllowed: true,
      isIncludedInWorkflow: activeDocument?.isIncludedInWorkflow || false,
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  const handleClose = () => {
    formik.resetForm()
    setIsLoading(false)

    addChapterDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      open={addChapterDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="xs"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Add Chapter</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          id="name"
          label="Chapter name"
          type="text"
          fullWidth
          required
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) =>
            formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)
          }
          disabled={isLoading}
        >
          Add
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default AddChapterDialogV1
