import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Project } from "shared/types/project"
import { Status } from "shared/types/status"
import { User } from "shared/types/user"
import "../../config"
import type { RootState } from "../store"

// Define a type for the slice state

type usersState = {
  users: User[]
  invitationStatus: Status
  invitationMessage: string
  getUserMessage: string
  getUserStatus: Status
  updateUserMessage: string
  updateUserStatus: Status
  deleteUserMessage: string
  deleteUserStatus: Status
  userProjects: Project[]
  userProjectsStatus: Status
  userProjectsMessage: string
}

// Define the initial state using that type
const initialState: usersState = {
  users: [],
  invitationStatus: Status.idle,
  invitationMessage: "",
  getUserMessage: "",
  getUserStatus: Status.idle,
  updateUserMessage: "",
  updateUserStatus: Status.idle,
  deleteUserMessage: "",
  deleteUserStatus: Status.idle,
  userProjects: [],
  userProjectsStatus: Status.idle,
  userProjectsMessage: "",
}

export const usersSlice = createSlice({
  name: "users",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload.map((user: User) => user)
    },

    addUser: (state, action: PayloadAction<User>) => {
      state.users.push(action.payload)
    },

    removeUser: (state, action: PayloadAction<User>) => {
      state.users = state.users.filter((user) => user.id !== action.payload.id)
    },

    editUser: (state, action: PayloadAction<User>) => {
      //user
      // let index = state.users.findIndex((e) => e.id === action.payload.id)
      let index = state.users.findIndex((e) => e.email === action.payload.email)

      index === -1 ? { ...state } : state.users.splice(index, 1, action.payload)
    },
    setInvitationStatus: (state, action: PayloadAction<Status>) => {
      state.invitationStatus = action.payload
    },
    setInvitationMessage: (state, action: PayloadAction<string>) => {
      state.invitationMessage = action.payload
    },
    setGetUserMessage: (state, action: PayloadAction<string>) => {
      state.getUserMessage = action.payload
    },
    setGetStatus: (state, action: PayloadAction<Status>) => {
      state.getUserStatus = action.payload
    },
    setUpdateUserMessage: (state, action: PayloadAction<string>) => {
      state.updateUserMessage = action.payload
    },
    setUpdateStatus: (state, action: PayloadAction<Status>) => {
      state.updateUserStatus = action.payload
    },
    setDeleteUserMessage: (state, action: PayloadAction<string>) => {
      state.deleteUserMessage = action.payload
    },
    setDeleteStatus: (state, action: PayloadAction<Status>) => {
      state.deleteUserStatus = action.payload
    },
    setUserProjects: (state, action: PayloadAction<Project[]>) => {
      state.userProjects = action.payload
    },
    setUserProjectStatus: (state, action: PayloadAction<Status>) => {
      state.userProjectsStatus = action.payload
    },
    setUserProjectMessage: (state, action: PayloadAction<string>) => {
      state.userProjectsMessage = action.payload
    },
  },
})

export const {
  createUsers,
  removeUser,
  editUser,
  addUser,
  setInvitationStatus,
  setInvitationMessage,
  setGetUserMessage,
  setGetStatus,
  setUpdateUserMessage,
  setUpdateStatus,
  setDeleteUserMessage,
  setDeleteStatus,
  setUserProjects,
  setUserProjectStatus,
  setUserProjectMessage,
} = usersSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getUsers = (state: RootState) => state.users.users
export const getUserById = (state: RootState, id: string) =>
  state.users.users.find((u) => u.id === id && u)
export const getInvitationStatus = (state: RootState) =>
  state.users.invitationStatus
export const getInvitationMessage = (state: RootState) =>
  state.users.invitationMessage

export default usersSlice.reducer
