import {
  Chip,
  Paper,
  Theme,
  Table,
  Tooltip,
  MenuItem,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  makeStyles,
  Typography,
  TableContainer,
  IconButton,
} from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import { RootState } from "redux/store"
import * as lookup from "country-code-lookup"
import EditIcon from "@material-ui/icons/Edit"
import ReactCountryFlag from "react-country-flag"
import DeleteIcon from "@material-ui/icons/Delete"
import HistoryIcon from "@material-ui/icons/History"
import GlobalIcon from "@material-ui/icons/Language"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState, useRef } from "react"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { User } from "shared/types/user"
import { createAuditTrailObject } from "util/helper"
import { usersHeadCells } from "util/constants"
import { Project, ProjectRole, ProjectUserRole } from "shared/types/project"
import useEditUserState from "hooksV1/useEditUserState"
import EditUserDialogV1 from "dialogsV1/EditUserDialogV1"
import { checkEnvironment } from "../../util/environment"
import DeleteUserDialogV1 from "dialogsV1/DeleteUserDialog"
import useEditUserDialogV1 from "hooksV1/useEditUserDialogV1"
import useDeleteUserDialogV1 from "hooksV1/useDeleteUserDialogV1"
import { fetchProjectRoles } from "redux/thunks/projectRolesThunk"
import UserActivityLogDialog from "dialogsV1/UserActivityLogDialog"
import useUserActivityLogDialog from "hooksV1/useUserActivityLogDialog"
import { TableActionMenu } from "components/TableActionMenu/TableActionMenu"
import EditProjectCountryRolesDialogV1 from "dialogsV1/EditProjectCountryRolesDialogV1"
import useEditProjectCountryRolesDialogV1 from "hooksV1/useEditProjectCountryRolesDialogV1"
import { checkFilteredRolesObject } from "util/helper"
import useAppState from "hooksV1/useAppState"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import { useCreateAuditTrailMutation } from "redux/services"
import { Status } from "shared/types/status"
import { editProjectInfoOnDB, fetchProjects } from "redux/thunks/projectsThunk"
import { cloneDeep } from "lodash"

type CreateChipProp = {
  code: string
  message: string
  outlined: boolean
}

type UsersTableBodyV1Props = {
  page: number
  users: User[] | ProjectUserRole[]
  rowsPerPage: number
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: "auto",
  },
  tableContainer: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    height: "100%",
  },
  skeleton: {
    width: "100%",
    height: "100%",
  },
  iconButton: {
    color: "primary",
  },
}))

export const CreateChip: React.FC<CreateChipProp> = ({
  code,
  message,
  outlined,
}) => {
  if (code === "global") {
    return (
      <span style={{ marginRight: "4px", marginTop: "4px" }}>
        <Chip
          label={message}
          variant={outlined ? "outlined" : "outlined"}
          color="primary"
          size="small"
          icon={
            <GlobalIcon
              fontSize="small"
              style={{
                verticalAlign: "middle",
              }}
            />
          }
        />
      </span>
    )
  }

  if (code !== "") {
    return (
      <span style={{ marginRight: "4px", marginTop: "4px" }}>
        <Chip
          label={message}
          variant={outlined ? "outlined" : "outlined"}
          color="primary"
          size="small"
          icon={<ReactCountryFlag countryCode={code} svg />}
        />
      </span>
    )
  }

  return (
    <span style={{ marginRight: "4px", marginTop: "4px" }}>
      <Chip
        label={message}
        variant={outlined ? "outlined" : "outlined"}
        color="primary"
        size="small"
      />
    </span>
  )
}

export const TableHeadings: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        {usersHeadCells.map((headCell) => {
          return (
            <TableCell
              align="left"
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              <Typography variant="body1" color="primary" component="h1">
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const TableContent: React.FC<UsersTableBodyV1Props> = ({
  users = [],
  page,
  rowsPerPage,
}) => {
  const {
    activeProject,
    activeProjectUser,
    setActiveProject,
    setActiveProjectUser,
  } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const editUserStateHook = useEditUserState()
  const editUserDialog = useEditUserDialogV1()
  const deleteUserDialog = useDeleteUserDialogV1()
  const userActivityLogDialog = useUserActivityLogDialog()

  const editProjectRolesDialog = useEditProjectCountryRolesDialogV1()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const roles: ProjectRole[] = useSelector(
    (state: RootState) => state.projectRoles.roles
  )

  let auditTrail: PartialAuditTrail = null
  const newActiveProject = useRef(activeProject)
  const newActiveProjectUser = useRef(null)

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const updateProjectStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )

  const [menuOpen, setMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (roles.length === 0) {
      const rolesThunk = fetchProjectRoles()
      dispatch(rolesThunk)
    }
  }, [])

  useEffect(() => {
    if (updateProjectStatus === Status.success) {
      snackBar.setMessage("Project Updated Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } else if (updateProjectStatus === Status.failed) {
      snackBar.setMessage("Project Update Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }, [updateProjectStatus])

  const fetchProjectsFromDB = () => {
    const fetchProjectsThunk = fetchProjects()
    dispatch(fetchProjectsThunk)
  }

  const handleClose = () => {
    setActiveProject(newActiveProject.current)

    if (newActiveProjectUser.current.user.id === activeProjectUser?.user.id) {
      setActiveProjectUser(newActiveProjectUser.current)
    }

    fetchProjectsFromDB()
    setIsLoading(false)
    editProjectRolesDialog.onClose()
  }

  const handleEditUserClick = (user: User) => {
    editUserStateHook.setNewFirstName(user.firstname)
    editUserStateHook.setNewLastName(user.surname)
    editUserStateHook.setNewEmailAddress(user.email)
    editUserStateHook.setActiveUser(user)
    editUserStateHook.setNewRole(user.role_id)
    editUserDialog.onOpen()
    setMenuOpen(false)
  }

  const handleUserActivityLogClick = (user: User) => {
    userActivityLogDialog.setEmail(user.email)
    userActivityLogDialog.onOpen()
    setMenuOpen(false)
  }

  const handleDeleteUserClick = (user: User) => {
    editUserStateHook.setActiveUser(user)
    deleteUserDialog.onOpen()
    setMenuOpen(false)
  }

  const handleEditRolesClick = (user: ProjectUserRole) => {
    editProjectRolesDialog.setActiveUser(user)
    editProjectRolesDialog.onOpen()
  }

  const isArrayOfProjectUserRoles = (array): array is ProjectUserRole[] => {
    return array.every((item) => {
      return (
        typeof item === "object" &&
        item !== null &&
        "user" in item &&
        "role" in item
      )
    })
  }

  const getCountryFlag = (role: ProjectRole): string => {
    if (!role.countryId || !role.countryName) return ""

    if (role.countryId === "global") return "global"

    return lookup.byCountry(role?.countryName)?.iso2 ?? ""
  }

  const updateUserRole = (
    existingData: Project,
    newUserRoleData: ProjectUserRole
  ): Project => {
    const { user: newUser, role: newRoles } = newUserRoleData

    // Find the user in the existing data by matching the user ID
    const userToUpdate = existingData.users.find(
      (u) => u.user.id === newUser.id
    )

    // If the user is found, update their roles
    if (userToUpdate) {
      userToUpdate.role = newRoles.filter(
        (role) => role?.countryId && role?.countryName
      )
    }

    return {
      ...existingData,
      lastUpdated: new Date().toISOString(),
    }
  }

  const handleSave = (value: ProjectUserRole) => {
    setIsLoading(true)

    newActiveProject.current = updateUserRole(
      cloneDeep(newActiveProject.current),
      value
    )

    newActiveProjectUser.current = value

    auditTrail = createAuditTrailObject(
      AuditTrailOperations.UPDATE,
      Operations.PROJECT,
      `The project ${newActiveProject.current.name} was edited`
    )
    const editProjectThunk = editProjectInfoOnDB(newActiveProject.current)
    dispatch(editProjectThunk)

    createAuditTrailAPI(auditTrail)
  }

  const isProjectRoles = isArrayOfProjectUserRoles(users)

  return (
    <>
      <TableBody>
        {users.length > 0 &&
          users
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, i) => {
              return (
                <TableRow key={`${user.id}-${i}`}>
                  <TableCell align="left">
                    {isProjectRoles ? user.user.firstname : user.firstname}
                  </TableCell>
                  <TableCell>
                    {isProjectRoles ? user.user.surname : user.surname}
                  </TableCell>
                  <TableCell
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    {isProjectRoles ? user.user.email : user.email}
                  </TableCell>
                  <TableCell style={{ overflow: "hidden" }}>
                    {isProjectRoles ? (
                      user.role.map((role) => {
                        const code = getCountryFlag(role)

                        return (
                          <CreateChip
                            code={code}
                            key={uuidv4()}
                            message={role.role_name}
                            outlined={false}
                          />
                        )
                      })
                    ) : (
                      <CreateChip
                        code={""}
                        key={uuidv4()}
                        message={user.role_id.role_name}
                        outlined={false}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {isTemplateEnvironment && (
                      <TableActionMenu
                        tooltip="Manage User"
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                      >
                        <Tooltip
                          title="View/Edit User Information and Access Management"
                          placement="right-end"
                        >
                          <MenuItem onClick={() => handleEditUserClick(user)}>
                            <EditIcon /> &nbsp;Edit User
                          </MenuItem>
                        </Tooltip>

                        <Tooltip
                          title="View User Activity Log"
                          placement="right-end"
                        >
                          <MenuItem
                            onClick={() => handleUserActivityLogClick(user)}
                          >
                            <HistoryIcon /> &nbsp;User Activity
                          </MenuItem>
                        </Tooltip>

                        <Tooltip title="Remove this User" placement="right-end">
                          <MenuItem onClick={() => handleDeleteUserClick(user)}>
                            <DeleteIcon /> &nbsp;Delete User
                          </MenuItem>
                        </Tooltip>
                      </TableActionMenu>
                    )}

                    {isProjectEnvironment && (
                      <Tooltip
                        title="Edit User Roles in the Project"
                        placement="top"
                      >
                        <IconButton
                          className={classes.iconButton}
                          color="primary"
                          onClick={() => handleEditRolesClick(user)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
      </TableBody>

      {editProjectRolesDialog.isOpen && (
        <EditProjectCountryRolesDialogV1
          isLoading={isLoading}
          handleSave={handleSave}
          projectId={activeProject?.id}
          roles={checkFilteredRolesObject(roles)}
        />
      )}
    </>
  )
}

const UserTableBody: React.FC<UsersTableBodyV1Props> = ({
  page,
  users,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const useEditUserDialog = useEditUserDialogV1()
  const useDeleteUserDialog = useDeleteUserDialogV1()
  const userActivityLogDialog = useUserActivityLogDialog()

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table" className={classes.table}>
          <TableHeadings />
          <TableContent users={users} page={page} rowsPerPage={rowsPerPage} />
        </Table>
      </TableContainer>

      {userActivityLogDialog.isOpen && <UserActivityLogDialog />}
      {useDeleteUserDialog.isOpen && <DeleteUserDialogV1 />}
      {useEditUserDialog.isOpen && <EditUserDialogV1 />}
    </>
  )
}

export default UserTableBody
