// ==================== External Imports ==================== //
import { z } from "zod"
import React from "react"
import { useFormik } from "formik"
import { TextField, Grid, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import useEditProjectState from "hooksV1/useEditProjectState"
import { toFormikValidationSchema } from "zod-formik-adapter"

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "100%",
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: "0.5rem",
  },
}))

const EditProjectDialogProjectInformation: React.FC = () => {
  // ==================== Hooks ==================== //
  const {
    activeProject,
    authorError,
    projectNameError,
    authorHelperText,
    projectNameHelperText,
    setNewAuthorName,
    setNewProjectName,
  } = useEditProjectState()
  const classes = useStyles()

  // ==================== Variables ==================== //
  const editProjectSchema = z.object({
    name: z.string().min(1, { message: "Project name cannot be empty" }),
    author: z.string().min(1, { message: "Project name cannot be empty" }),
  })

  const formik = useFormik({
    initialValues: {
      name: activeProject?.name ?? "",
      author: activeProject?.author ?? "",
    },
    validationSchema: toFormikValidationSchema(editProjectSchema),
    onSubmit: (values) => {
      null
    },
  })

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={3} container>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="projectName"
            id="projectName"
            label="Project Name"
            name="name"
            variant="outlined"
            fullWidth
            required
            value={formik.values.name}
            error={projectNameError}
            helperText={projectNameHelperText}
            onChange={(e) => {
              formik.handleChange(e)
              setNewProjectName(e.target.value)
            }}
          />
        </Grid>
        <Grid sm={6} xs={12} item>
          <TextField
            autoComplete="author"
            id="author"
            label="Author"
            name="author"
            variant="outlined"
            fullWidth
            required
            value={formik.values.author}
            error={authorError}
            helperText={authorHelperText}
            onChange={(e) => {
              formik.handleChange(e)
              setNewAuthorName(e.target.value)
            }}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default EditProjectDialogProjectInformation
