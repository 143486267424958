// Need to use the React-specific entry point to allow generating React hooks
import {
  AttachmentsFilesBody,
  AttachmentsPostBody,
  DeleteAttachmentsPostBody,
  GetPreSignedUrlBody,
  GetPreSignedUrlResponse,
} from "shared/types-exp/attachments"
import config from "config"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const baseUrl =
  "ATTACHMENTS_URL" in config.apiGateway
    ? `${config.apiGateway.ATTACHMENTS_URL}`
    : `${config.apiGateway.URL}/attachments/`

// Define a service using a base URL and expected endpoints
export const attachmentsApi = createApi({
  reducerPath: "attachmentsApi",
  tagTypes: ["Attachments"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")

      if (token) {
        headers.set("Authorization", `${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    deleteAttachments: builder.mutation<any, AttachmentsPostBody>({
      query: (files) => ({
        url: "",
        method: "DELETE",
        body: files,
      }),
      invalidatesTags: ["Attachments"],
    }),
    getAttachmentsPreSignedUrl: builder.mutation<
      GetPreSignedUrlResponse,
      GetPreSignedUrlBody
    >({
      query: (files) => ({
        url: "getPreSignedUrl",
        method: "POST",
        body: files,
      }),
      invalidatesTags: ["Attachments"],
    }),
    deleteSectionAttachments: builder.mutation<any, DeleteAttachmentsPostBody>({
      query: (files) => ({
        url: "delelte-section-attachment",
        method: "DELETE",
        body: files,
      }),
      invalidatesTags: ["Attachments"],
    }),
    uploadAttachments: builder.mutation<any, AttachmentsFilesBody>({
      query: (files) => ({
        url: "",
        method: "POST",
        body: files,
      }),
      invalidatesTags: ["Attachments"],
    }),
  }),
})

export const {
  useDeleteAttachmentsMutation,
  useUploadAttachmentsMutation,
  useDeleteSectionAttachmentsMutation,
  useGetAttachmentsPreSignedUrlMutation,
} = attachmentsApi

export const attachmentsUtil = attachmentsApi.util
