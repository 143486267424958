import { create } from "zustand"

type RemoveFromWorkflowDialogState = {
  isOpen: boolean
}

type RemoveFromWorkflowDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useRemoveFromWorkflowDialogV1 = create<
  RemoveFromWorkflowDialogState & RemoveFromWorkflowDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useRemoveFromWorkflowDialogV1
