// ==================== External Imports ==================== //
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import AddIcon from "@material-ui/icons/Add"
import GlobalIcon from "@material-ui/icons/Language"
import { Button, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import "./ChapterNavigationStyle.css"
import useAppState from "hooksV1/useAppState"
import { ChapterObject } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import ReOrderDialogV1 from "dialogsV1/ReOrderDialogV1"
import ChapterSettingsButton from "./ChapterSettingsButton"
import useReOrderDialogV1 from "hooksV1/useReOrderDialogV1"
import SyncContentDialog from "dialogsV1/SyncContentDialog"
import AssignUserDialogV1 from "dialogsV1/AssignUserDialogV1"
import AddChapterDialogV1 from "dialogsV1/AddChapterDialogV1"
import EditChapterDialogV1 from "dialogsV1/EditChapterDialogV1"
import useAddChapterDialogV1 from "hooksV1/useAddChapterDialogV1"
import useAssignUserDialogV1 from "hooksV1/useAssignUserDialogV1"
import useSyncContentDialogV1 from "hooksV1/useSyncContentDialog"
import useEditChapterDialogV1 from "hooksV1/useEditChapterDialogV1"
import AddSubChapterDialogV1 from "dialogsV1/AddSubChapterDialogV1"
import DeleteChapterDialogV1 from "dialogsV1/DeleteChapterDialogV1"
import useRemoveChapterDialogV1 from "hooksV1/useRemoveChapterDialogV1"
import useAddSubChapterDialogV1 from "../../hooksV1/useAddSubChapterDialogV1"
import { getColorForStatus, invalidateAll } from "util/helper"
import useChapterNavigationV1 from "hooksV1/useChapterNavigation"
import useBooleanState from "hooksV1/useBooleanStates"
import { AuthHelper } from "util/authHelper"

const useStyles = makeStyles((theme: Theme) => ({
  chaptersContainer: {
    height: "100%",
  },
  activeButton: {
    backgroundColor: "#001744",
    color: "white",
    fontWeight: "normal",
    borderRadius: 25,
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    padding: "0.75rem",
  },
  notActiveButton: {
    color: "#001744",
    fontWeight: "normal",
    borderRadius: 25,
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    padding: "0.75rem",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  addChapterButton: {
    border: "1px dashed grey",
    marginTop: "0.75rem",
    marginBottom: "0.25rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    minHeight: 40,
  },
  addChapterButtonVisible: {
    opacity: 1,
    visibility: "visible",
    transition: "opacity 0.25s ease-in-out",
  },
  addChapterButtonHidden: {
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.25s ease-in-out",
  },
  chapterButtonContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chapterButtonText: {
    flex: 5,
    gap: "0.5rem",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
  },
  textContainer: {
    width: "97.5%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  chapterIcons: {
    flex: 1,
    gap: "0.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  skeletonLoader: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(1.5, 1, 0, 0),
  },
  status: {
    width: "0.5rem",
    height: "0.5rem",
    borderRadius: "50%",
  },
}))

type ChapterProps = {
  chapter: ChapterObject
  index: string
}

const Chapter: React.FC<ChapterProps> = ({ chapter, index }) => {
  // ==================== Hooks ==================== //

  const {
    activeCountry,
    activeDocument,
    activeDocumentVersion,
    activeProjectUser,
    activeChapterObject,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isEditingBlocked } = useBooleanState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  // ==================== Variables ==================== //
  const newIndex = index + "."
  useEffect(() => {
    if (!activeChapterObject) return

    if (chapter.id === activeChapterObject?.id) {
      localStorage.setItem("chapterIndex", newIndex)
    }
  }, [activeChapterObject])

  // ==================== Functions ==================== //
  const isGlobalChapter = (chapter: ChapterObject) => {
    if (isTemplateEnvironment && chapter.isGlobal) return true

    if (
      isProjectEnvironment &&
      (chapter.isGlobal || (chapter.isCountry && !chapter.isProject))
    ) {
      return true
    }

    return false
  }

  const handleChapterNavigation = () => {
    if (activeChapterObject?.id === chapter.id || isEditingBlocked) return

    invalidateAll(dispatch)
    setActiveChapterObject(chapter)
  }

  const isSettingsDisplayed = () => {
    if (activeChapterObject?.id !== chapter.id) return false

    if (
      isTemplateEnvironment &&
      !activeCountry?.global &&
      activeChapterObject?.isGlobal &&
      activeChapterObject?.id === chapter.id &&
      !activeChapterObject?.subchapterCreateAllowed
    )
      return false

    if (
      isProjectEnvironment &&
      !AuthHelper.canViewChapterSettings(
        activeChapterObject?.assignedProjectUser,
        activeCountry?.id,
        activeDocumentVersion?.access,
        activeProjectUser
      )
    )
      return false

    return true
  }

  return (
    <li>
      <button
        className={`chapter-list-button ${
          chapter.id === activeChapterObject?.id
            ? classes.activeButton
            : classes.notActiveButton
        }`}
        onClick={handleChapterNavigation}
      >
        <div className={classes.chapterButtonContainer}>
          <div className={classes.chapterButtonText}>
            {isProjectEnvironment && activeDocument?.isIncludedInWorkflow && (
              <div
                className={classes.status}
                style={{
                  backgroundColor: getColorForStatus(
                    chapter?.epi_status?.status
                  ),
                }}
              />
            )}
            <div className={classes.textContainer}>
              {newIndex} {chapter.name}
            </div>
          </div>
          <div className={classes.chapterIcons}>
            {isSettingsDisplayed() && <ChapterSettingsButton />}
            {isGlobalChapter(chapter) && (
              <GlobalIcon
                style={{
                  color:
                    chapter.id === activeChapterObject?.id
                      ? "white"
                      : "#001744",
                  fontSize: "17.5px",
                }}
              />
            )}
          </div>
        </div>
      </button>
      {(chapter.subchapters || []).length > 0 && (
        <ul className="chapter-sub-list">
          {chapter.subchapters.map(
            (subchapter: ChapterObject, index: number) => {
              const subChapterIndex = newIndex + (index + 1).toString()

              return (
                <Chapter
                  chapter={subchapter}
                  index={subChapterIndex}
                  key={subchapter.id}
                />
              )
            }
          )}
        </ul>
      )}
    </li>
  )
}

const AddChapterButton = () => {
  const classes = useStyles()
  const chapterNavHook = useChapterNavigationV1()
  const addChapterDialog = useAddChapterDialogV1()

  return (
    <div
      className={`${classes.addChapterButton} ${
        chapterNavHook.isOpen
          ? classes.addChapterButtonVisible
          : classes.addChapterButtonHidden
      }`}
    >
      <Button fullWidth onClick={addChapterDialog.onOpen}>
        <AddIcon />
        &nbsp;Add Chapter
      </Button>
    </div>
  )
}

const ChapterNavigationV1: React.FC = () => {
  const classes = useStyles()

  const {
    activeCountry,
    chapterObjects,
    activeProjectUser,
    activeDocumentVersion,
  } = useAppState()

  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const addChapterDialog = useAddChapterDialogV1()
  const assignUserDialog = useAssignUserDialogV1()
  const reOrderChapterDialog = useReOrderDialogV1()
  const syncContentDialog = useSyncContentDialogV1()
  const editChapterDialog = useEditChapterDialogV1()
  const removeChapterDialog = useRemoveChapterDialogV1()
  const addSubChapterDialog = useAddSubChapterDialogV1()

  return (
    <div
      className="chapter-list"
      style={{
        maxHeight: "78vh",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        marginTop: "0.5rem",
        position: "sticky",
        top: "0",
      }}
    >
      {((isProjectEnvironment &&
        AuthHelper?.canAddChapter(
          activeCountry?.id,
          activeDocumentVersion?.access,
          activeProjectUser
        )) ||
        isTemplateEnvironment) && <AddChapterButton />}

      <div className={classes.chaptersContainer}>
        {chapterObjects?.map((chapter, index) => (
          <Chapter
            chapter={chapter}
            key={chapter.id}
            index={(index + 1).toString()}
          />
        ))}
      </div>

      {addChapterDialog.isOpen && <AddChapterDialogV1 />}
      {syncContentDialog.isOpen && <SyncContentDialog />}
      {assignUserDialog.isOpen && <AssignUserDialogV1 />}
      {reOrderChapterDialog.isOpen && (
        <ReOrderDialogV1 chaptersProp={chapterObjects} />
      )}
      {editChapterDialog.isOpen && <EditChapterDialogV1 />}
      {removeChapterDialog.isOpen && <DeleteChapterDialogV1 />}
      {addSubChapterDialog.isOpen && <AddSubChapterDialogV1 />}
    </div>
  )
}

export default ChapterNavigationV1
