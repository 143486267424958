// ==================== External Imports ==================== //
import {
  Paper,
  Theme,
  Tooltip,
  makeStyles,
  IconButton,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useLocation } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import React, { useEffect, useState } from "react"

// ==================== Local Imports ==================== //
import ChapterHeaderV1 from "./ChapterHeaderV1"
import { checkEnvironment } from "util/environment"
import useBooleanState from "hooksV1/useBooleanStates"
import ChapterHeaderButtons from "./ChapterHeaderButtons"
import useChapterNavigationV1 from "hooksV1/useChapterNavigation"
import CountrySelectorV1 from "../CountrySelector/CountrySelectorV1"
import ProjectCountrySelectorV1 from "components/CountrySelector/ProjectCountrySelectorV1"

const useStyles = makeStyles((theme: Theme) => ({
  paperCard: {
    paddingTop: "0.15em",
    paddingBottom: "0.15em",
    display: "flex",
    alignItems: "center",
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  skeletonContainer: {
    width: "100%",
    padding: "1rem",
    display: "flex",
  },

  countrySelector: {
    gap: "0.1rem",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0, 0, 0, 1.5),
  },
}))

const ManageDocumentsToolBar: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const location = useLocation()
  const booleanState = useBooleanState()
  const chapterNavHook = useChapterNavigationV1()
  const { isProjectEnvironment } = checkEnvironment()

  // ==================== Variables ==================== //
  let pathArray = location.pathname.split("/")
  let section = pathArray[pathArray.length - 1]

  // ==================== Use Hooks ==================== //
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(
      booleanState.isCountriesLoading &&
        section !== "settings" &&
        section !== "countries"
    )
  }, [booleanState.isCountriesLoading])

  const showCountrySelector = () => {
    if (section === "content") {
      return (
        <div className={classes.countrySelector}>
          <Tooltip title="Chapter navigation menu">
            <IconButton
              color="primary"
              onClick={() =>
                chapterNavHook.isOpen
                  ? chapterNavHook.onClose()
                  : chapterNavHook.onOpen()
              }
            >
              {chapterNavHook.isOpen ? (
                <CloseIcon fontSize="small" />
              ) : (
                <MenuIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          {isProjectEnvironment ? (
            <ProjectCountrySelectorV1 />
          ) : (
            <CountrySelectorV1 />
          )}
        </div>
      )
    }

    return (
      <div className={classes.countrySelector}>
        {isProjectEnvironment ? (
          <ProjectCountrySelectorV1 />
        ) : (
          <CountrySelectorV1 />
        )}
      </div>
    )
  }

  return (
    <Paper className={classes.paperCard}>
      {isLoading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton
            style={{
              margin: 0,
              borderRadius: "8px",
            }}
            variant="rect"
            width={"100%"}
            animation="wave"
            height={20}
          />
        </div>
      ) : (
        <div className={classes.container}>
          {showCountrySelector()}
          <ChapterHeaderV1 />
          <ChapterHeaderButtons />
        </div>
      )}
    </Paper>
  )
}

export default ManageDocumentsToolBar
