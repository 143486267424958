import axios from "axios"
import {
  createUsers,
  editUser,
  setDeleteStatus,
  setDeleteUserMessage,
  setGetStatus,
  setGetUserMessage,
  setInvitationMessage,
  setInvitationStatus,
  setUpdateStatus,
  setUpdateUserMessage,
  setUserProjectMessage,
  setUserProjectStatus,
} from "redux/slices/usersSlice"
import { Status } from "shared/types/status"
import { User } from "shared/types/user"
import "../../config"
import config from "../../config"
import type { EPIDispatch, RootState } from "../store"

// Define a type for the slice state

export function inviteUser(email: string) {
  return async function inviteUser(dispatch: EPIDispatch, getState: RootState) {
    dispatch({ type: setInvitationStatus.type, payload: Status.loading })
    axios
      .post(`${config.apiGateway.URL}/email/invite`, {
        email,
      })
      .then(function (response) {
        const attributes = response.data.message as string
        dispatch({ type: setInvitationStatus.type, payload: Status.success })
        dispatch({ type: setInvitationMessage.type, payload: attributes })
      })
      .catch(function (error) {
        dispatch({ type: setInvitationStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch({
              type: setInvitationMessage.type,
              payload: error.response.data.message,
            })
        } else dispatch({ type: setInvitationMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setInvitationStatus.type, payload: Status.idle })
        dispatch({ type: setInvitationMessage.type, payload: "" })
      })
  }
}

export function fetchUsers() {
  return async function fetchUsers(dispatch: EPIDispatch, getState: RootState) {
    dispatch({ type: setGetStatus.type, payload: Status.loading })
    dispatch({ type: setGetUserMessage.type, payload: "" })
    axios
      .get(`${config.apiGateway.URL}/users/get`)
      .then(function (response) {
        const attributes = response.data.message as User[]
        dispatch({ type: createUsers.type, payload: attributes })
        dispatch({ type: setGetStatus.type, payload: Status.success })
        dispatch({ type: setGetUserMessage.type, payload: attributes })
      })
      .catch(function (error) {
        dispatch({ type: setGetStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetUserMessage.type, payload: error })
          }
        } else dispatch({ type: setGetUserMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetStatus.type, payload: Status.idle })
        dispatch({ type: setGetUserMessage.type, payload: "" })
      })
  }
}

export function editUserOnDB(user: User) {
  return async function fetchUsers(dispatch: EPIDispatch, getState: RootState) {
    dispatch({ type: setUpdateStatus.type, payload: Status.loading })
    dispatch({ type: setUpdateUserMessage.type, payload: "" })
    axios
      .put(`${config.apiGateway.URL}/users/update`, {
        id: user.id,
        email: user.email,
        firstname: user.firstname,
        surname: user.surname,
        enabled: user.enabled,
        role_id: user.role_id,
        last_login: user.last_login,
      })
      .then(function (response) {
        const attributes = response.data.message as string
        dispatch({ type: editUser.type, payload: user })
        dispatch({ type: setUpdateStatus.type, payload: Status.success })
        dispatch({ type: setUpdateUserMessage.type, payload: attributes })
      })
      .catch(function (error) {
        dispatch({ type: setUpdateStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setUpdateUserMessage.type, payload: error })
          }
        } else dispatch({ type: setUpdateUserMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setUpdateStatus.type, payload: Status.idle })
        dispatch({ type: setUpdateUserMessage.type, payload: "" })
      })
  }
}
export function editUserTemplateSystemRole(user: User) {
  return async function addUsersRole(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setUpdateStatus.type, payload: Status.loading })
    dispatch({ type: setUpdateUserMessage.type, payload: "" })
    axios
      .put(`${config.apiGateway.URL}/users/role`, {
        id: user.id,
        role_id: user.role_id.id,
      })
      .then(function (response) {
        const attributes = response.data.message as string
        dispatch({ type: editUser.type, payload: user })
        dispatch({ type: setUpdateStatus.type, payload: Status.success })
        dispatch({ type: setUpdateUserMessage.type, payload: attributes })
      })
      .catch(function (error) {
        dispatch({ type: setUpdateStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setUpdateUserMessage.type, payload: error })
          }
        } else dispatch({ type: setUpdateUserMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setUpdateStatus.type, payload: Status.idle })
        dispatch({ type: setUpdateUserMessage.type, payload: "" })
      })
  }
}

export function deleteUserOnDB(user: User) {
  return async function deleteUserOnDB(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setDeleteStatus.type, payload: Status.loading })
    dispatch({ type: setDeleteUserMessage.type, payload: "" })
    axios
      .delete(`${config.apiGateway.URL}/users/delete`, {
        data: { id: `${user.id}` },
      })
      .then(function (response) {
        const attributes = response.data.message as string
        // dispatch({ type: editUser.type, payload: user })
        dispatch({ type: setDeleteStatus.type, payload: Status.success })
        dispatch({ type: setDeleteUserMessage.type, payload: attributes })
      })
      .catch(function (error) {
        dispatch({ type: setDeleteStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setDeleteUserMessage.type, payload: error })
          }
        } else dispatch({ type: setDeleteUserMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setDeleteStatus.type, payload: Status.idle })
        dispatch({ type: setDeleteUserMessage.type, payload: "" })
      })
  }
}

export function fetchUserProjectsOnDB(user: User) {
  return async function fetchUserProjectsOnDB(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setUserProjectStatus.type, payload: Status.loading })
    dispatch({ type: setUserProjectMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/users/assigned_projects`, {
        id: user.id,
      })
      .then(function (response) {
        const attributes = response.data.message as string
        // dispatch({ type: editUser.type, payload: user })
        dispatch({ type: setUserProjectStatus.type, payload: Status.success })
        dispatch({ type: setUserProjectMessage.type, payload: attributes })
      })
      .catch(function (error) {
        dispatch({ type: setUserProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setUserProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setUserProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setUserProjectStatus.type, payload: Status.idle })
        dispatch({ type: setUserProjectMessage.type, payload: "" })
      })
  }
}
