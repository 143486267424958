import React, { useState, useEffect } from "react"
import { Grid, TextField } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import useEditUserState from "hooksV1/useEditUserState"

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "100%",
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: "0.5rem",
  },
}))

const PersonalInformation: React.FC = () => {
  // ==================== Hooks ==================== //
  const {
    activeUser,
    firstNameError,
    lastNameError,
    emailAddressError,
    // companyNameError,
    firstNameHelperText,
    lastNameHelperText,
    emailAddressHelperText,
    // companyNameHelperText,
    setNewFirstName,
    setNewLastName,
    setNewEmailAddress,
    // setNewCompanyName,
  } = useEditUserState()
  const classes = useStyles()

  // ==================== Use Hooks ==================== //
  const [firstName, setFirstName] = useState<string>(
    activeUser?.firstname || ""
  )
  const [email, setEmail] = useState<string>(activeUser?.email || "")
  const [lastName, setLastName] = useState<string>(activeUser?.surname || "")
  //todo: get company from activeUser
  // const [companyName, setCompanyName] = useState<string>("")

  useEffect(() => {
    setFirstName(activeUser?.firstname || "")
    setLastName(activeUser?.surname || "")
    setEmail(activeUser?.email || "")
    //todo: get company from activeUser
    // setCompanyName("")
  }, [activeUser])

  // ==================== Functions ==================== //
  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value)
    setNewEmailAddress(event.target.value)
  }

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFirstName(event.target.value)
    setNewFirstName(event.target.value)
  }

  const handleLastNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLastName(event.target.value)
    setNewLastName(event.target.value)
  }

  // const handleCompanyNameChange = (
  //   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  // ) => {
  //   setCompanyName(event.target.value)
  //   setNewCompanyName(event.target.value)
  // }

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={3} container>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="fname"
            id="firstName"
            label="First Name"
            name="firstName"
            variant="outlined"
            fullWidth
            required
            value={firstName}
            onChange={handleFirstNameChange}
            error={firstNameError}
            helperText={firstNameHelperText}
          ></TextField>
        </Grid>
        <Grid sm={6} xs={12} item>
          <TextField
            autoComplete="lname"
            id="lastName"
            label="Last Name"
            name="lastName"
            variant="outlined"
            value={lastName}
            fullWidth
            required
            onChange={handleLastNameChange}
            error={lastNameError}
            helperText={lastNameHelperText}
          />
        </Grid>
        <Grid className={classes.EPIGridSpacingLeft} xs={12} item>
          <TextField
            autoComplete="email"
            id="email"
            label="Email Address"
            name="email"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={handleEmailChange}
            error={emailAddressError}
            helperText={emailAddressHelperText}
          ></TextField>
        </Grid>
        {/* <Grid sm={6} xs={12} item>
          <TextField
            autoComplete="companyName"
            id="companyName"
            label="Company Name"
            name="companyName"
            variant="outlined"
            value={companyName}
            fullWidth
            // required
            onChange={handleCompanyNameChange}
            error={companyNameError}
            helperText={companyNameHelperText}
          />
        </Grid> */}
      </Grid>
    </form>
  )
}

export default PersonalInformation
