// ==================== External Imports ==================== //
import React from "react"
import { List, Paper, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import useAppState from "../../hooksV1/useAppState"
import { checkEnvironment } from "../../util/environment"
import ProjectPageToolbar from "../PageToolBar/ProjectPageToolbar"
import ProjectManageCountriesItem from "./ProjectManageCountriesItem"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  listItem: {
    padding: "1.5rem",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  skeleton: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  paperCard: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
    marginTop: "1rem",
  },
}))

const ProjectCountryManagement: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { countries, activeProjectUser } = useAppState()
  const { isProjectEnvironment } = checkEnvironment()

  return (
    <div className={classes.container}>
      <ProjectPageToolbar isCountryManagement={isProjectEnvironment} />

      <Paper className={classes.paperCard}>
        <List dense style={{ width: "100%" }}>
          {countries
            ?.filter((country) => {
              const result = activeProjectUser.role.find(
                (role) =>
                  (role.countryId === country.id ||
                    role.countryId === "global") &&
                  (role.role_name.toLowerCase() === "project manager" ||
                    role.role_name.toLowerCase() === "ams consultant")
              )

              return !!result
            })
            .map((country, i) => (
              <ProjectManageCountriesItem key={country.id} country={country} />
            ))}
        </List>
      </Paper>
    </div>
  )
}

export default ProjectCountryManagement
