import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  subHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subHeader: {
    width: "20%",
    textAlign: "center",
  },
}))

const ManageSectionSubHeaders: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.subHeaderContainer}>
      <div style={{ width: "30%" }}>Section Name</div>
      <div style={{ width: "15%" }}>Section Type</div>
      <div className={classes.subHeader}>Global</div>
      <div className={classes.subHeader}>Country</div>
      <div className={classes.subHeader}>Client-Editable</div>
      <div className={classes.subHeader}>Delete</div>
    </div>
  )
}

export default ManageSectionSubHeaders
