import { ThemeProvider } from "@material-ui/core"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./config"
import "./index.css"
import store from "./redux/store"
import { EPI_CENTER_THEME as theme } from "./shared/styles/muiThemeStyle"

import SnackBar from "components/SnackBar"
import { Amplify } from "aws-amplify"
import config from "aws-exports"

Amplify.configure(config)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <SnackBar />
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,

  document.getElementById("root")
)
