import { create } from "zustand"
import { Role_Id, User } from "shared/types/user"
import { ProjectUserRole } from "shared/types/project"

export type EditUserState = {
  activeUser: User
  activeProjectUserRole: ProjectUserRole
  firstNameError: boolean
  lastNameError: boolean
  emailAddressError: boolean
  companyNameError: boolean,
  firstNameHelperText: string
  lastNameHelperText: string
  emailAddressHelperText: string
  companyNameHelperText: string,
  newFirstName: string
  newLastName: string
  newEmailAddress: string
  newCompanyName: string

  newRole: Role_Id
}

export type EditUserActions = {
  setActiveUser: (activeUser: User) => void
  setActiveProjectRoleUser: (activeUser: ProjectUserRole) => void
  setFirstNameError: (firstNameError: boolean) => void
  setLastNameError: (lastNameError: boolean) => void
  setEmailAddressError: (emailAddressError: boolean) => void
  setCompanyNameError: (companyNameError: boolean) => void
  setFirstNameHelperText: (firstNameHelperText: string) => void
  setLastNameHelperText: (lastNameHelperText: string) => void
  setEmailAddressHelperText: (emailAddressHelperText: string) => void
  setCompanyNameHelperText: (companyNameHelperText: string) => void

  setNewFirstName: (newFirstName: string) => void
  setNewLastName: (newLastName: string) => void
  setNewEmailAddress: (newEmailAddress: string) => void
  setNewCompanyName: (newCompanyName: string) => void

  setNewRole: (newRole: Role_Id) => void
}

const useEditUserState = create<EditUserState & EditUserActions>((set) => ({
  activeUser: null,
  firstNameError: false,
  lastNameError: false,
  emailAddressError: false,
  companyNameError: false,
  firstNameHelperText: "",
  lastNameHelperText: "",
  emailAddressHelperText: "",
  companyNameHelperText: "",
  newFirstName: "",
  newLastName: "",
  newEmailAddress: "",
  newCompanyName: "",
  newRole: null,
  activeProjectUserRole: null,
  setActiveUser: (activeUser) => set(() => ({ activeUser })),
  setActiveProjectRoleUser: (activeProjectUserRole) =>
    set(() => ({ activeProjectUserRole })),
  setFirstNameError: (firstNameError) => set(() => ({ firstNameError })),
  setLastNameError: (lastNameError) => set(() => ({ lastNameError })),
  setEmailAddressError: (emailAddressError) =>
    set(() => ({ emailAddressError })),
  setCompanyNameError: (companyNameError) => set(() => ({ companyNameError})),
  setFirstNameHelperText: (firstNameHelperText) =>
    set(() => ({ firstNameHelperText })),
  setLastNameHelperText: (lastNameHelperText) =>
    set(() => ({ lastNameHelperText })),
  setEmailAddressHelperText: (emailAddressHelperText) =>
    set(() => ({ emailAddressHelperText })),
  setCompanyNameHelperText: (companyNameHelperText) => set(() => ({ companyNameHelperText })),
  setNewFirstName: (newFirstName) => set(() => ({ newFirstName })),
  setNewLastName: (newLastName) => set(() => ({ newLastName })),
  setNewEmailAddress: (newEmailAddress) => set(() => ({ newEmailAddress })),
  setNewCompanyName: (newCompanyName) => set(() => ({ newCompanyName })),
  setNewRole: (newRole) => set(() => ({ newRole })),
}))

export default useEditUserState
