// ==================== External Imports ==================== //
import React from "react"
import { v4 as uuidv4 } from "uuid"
import AddIcon from "@material-ui/icons/Add"
import SaveIcon from "@material-ui/icons/Save"
import ReorderIcon from "@mui/icons-material/Reorder"
import { IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core"

// ==================== Local Imports ==================== //
import useAppState from "hooksV1/useAppState"
import { ChapterSection } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useManageSectionsState from "hooksV1/useManageSections"
import useEditSectionDialogV1 from "hooksV1/useEditSectionDialogV1"
import useReOrderSectionDialogV1 from "hooksV1/useReOrderSectionsDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  manageSectionButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
}))

const ManageSectionsButtons: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { activeCountry } = useAppState()

  const editSection = useEditSectionDialogV1()
  const reOrderSection = useReOrderSectionDialogV1()

  const { isProjectEnvironment } = checkEnvironment()
  const { sections, setSections, isLoadingSections } = useManageSectionsState()

  // ==================== Functions ==================== //
  const onAddSection = () => {
    const newSections = [...sections]

    const newSection: ChapterSection = {
      id: uuidv4(),
      type: "text",
      name: "New Section",
      refId: uuidv4(),
      viewing: true,
      isProject: isProjectEnvironment,
      isGlobal: activeCountry?.global,
      content: { plainText: "", uploadedFiles: [] },
      isClientEditable: isProjectEnvironment ? true : false,
      isCountry: !isProjectEnvironment && !activeCountry?.global,
    }

    newSections.push(newSection)

    setSections(newSections)
    // ==================== Functions ==================== //
  }

  return (
    <div className={classes.manageSectionButtons}>
      <Tooltip title="Add Section">
        <IconButton
          color="primary"
          onClick={onAddSection}
          disabled={isLoadingSections}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Re-order Sections">
        <IconButton
          color="primary"
          onClick={reOrderSection.onOpen}
          disabled={isLoadingSections}
        >
          <ReorderIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Save">
        <IconButton
          color="primary"
          onClick={editSection.onOpen}
          disabled={isLoadingSections}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default ManageSectionsButtons
