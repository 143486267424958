// ==================== External Imports ==================== //
import React, { useState, useEffect } from "react"
import SyncIcon from "@material-ui/icons/Sync"
import RefreshIcon from "@material-ui/icons/Refresh"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { IconButton, Tooltip, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import SyncAllDialogV1 from "dialogsV1/SyncAllDialogV1"
import useSyncAllDialogV1 from "hooksV1/useSyncAllDialogV1"
import SyncStructureDialogV1 from "dialogsV1/SyncStructureDialog"
import useSyncStructureDialogV1 from "hooksV1/useSyncStructureDialogV1"
import ChapterStatusInfoDialog from "dialogsV1/ChapterStatusInfoDialogV1"
import useChapterStatusInfoDialogV1 from "hooksV1/useChapterStatusInfoDialog"
import useAppState from "hooksV1/useAppState"
import { useGetDocumentVersionWithChaptersV1Query } from "redux/services"
import { getUpdatedRoles } from "util/helper"
import { AuthHelper } from "util/authHelper"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  iconButton: {
    color: "primary",
    marginRight: "0.5rem",
  },
}))

const ManageContentButtons: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const useSyncAll = useSyncAllDialogV1()
  const useSyncStructure = useSyncStructureDialogV1()
  const useChapterStatusInfo = useChapterStatusInfoDialogV1()
  const {
    activeCountry,
    activeDocumentVersion,
    activeDocument,
    activeProjectUser,
  } = useAppState()
  const [documentAccess, setDocumentAccess] = useState([])

  const { data: documentVersionData } =
    useGetDocumentVersionWithChaptersV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  useEffect(() => {
    if (!documentVersionData) return

    const access = getUpdatedRoles(
      documentVersionData?.data?.getDocumentVersion?.access
    )

    setDocumentAccess(access)
  }, [documentVersionData])

  return (
    <div className={classes.container}>
      {AuthHelper.canSyncStructure(
        activeCountry?.id,
        documentAccess,
        activeProjectUser
      ) && (
        <>
          <Tooltip title="Sync All">
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={useSyncAll.onOpen}
            >
              <SyncIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sync Structure">
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={useSyncStructure.onOpen}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </>
      )}

      <Tooltip title="Chapter Status Information">
        <IconButton
          className={classes.iconButton}
          color="primary"
          onClick={useChapterStatusInfo.onOpen}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      {useSyncAll.isOpen && <SyncAllDialogV1 />}
      {useSyncStructure.isOpen && <SyncStructureDialogV1 />}
      {useChapterStatusInfo.isOpen && <ChapterStatusInfoDialog />}
    </div>
  )
}

export default ManageContentButtons
