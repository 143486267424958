import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import {
  Operations,
  UploadedFile,
  ChapterObject,
  PartialAuditTrail,
  AuditTrailOperations,
  GetPreSignedUrlResponse,
  PreSignedUrlBody,
} from "shared/types-exp"
import {
  createAuditTrail,
  batchUpdateChapters,
  updateChapterObjectFunc,
} from "util/batchHook"
import {
  invalidateAll,
  createAuditTrailObject,
  mapFileTypeToMimeType,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useUpdateChapterObjectMutation,
  useBatchUpdateChapterObjectsMutation,
  useGetAttachmentsPreSignedUrlMutation,
} from "redux/services"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useSaveAttachmentsDialogV1 from "hooksV1/useSaveAttachmentsDialogV1"

type SaveAttachmentsDialogV1Props = {
  chapterObjectsToUpdate: ChapterObject[]
  setChapterObjectsToUpdate: React.Dispatch<React.SetStateAction<any[]>>
}

const SaveAttachmentsDialogV1: React.FC<SaveAttachmentsDialogV1Props> = ({
  chapterObjectsToUpdate,
  setChapterObjectsToUpdate,
}) => {
  const {
    activeCountry,
    activeDocument,
    activeChapterObject,
    setActiveChapterObject,
  } = useAppState()
  const {
    isOpen,
    onClose,
    filesToUpload,
    chapterSection,
    localFilesFunc,
    uploadedFilesFunc,
  } = useSaveAttachmentsDialogV1()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()

  const { isProjectEnvironment } = checkEnvironment()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [getPresignedUrlAPI] = useGetAttachmentsPreSignedUrlMutation()
  const [updateChapterObjectApi] = useUpdateChapterObjectMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()

  const [isLoading, setIsLoading] = useState(false)
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSave()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleGlobalUpdates = (
    sectionId: string,
    updatedAttachments: UploadedFile[]
  ): ChapterObject[] => {
    const updatableChapters = chapterObjectsToUpdate.map(
      (chapterObjectToUpdate) => {
        let chapterCopy = cloneDeep(chapterObjectToUpdate)

        chapterCopy.sections.forEach((section) => {
          if (section.id === sectionId && section.isGlobal) {
            section.content.uploadedFiles = [
              ...section.content.uploadedFiles,
              ...updatedAttachments,
            ]
          }
        })

        delete chapterCopy.subchapters

        return chapterCopy
      }
    )

    return updatableChapters
  }

  async function uploadFilesToS3(
    preSignedFiles: PreSignedUrlBody[],
    fileMap: Map<string, UploadedFile>
  ): Promise<UploadedFile[]> {
    const uploadPromises = preSignedFiles.map(async (preSignedFile) => {
      const { id, preSignedUrl, location } = preSignedFile

      // Find the file by id from the fileMap
      const file = fileMap.get(id)

      if (!file) throw new Error(`File with id ${id} not found.`)

      const binaryContent = atob(file.content) // Decode base64 to binary string
      const byteArray = new Uint8Array(binaryContent.length)

      for (let i = 0; i < binaryContent.length; i++) {
        byteArray[i] = binaryContent.charCodeAt(i)
      }

      // Upload the binary data to the pre-signed URL
      const response = await fetch(preSignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": mapFileTypeToMimeType(file.type),
        },
        body: byteArray,
      })

      if (!response.ok) {
        throw new Error(`Failed to upload file ${file.name}`)
      }

      return {
        ...file,
        content: location,
      }
    })

    return Promise.all(uploadPromises)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)

      const preSignedRequestBody = filesToUpload.map((file) => {
        return { id: file.id, type: file.type }
      })

      const result: any = await getPresignedUrlAPI({
        files: preSignedRequestBody,
      })

      if (result.data.statusCode >= 400) {
        throw Error(
          `${JSON.stringify(
            result.data?.body
          )}, Operation: [Get PreSigned URL], Data Passed: ${JSON.stringify(
            preSignedRequestBody
          )}`
        )
      }

      const preSignedResponse: GetPreSignedUrlResponse = result.data.body
      const filesToUploadMap = new Map(
        filesToUpload.map((file) => [file.id, file])
      )

      const updatedAttachments = await uploadFilesToS3(
        preSignedResponse.files,
        filesToUploadMap
      )

      const updatedSection = activeChapterObject.sections.map((section) => {
        if (section.id === chapterSection.id) {
          return {
            ...section,
            content: {
              plainText: section.content.plainText,
              uploadedFiles: [
                ...section.content.uploadedFiles,
                ...updatedAttachments,
              ],
            },
          }
        }

        return section
      })

      let newActiveObject: ChapterObject = cloneDeep(activeChapterObject)
      newActiveObject.sections = updatedSection

      delete newActiveObject.subchapters

      const updatableChapters: ChapterObject[] = handleGlobalUpdates(
        chapterSection.id,
        updatedAttachments
      )

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.SECTION,
        `Uploaded attachments in the section ${chapterSection.name}, in the chapter ${activeChapterObject?.name}, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        updateChapterObjectFunc(updateChapterObjectApi, newActiveObject),
        batchUpdateChapters(batchUpdateChapterObjectsAPI, updatableChapters),
      ])

      localFilesFunc((prevFiles) => ({
        ...prevFiles,
        [chapterSection.id]: [],
      }))

      uploadedFilesFunc((prevFiles) => ({
        ...prevFiles,
        [chapterSection.id]: [
          ...(prevFiles[chapterSection.id] || []),
          ...updatedAttachments,
        ],
      }))

      setActiveChapterObject(newActiveObject)
      setChapterObjectsToUpdate(updatableChapters)

      invalidateAll(dispatch)

      snackBar.setMessage("Attachments successfully saved")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("SaveAttachmentsDialogV1", "handleSave", error)

      snackBar.setMessage(
        "An error occurred whilst saving attachments. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog onClose={handleCloseDialog} open={isOpen} onKeyDown={handleKeyDown}>
      <DialogTitle>Save Attachments</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to save these attachments?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default SaveAttachmentsDialogV1
