// ==================== External Imports ==================== //
import React from "react"
import { useLocation } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

// ==================== Local Imports ==================== //
import DocumentTable from "./DocumentTable"
import TemplatePageToolbar from "../PageToolBar/TemplatePageToolbar"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}))

const DocumentManagement: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const location = useLocation()

  // ==================== Use Hooks ==================== //
  const [search, setSearch] = React.useState<string>("")

  // ==================== Variables ==================== //
  let pathArray = location.pathname.split("/")
  const managementPage = pathArray[pathArray.length - 1] === "template"

  return (
    <div className={classes.container}>
      <TemplatePageToolbar
        search={search}
        setSearch={setSearch}
        isDocumentManagement={managementPage}
      />
      <DocumentTable searchText={search} />
    </div>
  )
}

export default DocumentManagement
