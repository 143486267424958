import { create } from "zustand"

type UserActivityLogDialogState = {
  isOpen: boolean
  email: string
}

type UserActivityLogDialogActions = {
  onOpen: () => void
  onClose: () => void
  setEmail: (email: string) => void
}

const useUserActivityLogDialog = create<
  UserActivityLogDialogState & UserActivityLogDialogActions
>((set) => ({
  isOpen: false,
  email: "",
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setEmail: (email) => set({ email }),
}))

export default useUserActivityLogDialog
