import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

const Guide: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        padding: "2em 2em 2em 3em",
      }}
    >
      <Grid
        spacing={3}
        container
        justifyContent="center"
        alignItems="center"
        style={{ display: "flex", alignItems: "center", flex: "1 1 100%" }}
      >
        <Grid item xs={10} style={{ maxWidth: "55%" }}>
          <Paper
            style={{
              display: "flex",
              padding: "72px 16px",
              justifyContent: "center",
            }}
          >
            <Container maxWidth="md" component="main">
              <CssBaseline />
              <div>
                <Typography
                  style={{ textAlign: "left" }}
                  variant="h4"
                  color="textPrimary"
                >
                  EPI-Centre User Guide
                  <br />
                  <br />
                </Typography>
                <Divider variant="fullWidth" />
                <Typography
                  style={{ textAlign: "left" }}
                  variant="body1"
                  color="textPrimary"
                >
                  <br />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac neque consequat, finibus nunc a, pretium nisl. Quisque
                    vitae diam a lorem egestas auctor malesuada non elit. Sed eu
                    accumsan nisl, tincidunt pulvinar lectus. Phasellus
                    vestibulum elit sed finibus tincidunt. Pellentesque sagittis
                    arcu in tempor viverra. Quisque vulputate eget ex nec
                    mattis. Nulla tempor tristique eleifend. Sed facilisis
                    dignissim turpis, vel gravida risus tristique at. Morbi
                    interdum interdum iaculis. In iaculis ipsum et lectus
                    consequat, ut tempus est semper. Pellentesque id leo massa.
                    Donec facilisis ex leo. Mauris lacinia, purus vitae sagittis
                    tempus, magna lacus lacinia dolor, ut mollis quam diam vel
                    nibh.
                  </p>
                  <br />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac neque consequat, finibus nunc a, pretium nisl. Quisque
                    vitae diam a lorem egestas auctor malesuada non elit. Sed eu
                    accumsan nisl, tincidunt pulvinar lectus. Phasellus
                    vestibulum elit sed finibus tincidunt. Pellentesque sagittis
                    arcu in tempor viverra. Quisque vulputate eget ex nec
                    mattis. Nulla tempor tristique eleifend. Sed facilisis
                    dignissim turpis, vel gravida risus tristique at. Morbi
                    interdum interdum iaculis. In iaculis ipsum et lectus
                    consequat, ut tempus est semper. Pellentesque id leo massa.
                    Donec facilisis ex leo. Mauris lacinia, purus vitae sagittis
                    tempus, magna lacus lacinia dolor, ut mollis quam diam vel
                    nibh.
                  </p>
                </Typography>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Guide
