import React from "react"
import Typography from "@material-ui/core/Typography"

type PageTitleProps = {
  title?: string
  subTitle?: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title, subTitle }) => {
  return (
    <>
      <Typography variant="h5" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subTitle}
      </Typography>
    </>
  )
}

export default PageTitle
