import { Divider } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 100%",
  },
  mainDiv: {
    display: "flex",
    flex: 1,
  },
  paperContainer: {
    display: "flex",
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: "center",
  },
  paper: {
    dispaly: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: theme.spacing(3, 0),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: "55%",
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    "& > *": {
      paddingLeft: "0 !important",
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: "none !important",
    color: theme.palette.text.primary,
  },
}))

const Copyright: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="md" component="main">
              <CssBaseline />
              <div className={classes.paper}>
                <Typography
                  style={{ textAlign: "left" }}
                  variant="h4"
                  color="textPrimary"
                >
                  Copyright
                  <br />
                  <br />
                </Typography>
                <Divider variant="middle" />
                <Typography
                  style={{ textAlign: "left" }}
                  variant="body1"
                  color="textPrimary"
                >
                  <br />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac neque consequat, finibus nunc a, pretium nisl. Quisque
                    vitae diam a lorem egestas auctor malesuada non elit. Sed eu
                    accumsan nisl, tincidunt pulvinar lectus. Phasellus
                    vestibulum elit sed finibus tincidunt. Pellentesque sagittis
                    arcu in tempor viverra. Quisque vulputate eget ex nec
                    mattis. Nulla tempor tristique eleifend. Sed facilisis
                    dignissim turpis, vel gravida risus tristique at. Morbi
                    interdum interdum iaculis. In iaculis ipsum et lectus
                    consequat, ut tempus est semper. Pellentesque id leo massa.
                    Donec facilisis ex leo. Mauris lacinia, purus vitae sagittis
                    tempus, magna lacus lacinia dolor, ut mollis quam diam vel
                    nibh.
                  </p>
                  <br />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ac neque consequat, finibus nunc a, pretium nisl. Quisque
                    vitae diam a lorem egestas auctor malesuada non elit. Sed eu
                    accumsan nisl, tincidunt pulvinar lectus. Phasellus
                    vestibulum elit sed finibus tincidunt. Pellentesque sagittis
                    arcu in tempor viverra. Quisque vulputate eget ex nec
                    mattis. Nulla tempor tristique eleifend. Sed facilisis
                    dignissim turpis, vel gravida risus tristique at. Morbi
                    interdum interdum iaculis. In iaculis ipsum et lectus
                    consequat, ut tempus est semper. Pellentesque id leo massa.
                    Donec facilisis ex leo. Mauris lacinia, purus vitae sagittis
                    tempus, magna lacus lacinia dolor, ut mollis quam diam vel
                    nibh.
                  </p>
                </Typography>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Copyright

// const LogoImg = styled.img<{ src: string; alt: string }>`
//   display: block;
//   margin: 0 auto;
//   margin-bottom: 2em;
// `;
