import React from "react"
import { CSS } from "@dnd-kit/utilities"
import { useDraggable } from "@dnd-kit/core"
import { useSortable } from "@dnd-kit/sortable"
import GlobalIcon from "@material-ui/icons/Language"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Theme, Typography, makeStyles } from "@material-ui/core"

import { isValidCursor } from "util/checker"
import { ChapterSection } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useAppState from "hooksV1/useAppState"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "70%",
    height: 50,
    cursor: "grab",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    transition: "background-color 0.2s ease",
  },
  dragging: {
    backgroundColor: "#f0f0f0",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
}))

type SectionItemProps = {
  section: ChapterSection
}

const SectionItem: React.FC<SectionItemProps> = ({ section }) => {
  const classes = useStyles()
  const { activeCountry } = useAppState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const isDisabled = () => {
    if (isTemplateEnvironment && !activeCountry?.global && !section.isCountry)
      return true

    if (isProjectEnvironment && !section.isProject) return true
  }

  const isDraggingDisabled = isDisabled()

  const {
    attributes: draggableAttributes,
    listeners: draggableListeners,
    setNodeRef: setDraggableNodeRef,
    transform: draggableTransform,
    isDragging: isDraggableDragging,
  } = useDraggable({
    id: section.id,
    disabled: isDisabled(),
  })

  const {
    attributes: sortableAttributes,
    listeners: sortableListeners,
    setNodeRef: setSortableNodeRef,
    transform: sortableTransform,
    transition: sortableTransition,
    isDragging: isSortableDragging,
  } = useSortable({
    id: section.id,
  })

  // Use draggable if disabled is true, otherwise use sortable
  const attributes = isDraggingDisabled
    ? draggableAttributes
    : sortableAttributes
  const listeners = isDraggingDisabled ? draggableListeners : sortableListeners
  const setNodeRef = isDraggingDisabled
    ? setDraggableNodeRef
    : setSortableNodeRef
  const transform = isDraggingDisabled ? draggableTransform : sortableTransform
  const transition = sortableTransition
  const isDragging = isDraggingDisabled
    ? isDraggableDragging
    : isSortableDragging

  const isGlobalSection = (section: ChapterSection): boolean => {
    if (isTemplateEnvironment && !section.isCountry) return true

    if (isProjectEnvironment && !section.isProject) return true

    return false
  }

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  const cursorStyle = "grab"

  if (!isValidCursor(cursorStyle)) {
    console.error(`Invalid cursor property: ${cursorStyle}`)
  }

  return (
    <div
      style={style}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      className={`${isDragging ? classes.dragging : ""}`}
    >
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <DragIndicatorIcon />
          <Typography variant="body1">{section.name}</Typography>
        </div>

        {isGlobalSection(section) && (
          <GlobalIcon
            style={{
              color: "#001744",
              width: "20px",
              marginRight: "5px",
            }}
          />
        )}
      </div>
    </div>
  )
}

export default SectionItem
