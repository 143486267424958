import { useLocation } from "react-router-dom";

type EnvironmentChecks = {
    isProjectEnvironment: boolean,
    isTemplateEnvironment: boolean,
    isHome: boolean,
}

export const checkEnvironment = (): EnvironmentChecks => {
    const location = useLocation()
    const firstPathSegment = location.pathname.split('/')[1]

    return {
        isProjectEnvironment: firstPathSegment === "project",
        isTemplateEnvironment: firstPathSegment === "template",
        isHome: location.pathname === "/",
    }
}
