import { useState } from "react"

export default function useToken() {
  const getToken = () => {
    const sessionToken = sessionStorage.getItem("token")
    const localToken = localStorage.getItem("token")
    const expiryTime = localStorage.getItem("token_expiry")

    if (sessionToken) {
      return sessionToken
    }

    if (localToken) {
      const now = new Date().getTime()

      if (expiryTime && now < parseInt(expiryTime)) {
        return localToken
      }
      // Token expired, clear it
      localStorage.removeItem("token")
      localStorage.removeItem("token_expiry")
    }

    return null
  }

  const [token, setToken] = useState(getToken())

  const saveToken = (userToken, expiresIn = 24 * 60 * 60 * 1000) => {
    const expiryTime = new Date().getTime() + expiresIn

    sessionStorage.setItem("token", userToken)
    localStorage.setItem("token", userToken)
    localStorage.setItem("token_expiry", expiryTime.toString())
    setToken(userToken)
  }

  return {
    setToken: saveToken,
    token,
  }
}
