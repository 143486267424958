/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { User } from "shared/types/user"
import {
  Document,
  Operations,
  ChapterObject,
  ChapterSection,
  DocumentVersion,
  PartialAuditTrail,
  getEventDescription,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  attachmentsUtil,
  auditTrailUtil,
  chapterObjectsApi,
  countriesUtil,
  documentsUtil,
  documentVersionsUtil,
} from "redux/services"
import { defaultAccess, statusAndColor } from "./constants"
import { ProjectRole } from "shared/types/project"

function containsPath(currentPath: string): boolean {
  return (
    currentPath === "roles" ||
    currentPath === "content" ||
    currentPath === "sections" ||
    currentPath === "settings" ||
    currentPath === "countries"
  )
}

function containsPathProjectHome(currentPath: string): boolean {
  return (
    currentPath === "home" ||
    currentPath === "users" ||
    currentPath === "countries"
  )
}

function containsPathProject(currentPath: string): boolean {
  return (
    currentPath === "content" ||
    currentPath === "sections" ||
    currentPath === "settings" ||
    currentPath === "versions" ||
    currentPath === "roles"
  )
}

const sortDocuments = (documents: Document[]): Document[] => {
  const documentsCopy = [...documents]

  // Find the index of the "Business Blueprint" document object
  const businessBlueprintIndex = documentsCopy.findIndex(
    (doc) => doc.name === "Business Blueprint"
  )

  // If "Business Blueprint" exists in the array, remove it temporarily
  const businessBlueprint =
    businessBlueprintIndex !== -1
      ? documentsCopy.splice(businessBlueprintIndex, 1)[0]
      : null

  // Sort the remaining documents by their name
  documentsCopy.sort((a, b) => {
    const nameA = a.name.toUpperCase() // Convert names to uppercase for case-insensitive comparison
    const nameB = b.name.toUpperCase()

    if (nameA < nameB) return -1

    if (nameA > nameB) return 1

    return 0 // Names are equal
  })

  // If "Business Blueprint" exists, add it back to the beginning of the array
  if (businessBlueprint) {
    documentsCopy.unshift(businessBlueprint)
  }

  return documentsCopy
}

const transformArray = (
  data: ChapterObject[],
  parentId = "0"
): ChapterObject[] => {
  const result = []

  data
    .filter((item) => !!item && item.parentId === parentId)
    .sort((a, b) => a.chapterOrder - b.chapterOrder)
    .forEach((item) => {
      const newItem = {
        ...item,
        subchapters: transformArray(data, item.id),
      }
      result.push(newItem)
    })

  return result
}

function isInArray(arr: User[], user: User): boolean {
  let isIn = false

  if (arr.length > 0) {
    arr.forEach((data) => {
      if (data.id === user.id) {
        isIn = true

        return
      }
    })
  }

  return isIn
}

function isMatch(str1: string | undefined, str2: string): boolean {
  if (str1 === undefined) str1 = ""
  str1 = str1.toLowerCase()
  str2 = str2.toLowerCase()

  if (str1.indexOf(str2) === -1) return false

  return true
}

function a11yProps(index: number): any {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const findObjectByChapterIndex = (
  chapter: ChapterObject,
  index: string
): ChapterObject | null => {
  const newIndex = index + "."

  if (localStorage.getItem("chapterIndex") === newIndex) {
    return chapter
  }

  if (chapter.subchapters && chapter.subchapters.length > 0) {
    let counter = 1

    for (const subChapter of chapter.subchapters) {
      const subChapterIndex = newIndex + counter.toString()

      const nestedResult = findObjectByChapterIndex(subChapter, subChapterIndex)

      if (nestedResult) return nestedResult

      counter++
    }
  }

  return null // Object with the specified ID not found
}

const findObjectById = (json: ChapterObject[], id: string): ChapterObject => {
  for (const obj of json) {
    if (obj.id === id) {
      return obj // Found the object with the specified ID
    }

    if (obj.subchapters && obj.subchapters.length > 0) {
      const nestedResult = findObjectById(obj.subchapters, id)

      if (nestedResult) {
        return nestedResult // Found the object in the nested items
      }
    }
  }

  return null // Object with the specified ID not found
}

const findObjectByRefId = (
  json: ChapterObject[],
  refId: string
): ChapterObject => {
  for (const obj of json) {
    if (!obj) return

    if (obj.refId === refId) {
      return obj // Found the object with the specified ID
    }

    if (obj.subchapters && obj.subchapters.length > 0) {
      const nestedResult = findObjectByRefId(obj.subchapters, refId)

      if (nestedResult) {
        return nestedResult // Found the object in the nested items
      }
    }
  }

  return null // Object with the specified ID not found
}

const findChapterByIdAndUpdateSections = (
  chapters: ChapterObject[],
  activeChapter: ChapterObject
): void => {
  for (const obj of chapters) {
    if (obj.id === activeChapter.id) {
      obj.name = activeChapter.name
      obj.sections = activeChapter.sections

      return
    }

    if (obj.subchapters && obj.subchapters.length > 0) {
      findChapterByIdAndUpdateSections(obj.subchapters, activeChapter)
    }
  }
}

const findChapterByIdAndUpdate = (
  chapters: ChapterObject[],
  activeChapter: ChapterObject
): void => {
  const { id } = activeChapter

  const updateChapter = (chapter: ChapterObject): void => {
    if (chapter.id === id) {
      Object.assign(chapter, {
        editing: activeChapter.editing,
        epi_status: activeChapter.epi_status,
        client_status: activeChapter.client_status,
        assignedProjectUser: activeChapter.assignedProjectUser,
      })

      return
    }

    if (chapter.subchapters && chapter.subchapters.length > 0) {
      chapter.subchapters.forEach(updateChapter)
    }
  }

  chapters.forEach(updateChapter)
}

const findChapterByIdUsingParentAndUpdate = (
  chapters: ChapterObject[],
  parentChapter: ChapterObject
): void => {
  for (const obj of chapters) {
    if (obj.id === parentChapter.id) {
      obj.subchapters = parentChapter.subchapters
    }

    if (obj.subchapters && obj.subchapters.length > 0) {
      findChapterByIdUsingParentAndUpdate(obj.subchapters, parentChapter)
    }
  }
}

const findChapterByRefIdUsingParentAndUpdate = (
  chapters: ChapterObject[],
  parentChapter: ChapterObject
): void => {
  for (const obj of chapters) {
    if (obj.refId === parentChapter.refId) {
      obj.subchapters = parentChapter.subchapters
    }

    if (obj.subchapters && obj.subchapters.length > 0) {
      findChapterByRefIdUsingParentAndUpdate(obj.subchapters, parentChapter)
    }
  }
}

const getNextAvailableChapterOrder = (
  chaptersList: ChapterObject[],
  currentChapterObject: ChapterObject
): number => {
  // Sort subchapters based on chapterOrder
  chaptersList.sort((a, b) => a.chapterOrder - b.chapterOrder)

  // Initialize variables
  let nextAvailableChapterOrder = currentChapterObject.chapterOrder

  // Loop through subchapters to find the next available chapter order
  for (const chapter of chaptersList) {
    // If the chapter order conflicts with any subchapter, increment it
    if (nextAvailableChapterOrder === chapter.chapterOrder) {
      nextAvailableChapterOrder++
    } else {
      // If no conflict is found, break the loop
      break
    }
  }

  // Return the next available chapter order
  return nextAvailableChapterOrder
}

// recursive function to get all subchapters' id and documentVersionId and flatten it out
const getSubChapters = (chapter: ChapterObject): any[] => {
  let subChapters = []

  if (chapter.subchapters?.length > 0) {
    chapter.subchapters.forEach((subChapter) => {
      subChapters?.push({
        id: subChapter.id,
        documentVersionId: subChapter.documentVersionId,
      })
      subChapters = subChapters.concat(getSubChapters(subChapter))
    })
  }

  return subChapters
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const removeObjectById = (json, id) => {
  for (let i = 0; i < json.length; i++) {
    if (json[i].id === id) {
      // Remove the object from the array
      json.splice(i, 1)

      return
    }

    if (json[i].subchapters && json[i].subchapters.length > 0) {
      removeObjectById(json[i].subchapters, id)
    }
  }
}

const newChapterSectionsMap = (
  chapterSections: ChapterSection[]
): ChapterSection[] => {
  if (chapterSections.length === 0) return []

  return chapterSections.map((section) => {
    return {
      ...section,
      content: section.content || { plainText: "", uploadedFiles: [] },
    }
  })
}

const hasChapterChangedNameOrChapterOrder = (
  chapter: ChapterObject,
  chapterToUpdate: ChapterObject
): boolean => {
  return (
    chapter.name !== chapterToUpdate.name ||
    chapter.chapterOrder !== chapterToUpdate.chapterOrder ||
    chapter.subchapterCreateAllowed !== chapterToUpdate.subchapterCreateAllowed
  )
}

const reorderChapterObjects = (
  clonedChapterObjects: ChapterObject[],
  activeChapterObject: ChapterObject
): ChapterObject[] => {
  const updatedChapterObjects: ChapterObject[] = [] // Array to store updated objects

  const reorder = (chapters: ChapterObject[]): void => {
    let orderedChapterOrder = 0 // Start from 0 and increment for each ordered chapter
    chapters.forEach((chapter) => {
      if (chapter.chapterOrder !== orderedChapterOrder) {
        // Reorder only if chapterOrder is not in sequence
        chapter.treeIndex = orderedChapterOrder
        chapter.chapterOrder = orderedChapterOrder
        updatedChapterObjects.push(chapter) // Add updated object to the list
      }
      orderedChapterOrder++ // Increment for the next chapter

      if (chapter.subchapters && chapter.subchapters.length > 0) {
        reorder(chapter.subchapters) // Recursively reorder subchapters
      }
    })
  }

  // Check if it's a root level chapter
  if (activeChapterObject.parentId === "0") {
    // Iterate over clonedChapterObjects without going into subchapters
    clonedChapterObjects.sort((a, b) => a.chapterOrder - b.chapterOrder)
    reorder(clonedChapterObjects)
  } else {
    // Recursively loop through clonedChapterObjects and subchapters
    const findAndUpdateChapter = (chapters: ChapterObject[]): void => {
      for (const chapter of chapters) {
        if (chapter.id === activeChapterObject.parentId) {
          // Found the parent chapter, reorder its subchapters
          chapter.subchapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
          reorder(chapter.subchapters)

          return // Exit the loop
        }

        if (chapter.subchapters && chapter.subchapters.length > 0) {
          // Recursively search in subchapters
          findAndUpdateChapter(chapter.subchapters)
        }
      }
    }

    findAndUpdateChapter(clonedChapterObjects)
  }

  return updatedChapterObjects
}

const mapMimeTypeToFileType = (mimeType: string): string | null => {
  switch (mimeType) {
    case "image/png":
      return "png"
    case "image/jpeg":
      return "jpg"
    case "image/jpg":
      return "jpeg"
    case "application/pdf":
      return "pdf"
    case "application/msword":
      return "doc"
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx"
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx"
    case "text/csv":
      return "csv"
    default:
      return null
  }
}

const mapFileTypeToMimeType = (mimeType: string): string | null => {
  switch (mimeType) {
    case "png":
      return "image/png"
    case "jpeg":
      return "image/jpeg"
    case "jpg":
      return "image/jpg"
    case "pdf":
      return "application/pdf"
    case "doc":
      return "application/msword"
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    case "csv":
      return "text/csv"
    default:
      return null
  }
}

const getColorForStatus = (status: string): string => {
  return statusAndColor.get(status)
}

const invalidateAll = (dispatch: any) => {
  dispatch(attachmentsUtil.invalidateTags(["Attachments"]))
  dispatch(auditTrailUtil.invalidateTags(["AuditTrail"]))
  dispatch(countriesUtil.invalidateTags(["Countries"]))
  dispatch(documentsUtil.invalidateTags(["Document"]))
  dispatch(documentVersionsUtil.invalidateTags(["DocumentVersions"]))
  dispatch(chapterObjectsApi.util.invalidateTags(["ChapterObject"]))
}

const updateChapterOrders = (chaptersArray: ChapterObject[]) => {
  let newTreeIndex = 0
  let indexToInsert = 0
  let newChapterOrder = 0
  const updatedChapters = []

  // Find the last object with a refId that doesn't include "non-global"
  for (let i = 0; i < chaptersArray.length; i++) {
    if (!chaptersArray[i].isCountry) {
      indexToInsert = i + 1
      newTreeIndex = chaptersArray[i].treeIndex + 1
      newChapterOrder = chaptersArray[i].chapterOrder + 1
    } else {
      break
    }
  }

  // Update the chapterOrder of subsequent objects with "non-global" in their refId
  for (let i = indexToInsert; i < chaptersArray.length; i++) {
    if (chaptersArray[i].isCountry) {
      const updatedChapter = {
        ...chaptersArray[i],
        chapterOrder: chaptersArray[i].chapterOrder + 1,
      }

      delete updatedChapter.subchapters
      updatedChapters.push(updatedChapter)
    }
  }

  return {
    newTreeIndex,
    newChapterOrder,
    updatedChapters,
  }
}

const createAuditTrailObject = (
  operation: AuditTrailOperations,
  entity: Operations,
  details: string
): PartialAuditTrail => {
  const auditTrail: PartialAuditTrail = {
    details,
    type: operation,
    createdAt: new Date().toISOString(),
    email: localStorage.getItem("email"),
    name: localStorage.getItem("firstname"),
    surname: localStorage.getItem("surname"),
    event: getEventDescription(operation, entity),
  }

  return auditTrail
}

const updateDocumentVersionData = (
  activeVersion: DocumentVersion
): DocumentVersion => ({
  ...activeVersion,
  access: activeVersion.access || defaultAccess,
  sections: newChapterSectionsMap(activeVersion.sections),
})

const updateDocumentData = (doc: Document): Document => ({
  ...doc,
  lastUpdated: new Date().toISOString(),
})

export const getUpdatedRole = (defaultRole: string, accessRole: string) => {
  if (defaultRole === "maintain") {
    return "maintain"
  }

  return accessRole.toLocaleLowerCase() === "no access"
    ? "none"
    : accessRole.toLocaleLowerCase()
}

export const getImageTagsFromPlainText = (plainText: string) => {
  const templateDom = new DOMParser().parseFromString(plainText, "text/html")

  const templateImgTags = Array.from(templateDom.querySelectorAll("img"))

  return templateImgTags
}

/**
 * Get the correct enforced access for roles or return default access
 * @param access The current access array
 * @returns the correct current access array
 */
export const getUpdatedRoles = (
  access?: string[],
  documentRoles?: string[]
) => {
  if (!access || access.length === 0) {
    return defaultAccess
  }

  return access.map((accessRole, index) => {
    const defaultRole = defaultAccess[index]

    if (defaultRole === "maintain") {
      return "maintain"
    }

    if (documentRoles && documentRoles.length > 0) {
      return documentRoles[index].toLowerCase() === "no access"
        ? "none"
        : documentRoles[index].toLowerCase()
    }

    return accessRole.toLowerCase() === "no access"
      ? "none"
      : accessRole.toLowerCase()
  })
}

export const checkDisabledRole = (name: string) => {
  return name === "Project Manager"
}

export const checkFilteredRoles = (name: string) => {
  return name.toLowerCase().trim() !== "super user"
}

const checkFilteredRolesObject = (roles: ProjectRole[]) => {
  return roles.filter(
    (val) => val.role_name.toLowerCase().trim() !== "super user"
  )
}

const sortDocumentsOrder = (documents) => {
  return documents.sort((a, b) => {
    if (a.name.toLowerCase() === "business blueprint") return -1

    if (b.name.toLowerCase() === "business blueprint") return 1

    return a.name.localeCompare(b.name)
  })
}

const setEditingState = (chapter: ChapterObject): ChapterObject => {
  if (!chapter.editing.isEditing) {
    chapter.editing = {
      isEditing: true,
      email: localStorage.getItem("email"),
      name: localStorage.getItem("firstname"),
      surname: localStorage.getItem("surname"),
    }
  }

  return chapter
}

const mapToChapterObject = (data: any): ChapterObject => {
  if (!data) return null

  const {
    documentVersionId,
    id,
    refId,
    parentId,
    sharedId,
    name,
    isGlobal,
    isCountry,
    isProject,
    documentRefId,
    sections,
    access,
    editing,
    epi_status,
    client_status,
    treeIndex,
    assignedProjectUser,
    chapterOrder,
    isIncludedInWorkflow,
    subchapterCreateAllowed,
  } = data

  return {
    documentVersionId,
    id,
    refId,
    parentId,
    sharedId,
    name,
    isGlobal,
    isCountry,
    isProject,
    documentRefId,
    access: access || [],
    editing: editing
      ? {
          email: editing.email,
          name: editing.name,
          surname: editing.surname,
          isEditing: editing.isEditing,
        }
      : undefined,
    epi_status: epi_status
      ? {
          status: epi_status.status,
          lastUpdated: epi_status.lastUpdated,
          userLastUpdated: epi_status.userLastUpdated,
          comments: epi_status.comments,
        }
      : undefined,
    client_status: client_status
      ? {
          status: client_status.status,
          lastUpdated: client_status.lastUpdated,
          userLastUpdated: client_status.userLastUpdated,
          comments: client_status.comments,
        }
      : undefined,
    subchapters: [],
    sections:
      sections?.map((section) => ({
        id: section?.id,
        isGlobal: section?.isGlobal,
        isCountry: section?.isCountry,
        isProject: section?.isProject,
        isClientEditable: section?.isClientEditable,
        name: section?.name,
        type: section?.type,
        viewing: section?.viewing,
        refId: section?.refId,
        content: section?.content
          ? {
              uploadedFiles:
                section.content.uploadedFiles?.map((file) => ({
                  id: file?.id,
                  name: file?.name,
                  type: file?.type,
                  size: file?.size,
                  content: file?.content,
                })) || [],
              plainText: section.content.plainText,
            }
          : undefined,
      })) || [],
    treeIndex,
    assignedProjectUser: assignedProjectUser || "",
    chapterOrder,
    isIncludedInWorkflow,
    subchapterCreateAllowed: subchapterCreateAllowed || false,
  }
}

export {
  isMatch,
  isInArray,
  a11yProps,
  containsPath,
  invalidateAll,
  sortDocuments,
  getSubChapters,
  transformArray,
  findObjectById,
  setEditingState,
  removeObjectById,
  getColorForStatus,
  findObjectByRefId,
  sortDocumentsOrder,
  updateDocumentData,
  mapToChapterObject,
  updateChapterOrders,
  containsPathProject,
  reorderChapterObjects,
  mapMimeTypeToFileType,
  mapFileTypeToMimeType,
  newChapterSectionsMap,
  createAuditTrailObject,
  containsPathProjectHome,
  findObjectByChapterIndex,
  checkFilteredRolesObject,
  findChapterByIdAndUpdate,
  updateDocumentVersionData,
  getNextAvailableChapterOrder,
  findChapterByIdAndUpdateSections,
  hasChapterChangedNameOrChapterOrder,
  findChapterByIdUsingParentAndUpdate,
  findChapterByRefIdUsingParentAndUpdate,
}
