import { create } from "zustand"

type DeleteUserDialogState = {
  isOpen: boolean
}

type DeleteUserDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useDeleteUserDialogV1 = create<
  DeleteUserDialogState & DeleteUserDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useDeleteUserDialogV1
