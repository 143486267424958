import {
  Grid,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core"
import { z } from "zod"
import { v4 as uuidv4 } from "uuid"
import { useFormik } from "formik"
import { RootState } from "redux/store"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { toFormikValidationSchema } from "zod-formik-adapter"
import React, { useState, useEffect, FormEvent } from "react"

import {
  useCreateCountryMutation,
  useCreateAuditTrailMutation,
  useListCountriesWithProjectRefIdV1Query,
} from "redux/services"
import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
  CountryResponse,
} from "shared/types-exp"
import { logger } from "util/logger"
import { Status } from "shared/types/status"
import { Project } from "shared/types/project"
import useSnackBar from "../hooksV1/useSnackBar"
import Loader from "../components/Loading/Loader"
import { SnackType } from "../hooksV1/useSnackBar"
import { createAuditTrailObject } from "util/helper"
import { setAddProjectStatus } from "redux/slices/projects"
import { checkIfError, createAuditTrail } from "util/batchHook"
import useCreateProjectDialogV1 from "../hooksV1/useCreateProjectDialogV1"
import { addProjectOnDB, fetchProjects } from "redux/thunks/projectsThunk"

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5em",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  dialogContent: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
  },
  dialogActions: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "1rem",
  },
}))

const CreateProjectDialogV1: React.FC = () => {
  const classes = useStyles()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()
  const projects = useSelector((state: RootState) => state.projects.allProjects)
  const createProjectDialog = useCreateProjectDialogV1()

  const creationStatus = useSelector(
    (state: RootState) => state.projects.addProjectStatus
  )

  const projectNameExists = (name) => {
    return projects.some(
      (project) => project.name.trim().toLowerCase() === name.toLowerCase()
    )
  }

  const createProjectSchema = z.object({
    projectName: z
      .string()
      .min(2, { message: "Project name has to be at least 3 characters long" })
      .refine((value) => !projectNameExists(value), {
        message: "Project Name already exists",
      }),
    author: z.string().min(1, { message: "Author name cannot be empty" }),
  })

  const formik = useFormik({
    initialValues: {
      author: "",
      projectName: "",
    },
    validateOnBlur: true,
    validationSchema: toFormikValidationSchema(createProjectSchema),
    onSubmit: (values) => {
      setIsLoading(true)

      const id = uuidv4()
      setProjectId(id)
      const { author, projectName } = values
      const project: Project = {
        id,
        name: projectName,
        author,
        value: "",
        lastUpdated: new Date().toISOString(),
        countries: [],
        users: [],
      }

      const addProjectThunk = addProjectOnDB(project)
      dispatch(addProjectThunk)
    },
  })
  const [projectId, setProjectId] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [countries, setCountries] = useState<CountryResponse[]>([])

  const [createCountryAPI] = useCreateCountryMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const { data: projectCountries, isLoading: isProjectsLoading } =
    useListCountriesWithProjectRefIdV1Query("")

  useEffect(() => {
    if (!projectCountries) return

    const countries = [
      ...(projectCountries?.data?.listCountries?.items || []),
    ].sort((a, b) => {
      const nameA = a.country_name.toUpperCase()
      const nameB = b.country_name.toUpperCase()

      if (nameA < nameB) {
        return -1
      }

      if (nameA > nameB) {
        return 1
      }

      return 0
    })

    setCountries(countries)
  }, [projectCountries])

  useEffect(() => {
    const handleCreationStatus = async () => {
      if (creationStatus === Status.success) {
        try {
          dispatch({ type: setAddProjectStatus.type, payload: Status.idle })

          const fetchProjectsThunk = fetchProjects()

          const auditTrail: PartialAuditTrail = createAuditTrailObject(
            AuditTrailOperations.CREATE,
            Operations.PROJECT,
            `A Project with the name ${formik.values.projectName}.`
          )

          await Promise.all([
            copyData(projectId),
            dispatch(fetchProjectsThunk),
            createAuditTrail(createAuditTrailAPI, auditTrail),
          ])

          snackBar.setMessage("Project Created Successfully.")
          snackBar.setMessageSeverity(SnackType.SnackSuccess)
          snackBar.onOpen()

          handleClose()
        } catch (error) {
          logger("CreateProjectDialogV1", "useEffect", error)

          snackBar.setMessage("Failed to Create a Project. Please try again.")
          snackBar.setMessageSeverity(SnackType.SnackError)
          snackBar.onOpen()
          setIsLoading(false)
        }
      } else if (creationStatus === Status.failed) {
        logger("CreateProjectDialogV1", "useEffect", "")

        snackBar.setMessage("Failed to Create a Project. Please try again.")
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        setIsLoading(false)
      }
    }

    handleCreationStatus()
  }, [creationStatus])

  const copyData = async (projectId: string) => {
    for (const country of countries) {
      const countryTemp = {
        global: false,
        active_status: false,
        projectRefId: projectId,
        country_name: country.country_name,
      }

      const response = await createCountryAPI(countryTemp)

      checkIfError(response, countryTemp, "[Create Country]")
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  const handleClose = () => {
    formik.resetForm()
    setIsLoading(false)
    createProjectDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      open={createProjectDialog.isOpen}
      onClose={handleCloseDialog}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle className={classes.dialogTitle}>Create Project</DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <form noValidate>
          <Grid container>
            <Grid xs={12} item>
              <TextField
                autoComplete="projectName"
                id="projectName"
                label="Project Name"
                margin="normal"
                name="projectName"
                variant="outlined"
                fullWidth
                required
                value={formik.values.projectName}
                error={
                  formik.touched.projectName &&
                  Boolean(formik.errors.projectName)
                }
                helperText={
                  formik.touched.projectName && formik.errors.projectName
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                autoComplete="author"
                id="author"
                label="Author"
                margin="normal"
                name="author"
                variant="outlined"
                fullWidth
                required
                value={formik.values.author}
                error={formik.touched.author && Boolean(formik.errors.author)}
                helperText={formik.touched.author && formik.errors.author}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) =>
            formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)
          }
          disabled={isLoading || isProjectsLoading || !formik.isValid}
        >
          Create
        </Button>
      </DialogActions>

      {(isLoading || isProjectsLoading) && <Loader open={true} />}
    </Dialog>
  )
}

export default CreateProjectDialogV1
