import React, { useState } from "react"
import {
  Box,
  Theme,
  Radio,
  Select,
  MenuItem,
  ListItem,
  TextField,
  makeStyles,
  RadioGroup,
  IconButton,
  FormControlLabel,
} from "@material-ui/core"
import { Delete } from "@material-ui/icons"

import { cloneDeep } from "lodash"
import useAppState from "hooksV1/useAppState"
import { ChapterSection } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useManageSectionsState from "hooksV1/useManageSections"

type ManageSectionListTileProps = {
  index: number
  isEditable: boolean
  section: ChapterSection
  onTextFieldChange: (value: string, index: number) => void
  onRadioFiledChange: (value: string, index: number) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  radioGroup: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioButton: {
    width: "33.33%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  deleteButtonContainer: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const ManageSectionListTile: React.FC<ManageSectionListTileProps> = ({
  index,
  section,
  isEditable,
  onTextFieldChange,
  onRadioFiledChange,
}) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { activeCountry } = useAppState()
  const { isProjectEnvironment } = checkEnvironment()
  const { sections, setSections } = useManageSectionsState()

  const [sectionType, setSectionType] = useState(section.type)

  // ==================== Functions ==================== //
  const onSectionTypeChange = (newType: string) => {
    let newSections = cloneDeep(sections)

    newSections = newSections.map((tempSection) => {
      if (tempSection.id === section.id) {
        return {
          ...tempSection,
          type: newType,
        }
      }

      return tempSection
    })

    setSectionType(newType)
    setSections(newSections)
  }

  const onDeleteSection = () => {
    let newSections = [...sections]

    newSections.splice(index, 1)
    setSections(newSections)
  }

  const value = section.isGlobal ? "0" : section.isClientEditable ? "2" : "1"

  return (
    <ListItem>
      <div className={classes.container}>
        <div style={{ width: "30%" }}>
          <TextField
            fullWidth
            value={`${section.name}`}
            id="standard-basic"
            label=""
            disabled={!isEditable}
            onChange={(event: any) =>
              onTextFieldChange(event.target.value, index)
            }
            inputProps={
              !isEditable
                ? {
                    style: {
                      color: "black",
                      opacity: 0.7,
                    },
                  }
                : {}
            }
          />
        </div>
        <div style={{ width: "15%" }}>
          {isEditable ? (
            <Select
              value={sectionType}
              disabled={!isEditable}
              defaultValue={section.type}
              onChange={(event) =>
                onSectionTypeChange(event.target.value as string)
              }
            >
              <MenuItem value="text">Text Editor</MenuItem>
              <MenuItem value="attachment">Attachments</MenuItem>
            </Select>
          ) : (
            <Box display="flex" alignItems="center">
              {sectionType === "text" ? "Text Editor" : "Attachments"}
            </Box>
          )}
        </div>
        <RadioGroup
          className={classes.radioGroup}
          value={value}
          onChange={(event: any) =>
            onRadioFiledChange(event.target.value, index)
          }
        >
          <div className={classes.radioButton}>
            <FormControlLabel
              value="0"
              control={<Radio />}
              label=""
              labelPlacement="bottom"
              disabled={
                !isEditable || !activeCountry?.global || isProjectEnvironment
              }
            />
          </div>
          <div className={classes.radioButton}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label=""
              labelPlacement="bottom"
              disabled={!isEditable || isProjectEnvironment}
            />
          </div>
          <div className={classes.radioButton}>
            <FormControlLabel
              value="2"
              control={<Radio />}
              label=""
              labelPlacement="bottom"
              disabled={!isEditable}
            />
          </div>
        </RadioGroup>
        <div className={classes.deleteButtonContainer}>
          <IconButton
            aria-label="delete"
            onClick={onDeleteSection}
            disabled={!isEditable}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    </ListItem>
  )
}

export default ManageSectionListTile
