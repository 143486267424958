import { create } from "zustand"
import {
  Document,
  Country,
  ChapterObject,
  DocumentVersion,
} from "shared/types-exp"
import { Project, ProjectUserRole } from "../shared/types/project"

export type AppState = {
  activeRole: string
  documents: Document[]
  activeDocument: Document
  activeProject: Project
  currentSectionEditing: string
  activeProjectUser: ProjectUserRole
  countries: Country[]
  activeCountry: Country
  chapterObjects: ChapterObject[]
  chapterObjectsList: ChapterObject[]
  activeChapterObject: ChapterObject
  activeDocumentVersion: DocumentVersion
  activeDocumentVersions: DocumentVersion[]
  documentRoles: string[]
  isAdding: boolean // For add skeleton
  isDeleting: { flag: true; id: string } | null // For delete skeleton
}

export type AppActions = {
  setActiveRole: (activeRole: string) => void
  setCurrentSectionEditing: (activeRole: string) => void
  setDocuments: (documents: Document[]) => void
  setActiveDocument: (activeDocument: Document) => void
  setActiveProject: (activeProject: Project) => void
  setActiveProjectUser: (activeProjectUser: ProjectUserRole) => void
  setCountries: (countries: Country[]) => void
  setActiveCountry: (activeCountry: Country) => void
  setIsAdding: (isAdding: boolean) => void
  setDocumentRoles: (roles: string[]) => void
  setIsDeleting: (isDeleting: { flag: true; id: string } | null) => void
  setChapterObjects: (chapterObjects: ChapterObject[]) => void
  setChapterObjectsList: (chapterObjectsList: ChapterObject[]) => void
  setActiveChapterObject: (activeChapterObject: ChapterObject) => void
  setActiveDocumentVersion: (activeDocumentVersion: DocumentVersion) => void
  setActiveDocumentVersions: (activeDocumentVersions: DocumentVersion[]) => void
}

const useAppState = create<AppState & AppActions>((set) => ({
  activeRole: "All Roles",
  currentSectionEditing: "",
  isAdding: false,
  activeCountry: null,
  activeDocument: null,
  activeProject: null,
  activeChapterObject: null,
  activeDocumentVersion: null,
  activeProjectUser: null,
  documentRoles: [],
  isDeleting: null,
  countries: [] as Country[],
  documents: [] as Document[],
  chapterObjects: [] as ChapterObject[],
  chapterObjectsList: [] as ChapterObject[],
  activeDocumentVersions: [] as DocumentVersion[],
  setActiveRole: (activeRole) => set(() => ({ activeRole })),
  setCurrentSectionEditing: (currentSectionEditing) =>
    set(() => ({ currentSectionEditing })),
  setDocuments: (documents) => set(() => ({ documents })),
  setActiveProjectUser: (activeProjectUser) =>
    set(() => ({ activeProjectUser })),
  setDocumentRoles: (documentRoles) => set(() => ({ documentRoles })),
  setIsAdding: (isAdding) => set(() => ({ isAdding })),
  setActiveDocument: (activeDocument) => set(() => ({ activeDocument })),
  setIsDeleting: (isDeleting) => set({ isDeleting }),
  setActiveProject: (activeProject) => set(() => ({ activeProject })),
  setCountries: (countries) => set(() => ({ countries })),
  setActiveCountry: (activeCountry) => set(() => ({ activeCountry })),
  setChapterObjects: (chapterObjects) => set(() => ({ chapterObjects })),
  setChapterObjectsList: (chapterObjectsList) =>
    set(() => ({ chapterObjectsList })),
  setActiveChapterObject: (activeChapterObject) =>
    set(() => ({ activeChapterObject })),
  setActiveDocumentVersion: (activeDocumentVersion) =>
    set(() => ({ activeDocumentVersion })),
  setActiveDocumentVersions: (activeDocumentVersions) =>
    set(() => ({ activeDocumentVersions })),
}))

export default useAppState
