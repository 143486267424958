import React, { useEffect, useState } from "react"
import {
  Dialog,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
  Table,
  TablePagination,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { makeStyles, Theme } from "@material-ui/core/styles"

import { logger } from "util/logger"
import { AuditTrail } from "shared/types-exp"
import NoDataMessage from "components/NoDataMessage"
import { userActivityLogHeadCells } from "../util/constants"
import useUserActivityLogDialog from "../hooksV1/useUserActivityLogDialog"
import { useListAuditTrailFilteredByEmailQuery } from "redux/services"

type UserActivityProps = {
  page: number
  rowsPerPage: number
  auditLogs: AuditTrail[]
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    maxHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    padding: "0.5em",
    alignSelf: "center",
  },
  paginationContainer: {
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  skeleton: {
    width: "100%",
    height: 75,
    flex: 1,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
  },
  button: {
    paddingLeft: "1rem",
  },
  table: {
    width: "100%",
  },
  cell: {
    width: "12%",
  },
  dialogTitle: { padding: theme.spacing(2.5, 0, 0, 5) },
  dialogContentText: { padding: theme.spacing(0, 0, 0, 2) },
  dialogActions: { padding: theme.spacing(0, 5, 2.5, 5) },
}))

const TableHeadings: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        {userActivityLogHeadCells.map((headCell) => {
          return (
            <TableCell
              align="left"
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              <Typography variant="body1" color="primary" component="h1">
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const UserActivityLogTableBody: React.FC<UserActivityProps> = ({
  page,
  auditLogs,
  rowsPerPage,
}) => {
  const classes = useStyles()

  function separateDateAndTime(dateTimeString) {
    const dateTimeObj = new Date(dateTimeString)

    const year = dateTimeObj.getFullYear()
    const month = String(dateTimeObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateTimeObj.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`

    const hours = String(dateTimeObj.getHours()).padStart(2, "0")
    const minutes = String(dateTimeObj.getMinutes()).padStart(2, "0")
    const seconds = String(dateTimeObj.getSeconds()).padStart(2, "0")
    const formattedTime = `${hours}:${minutes}:${seconds}`

    return { formattedDate, formattedTime }
  }

  return (
    <TableBody>
      {auditLogs
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((log) => {
          const username = `${log.name} ${log.surname}`
          const { formattedDate, formattedTime } = separateDateAndTime(
            log.createdAt
          )

          return (
            <TableRow key={log.id}>
              <TableCell className={classes.cell}>{formattedDate}</TableCell>
              <TableCell className={classes.cell}>{formattedTime}</TableCell>
              <TableCell className={classes.cell}>{username}</TableCell>
              <TableCell className={classes.cell}>{log.type}</TableCell>
              <TableCell>{log.details}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

const UserActivityLogDialog: React.FC = () => {
  const classes = useStyles()
  const userActivityLogDialog = useUserActivityLogDialog()

  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [auditLog, setAuditLog] = useState<AuditTrail[]>([])
  const [nextToken, _] = useState<string | null>(null)
  // const [currentToken, _] = useState<string | null>(null)

  const { data: auditLogs, isLoading } = useListAuditTrailFilteredByEmailQuery(
    {
      email: userActivityLogDialog?.email,
      nextToken,
    },
    { skip: !userActivityLogDialog.email }
  )

  useEffect(() => {
    if (auditLogs) {
      const logs = sortByCreatedAtDesc(auditLogs.data.listAuditTrail.items)
      setAuditLog((prevLogs) => [...prevLogs, ...logs])
      // setCurrentToken(auditLogs.data.listAuditTrail.nextToken)
    }
  }, [auditLogs])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
    // const logsNeeded = (newPage + 1) * rowsPerPage
    // const shouldFetch = auditLog.length - logsNeeded <= 10

    // if (shouldFetch && currentToken) setNextToken(currentToken)
  }

  const sortByCreatedAtDesc = (array: AuditTrail[]): AuditTrail[] => {
    return [...array].sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()

      if (!isNaN(dateA) && !isNaN(dateB)) {
        return dateB - dateA
      }

      logger(
        "UserActivityDialog",
        "sortByCreatedAtDesc",
        `"Invalid date format:", ${a.createdAt}, ${b.createdAt}`
      )

      return 0
    })
  }

  const handleClose = () => {
    userActivityLogDialog.onClose()
  }

  const displayDialogBody = () => {
    if (isLoading) {
      return (
        <Skeleton
          className={classes.skeleton}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      )
    }

    if (auditLog.length === 0) {
      return <NoDataMessage text={"No Logs Available"} />
    }

    return (
      <>
        <TableContainer>
          <Table className={classes.table}>
            <TableHeadings />
            <UserActivityLogTableBody
              auditLogs={auditLog}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Table>
        </TableContainer>
        <div className={classes.paginationContainer}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={auditLog.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </div>
      </>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      open={userActivityLogDialog.isOpen}
      className={classes.container}
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        User Activity Log
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          View History of User Activities
        </DialogContentText>
        <Divider />
        {displayDialogBody()}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserActivityLogDialog
