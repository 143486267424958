// ==================== External Imports ==================== //
import React from "react"
import { useLocation } from "react-router-dom"
import { makeStyles, Theme, IconButton, Tooltip } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

// ==================== Local Imports ==================== //
import useAppState from "hooksV1/useAppState"
import { checkEnvironment } from "util/environment"
import ManageRolesButton from "./ManageRolesButton"
import ManageSettingsButton from "./ManageSettingsButton"
import ManageContentButtons from "./ManageContentButtons"
import ManageSectionsButtons from "./ManageSectionsButton"
import AddCountryDialogV1 from "dialogsV1/AddCountryDialogV1"
import useAddCountryDialogV1 from "hooksV1/useAddCountryDialogV1"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "1rem",
  },
  manageSectionButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
}))

const ChapterHeaderButtons: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const location = useLocation()
  const { isProjectEnvironment } = checkEnvironment()

  // ==================== Variables ==================== //
  let pathArray = location.pathname.split("/")
  let section = pathArray[pathArray.length - 1]

  const isOnManageContent = section === "content"
  const isOnManageSections = section === "sections"
  const isOnManageSettings = section === "settings"
  const isOnManageCountries = section === "countries"
  const isOnManageRoles = section === "roles"

  return (
    <div className={classes.container}>
      {isOnManageContent && isProjectEnvironment && <ManageContentButtons />}
      {isOnManageSections && <ManageSectionsButtons />}
      {isOnManageSettings && <ManageSettingsButton />}
      {isOnManageCountries && <ManageCountriesButtons />}
      {isOnManageRoles && <ManageRolesButton />}
    </div>
  )
}

export default ChapterHeaderButtons

const ManageCountriesButtons = () => {
  const classes = useStyles()

  const snackBar = useSnackBar()
  const { activeCountry } = useAppState()
  const addCountryDialog = useAddCountryDialogV1()

  const handOnClick = () => {
    if (activeCountry?.global) addCountryDialog.onOpen()
    else {
      snackBar.setMessage(
        "Current country needs to be on Global. Please switch to Global country"
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }

  return (
    <div className={classes.manageSectionButtons}>
      <Tooltip title="Add Country">
        <IconButton color="primary" onClick={handOnClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {addCountryDialog.isOpen && <AddCountryDialogV1 />}
    </div>
  )
}
