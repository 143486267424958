// ==================== External Imports ==================== //
import React from "react"
import {
  Theme,
  Select,
  Switch,
  Tooltip,
  MenuItem,
  makeStyles,
  FormControl,
  FormControlLabel,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import AddIcon from "@material-ui/icons/Add"
import SyncIcon from "@material-ui/icons/Sync"
import SaveIcon from "@material-ui/icons/Save"
import RefreshIcon from "@material-ui/icons/Refresh"
import IconButton from "@material-ui/core/IconButton"
import PersonAddIcon from "@material-ui/icons/PersonAdd"

// ==================== Local Imports ==================== //
import {
  useUpdateCountryMutation,
  useGetGlobalCountryWithDocumentsV1Query,
} from "redux/services"
import useAppState from "hooksV1/useAppState"
import useBooleanState from "hooksV1/useBooleanStates"
import { fetchUsers } from "redux/thunks/usersSliceThunk"
import { fetchProjects } from "redux/thunks/projectsThunk"

import useSyncCountriesV1 from "hooksV1/useSyncCountriesV1"
import InviteUserDialogV1 from "dialogsV1/InviteUserDialogV1"
import CreateProjectDialogV1 from "dialogsV1/CreateProjectDialogV1"
import SyncCountriesDialogV1 from "dialogsV1/SyncCountriesDialogV1"
import CreateDocumentDialogV1 from "dialogsV1/CreateDocumentDialogV1"
import useInviteUserDialogV1 from "../../hooksV1/useInviteUserDialogV1"
import useSyncTemplateDocDialogV1 from "hooksV1/useSyncTemplateDocDialogV1"
import useCreateProjectDialogV1 from "../../hooksV1/useCreateProjectDialogV1"
import useCreateDocumentDialogV1 from "../../hooksV1/useCreateDocumentDialogV1"
import SyncNewTemplateDocsDialogV1 from "dialogsV1/SyncNewTemplateDocsDialogV1"
import ManageSettingsButton from "../ManageDocumentsToolBar/ManageSettingsButton"
import { invalidateAll } from "util/helper"
import { AuthHelper } from "util/authHelper"
import { checkEnvironment } from "util/environment"

type ToolbarButtonsProp = {
  buttonsDetails: string
}

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    color: "primary",
    marginLeft: "0.25rem",
  },
  roleSelector: {
    width: "10rem",
  },
}))

const getButtonDetails = (buttonsVariant: string) => {
  switch (buttonsVariant) {
    case "isDocumentManagement":
      return "Create Document"
    case "isUserManagement":
      return "Invite"
    case "isProjectManagement":
      return "Create Project"
  }
}

const ToolbarButtons: React.FC<ToolbarButtonsProp> = ({ buttonsDetails }) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const dispatch = useDispatch()
  const appState = useAppState()
  const booleanState = useBooleanState()
  const syncDocDialog = useSyncTemplateDocDialogV1()
  const syncCountriesDialog = useSyncCountriesV1()

  const inviteUserDialogV1 = useInviteUserDialogV1()
  const createProjectDialogV1 = useCreateProjectDialogV1()
  const createDocumentDialogV1 = useCreateDocumentDialogV1()

  const { refetch: refreshGlobalDocs } =
    useGetGlobalCountryWithDocumentsV1Query()

  const { isProjectEnvironment } = checkEnvironment()
  const [updateCountryAPI, { isLoading }] = useUpdateCountryMutation()

  // ==================== Variables ==================== //
  const role = appState.activeRole
  const showDisabled = booleanState.showDisabled ?? true

  // ==================== Functions ==================== //
  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    appState.setActiveRole(event.target.value as string)
  }

  const handleAddButtonClick = () => {
    switch (true) {
      case buttonsDetails === "isDocumentManagement":
        createDocumentDialogV1.onOpen()
        break
      case buttonsDetails === "isUserManagement":
        inviteUserDialogV1.onOpen()
        break
      case buttonsDetails === "isProjectManagement":
        createProjectDialogV1.onOpen()
        break
    }
  }

  const handleRefreshClick = async () => {
    if (buttonsDetails === "isDocumentManagement") {
      refreshGlobalDocs()
    }

    if (buttonsDetails === "isUserManagement") {
      const fetchUsersThunk = fetchUsers()
      dispatch(fetchUsersThunk)
    }

    if (buttonsDetails === "isProjectManagement") {
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
    }
  }

  const handleToggle = () => {
    booleanState.setShowDisabled(!booleanState.showDisabled)
  }

  const handleSaveCountryChanges = async () => {
    const countries = appState.countries

    const updatePromises = countries.map((country) =>
      updateCountryAPI({ ...country })
    )

    try {
      await Promise.all(updatePromises)
      invalidateAll(dispatch)
    } catch (error) {
      console.error("Error updating countries:", error)
    }
  }

  const variant = getButtonDetails(buttonsDetails)

  return (
    <>
      {buttonsDetails === "isDocumentManagement" && (
        <FormControlLabel
          style={{ marginLeft: "1em" }}
          control={<Switch checked={showDisabled} size="small" />}
          label="Show Disabled"
          onChange={handleToggle}
        />
      )}
      {buttonsDetails === "isUserManagement" && (
        <FormControl
          style={{ marginLeft: "1em" }}
          size="small"
          variant="outlined"
          className={classes.roleSelector}
        >
          {isProjectEnvironment ? (
            <>
              <Select value={role} onChange={handleRoleChange}>
                <MenuItem selected value={"All Roles"}>
                  All Roles
                </MenuItem>
                <MenuItem value={"Project Manager"}>Project Manager</MenuItem>
                <MenuItem value={"Lead Consultant"}>Lead Consultant</MenuItem>
                <MenuItem value={"Implementation Consultant"}>
                  Implementation Consultant
                </MenuItem>
                <MenuItem value={"AMS Consultant"}>AMS Consultant</MenuItem>
                <MenuItem value={"Client User"}>Client User</MenuItem>
                <MenuItem value={"Client Project Manager"}>
                  Client Project Manager
                </MenuItem>
              </Select>
            </>
          ) : (
            <>
              <Select value={role} onChange={handleRoleChange}>
                <MenuItem selected value={"All Roles"}>
                  All Roles
                </MenuItem>
                <MenuItem value={"Guest"}>Guest</MenuItem>
                <MenuItem value={"Administrator"}>Administrator</MenuItem>
              </Select>
            </>
          )}
        </FormControl>
      )}
      {buttonsDetails !== "isProjectDocuments" &&
        buttonsDetails !== "isCountryManagement" &&
        variant && (
          <Tooltip title={variant}>
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={handleAddButtonClick}
            >
              {buttonsDetails === "isDocumentManagement" && <AddIcon />}
              {buttonsDetails === "isUserManagement" && <PersonAddIcon />}
              {buttonsDetails === "isProjectManagement" && <AddIcon />}
            </IconButton>
          </Tooltip>
        )}
      {buttonsDetails === "isProjectDocuments" &&
        AuthHelper.canSyncDocuments(
          appState?.activeCountry?.id,
          appState?.activeProjectUser
        ) && (
          <Tooltip title="Sync Documents">
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={syncDocDialog.onOpen}
            >
              <SyncIcon />
            </IconButton>
          </Tooltip>
        )}
      {buttonsDetails === "isCountryManagement" && (
        <>
          {AuthHelper.canSyncCountries(appState?.activeProjectUser) && (
            <Tooltip title="Sync Countries">
              <IconButton
                className={classes.iconButton}
                color="primary"
                disabled={isLoading}
                onClick={syncCountriesDialog.onOpen}
              >
                <SyncIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Save">
            <IconButton
              className={classes.iconButton}
              color="primary"
              disabled={isLoading}
              onClick={handleSaveCountryChanges}
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {buttonsDetails !== "isCountryManagement" &&
        buttonsDetails !== "isVersionManagement" &&
        buttonsDetails !== "isSettingsManagement" &&
        buttonsDetails !== "isProjectDocuments" && (
          <Tooltip title="Refresh">
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={handleRefreshClick}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
      {buttonsDetails === "isSettingsManagement" && <ManageSettingsButton />}
      {inviteUserDialogV1.isOpen && <InviteUserDialogV1 />}
      {syncCountriesDialog.isOpen && <SyncCountriesDialogV1 />}
      {syncDocDialog.isOpen && <SyncNewTemplateDocsDialogV1 />}
      {createProjectDialogV1.isOpen && <CreateProjectDialogV1 />}
      {createDocumentDialogV1.isOpen && <CreateDocumentDialogV1 />}
    </>
  )
}

export default ToolbarButtons
