import { appSyncApiConfig as config } from "./config"
import { QueryResponse } from "shared/types/queryResponse"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { PartialAuditTrail } from "shared/types-exp"

export const auditTrailApi = createApi({
  reducerPath: "auditTrailApi",
  tagTypes: ["AuditTrail"],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", config.apiKey)

      return headers
    },
  }),
  endpoints: (builder) => ({
    ListAuditTrailFilteredByEmail: builder.query<
      QueryResponse<"listAuditTrail">,
      { email: string; nextToken?: string }
    >({
      query: ({ email, nextToken }) => ({
        url: "",
        method: "POST",
        body: {
          query: `
              query ListAuditTrailFilteredByEmail($eq: String = "", $limit: Int = 100) {
                listAuditTrail(filter: {email: {eq: $eq}}, limit: $limit) {
                  items {
                    createdAt
                    details
                    email
                    event
                    id
                    name
                    surname
                    type
                  }
                  nextToken
                }
              }
          `,
          variables: {
            eq: email,
            nextToken: nextToken || null,
          },
        },
      }),
      providesTags: ["AuditTrail"],
    }),
    CreateAuditTrail: builder.mutation<
      QueryResponse<"getAuditTrail">,
      PartialAuditTrail
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            mutation CreateAuditTrail(
              $createdAt: String = "", 
              $details: String = "", 
              $email: String = "", 
              $event: String = "", 
              $name: String = "", 
              $surname: String = "", 
              $type: String = ""
            ) {
              createAuditTrail(
                input: {
                  createdAt: $createdAt
                  details: $details
                  email: $email, 
                  event: $event, 
                  name: $name,
                  surname: $surname, 
                  type: $type
                }
              ) {
                createdAt
                details
                email
                event
                id
                name
                surname
                type
              }
            }
          `,
          variables: {
            createdAt: data.createdAt,
            details: data.details,
            email: data.email,
            event: data.event,
            name: data.name,
            surname: data.surname,
            type: data.type,
          },
        },
      }),
      invalidatesTags: ["AuditTrail"],
    }),
    batchDeleteAuditTrails: builder.mutation<any, any>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: {
          query: `
            mutation BatchDeleteAuditTrailInput($objects: [DeleteAuditTrailInput!]!) {
              batchDeleteAuditTrail(input: {objects: $objects}) {
                id
                email
              }
            }
          `,
          variables: {
            objects: data.objects,
          },
        },
      }),
      invalidatesTags: ["AuditTrail"],
    }),
  }),
})

export const {
  useCreateAuditTrailMutation,
  useListAuditTrailFilteredByEmailQuery,
} = auditTrailApi

export const auditTrailUtil = auditTrailApi.util
