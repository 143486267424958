// ==================== External Imports ==================== //
import { Skeleton } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { Paper, TablePagination, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import { Document } from "shared/types-exp"
import NoDataMessage from "../NoDataMessage"
import useAppState from "hooksV1/useAppState"
import useBooleanState from "hooksV1/useBooleanStates"
import DocumentsTableBodyV1 from "./DocumentsTableBodyV1"
import useDeleteDocumentDialogV1 from "hooksV1/useDeleteDocumentV1"
import DeleteDocumentDialogV1 from "dialogsV1/DeleteDocumentDialogV1"

type DocumentTableProps = {
  searchText: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: "0.5rem",
  },
  paginationContainer: {
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    marginTop: "0.5rem",
    marginLeft: "1rem",
    width: "100%",
    height: "100%",
  },
  skeleton: {
    width: "100%",
    height: "100%",
  },
}))

const DocumentTable: React.FC<DocumentTableProps> = ({ searchText }) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { documents } = useAppState()
  const deleteDocumentDialog = useDeleteDocumentDialogV1()
  const { showDisabled, isDocumentsLoading, isCountriesLoading } =
    useBooleanState()

  // ==================== use Hooks ==================== //
  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>(null)

  useEffect(() => {
    setIsLoading(true)
    setUsers()
  }, [documents, showDisabled, searchText])

  // ==================== Functions ==================== //
  const setUsers = () => {
    let rows = documents

    if (searchText !== "") {
      rows = documents.filter((row, key) => {
        if (
          row.name.toLowerCase().includes(searchText.toLowerCase()) ||
          row.author.toLowerCase().includes(searchText.toLowerCase()) ||
          row.lastUpdated.toLowerCase().includes(searchText.toLowerCase())
        )
          return row
      })
    }

    if (!showDisabled) rows = rows.filter((row) => row.enabled)

    setFilteredDocuments(rows)
    setIsLoading(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  if (isLoading || !documents || isDocumentsLoading || isCountriesLoading) {
    return (
      <Paper className={classes.container} style={{marginTop: "0.5rem"}}>
        <Skeleton
          className={classes.skeleton}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      </Paper>
    )
  }

  return (
    <>
      <div className={classes.container}>
        {filteredDocuments && filteredDocuments.length > 0 ? (
          <>
            <DocumentsTableBodyV1
              documents={filteredDocuments}
              page={page}
              rowsPerPage={rowsPerPage}
            />
            <Paper className={classes.paginationContainer}>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={filteredDocuments.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Paper>
          </>
        ) : (
          <NoDataMessage text={"No Documents Found"} />
        )}
      </div>

      {deleteDocumentDialog.isOpen && <DeleteDocumentDialogV1 />}
    </>
  )
}

export default DocumentTable
