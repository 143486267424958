import {
  Paper,
  Theme,
  Avatar,
  Divider,
  Tooltip,
  makeStyles,
} from "@material-ui/core"
import React, { useState, useEffect, useRef } from "react"
import NoAccountsIcon from "@mui/icons-material/NoAccounts"
import CircularProgress from "@mui/material/CircularProgress"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded"
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded"

import {
  getChapterObjectForSections,
  listCountriesWithNoRefId,
} from "graphql/queries"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import { getColorForStatus } from "util/helper"
import { generateClient } from "aws-amplify/api"
import TextEditorSection from "./TextEditorSection"
import { checkEnvironment } from "util/environment"
import AttachmentSection from "./AttachmentSection"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

import { ChapterObject, ChapterSection } from "shared/types-exp"

import useChapterStatusDialogV1 from "hooksV1/useChapterStatusDialog"
import useSaveAttachmentsDialogV1 from "hooksV1/useSaveAttachmentsDialogV1"
import useDeleteAttachmentsDialogV1 from "hooksV1/useDeleteAttachmentsDialogV1"

import ChapterStatusDialog from "dialogsV1/ChapterStatusDialogV1"
import SaveAttachmentsDialogV1 from "dialogsV1/SaveAttachmentsDialogV1"
import DeleteAttachmentsDialogV1 from "dialogsV1/DeleteAttachmentsDialogV1"
import { ProjectUserRole } from "shared/types/project"

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    fontSize: "1rem",
    padding: "0.15rem",
    backgroundColor: "#37017e",
    color: theme.palette.getContrastText("#37017e"),
  },
  avatarName: {
    color: "white",
    minWidth: "105px",
  },
  iconButton: {
    color: "primary",
    marginRight: "0.5rem",
  },
  sectionsContainer: {
    width: "100%",
    height: "98%",
    display: "flex",
    padding: "0.5rem",
    flexDirection: "column",
    margin: theme.spacing(0, 0, 0, 0),
  },
  skeletonLoader: {
    width: "100%",
    height: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    margin: theme.spacing(1.5, 0, 0, 0),
  },
  noSectionsContainer: {
    width: "100%",
    height: "99%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1.5, 0, 0, 0),
  },
  editingContainer: {
    width: "100%",
    display: "flex",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    marginBottom: "0.5rem",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  parentChapterContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  chapterStatusContainer: {
    flex: 1,
    height: 25,
    marginBottom: "0.25rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chapterStatusHeading: {
    display: "inline-block",
  },
  chapterStatusInnerContainer: {
    gap: 8,
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  expandIconContainer: {
    width: "1rem",
    height: "1rem",
  },
  avatarContainer: {
    borderRadius: "50%",
    border: "2px solid #39ff14",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  },
  status: {
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
  },
}))

const client = generateClient()

const DocumentSectionsV1: React.FC = () => {
  const {
    activeProject,
    activeCountry,
    activeDocument,
    chapterObjectsList,
    activeChapterObject,
    setActiveChapterObject,
  } = useAppState()

  const classes = useStyles()
  const snackBar = useSnackBar()
  const useChapterStatus = useChapterStatusDialogV1()
  const { isProjectEnvironment } = checkEnvironment()
  const saveAttachments = useSaveAttachmentsDialogV1()
  const deleteAttachments = useDeleteAttachmentsDialogV1()

  const fetchInitiated = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const [chapterObjectsToUpdate, setChapterObjectsToUpdate] = useState([])
  const [chapterObject, setChapterObject] = useState<ChapterObject | null>(null)
  const [projectUsers, setProjectUsers] = useState<ProjectUserRole[]>([])

  const firstName: string = activeChapterObject?.editing?.name
  const lastName: string = activeChapterObject?.editing?.surname

  useEffect(() => {
    if (
      !activeCountry ||
      !activeChapterObject ||
      fetchInitiated.current ||
      activeChapterObject.id === chapterObject?.id
    ) {
      return
    }

    const fetchChapterObject = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: getChapterObjectForSections,
          variables: {
            id: activeChapterObject?.id,
            documentVersionId: activeChapterObject?.documentVersionId,
          },
        })

        const activeChapterSections = result.data.getChapterObject

        if (activeChapterSections) {
          const newActiveChapterObject = {
            ...activeChapterObject,
            ...activeChapterSections,
          }

          setChapterObject(newActiveChapterObject)

          setActiveChapterObject(newActiveChapterObject)

          if (isProjectEnvironment) setProjectUsers(activeProject.users || [])
        } else {
          throw Error(
            `No chapter object found. The result object was null. Here is the object passed: ${activeChapterObject}`
          )
        }
      } catch (error) {
        logger(
          "DocumentSectionsV1",
          "useEffect (getChapterObjectForSections)",
          error
        )

        snackBar.setMessage(
          "An error occurred fetching the active chapter information. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    const fetchListCountriesWithNoRefId = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: listCountriesWithNoRefId,
          variables: {
            filter: {
              projectRefId: {
                eq: "",
              },
            },
          },
        })

        const chapterObjects: ChapterObject[] = []
        const countries = result?.data?.listCountries?.items || []

        const documentsWithActiveVersion = countries.flatMap((country) =>
          country.documents.items.filter(
            (doc) =>
              doc.refId === activeDocument?.refId &&
              doc.documentVersions.items.length > 0
          )
        )

        for (const doc of documentsWithActiveVersion) {
          const activeVersion = doc.documentVersions.items[0]

          if (activeVersion) {
            const chapters =
              (activeVersion.chapters.items as ChapterObject[]) || []

            const updatableChapter = chapters.find(
              (chapter) => chapter.refId === activeChapterObject?.refId
            )

            if (
              updatableChapter &&
              updatableChapter.id !== activeChapterObject?.id
            ) {
              chapterObjects.push(updatableChapter)
            } else {
              const newActiveChapterObject = {
                ...activeChapterObject,
                ...updatableChapter,
              }

              setChapterObject(newActiveChapterObject)
              setActiveChapterObject(newActiveChapterObject)
            }
          }
        }

        setChapterObjectsToUpdate(chapterObjects)
      } catch (error) {
        logger(
          "DocumentSectionsV1",
          "useEffect (fetchListCountriesWithNoRefId)",
          error
        )

        snackBar.setMessage(
          "An error occurred fetching the list of countries. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    if (activeCountry?.global) fetchListCountriesWithNoRefId()
    else fetchChapterObject()
  }, [activeChapterObject, activeCountry])

  useEffect(() => {
    if (
      chapterObject &&
      activeChapterObject &&
      activeChapterObject.id === chapterObject.id &&
      isChangePresent()
    ) {
      setChapterObject(activeChapterObject)
    }
  }, [activeChapterObject])

  useEffect(() => {
    if (chapterObjectsList && chapterObjectsList.length === 0) {
      setIsLoading(false)
    }
  }, [chapterObjectsList])

  const isEditing = () => {
    return chapterObject?.editing && chapterObject?.editing.isEditing
  }

  const isInWorkFlow = () => {
    return activeDocument?.isIncludedInWorkflow && isProjectEnvironment
  }

  const isChangePresent = (): boolean => {
    const {
      editing,
      sections,
      epi_status,
      client_status,
      assignedProjectUser,
    } = activeChapterObject

    if (chapterObject?.editing?.email !== editing?.email) return true

    if (chapterObject?.epi_status?.status !== epi_status?.status) return true

    if (chapterObject?.assignedProjectUser !== assignedProjectUser) return true

    if (chapterObject?.client_status?.status !== client_status?.status)
      return true

    if (
      !chapterObject.sections ||
      (chapterObject.sections && chapterObject.sections.length === 0)
    )
      return false

    const sectionMap = new Map(sections.map((section) => [section.id, section]))

    for (const section of chapterObject?.sections || []) {
      const activeSection = sectionMap.get(section.id)

      if (!activeSection) return true

      if (
        section.type === "attachment" &&
        activeSection.content?.uploadedFiles?.length !==
          section.content?.uploadedFiles?.length
      ) {
        return true
      }

      if (
        section.type === "text" &&
        activeSection.content?.plainText !== section.content?.plainText
      ) {
        return true
      }
    }

    return false
  }

  const displayResponsibleConsultantToolTip = () => {
    if (!chapterObject?.assignedProjectUser) {
      return (
        <Tooltip title="No Responsible Consultant Assigned">
          <NoAccountsIcon fontSize="medium" />
        </Tooltip>
      )
    }

    const assignedUser = projectUsers.find(
      (user) => user.user.id === chapterObject?.assignedProjectUser
    )

    if (!assignedUser) {
      return (
        <Tooltip title="No Responsible Consultant Assigned">
          <NoAccountsIcon fontSize="medium" />
        </Tooltip>
      )
    }

    return (
      <Tooltip
        title={assignedUser.user.firstname + " " + assignedUser.user.surname}
      >
        <AccountCircleIcon fontSize="medium" />
      </Tooltip>
    )
  }

  const chapterStatusView = () => {
    return (
      isProjectEnvironment &&
      activeDocument?.isIncludedInWorkflow && (
        <div className={classes.chapterStatusContainer}>
          <div
            style={{
              gap: 8,
              display: "flex",
              alignItems: "center",
            }}
          >
            {chapterObject?.epi_status.status !== "" && (
              <div
                className={classes.status}
                style={{
                  backgroundColor: `${getColorForStatus(
                    activeChapterObject?.epi_status.status
                  )}`,
                }}
              ></div>
            )}
            <div className={classes.chapterStatusInnerContainer}>
              <h4 className={classes.chapterStatusHeading}>
                {activeChapterObject?.epi_status.status}
              </h4>
              <div onClick={useChapterStatus?.onOpen}>
                {useChapterStatus?.isOpen ? (
                  <CloseFullscreenRoundedIcon fontSize="small" />
                ) : (
                  <OpenInFullRoundedIcon fontSize="small" />
                )}
              </div>
            </div>
          </div>

          {isProjectEnvironment && displayResponsibleConsultantToolTip()}
        </div>
      )
    )
  }

  const displayContent = () => {
    if (isLoading) {
      return (
        <div className={classes.skeletonLoader}>
          <CircularProgress />
        </div>
      )
    }

    if (!activeChapterObject || !chapterObject) {
      return (
        <Paper className={classes.noSectionsContainer}>
          <h3>No Chapters Available</h3>
        </Paper>
      )
    }

    if (activeChapterObject?.sections?.length === 0) {
      return (
        <Paper className={classes.noSectionsContainer}>
          <h3>No Sections Available</h3>
        </Paper>
      )
    }

    return (
      <>
        <div className={classes.sectionsContainer}>
          <>
            <div className={classes.editingContainer}>
              {chapterStatusView()}
              {isEditing() && (
                <div className={classes.avatarContainer}>
                  <Tooltip
                    title={`${firstName} ${lastName} is currently editing`}
                  >
                    <Avatar
                      className={classes.avatar}
                      alt={`${firstName} ${lastName}`}
                      src=""
                    >
                      {firstName ? firstName.charAt(0) : ""}{" "}
                      {lastName ? lastName.charAt(0) : ""}
                    </Avatar>
                  </Tooltip>
                </div>
              )}
            </div>
            {(isEditing() || isInWorkFlow()) && <Divider />}
          </>
          <div>
            {chapterObject?.sections
              .filter((section) => {
                if (activeCountry?.global) return section.isGlobal

                return section
              })
              .map((chapterSection: ChapterSection, index: number) => {
                return chapterSection.type === "text" ? (
                  <TextEditorSection
                    chapterSection={chapterSection}
                    chapterObject={chapterObject}
                    chapterObjectsToUpdate={chapterObjectsToUpdate}
                    setChapterObjectsToUpdate={setChapterObjectsToUpdate}
                    key={`${chapterSection.id}-${activeChapterObject.id}`}
                  />
                ) : (
                  <AttachmentSection
                    sectionId={chapterSection.id}
                    chapterSection={chapterSection}
                    chapterObject={chapterObject}
                    key={`${chapterSection.id}-${activeChapterObject.id}`}
                  />
                )
              })}
          </div>
        </div>

        {useChapterStatus?.isOpen && <ChapterStatusDialog />}

        {saveAttachments.isOpen && (
          <SaveAttachmentsDialogV1
            chapterObjectsToUpdate={chapterObjectsToUpdate}
            setChapterObjectsToUpdate={setChapterObjectsToUpdate}
          />
        )}

        {deleteAttachments.isOpen && (
          <DeleteAttachmentsDialogV1
            chapterObjectsToUpdate={chapterObjectsToUpdate}
          />
        )}
      </>
    )
  }

  return displayContent()
}

export default DocumentSectionsV1
