import { create } from "zustand"

type CreateDocumentDialogState = {
  isOpen: boolean
}

type CreateDocumentDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useCreateDocumentDialogV1 = create<
  CreateDocumentDialogState & CreateDocumentDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useCreateDocumentDialogV1
