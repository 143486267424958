import {
  UploadedFile,
  MetadataProps,
  ImageMetadata,
  ImageRemoveProp,
} from "shared/types-exp"
import { v4 as uuidv4 } from "uuid"
import { mapMimeTypeToFileType } from "./helper"

export const isValidUrl = (url: string): boolean => {
  try {
    return new URL(url).protocol.startsWith("http")
  } catch {
    return false
  }
}

export const hasSingleBase64ImgTag = (htmlString: string): boolean => {
  const imgTagRegex =
    /<img\b[^>]*\bsrc="(?:data:image\/[a-zA-Z]+;base64,[^"]+|blob:http:\/\/localhost:\d+\/[a-z0-9-]+)"[^>]*>/gi
  const matches = htmlString.match(imgTagRegex)

  // Check if there are any image tags
  return matches && matches.length > 0
}

export const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const haveImagesChanged = (
  oldHtml: string,
  newHtml: string
): boolean => {
  const parser = new DOMParser()
  const oldDoc = parser.parseFromString(oldHtml, "text/html")
  const newDoc = parser.parseFromString(newHtml, "text/html")

  const oldImages = Array.from(oldDoc.getElementsByTagName("img"))
    .filter((img) => isValidUrl(img.src))
    .map((img) => img.outerHTML)

  const newImages = Array.from(newDoc.getElementsByTagName("img"))
    .filter((img) => isValidUrl(img.src))
    .map((img) => img.outerHTML)

  if (oldImages.length !== newImages.length) {
    return true
  }

  for (let i = 0; i < oldImages.length; i++) {
    if (oldImages[i] !== newImages[i]) {
      return true
    }
  }

  return false
}

export const updateImgSrc = (
  htmlString: string,
  files: ImageMetadata[]
): string => {
  // Create a DOM parser
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, "text/html")

  // Create a map for quick lookup of file metadata
  const fileMap = new Map(files.map((file) => [file.attributes?.src, file]))

  // Find all img elements
  const imgElements = doc.getElementsByTagName("img")

  // Update each img element
  Array.from(imgElements).forEach((img) => {
    const src = img.getAttribute("src")
    const file = fileMap.get(src)

    if (file && file.attributes) {
      const { alt, width, height } = file.attributes
      img.src = file.image.content

      img.id = file.image.id

      if (alt) img.alt = alt

      if (width) img.width = parseInt(width)

      if (height) img.height = parseInt(height)
    }
  })

  // Return the updated HTML string
  return doc.body.innerHTML
}

export const updateMetadataWithContent = (
  result: UploadedFile[],
  extractedImages: MetadataProps
): MetadataProps => {
  // Create a map from the result array for quick lookups
  const contentMap = result.reduce((map, item) => {
    map[item.id] = item.content

    return map
  }, {})

  // Update the metadata field with the content from the result array
  const updatedMetadata = extractedImages.metadata.map((metaItem) => {
    const imageId = metaItem.image.id

    return {
      ...metaItem,
      image: {
        ...metaItem.image,
        content: contentMap[imageId] || metaItem.image.content,
      },
    }
  })

  // Return the updated object with modified metadata
  return {
    ...extractedImages,
    metadata: updatedMetadata,
  }
}

export const getChangedImageSrc = (
  oldHtml: string,
  newHtml: string
): ImageRemoveProp | null => {
  const parser = new DOMParser()
  const oldDoc = parser.parseFromString(oldHtml, "text/html")
  const newDoc = parser.parseFromString(newHtml, "text/html")

  const getValidImageSrcs = (
    doc: Document
  ): Map<string, { id: string | null; src: string | null }> => {
    const srcs = new Map<string, { id: string | null; src: string | null }>()
    const images = doc.getElementsByTagName("img")

    for (let i = 0; i < images.length; i++) {
      const src = images[i].getAttribute("src")
      const id = images[i].id || null // Access the 'id' attribute of the image element, default to null

      if (src && isValidUrl(src)) {
        srcs.set(src, { id, src })
      }
    }

    return srcs
  }

  const oldImageSrcs = getValidImageSrcs(oldDoc)
  const newImageSrcs = getValidImageSrcs(newDoc)

  // Check for removed images
  for (const [src, { id }] of oldImageSrcs.entries()) {
    if (!newImageSrcs.has(src)) {
      return { id, content: src, isRemoved: true } // Image was removed
    }
  }

  // Check for added images
  for (const [src, { id }] of newImageSrcs.entries()) {
    if (!oldImageSrcs.has(src)) {
      return { id, content: src, isRemoved: false } // Image was added
    }
  }

  return null // No changes in images
}

export const fetchBlobInfoAndParse = async (
  htmlString: string
): Promise<MetadataProps> => {
  const imgTagRegex = /<img\b[^>]*\bsrc="([^"]*)"[^>]*>/gi
  const base64Regex = /^data:image\/([a-zA-Z]+);base64,/

  const uploadableImages: UploadedFile[] = []
  const imgSrcMetadata: ImageMetadata[] = []

  let match
  const allowedTypes = ["image/png", "image/jpg", "image/jpeg"]

  while ((match = imgTagRegex.exec(htmlString)) !== null) {
    const src = match[1]
    const base64Match = base64Regex.exec(src)

    if (base64Match) {
      const base64Data = src.split(",")[1]
      const mimeType = `image/${base64Match[1]}`
      const fileExtension = mapMimeTypeToFileType(mimeType)

      const fileSize = (base64Data.length * 3) / 4 / (1024 * 1024) // Approximate file size in MB

      if (fileSize > 4) throw Error("File too large.")

      if (!allowedTypes.includes(mimeType)) throw Error("Unsupported file type")

      const attributes = {
        src,
        alt: match[0].match(/alt="([^"]*)"/)?.[1] || "",
        width: match[0].match(/width="([^"]*)"/)?.[1] || "",
        height: match[0].match(/height="([^"]*)"/)?.[1] || "",
      }

      const image: UploadedFile = {
        id: uuidv4(),
        name: `image_${Date.now()}.${fileExtension}`,
        size: fileSize,
        content: base64Data,
        type: fileExtension,
      }

      const imageMetadata: ImageMetadata = {
        image,
        attributes,
      }

      uploadableImages.push(image)
      imgSrcMetadata.push(imageMetadata)
    } else if (src.startsWith("blob:")) {
      try {
        const response = await fetch(src)
        const blob = await response.blob()
        const base64DataUrl = await blobToDataURL(blob)

        const sizeInMB = blob.size / (1024 * 1024)

        if (sizeInMB > 4) throw Error("File too large.")

        if (!allowedTypes.includes(blob.type))
          throw Error("Unsupported file type")

        const attributes = {
          src,
          alt: match[0].match(/alt="([^"]*)"/)?.[1] || "",
          width: match[0].match(/width="([^"]*)"/)?.[1] || "",
          height: match[0].match(/height="([^"]*)"/)?.[1] || "",
        }

        const type = mapMimeTypeToFileType(blob.type)

        const uploadedFile: UploadedFile = {
          id: uuidv4(),
          name: `blob_image_${Date.now()}.${type}`,
          type,
          size: blob.size,
          content: base64DataUrl,
        }

        const imageMetadata: ImageMetadata = {
          image: uploadedFile,
          attributes,
        }

        uploadableImages.push(uploadedFile)
        imgSrcMetadata.push(imageMetadata)
      } catch (error) {
        throw Error("Failed to fetch or process blob data:", error)
      }
    }
  }

  return { images: uploadableImages, metadata: imgSrcMetadata }
}
