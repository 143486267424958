import { create } from "zustand"

type DeleteCountryDialogState = {
  isOpen: boolean
}

type DeleteCountryDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useDeleteCountryDialogV1 = create<
  DeleteCountryDialogState & DeleteCountryDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useDeleteCountryDialogV1
