import axios from "axios"
import { isExpired } from "react-jwt"
import * as envConfig from "../../config"

//request interceptor to add the auth token header to requests
export default function authInterceptors() {
  const tokenString = sessionStorage.getItem("token")

  if (tokenString) {
    axios.defaults.headers.common["authorization"] = tokenString
  }

  const localToken = localStorage.getItem("token")

  if (localToken) {
    // const userToken = JSON.parse(localToken)
    axios.defaults.headers.common["authorization"] = localToken
  } else if (!localToken && !tokenString) {
    axios.defaults.headers.common["authorization"] = "allow"
  }

  axios.defaults.timeout = 360000

  axios.interceptors.request.use(
    function (config) {
      const token = sessionStorage.getItem("token")

      if (
        token &&
        config.url !== `${envConfig.default.apiGateway.URL}/auth/refresh`
      ) {
        if (isExpired(token)) {
          axios.defaults.headers.common["authorization"] = "allow"
          axios
            .post(
              `${envConfig.default.apiGateway.URL}/auth/refresh`,
              {
                token,
              },
              {
                headers: {
                  authorization: "allow",
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                const token: string =
                  response.headers["x-amzn-remapped-authorization"]
                const finalToken = token.slice(
                  token.indexOf("=") + 1,
                  token.indexOf(";")
                )

                const expiresIn = 24 * 60 * 60 * 1000
                const expiryTime = new Date().getTime() + expiresIn

                sessionStorage.setItem("token", finalToken)
                localStorage.setItem("token", finalToken)
                localStorage.setItem("token_expiry", expiryTime.toString())
                axios.defaults.headers.common["authorization"] = finalToken
              }
            })
            .catch(() => {
              //
            })
        }
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  //response interceptor to refresh token on receiving token expired error
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    function (error) {
      const token = sessionStorage.getItem("token")
      const originalRequest = error.config

      if (error.response && error.response.status) {
        if (token && error.response.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true

          return axios
            .post(`${envConfig.default.apiGateway.URL}/auth/refresh`, {
              token,
            })
            .then((response) => {
              if (response.status === 200) {
                const token: string =
                  response.headers["x-amzn-remapped-authorization"]
                const finalToken = token.slice(
                  token.indexOf("=") + 1,
                  token.indexOf(";")
                )
                localStorage.setItem("token", finalToken)
                sessionStorage.setItem("token", finalToken)
                axios.defaults.headers.common["authorization"] = finalToken

                return axios(originalRequest)
              }
            })
        }
      }

      return Promise.reject(error)
    }
  )
}
