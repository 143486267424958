import { IconButton, InputAdornment } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles, Theme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import clsx from "clsx"
import * as EmailValidator from "email-validator"
import image from "media/logo.svg"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setRegistrationStatus } from "redux/slices/registration"
import { RootState } from "redux/store"
import { registerUser } from "redux/thunks/registrationThunk"
import { Status } from "shared/types/status"
import styled from "styled-components"
import Loader from "components/Loading/Loader"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 100%",
    overflow: "auto",
  },
  mainDiv: {
    display: "flex",
    flex: 1,
    padding: "2em",
  },
  paperContainer: {
    display: "flex",
    padding: theme.spacing(9, 2, 2, 2),
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: theme.spacing(3, 0),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  formHalfField: {
    /* paddingRight: theme.spacing(3), */
  },
  divPaddingLeft: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
    },
  },
  divPaddingRight: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
    },
  },
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    "& > *": {
      paddingLeft: "0 !important",
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: "none !important",
    color: theme.palette.primary.main,
  },
}))

const Register: React.FC = () => {
  const classes = useStyles()
  let navigate = useNavigate()
  const snackBar = useSnackBar()
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [repeatPasswordError, setRepeatPasswordError] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false)

  const Message = useSelector(
    (state: RootState) => state.registration.registrationMessage
  )
  const theRegistrationStatus = useSelector(
    (state: RootState) => state.registration.registrationStatus
  )

  useEffect(() => {
    if (theRegistrationStatus === Status.success) {
      setFirstName("")
      setLastName("")
      setEmail("")
      navigate("/completion")
    } else if (theRegistrationStatus === Status.failed) {
      snackBar.setMessage(
        Message ? Message : "Error. Could not connect to the server"
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [theRegistrationStatus])

  const sendDetailsToServer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let valid = true

    let emailValidation = EmailValidator

    if (firstName === "") {
      setFirstNameError(true)
      valid = false

      return
    }
    setFirstNameError(false)
    valid = true

    if (lastName === "") {
      setLastNameError(true)
      valid = false

      return
    }
    setLastNameError(false)
    valid = true

    if (email === "" || !emailValidation.validate(email)) {
      setEmailError(true)
      valid = false

      return
    }
    setEmailError(false)
    valid = true

    if (password === "") {
      setPasswordError(true)
      valid = false

      return
    }
    setPasswordError(false)
    valid = true

    if (repeatPassword === "" || repeatPassword !== password) {
      setRepeatPasswordError(true)
      valid = false

      return
    }
    setRepeatPasswordError(false)
    valid = true

    if (valid) {
      /* register() */
      const registerThunk = registerUser(email, password, lastName, firstName)
      dispatch(registerThunk)
      dispatch({ type: setRegistrationStatus.type, payload: Status.loading })
    }
  }

  return (
    <div className={classes.mainDiv}>
      <Grid
        alignItems="center"
        className={classes.gridContainer}
        justifyContent="center"
        spacing={3}
        container
      >
        <Grid className={classes.gridItemPaperContainer} xs={10} item>
          <Paper className={classes.paperContainer}>
            <Container component="main" maxWidth="sm">
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.logoContainer}>
                  <LogoImg src={image} alt="logo" />
                </div>
                <Typography
                  color="textPrimary"
                  component="h1"
                  style={{ textAlign: "center" }}
                  variant="h5"
                >
                  Complete registration
                </Typography>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={sendDetailsToServer}
                >
                  <Grid container spacing={0}>
                    <Grid sm={6} xs={12} item>
                      <div
                        className={clsx(
                          classes.formFields,
                          classes.divPaddingRight
                        )}
                      >
                        <TextField
                          autoComplete="firstName"
                          id="firstName"
                          label="First name"
                          margin="normal"
                          name="firstName"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setFirstName(event.target.value)}
                          error={firstNameError ? true : false}
                          helperText={
                            firstNameError
                              ? "Please provide your first name"
                              : ""
                          }
                          className={classes.formHalfField}
                        />
                      </div>
                    </Grid>
                    <Grid sm={6} xs={12} item>
                      <div
                        className={clsx(
                          classes.formFields,
                          classes.divPaddingLeft
                        )}
                      >
                        <TextField
                          autoComplete="lastName"
                          id="lastName"
                          label="Last name"
                          margin="normal"
                          name="lastName"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setLastName(event.target.value)}
                          error={lastNameError ? true : false}
                          helperText={
                            lastNameError ? "Please provide your last name" : ""
                          }
                          className={classes.formHalfField}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div className={classes.formFields}>
                        <TextField
                          autoComplete="email"
                          id="email"
                          label="Email address"
                          margin="normal"
                          name="email"
                          variant="outlined"
                          autoFocus
                          fullWidth
                          required
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setEmail(event.target.value)}
                          error={emailError ? true : false}
                          helperText={
                            emailError
                              ? "Please provide correct email format"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div className={classes.formFields}>
                        <TextField
                          autoComplete="current-password"
                          id="password"
                          label="Password"
                          margin="normal"
                          name="password"
                          variant="outlined"
                          type={passwordShown ? "text" : "password"}
                          fullWidth
                          required
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setPassword(event.target.value)}
                          error={passwordError ? true : false}
                          helperText={
                            passwordError ? "Password cannot be empty" : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordShown(!passwordShown)
                                  }
                                >
                                  {passwordShown ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div className={classes.formFields}>
                        <TextField
                          autoComplete="current-password"
                          id="repeatPassword"
                          label="Repeat password"
                          margin="normal"
                          name="repeatPassword"
                          variant="outlined"
                          type={repeatPasswordShown ? "text" : "password"}
                          fullWidth
                          required
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setRepeatPassword(event.target.value)}
                          error={repeatPasswordError ? true : false}
                          helperText={
                            repeatPasswordError
                              ? "Current input is not matching password input"
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setRepeatPasswordShown(!repeatPasswordShown)
                                  }
                                >
                                  {repeatPasswordShown ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className={classes.formFields}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Register
                    </Button>
                  </div>

                  <Grid className={classes.formLink} container>
                    <Grid item xs>
                      <Link className={classes.link} to="/signin">
                        Sign In instead
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Loader open={theRegistrationStatus === Status.loading} />
    </div>
  )
}

export default Register

const LogoImg = styled.img<{ src: string; alt: string }>`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`
