/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// logger.ts
let isLoggerEnabled = true // Initially set to false

/**
 * Enables or disables the logger.
 * @param enabled - Whether to enable or disable the logger.
 */
export const setLoggerEnabled = (enabled: boolean): void => {
  isLoggerEnabled = enabled
}

/**
 * A reusable logger function that logs messages with additional context.
 * @param componentName - The name of the component or function where the logger is called.
 * @param message - The message to be logged.
 * @param meta - Optional additional metadata or context to be logged.
 */
export const logger = (
  componentName: string,
  functionName: string,
  meta: any
): void => {
  if (isLoggerEnabled) {
    const logMessage = `[${componentName}] [${functionName} Function] `

    console.error(logMessage, meta)
  }
}
