// ==================== External Imports ==================== //
import {
  List,
  Theme,
  Paper,
  makeStyles,
  ListSubheader,
} from "@material-ui/core"
import React, { useEffect } from "react"

// ==================== Local Imports ==================== //
import Loading from "../Loading/Loading"
import NoDataMessage from "../NoDataMessage"
import useAppState from "hooksV1/useAppState"
import { ChapterSection } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useBooleanState from "hooksV1/useBooleanStates"
import EditSectionDialog from "dialogsV1/EditSectionDialog"
import ManageSectionListTile from "./ManageSectionListTile"
import useManageSectionsState from "hooksV1/useManageSections"
import ManageSectionSubHeaders from "./ManageSectionSubHeaders"
import useEditSectionDialogV1 from "hooksV1/useEditSectionDialogV1"
import ReOrderSectionsDialogV1 from "dialogsV1/ReOrderSectionsDialogV1"
import useReOrderSectionDialogV1 from "hooksV1/useReOrderSectionsDialogV1"
import { useGetDocumentVersionForManageSectionV1Query } from "redux/services"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0.5rem",
    marginTop: "0.5rem",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    padding: theme.spacing(1, 1),
  },
  subHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subHeader: {
    width: "20%",
    textAlign: "center",
  },
  noSectionsContainer: {
    padding: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}))

const ManageSections: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const editSection = useEditSectionDialogV1()
  const reOrderSection = useReOrderSectionDialogV1()
  const { sections, setSections } = useManageSectionsState()
  const { setIsChaptersLoading, isChaptersLoading } = useBooleanState()
  const { activeCountry, activeDocument, activeDocumentVersion } = useAppState()

  const { isProjectEnvironment } = checkEnvironment()

  const { data: documentVersionData, isLoading: isLoadingDocumentsVersion } =
    useGetDocumentVersionForManageSectionV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  // ==================== Use Hooks ==================== //
  useEffect(() => {
    if (documentVersionData) {
      const docVersionSections =
        documentVersionData.data.getDocumentVersion.sections
      setSections(docVersionSections)
    }
  }, [documentVersionData])

  useEffect(() => {
    if (isChaptersLoading) setIsChaptersLoading(false)
  }, [isChaptersLoading])

  // ==================== Functions ==================== //
  const onTextFieldChange = (value: string, index: number) => {
    let newSections: ChapterSection[] = [...sections]
    newSections[index] = { ...newSections[index], name: value }
    setSections(newSections)
  }

  const onRadioFiledChange = (value: string, index: number) => {
    let newSections: ChapterSection[] = [...sections]

    if (value === "0") {
      newSections[index] = {
        ...newSections[index],
        isGlobal: true,
        isClientEditable: false,
      }
    } else if (value === "1") {
      newSections[index] = {
        ...newSections[index],
        isGlobal: false,
        isClientEditable: false,
      }
    } else if (value === "2") {
      newSections[index] = {
        ...newSections[index],
        isGlobal: false,
        isClientEditable: true,
      }
    }

    setSections(newSections)
  }

  const isEditable = (section: ChapterSection) => {
    if (isProjectEnvironment && section.isClientEditable) return true

    if (!isProjectEnvironment && activeCountry?.global) return true

    if (!isProjectEnvironment && !activeCountry?.global && !section.isGlobal)
      return true

    return false
  }

  const displaySections = () => {
    if (isLoadingDocumentsVersion) return <Loading />

    if (sections?.length === 0)
      return <NoDataMessage text={"No Sections Found"} />

    return (
      <Paper
        style={{
          padding: "1em",
        }}
      >
        <div className={classes.list}>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <ManageSectionSubHeaders />
              </ListSubheader>
            }
            dense={true}
          >
            {sections?.map((section, index) => {
              return (
                <ManageSectionListTile
                  index={index}
                  key={section.id}
                  section={section}
                  isEditable={isEditable(section)}
                  onTextFieldChange={onTextFieldChange}
                  onRadioFiledChange={onRadioFiledChange}
                />
              )
            })}
          </List>
        </div>
      </Paper>
    )
  }

  return (
    <>
      <div className={classes.container}>{displaySections()}</div>

      {editSection.isOpen && <EditSectionDialog />}
      {reOrderSection.isOpen && <ReOrderSectionsDialogV1 />}
    </>
  )
}

export default ManageSections
