import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"

import {
  Operations,
  ChapterObject,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  useUpdateDocumentMutation,
  useCreateAuditTrailMutation,
  useBatchUpdateChapterObjectsMutation,
  useGetDocumentVersionWithChaptersV1Query,
} from "redux/services"
import {
  updateDocument,
  createAuditTrail,
  batchUpdateChapters,
} from "util/batchHook"
import {
  transformArray,
  createAuditTrailObject,
  invalidateAll,
} from "util/helper"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import useRemoveFromWorkflowDialogV1 from "hooksV1/useRemoveFromWorkflowDialogV1"

type RemoveFromWorkflowDialogV1Props = {
  setWorkflow: (isEnabled: boolean) => void
}

const RemoveFromWorkflowDialogV1: React.FC<RemoveFromWorkflowDialogV1Props> = ({
  setWorkflow,
}) => {
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const { isProjectEnvironment } = checkEnvironment()
  const removeFromWorkflowDialog = useRemoveFromWorkflowDialogV1()
  const {
    activeCountry,
    activeDocument,
    activeDocumentVersion,
    setActiveDocument,
    setChapterObjects,
    setChapterObjectsList,
  } = useAppState()

  const [isLoading, setIsLoading] = useState(false)
  const [chapters, setChapters] = useState<ChapterObject[]>([])
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [updateDocumentAPI] = useUpdateDocumentMutation()
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchUpdateChapterObjectAPI] = useBatchUpdateChapterObjectsMutation()

  const { data: documentVersionData, isLoading: isLoadingDocumentsVersion } =
    useGetDocumentVersionWithChaptersV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  useEffect(() => {
    if (!documentVersionData) return

    const documentVersion = documentVersionData.data.getDocumentVersion

    if (!documentVersion) return

    const chapters = documentVersion.chapters["items"] as ChapterObject[]
    setChapters(chapters)
  }, [documentVersionData])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleRemoveFromWorkFlow()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    removeFromWorkflowDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleRemoveFromWorkFlow = async () => {
    try {
      if (activeCountry?.global) {
        snackBar.setMessage(
          "Can't remove a global document to a workflow. Please try a different document."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        return
      }

      setIsLoading(true)

      let clonedChapters = []

      if (isProjectEnvironment) {
        const allChaptersCompleted = chapters.every(
          (chapter) =>
            chapter.epi_status.status === "Completed" &&
            chapter.client_status.status === "Approved"
        )

        if (!allChaptersCompleted) {
          snackBar.setMessage(
            "There are chapters with incomplete statuses. Please make sure all chapters have a completed status."
          )
          snackBar.setMessageSeverity(SnackType.SnackError)
          snackBar.onOpen()
          setIsLoading(false)

          return
        }

        clonedChapters = clonedChapters.map((chapter) => {
          const chapterCopy = {
            ...chapter,
            isIncludedInWorkflow: false,
            epi_status: {
              status: "",
              comments: "",
              userLastUpdated: "",
              lastUpdated: new Date().toISOString(),
            },
            client_status: {
              status: "",
              comments: "",
              userLastUpdated: "",
              lastUpdated: new Date().toISOString(),
            },
          }

          delete chapterCopy.subchapters

          return chapterCopy
        })
      }

      const updatedDocument = {
        ...activeDocument,
        isIncludedInWorkflow: false,
        lastUpdated: new Date().toISOString(),
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.DOCUMENT,
        `Removed the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment, from a workflow.`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        updateDocument(updateDocumentAPI, updatedDocument),
        batchUpdateChapters(batchUpdateChapterObjectAPI, clonedChapters),
      ])

      invalidateAll(dispatch)

      setWorkflow(false)
      setActiveDocument(updatedDocument)
      setChapterObjectsList(clonedChapters)
      setChapterObjects(transformArray(clonedChapters))
      handleClose()
    } catch (error) {
      logger("RemoveFromWorkflowDialogV1", "handleRemoveFromWorkFlow", error)

      snackBar.setMessage(
        "An error occurred removing the document from a workflow. Please try again."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleCloseDialog}
      open={removeFromWorkflowDialog.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Confirm Remove From Workflow</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to remove this document from a workflow?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="text"
          color="primary"
          disabled={isLoading}
          onClick={removeFromWorkflowDialog.onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={handleRemoveFromWorkFlow}
        >
          Confirm
        </Button>
      </DialogActions>
      {(isLoading || isLoadingDocumentsVersion) && <Loader open={true} />}
    </Dialog>
  )
}

export default RemoveFromWorkflowDialogV1
