import { create } from "zustand"

type SaveWorkingVersionDialogState = {
  isOpen: boolean
}

type SaveWorkingVersionDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useSaveWorkingVersionDialogV1 = create<
  SaveWorkingVersionDialogState & SaveWorkingVersionDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useSaveWorkingVersionDialogV1
