import React, { useEffect, useState } from "react"
import {
  List,
  Theme,
  Paper,
  Switch,
  ListItem,
  TextField,
  makeStyles,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Collapse, ListItemButton } from "@mui/material"
import { ExpandLess, ExpandMore } from "@material-ui/icons"

import useAppState from "hooksV1/useAppState"
import useAddToWorkflowDialogV1 from "hooksV1/useAddToWorkflowDialogV1"
import useManageSettingsState from "../../hooksV1/useManageSettingsState"
import AddToWorkflowDialogV1 from "dialogsV1/AddToWorkflowDialogV1"
import RemoveFromWorkflowDialogV1 from "dialogsV1/RemoveFromWorkflowDialogV1"
import useRemoveFromWorkflowDialogV1 from "hooksV1/useRemoveFromWorkflowDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: "1.5rem",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
  },
  form: {
    width: "100%",
    padding: theme.spacing(0, 0, 0, 0),
  },
  textField: {
    marginBottom: "1.2rem",
  },
  iconButton: {
    marginLeft: "0.5rem",
  },
  skeletonContainer: {
    width: "100%",
  },
  skeleton: {
    width: "100%",
    height: "40px",
  },
}))

const ProjectManageSettings: React.FC = () => {
  const classes = useStyles()
  const addToWorkflowDialog = useAddToWorkflowDialogV1()
  const removeFromWorkflowDialog = useRemoveFromWorkflowDialogV1()

  const {
    newIsIncludedInWorkflow,
    setNewAuthorName,
    setNewDocumentName,
    setNewIsIncludedInWorkflow,
  } = useManageSettingsState()
  const { activeDocument, activeProjectUser, activeCountry } = useAppState()

  const [collapseOpen, setCollapseOpen] = useState(false)

  useEffect(() => {
    if (activeDocument) {
      setNewAuthorName(activeDocument?.author)
      setNewDocumentName(activeDocument?.name)
      setNewIsIncludedInWorkflow(activeDocument?.isIncludedInWorkflow)
    }
  }, [activeDocument])

  const showSkeleton = () => {
    return (
      <>
        {Array.from({ length: 3 }, (_, index) => (
          <ListItem key={index} button className={classes.skeletonContainer}>
            <Skeleton
              variant="text"
              width={"100%"}
              animation="wave"
              style={{ borderRadius: "8px" }}
              className={classes.skeleton}
            />
          </ListItem>
        ))}
      </>
    )
  }

  const handleCollapseOpen = () => {
    setCollapseOpen(!collapseOpen)
  }

  const handleChangeIncludedInWorkflow = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      removeFromWorkflowDialog.onOpen()
    } else {
      addToWorkflowDialog.onOpen()
    }
  }

  const isDisabled = (): boolean => {
    return !activeProjectUser?.role
      .filter(
        (role) =>
          role.countryId &&
          (role.countryId === activeCountry?.id || role.countryId === "global")
      )
      .some(
        (roleEntry) =>
          roleEntry.role_name === "Project Manager" ||
          roleEntry.role_name === "AMS Consultant" ||
          roleEntry.role_name === "Implementation Consultant"
      )
  }

  return (
    <>
      <Paper className={classes.content}>
        {activeDocument ? (
          <form className={classes.form} noValidate>
            <TextField
              className={classes.textField}
              autoComplete="DocumentName"
              id="DocumentName"
              label="Document Name"
              name="DocumentName"
              variant="outlined"
              fullWidth
              value={activeDocument?.name}
              InputProps={{ readOnly: true }}
            />
            <TextField
              className={classes.textField}
              focused={false}
              id="author"
              label="Author"
              name="author"
              variant="outlined"
              fullWidth
              value={activeDocument?.author}
              InputProps={{ readOnly: true }}
            />

            <ListItemButton onClick={handleCollapseOpen}>
              <ListItemText primary="Advanced Settings" />
              {collapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isDisabled()}
                        checked={newIsIncludedInWorkflow}
                        onChange={handleChangeIncludedInWorkflow}
                      />
                    }
                    label={
                      newIsIncludedInWorkflow
                        ? "Included in workflow"
                        : "Not included in workflow"
                    }
                  />
                </ListItem>
              </List>
            </Collapse>
          </form>
        ) : (
          showSkeleton()
        )}
      </Paper>
      {addToWorkflowDialog.isOpen && (
        <AddToWorkflowDialogV1 setWorkflow={setNewIsIncludedInWorkflow} />
      )}
      {removeFromWorkflowDialog.isOpen && (
        <RemoveFromWorkflowDialogV1 setWorkflow={setNewIsIncludedInWorkflow} />
      )}
    </>
  )
}

export default ProjectManageSettings
