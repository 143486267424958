import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import useDeleteProjectTypeDialog from "../hooksV1/useDeleteProjectTypeDialog"

const DeleteProjectTypeDialog: React.FC = () => {
  const deleteProjectTypeDialog = useDeleteProjectTypeDialog()

  const handleClose = () => {
    deleteProjectTypeDialog.onClose()
  }

  return (
    <Dialog
      maxWidth="xs"
      open={deleteProjectTypeDialog.isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to delete this project type?
          <br />
          <span>
            <b>(This action is permanent)</b>
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" variant="text">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteProjectTypeDialog
