import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import { RootState } from "redux/store"
import { Status } from "shared/types/status"
import { useDispatch, useSelector } from "react-redux"

import {
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import useEditUserState from "hooksV1/useEditUserState"
import { useCreateAuditTrailMutation } from "redux/services"
import { deleteUserOnDB } from "redux/thunks/usersSliceThunk"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useDeleteUserDialogV1 from "hooksV1/useDeleteUserDialogV1"
import { createAuditTrailObject } from "util/helper"

const DeleteUserDialogV1: React.FC = () => {
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const { activeUser } = useEditUserState()
  const useDeleteUser = useDeleteUserDialogV1()

  const [isLoading, setIsLoading] = useState(false)

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()

  const deleteUserStatus = useSelector(
    (state: RootState) => state.users.deleteUserStatus
  )

  const deleteUserMessage = useSelector(
    (state: RootState) => state.users.deleteUserMessage
  )

  useEffect(() => {
    const handleAsyncEffect = async () => {
      if (deleteUserStatus === Status.success) {
        const auditTrail: PartialAuditTrail = createAuditTrailObject(
          AuditTrailOperations.DELETE,
          Operations.USER,
          `The user ${activeUser.firstname} ${
            activeUser.surname
          } has been deleted by ${localStorage.getItem(
            "firstname"
          )} ${localStorage.getItem("surname")}`
        )

        await createAuditTrailAPI(auditTrail)

        snackBar.setMessage("User Deleted Successfully")
        snackBar.setMessageSeverity(SnackType.SnackSuccess)
        snackBar.onOpen()

        handleClose()
      } else if (deleteUserStatus === Status.failed) {
        logger("DeleteUserDialogV1", "useEffect", deleteUserMessage)

        snackBar.setMessage("User Deletion Failed. Please try again.")
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        setIsLoading(false)
      }
    }

    handleAsyncEffect()
  }, [deleteUserStatus])

  const handleConfirmDeleteUser = async () => {
    if (!activeUser) return

    setIsLoading(true)
    const deleteUserThunk = deleteUserOnDB(activeUser)
    dispatch(deleteUserThunk)
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await handleConfirmDeleteUser()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    useDeleteUser.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      maxWidth="xs"
      open={useDeleteUser.isOpen}
      onClose={handleCloseDialog}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to delete this user? This user will be deleted
          and removed from all Projects.
          <br />
          <span>
            <b>(This action is permanent)</b>
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          variant="text"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDeleteUser}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUserDialogV1
