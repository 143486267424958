import { create } from "zustand"

type ManageProjectTypesDialogState = {
    isOpen: boolean
}

type ManageProjectTypesDialogActions = {
    onOpen: () => void
    onClose: () => void
}

const useManageProjectTypesDialog = create<
  ManageProjectTypesDialogState & ManageProjectTypesDialogActions
>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
}))

export default useManageProjectTypesDialog
