import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import {
  invalidateAll,
  createAuditTrailObject,
  getImageTagsFromPlainText,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useGetFullCountryIdsToDeleteQuery,
  useDeleteCountryOperationMutation,
} from "redux/services"
import { useDispatch } from "react-redux"
import Loader from "components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import {
  Operations,
  AuditTrailOperations,
  ChapterSection,
} from "shared/types-exp"

import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import { createAuditTrail } from "util/batchHook"
import { DeleteCountryModel } from "shared/types-exp/delete"
import useDeleteCountryState from "hooksV1/useDeleteCountryState"
import useDeleteCountryDialogV1 from "hooksV1/useDeleteCountryDialogV1"

const DeleteCountryDialogV1: React.FC = () => {
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const useDeleteState = useDeleteCountryState()
  const useDeleteCountry = useDeleteCountryDialogV1()
  const { activeDocument, setIsDeleting } = useAppState()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [deleteCountryApi] = useDeleteCountryOperationMutation()
  const { data: countryToDelete, isLoading: isLoadingGetCountryIds } =
    useGetFullCountryIdsToDeleteQuery(useDeleteState.country?.id)

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [isLoading, setIsLoading] = useState(false)
  const [deleteCountryModel, setDeleteCountryModel] =
    useState<DeleteCountryModel>(null)

  const filesArray = []

  useEffect(() => {
    if (!countryToDelete) return

    const { getCountry } = countryToDelete.data

    if (!getCountry || !activeDocument) return

    const { refId: activeRefId } = activeDocument
    const documents = getCountry.documents.items

    const foundDocument = documents.find((doc) => doc.refId === activeRefId)

    if (foundDocument) {
      const documentVersion = foundDocument.documentVersions.items[0]

      const chapters = documentVersion.chapters.items.map((chapter) => {
        extractSections(chapter.sections)

        return {
          id: chapter.id,
          documentVersionId: chapter.documentVersionId,
        }
      })

      const isCountryBeingDeleted = documents.length === 1
      const countryId = isCountryBeingDeleted ? getCountry.id : ""

      const newModel: DeleteCountryModel = {
        document: {
          id: foundDocument.id,
          countryId: foundDocument.countryId,
        },
        documentVersion: {
          id: documentVersion.id,
          documentId: documentVersion.documentId,
        },
        uploadedFiles: filesArray,
        chapters,
        isCountryBeingDeleted,
        countryId,
      }

      setDeleteCountryModel(newModel)
    }
  }, [activeDocument, countryToDelete])

  const extractSections = (sections: ChapterSection[]) => {
    for (const section of sections) {
      if (section.isGlobal) continue

      if (
        section.type === "attachment" &&
        section.content.uploadedFiles.length > 0
      ) {
        section.content.uploadedFiles.map((file) => {
          filesArray.push({ id: file.id, content: file.content })
        })

        continue
      }

      const templateImgTags = getImageTagsFromPlainText(
        section.content.plainText
      )

      if (section.type === "text" && templateImgTags.length > 0) {
        templateImgTags.forEach((img) => {
          if (img.src.includes("uploaded-files-s3") && img.id) {
            const file = {
              id: img.id,
              content: img.src,
            }

            filesArray.push(file)
          }
        })
      }
    }
  }

  const handleConfirmDeleteCountry = async () => {
    if (!countryToDelete) return

    try {
      setIsLoading(true)

      await deleteCountryApi(deleteCountryModel)

      const auditTrail = createAuditTrailObject(
        AuditTrailOperations.DELETE,
        Operations.COUNTRY,
        `${useDeleteState.country?.country_name} was deleted from the ${activeDocument?.name} document in the ${environment} environment.`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)

      setIsDeleting({ flag: true, id: useDeleteState.country?.id })

      invalidateAll(dispatch)

      snackBar.setMessage("Country Deleted Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("DeleteCountryDialog", "handleConfirmDeleteCountry", error)

      snackBar.setMessage("Country Delete Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleConfirmDeleteCountry()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    useDeleteCountry.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleCloseDialog}
      open={useDeleteCountry.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: "center", justifyContent: "stretch" }}
          variant="body1"
          color="textPrimary"
        >
          Are you sure you want to delete this country?
          <br />
          <span>
            <b>(This action is permanent)</b>
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          variant="text"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDeleteCountry}
          color="primary"
          variant="contained"
          disabled={isLoading || isLoadingGetCountryIds}
        >
          Delete
        </Button>
      </DialogActions>

      {(isLoading || isLoadingGetCountryIds) && <Loader open={true} />}
    </Dialog>
  )
}

export default DeleteCountryDialogV1
