/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API"
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType
  __generatedMutationOutput: OutputType
}

export const createAuditTrail =
  /* GraphQL */ `mutation CreateAuditTrail($input: CreateAuditTrailInput!) {
  createAuditTrail(input: $input) {
    email
    id
    name
    surname
    event
    details
    type
    createdAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateAuditTrailMutationVariables,
    APITypes.CreateAuditTrailMutation
  >
export const deleteAuditTrail =
  /* GraphQL */ `mutation DeleteAuditTrail($input: DeleteAuditTrailInput!) {
  deleteAuditTrail(input: $input) {
    email
    id
    name
    surname
    event
    details
    type
    createdAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteAuditTrailMutationVariables,
    APITypes.DeleteAuditTrailMutation
  >
export const batchDeleteAuditTrail =
  /* GraphQL */ `mutation BatchDeleteAuditTrail($input: BatchDeleteAuditTrailInput!) {
  batchDeleteAuditTrail(input: $input) {
    email
    id
    name
    surname
    event
    details
    type
    createdAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchDeleteAuditTrailMutationVariables,
    APITypes.BatchDeleteAuditTrailMutation
  >
export const createCountry =
  /* GraphQL */ `mutation CreateCountry($input: CreateCountryInput!) {
  createCountry(input: $input) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateCountryMutationVariables,
    APITypes.CreateCountryMutation
  >
export const updateCountry =
  /* GraphQL */ `mutation UpdateCountry($input: UpdateCountryInput!) {
  updateCountry(input: $input) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateCountryMutationVariables,
    APITypes.UpdateCountryMutation
  >
export const deleteCountry =
  /* GraphQL */ `mutation DeleteCountry($input: DeleteCountryInput!) {
  deleteCountry(input: $input) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteCountryMutationVariables,
    APITypes.DeleteCountryMutation
  >
export const createImageData =
  /* GraphQL */ `mutation CreateImageData($input: CreateImageDataInput!) {
  createImageData(input: $input) {
    id
    name
    type
    size
    content
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateImageDataMutationVariables,
    APITypes.CreateImageDataMutation
  >
export const updateImageData =
  /* GraphQL */ `mutation UpdateImageData($input: UpdateImageDataInput!) {
  updateImageData(input: $input) {
    id
    name
    type
    size
    content
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateImageDataMutationVariables,
    APITypes.UpdateImageDataMutation
  >
export const createDocument =
  /* GraphQL */ `mutation CreateDocument($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateDocumentMutationVariables,
    APITypes.CreateDocumentMutation
  >
export const updateDocument =
  /* GraphQL */ `mutation UpdateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateDocumentMutationVariables,
    APITypes.UpdateDocumentMutation
  >
export const deleteDocument =
  /* GraphQL */ `mutation DeleteDocument($input: DeleteDocumentInput!) {
  deleteDocument(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteDocumentMutationVariables,
    APITypes.DeleteDocumentMutation
  >
export const createVersion =
  /* GraphQL */ `mutation CreateVersion($input: CreateVersionInput!) {
  createVersion(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    lastUpdated
    enabled
    chapters {
      documentVersionId
      id
      refId
      parentId
      sharedId
      isGlobal
      isCountry
      isProject
      documentRefId
      name
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      editing {
        email
        name
        surname
        isEditing
        __typename
      }
      epi_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      client_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      treeIndex
      assignedProjectUser
      chapterOrder
      isIncludedInWorkflow
      subchapterCreateAllowed
      __typename
    }
    documentVersion {
      documentId
      id
      latestVersion
      users
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      isProject
      isCountry
      isGlobal
      documentRefId
      refId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateVersionMutationVariables,
    APITypes.CreateVersionMutation
  >
export const deleteVersion =
  /* GraphQL */ `mutation DeleteVersion($input: DeleteVersionInput!) {
  deleteVersion(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    lastUpdated
    enabled
    chapters {
      documentVersionId
      id
      refId
      parentId
      sharedId
      isGlobal
      isCountry
      isProject
      documentRefId
      name
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      editing {
        email
        name
        surname
        isEditing
        __typename
      }
      epi_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      client_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      treeIndex
      assignedProjectUser
      chapterOrder
      isIncludedInWorkflow
      subchapterCreateAllowed
      __typename
    }
    documentVersion {
      documentId
      id
      latestVersion
      users
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      isProject
      isCountry
      isGlobal
      documentRefId
      refId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteVersionMutationVariables,
    APITypes.DeleteVersionMutation
  >
export const batchDeletVersions =
  /* GraphQL */ `mutation BatchDeletVersions($input: BatchDeleteVersionInput!) {
  batchDeletVersions(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    lastUpdated
    enabled
    chapters {
      documentVersionId
      id
      refId
      parentId
      sharedId
      isGlobal
      isCountry
      isProject
      documentRefId
      name
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      editing {
        email
        name
        surname
        isEditing
        __typename
      }
      epi_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      client_status {
        status
        lastUpdated
        userLastUpdated
        comments {
          email
          comment
          lastName
          timeStamp
          firstName
          metadata
          __typename
        }
        __typename
      }
      treeIndex
      assignedProjectUser
      chapterOrder
      isIncludedInWorkflow
      subchapterCreateAllowed
      __typename
    }
    documentVersion {
      documentId
      id
      latestVersion
      users
      sections {
        id
        content {
          uploadedFiles {
            id
            name
            type
            size
            content
            __typename
          }
          plainText
          __typename
        }
        isGlobal
        isCountry
        isProject
        isClientEditable
        name
        type
        viewing
        refId
        __typename
      }
      access
      isProject
      isCountry
      isGlobal
      documentRefId
      refId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchDeletVersionsMutationVariables,
    APITypes.BatchDeletVersionsMutation
  >
export const createDocumentVersion =
  /* GraphQL */ `mutation CreateDocumentVersion($input: CreateDocumentVersionInput!) {
  createDocumentVersion(input: $input) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateDocumentVersionMutationVariables,
    APITypes.CreateDocumentVersionMutation
  >
export const updateDocumentVersion =
  /* GraphQL */ `mutation UpdateDocumentVersion($input: UpdateDocumentVersionInput!) {
  updateDocumentVersion(input: $input) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          # comments {
          #   email
          #   comment
          #   lastName
          #   timeStamp
          #   firstName
          #   metadata
          #   __typename
          # }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateDocumentVersionMutationVariables,
    APITypes.UpdateDocumentVersionMutation
  >
export const deleteDocumentVersion =
  /* GraphQL */ `mutation DeleteDocumentVersion($input: DeleteDocumentVersionInput!) {
  deleteDocumentVersion(input: $input) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteDocumentVersionMutationVariables,
    APITypes.DeleteDocumentVersionMutation
  >
export const createChapterObject =
  /* GraphQL */ `mutation CreateChapterObject($input: CreateChapterObjectInput!) {
  createChapterObject(input: $input) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateChapterObjectMutationVariables,
    APITypes.CreateChapterObjectMutation
  >
export const updateChapterObject =
  /* GraphQL */ `mutation UpdateChapterObject($input: UpdateChapterObjectInput!) {
  updateChapterObject(input: $input) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateChapterObjectMutationVariables,
    APITypes.UpdateChapterObjectMutation
  >
export const deleteChapterObject =
  /* GraphQL */ `mutation DeleteChapterObject($input: DeleteChapterObjectInput!) {
  deleteChapterObject(input: $input) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteChapterObjectMutationVariables,
    APITypes.DeleteChapterObjectMutation
  >
export const batchCreateChapterObjects =
  /* GraphQL */ `mutation BatchCreateChapterObjects($input: BatchCreateChapterObjectsInput!) {
  batchCreateChapterObjects(input: $input) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchCreateChapterObjectsMutationVariables,
    APITypes.BatchCreateChapterObjectsMutation
  >
export const batchCreateImageData =
  /* GraphQL */ `mutation BatchCreateImageData($input: BatchCreateImageDataInput!) {
  batchCreateImageData(input: $input) {
    id
    name
    type
    size
    content
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchCreateImageDataMutationVariables,
    APITypes.BatchCreateImageDataMutation
  >
export const batchUpdateImageData =
  /* GraphQL */ `mutation BatchUpdateImageData($input: BatchUpdateImageDataInput!) {
  batchUpdateImageData(input: $input) {
    id
    name
    type
    size
    content
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchUpdateImageDataMutationVariables,
    APITypes.BatchUpdateImageDataMutation
  >
export const batchUpdateChapterObjects =
  /* GraphQL */ `mutation BatchUpdateChapterObjects($input: BatchUpdateChapterObjectsInput!) {
  batchUpdateChapterObjects(input: $input) {
    documentVersionId
    id
  }
}
` as GeneratedMutation<
    APITypes.BatchUpdateChapterObjectsMutationVariables,
    APITypes.BatchUpdateChapterObjectsMutation
  >
export const batchUpdateChapterSection =
  /* GraphQL */ `mutation BatchUpdateChapterSection($input: BatchUpdateChapterSectionInput!) {
  batchUpdateChapterSection(input: $input) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchUpdateChapterSectionMutationVariables,
    APITypes.BatchUpdateChapterSectionMutation
  >
export const batchDeleteChapterObjects =
  /* GraphQL */ `mutation BatchDeleteChapterObjects($input: BatchDeleteChapterObjectsInput!) {
  batchDeleteChapterObjects(input: $input) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchDeleteChapterObjectsMutationVariables,
    APITypes.BatchDeleteChapterObjectsMutation
  >
export const batchDeleteDocuments =
  /* GraphQL */ `mutation BatchDeleteDocuments($input: BatchDeleteDocumentsInput!) {
  batchDeleteDocuments(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchDeleteDocumentsMutationVariables,
    APITypes.BatchDeleteDocumentsMutation
  >
export const batchUpdateDocuments =
  /* GraphQL */ `mutation BatchUpdateDocuments($input: BatchUpdateDocumentsInput!) {
  batchUpdateDocuments(input: $input) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchUpdateDocumentsMutationVariables,
    APITypes.BatchUpdateDocumentsMutation
  >
export const batchDeleteDocumentVersions =
  /* GraphQL */ `mutation BatchDeleteDocumentVersions(
  $input: BatchDeleteDocumentVersionsInput!
) {
  batchDeleteDocumentVersions(input: $input) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchDeleteDocumentVersionsMutationVariables,
    APITypes.BatchDeleteDocumentVersionsMutation
  >
export const batchUpdateDocumentVersions =
  /* GraphQL */ `mutation BatchUpdateDocumentVersions(
  $input: BatchUpdateDocumentVersionsInput!
) {
  batchUpdateDocumentVersions(input: $input) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.BatchUpdateDocumentVersionsMutationVariables,
    APITypes.BatchUpdateDocumentVersionsMutation
  >
