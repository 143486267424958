import React from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"

const useStyles = makeStyles((theme: Theme) => ({
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flex: "0 1 15px",
    [theme.breakpoints.down("sm")]: {
      flex: "1 1 25ch",
    },
  },
  searchField: {
    width: "45ch",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

type SearchFieldProps = {
  searchText: string
  setSearchText: (value: string) => void
}

const SearchField: React.FC<SearchFieldProps> = ({
  searchText,
  setSearchText,
}) => {
  const classes = useStyles()

  return (
    <span className={classes.formSpan}>
      <FormControl
        size="small"
        className={classes.searchField}
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment-weight"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          labelWidth={0}
          placeholder="Search..."
          value={searchText}
          onChange={(event) => setSearchText(event.target.value as string)}
        />
      </FormControl>
    </span>
  )
}

export default SearchField
