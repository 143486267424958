import {
  Theme,
  makeStyles,
  createStyles,
  LinearProgress,
} from "@material-ui/core"
import React from "react"

type Props = {
  open: boolean
  setOpen?: (open: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorPrimary: {
      backgroundColor: "#FFFFFF",
    },
    barColorPrimary: {
      backgroundColor: "#001744",
    },
  })
)

const Loader: React.FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles()

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 100000,
        width: "100%",
        left: 0,
        top: 0,
      }}
    >
      {open && <LinearProgress classes={classes} />}
    </div>
  )
}

export default Loader
