import axios from "axios"
import { cloneDeep } from "lodash"
import {
  addDocument,
  createDocuments,
  editChangeOnVersion,
  editChangesOnVersion,
  editContentOfSpecificDocument,
  fetchSpecificDocument,
  setAddDocumentChangeMessage,
  setAddDocumentChangeStatus,
  setAddDocumentMessage,
  setAddDocumentStatus,
  setDocumentByCountryVersions,
  setDocumentChanges,
  setDocumentVersions,
  setGetDocumentMessage,
  setGetDocumentStatus,
  setUpdateDocumentMessage,
  setUpdateDocumentStatus,
} from "redux/slices/documents"
import { Country } from "shared/types/country"
import type {
  ChapterObject,
  ChapterSection,
  Content,
  CountrySpecificContent,
  Document,
  DocumentChange,
  DocumentVersion,
} from "shared/types/document"
import { Status } from "shared/types/status"
import config from "../../config"
import {
  findDFSByTreeIndexAndUpdateChapterWasChanged,
  reIndexChapters,
} from "../../util/treeUtils"
import type { EPIDispatch, RootState } from "../store"
import { v4 as uuidv4 } from "uuid"

export const createChapterSection = (
  chapterName: string,
  sectionName: string,
  isGlobal: boolean,
  isClientEditable: boolean,
  globalContent: Content,
  countrySpecificContent: Array<Country>
) => {
  const listOfCountries: Array<Country> = JSON.parse(
    JSON.stringify(countrySpecificContent)
  )
  const countrySpecificContentArray: Array<CountrySpecificContent> =
    listOfCountries.map((country) => {
      const newnonClientContent: Content = {
        rawHTML: `This is ${country.country_name} content!`,
      }
      const newClientContent: Content = {
        rawHTML: `This is ${country.country_name} content!`,
      }
      const isActive = country.active_status
      country.active_status = true

      return {
        country,
        clientContent: newClientContent,
        chapterCountrySpecificName: chapterName,
        nonClientContent: newnonClientContent,
        isChapterActiveInCountry: isActive,
      }
    })
  const templateObject: ChapterSection = {
    name: sectionName,
    isGlobal,
    isClientEditable,
    chapterGlobalContent: globalContent,
    viewing: false,
    countrySpecificContent: countrySpecificContentArray,
    wasChanged: true,
  }

  return templateObject
}

export const createChapterOrSubchapter = (
  chapterName: string,
  countries: Array<Country>,
  sections: Array<ChapterSection>,
  documentVersion: DocumentChange,
  parentChapter?: ChapterObject
) => {
  const newSections: Array<ChapterSection> = []

  if (sections && sections.length > 0)
    sections.map((section: any) => {
      newSections.push(
        createChapterSection(
          chapterName,
          section.name,
          section.isGlobal,
          section.isClientEditable,
          {
            rawHTML: `This is Global content!`,
          },
          JSON.parse(JSON.stringify(countries))
        )
      )
    })

  if (parentChapter) {
    const newObject: ChapterObject = {
      id: Math.floor(Math.random() * Date.now()).toString(),
      refId: undefined,
      sharedId: uuidv4(),
      parentId: parentChapter.id,
      name: chapterName,
      subchapters: [],
      assignedProjectUser: [],
      sections: newSections,
      treeIndex: documentVersion.globalTreeIndexCounter + 1,
      chapterOrder: parentChapter.subchapters.length + 1,
    }

    const subChaptersCpy: ChapterObject[] = cloneDeep(parentChapter.subchapters)
    subChaptersCpy
      .sort((a, b) => a.chapterOrder - b.chapterOrder)
      .forEach((chapter, index) => {
        chapter.chapterOrder = index + 1
      })
    subChaptersCpy.push(newObject)

    parentChapter.subchapters = subChaptersCpy

    return newObject
  }

  const newChapter: ChapterObject = {
    id: Math.floor(Math.random() * Date.now()).toString(),
    sharedId: uuidv4(),
    name: chapterName,
    subchapters: [],
    sections: newSections,
    assignedProjectUser: [],
    treeIndex: documentVersion.globalTreeIndexCounter + 1,
    chapterOrder: documentVersion.chapters.length + 1,
    parentId: null,
    refId: null,
  }

  return newChapter
}

export const createBaseTemplateDoc = (
  newDoc: Document,
  initialVersion: DocumentVersion
) => {
  return {
    id: newDoc.id,
    name: newDoc.name,
    author: newDoc.author,
    activeVersion: newDoc.activeVersion,
    lastUpdated: newDoc.lastUpdated,
    documentVersions: [initialVersion],
  }
}

// Define the initial state using that type

export const clearContent = (
  chapters,
  sectionsToCopy,
  newGlobalSectionName
) => {
  chapters.forEach((chapter) => {
    chapter.sections.forEach((section) => {
      if (section.isGlobal) {
        section.name = newGlobalSectionName
      }
      section.chapterGlobalContent.rawHTML = ""
      section.countrySpecificContent.forEach((countryContent) => {
        countryContent.clientContent.rawHTML = ""
        countryContent.nonClientContent.rawHTML = ""
      })
    })
    clearContent(chapter.subchapters, sectionsToCopy, newGlobalSectionName)
  })
}

export function copyDFSForBlueprint(
  localStateDocument: DocumentChange,
  doc: DocumentChange
) {
  let newDocumentChapters: ChapterObject[]

  newDocumentChapters = cloneDeep(localStateDocument.chapters)
  deleteSectionsDFS(newDocumentChapters)
  //CLONE BUSINESS BLUEPRINT STRUCTURE OVER TO NEW DOCUMENT AND KEEP EXISTING DOCUMENT CONTENT

  const sectionsToCopy = cloneDeep(localStateDocument.sections)
  const chaptersToCopy = cloneDeep(localStateDocument.chapters)
  const newGlobalSectionName = doc.sections[0].name
  clearContent(chaptersToCopy, sectionsToCopy, newGlobalSectionName)
  const countriesToCopy = cloneDeep(doc.countries)
  copyDFS(newDocumentChapters, chaptersToCopy, sectionsToCopy, countriesToCopy)

  doc.chapters = cloneDeep(newDocumentChapters)
  clearContent(doc.chapters, sectionsToCopy, newGlobalSectionName)

  doc.sections = localStateDocument.sections

  const newSections = JSON.parse(JSON.stringify(doc.sections))
  newSections.forEach((section) => {
    if (section.isGlobal) {
      section.name = newGlobalSectionName
    }
  })
  doc.sections = newSections
}

export function isMasterBlueprintDocument(document: Document) {
  if (document.id === "0") {
    return true
  }

  return false
}

type responseDocument = {
  id: string
  refId: string | undefined
  document_name: string
  author: string
  lastUpdated: string
  latestVersion: number
  documentVersions: Array<DocumentVersion> //v1
  enabled: boolean
}

export function fetchDocuments() {
  return async function fetchDocuments(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setGetDocumentMessage.type, payload: "" })
    await axios
      .get(`${config.apiGateway.URL}/documents/get`)
      .then(function (response) {
        const attributes = response.data.message as responseDocument[]
        attributes.map((docs) => {
          docs.documentVersions.map((docVersion) => {
            if (!docVersion.country) {
              docVersion.country = null
            }
          })
        })
        let documents: Document[] = []
        attributes.map((res) => {
          let resDocVersion =
            res.documentVersions[res.documentVersions.length - 1]
          let resDocChange =
            resDocVersion.changes[resDocVersion.changes.length - 1]
          reIndexChapters(resDocChange.chapters, 0, "")
          documents.push({
            id: res.id,
            refId: res.refId,
            name: res.document_name,
            author: res.author,
            lastUpdated: res.lastUpdated.replace(
              "+0000 (Coordinated Universal Time)",
              ""
            ),
            documentVersions: res.documentVersions,
            activeVersion: res.latestVersion,
            enabled: res.enabled !== undefined ? res.enabled : true,
          })
        })

        documents.sort((a, b) => (a.name < b.name ? -1 : 1))
        dispatch({ type: createDocuments.type, payload: documents })
        dispatch({
          type: setGetDocumentStatus.type,
          payload: Status.success,
        })
        dispatch({ type: setGetDocumentMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({
          type: setGetDocumentStatus.type,
          payload: Status.failed,
        })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetDocumentMessage.type, payload: error })
          }
        } else dispatch({ type: setGetDocumentMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetDocumentStatus.type, payload: Status.idle })
        dispatch({ type: setGetDocumentMessage.type, payload: "" })
        localStorage.setItem("latestChanges", "true")
      })
  }
}

export function fetchDocumentsV2() {
  return async function fetchDocumentsV2(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setGetDocumentMessage.type, payload: "" })
    axios
      .get(`${config.apiGateway.URL}/documents/get_all_documents`)
      .then(function (response) {
        const attributes = response.data.message as responseDocument[]
        let documents: Document[] = []
        attributes.map((res) => {
          documents.push({
            id: res.id,
            refId: res.refId,
            name: res.document_name,
            author: res.author,
            lastUpdated: res.lastUpdated.replace(
              "+0000 (Coordinated Universal Time)",
              ""
            ),
            documentVersions: res.documentVersions,
            activeVersion: res.latestVersion,
            enabled: res.enabled !== undefined ? res.enabled : true,
          })
        })

        documents.sort((a, b) => (a.name < b.name ? -1 : 1))
        dispatch({ type: createDocuments.type, payload: documents })
        dispatch({
          type: setGetDocumentStatus.type,
          payload: Status.success,
        })
        dispatch({ type: setGetDocumentMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({
          type: setGetDocumentStatus.type,
          payload: Status.failed,
        })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetDocumentMessage.type, payload: error })
          }
        } else dispatch({ type: setGetDocumentMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetDocumentStatus.type, payload: Status.idle })
        dispatch({ type: setGetDocumentMessage.type, payload: "" })
      })
  }
}

export async function downloadDocument(
  documentVersionId: string,
  fileType: string,
  name: string,
  version = 1,
  countryId = "",
  latest = true
) {
  try {
    const response = await axios.post(
      "EXPORT_FILES" in config.apiGateway
        ? (config.apiGateway.EXPORT_FILES as string)
        : `${config.apiGateway.URL}/export/download`,
      {
        documentVersionId,
        fileType,
        latest,
        countryId,
        name,
        version,
      }
    )

    if (response.status === 200) {
      const fileUrl =
        "EXPORT_FILES" in config.apiGateway
          ? JSON.parse(response.data.body).fileUrl
          : response.data.body

      // Create an anchor element and trigger a download
      const link = document.createElement("a")
      link.href = fileUrl
      link.download = `EPI-CENTRE_V1_specifications_v01.${
        fileType === "pdf" ? "docx" : fileType
      }`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      console.error("Download failed:", response.data.message)
    }
  } catch (error) {
    console.error("Download failed:", error)
  }
}

export function fetchSpecificDocumentV2(id: string) {
  return async function fetchSpecificDocumentV2(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setGetDocumentMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/documents/get_document`, {
        id,
      })
      .then(function (response) {
        const attribute = response.data.message as responseDocument

        attribute.documentVersions.map((docVersion) => {
          if (!docVersion.country) {
            docVersion.country = null
          }
        })
        let document: Document = {
          id: attribute.id,
          refId: attribute.refId,
          name: attribute.document_name,
          author: attribute.author,
          lastUpdated: attribute.lastUpdated.replace(
            "+0000 (Coordinated Universal Time)",
            ""
          ),
          documentVersions: attribute.documentVersions,
          activeVersion: attribute.latestVersion,
          enabled: attribute.enabled !== undefined ? attribute.enabled : true,
        }

        let version =
          document.documentVersions[document.documentVersions.length - 1]
        let change = version.changes[version.changes.length - 1]

        findDFSByTreeIndexAndUpdateChapterWasChanged(change.chapters)
        reIndexChapters(change.chapters, 0, "")
        version.changes = [change]
        document.documentVersions = [version]

        dispatch({ type: fetchSpecificDocument.type, payload: document })
        dispatch({
          type: setGetDocumentStatus.type,
          payload: Status.success,
        })
        dispatch({ type: setGetDocumentMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({
          type: setGetDocumentStatus.type,
          payload: Status.failed,
        })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetDocumentMessage.type, payload: error })
          }
        } else dispatch({ type: setGetDocumentMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetDocumentStatus.type, payload: Status.idle })
        dispatch({ type: setGetDocumentMessage.type, payload: "" })
        localStorage.setItem("latestChanges", "true")
      })
  }
}

export function addDocumentOnDB(document: Document) {
  return async function addDocumentOnDB(dispatch: EPIDispatch, getState) {
    let reduxState = getState()

    let localStateDocument: Document = cloneDeep(document)
    // let index = reduxState.documents.Documents.findIndex(
    //   (p) => p.id === localStateDocument.id
    // );

    let masterBlueprintDocument: Document =
      reduxState.documents.Documents.filter((doc) => doc.id === "0")[0]
    dispatch({ type: setAddDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setAddDocumentMessage.type, payload: "" })

    if (masterBlueprintDocument) {
      let latestVersionLocalStateDocument =
        localStateDocument.documentVersions[
          localStateDocument.documentVersions.length - 1
        ]
      let latestChangeLocalStateDocVersion =
        latestVersionLocalStateDocument.changes[0]
      let masterBlueprintLatestVersion =
        masterBlueprintDocument.documentVersions[
          localStateDocument.documentVersions.length - 1
        ]
      let masterBlueprintLatestChange =
        masterBlueprintLatestVersion.changes[
          masterBlueprintLatestVersion.changes.length - 1
        ]

      copyDFSForBlueprint(
        masterBlueprintLatestChange,
        latestChangeLocalStateDocVersion
      )

      axios
        .post(`${config.apiGateway.URL}/documents/add`, {
          id: localStateDocument.id,
          document_name: localStateDocument.name,
          author: localStateDocument.author,
          documentVersions: localStateDocument.documentVersions,
          lastUpdated: localStateDocument.lastUpdated,
          latestVersion: latestVersionLocalStateDocument.latestVersion,
          enabled: true,
        })
        .then(function (response) {
          dispatch({
            type: setAddDocumentStatus.type,
            payload: Status.success,
          })
          dispatch({ type: setAddDocumentMessage.type, payload: "" })
        })
        .catch(function (error) {
          dispatch({ type: setAddDocumentStatus.type, payload: Status.failed })

          if (error.response !== undefined) {
            if (error.response.data !== undefined) {
              dispatch({
                type: setAddDocumentMessage.type,
                payload: error.response.data.message,
              })
            }
          } else dispatch({ type: setAddDocumentMessage.type, payload: "Unknown error" })
        })
    } else {
      axios
        .post(`${config.apiGateway.URL}/documents/add`, {
          id: localStateDocument.id,
          document_name: localStateDocument.name,
          author: localStateDocument.author,
          documentVersions: localStateDocument.documentVersions,
          lastUpdated: localStateDocument.lastUpdated,
          latestVersion: 0,
        })
        .then(function (response) {
          const res = response.data.message as responseDocument

          let newDocument: Document = {
            author: res.author,
            id: res.id,
            refId: res.refId,
            name: res.document_name,
            lastUpdated: res.lastUpdated,
            activeVersion: res.latestVersion,
            documentVersions: res.documentVersions,
            enabled: res.enabled,
          }
          dispatch({ type: addDocument.type, payload: newDocument })
          dispatch({
            type: setAddDocumentStatus.type,
            payload: Status.success,
          })
          dispatch({ type: setAddDocumentMessage.type, payload: "" })
        })
        .catch(function (error) {
          dispatch({ type: setAddDocumentStatus.type, payload: Status.failed })

          if (error.response !== undefined) {
            if (error.response.data !== undefined) {
              dispatch({
                type: setAddDocumentMessage.type,
                payload: error.response.data.message,
              })
            }
          } else dispatch({ type: setAddDocumentMessage.type, payload: "Unknown error" })
        })
      dispatch({ type: setAddDocumentStatus.type, payload: Status.idle })
      dispatch({ type: setAddDocumentMessage.type, payload: "" })
    }
  }
}

export function editDocumentOnDB(document: Document) {
  return async function editDocumentonDB(dispatch: EPIDispatch, getState) {
    dispatch({ type: setUpdateDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setUpdateDocumentMessage.type, payload: "" })

    const latestChange =
      document.documentVersions[document.documentVersions.length - 1].changes[
        document.documentVersions[document.documentVersions.length - 1].changes
          .length - 1
      ]
    latestChange.id && delete latestChange.id

    axios
      .put(`${config.apiGateway.URL}/documents/update`, {
        id: document.id,
        document_name: document.name,
        author: document.author,
        lastUpdated: document.lastUpdated,
        documentVersions: document.documentVersions,
        latestVersion: document.documentVersions.length - 1,
        enabled: document.enabled,
      })
      .then(function (response) {
        dispatch({
          type: setUpdateDocumentStatus.type,
          payload: Status.success,
        })
        dispatch({ type: setUpdateDocumentMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({
          type: setUpdateDocumentStatus.type,
          payload: Status.failed,
        })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setUpdateDocumentMessage.type, payload: error })
          }
        } else dispatch({ type: setUpdateDocumentMessage.type, payload: "Unknown error" })
      })
  }
}

export function editDocumentsOnDB(documents: Document[]) {
  return async function editDocumentsOnDB(dispatch: EPIDispatch, getState) {
    documents.map((document) => {
      axios
        .put(`${config.apiGateway.URL}/documents/update`, {
          id: document.id,
          document_name: document.name,
          author: document.author,
          documentVersions: document.documentVersions,
          lastUpdated: document.lastUpdated,
          latestVersion: document.activeVersion,
          enabled: document.enabled,
        })
        .then(function (response) {
          const res = JSON.parse(response.data.message)
            .Attributes as responseDocument
          let newDocument: Document = {
            author: res.author,
            id: res.id,
            refId: res.refId,
            name: res.document_name,
            lastUpdated: res.lastUpdated,
            activeVersion: res.latestVersion,
            documentVersions: res.documentVersions,
            enabled: res.enabled,
          }
          dispatch({
            type: editContentOfSpecificDocument.type,
            payload: newDocument,
          })
        })
    })
  }
}

export function copyDFS(
  newDocChapters: ChapterObject[],
  originalDocChapters: ChapterObject[],
  sectionTemplates: ChapterSection[],
  countries: Country[]
) {
  newDocChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
  originalDocChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)

  if (newDocChapters) {
    newDocChapters.map((newDocChapter: ChapterObject, index: number) => {
      //FOUND MY OBJECT
      //
      //

      let matchingOriginalChapter = originalDocChapters.find(
        (originalChapter) => originalChapter.sharedId === newDocChapter.sharedId
      )

      doCopy(
        matchingOriginalChapter,
        index,
        sectionTemplates,
        newDocChapter,
        countries
      )

      if (newDocChapter.subchapters.length == 0) {
        return
      }

      if (newDocChapter.subchapters) {
        copyDFS(
          newDocChapter.subchapters,
          originalDocChapters[index] && originalDocChapters[index].subchapters
            ? originalDocChapters[index].subchapters
            : [],
          sectionTemplates,
          countries
        )
      }
    })
  }
}

function doCopy(
  originalDocChapter: ChapterObject,
  index: number,
  sectionTemplates: ChapterSection[],
  newDocChapter: ChapterObject,
  countries: Country[]
) {
  let updatedSectionContent = originalDocChapter && originalDocChapter.sections

  //UPDATED SECTION
  if (originalDocChapter && updatedSectionContent) {
    updateExistingContentInChapter(
      sectionTemplates,
      originalDocChapter,
      newDocChapter,
      index,
      countries
    )
  }
  //NEW SECTION CONTENT
  else {
    createNewContentFromTemplates(sectionTemplates, newDocChapter, countries)
  }
}

function updateExistingContentInChapter(
  sectionTemplates: ChapterSection[],
  originalDocChapter: ChapterObject,
  newDocChapter: ChapterObject,
  index: number,
  countries: Country[]
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate, sectionTemplateIndex) => {
    let originalDocSectionsIndex = sectionTemplateIndex
    let hasChangedChapterName = originalDocChapter && originalDocChapter.name

    let newChapterName = hasChangedChapterName
      ? originalDocChapter.name
      : newDocChapter.name
    originalDocChapter.sections.map((originalSection, index) => {
      if (sectionTemplate.name === originalSection.name) {
        originalDocSectionsIndex = index
      }
    })
    let syncOriginalDocument =
      originalDocChapter.sections[originalDocSectionsIndex]
    let createdSection: ChapterSection

    if (syncOriginalDocument) {
      let globalContent = syncOriginalDocument.chapterGlobalContent
      let countryContent = syncOriginalDocument.countrySpecificContent
      createdSection = createChapterSection(
        newChapterName,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        globalContent,
        countries
      )

      if (countryContent.length > 0) {
        countryContent.map((countryCont) => {
          createdSection.countrySpecificContent.map(
            (countrySpecificCont, index) => {
              if (
                countryCont.country.country_name ===
                countrySpecificCont.country.country_name
              ) {
                createdSection.countrySpecificContent[index] = countryCont
              }
            }
          )
        })
      }
    } else {
      createdSection = createChapterSection(
        newDocChapter.name,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        sectionTemplate.chapterGlobalContent,
        countries
      )
    }
    createdSections.push(createdSection)
  })
  newDocChapter.sections = createdSections
  newDocChapter.name = originalDocChapter.name
}

function createNewContentFromTemplates(
  sectionTemplates,
  newDocChapter,
  countries
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate) => {
    let createdSection: ChapterSection
    createdSection = createChapterSection(
      undefined,
      sectionTemplate.name,
      sectionTemplate.isGlobal,
      sectionTemplate.isClientEditable,
      sectionTemplate.chapterGlobalContent,
      countries
    )
    createdSections.push(createdSection)
  })
  newDocChapter.sections = createdSections
}

function deleteSectionsDFS(newDocChapters: ChapterObject[]) {
  if (newDocChapters) {
    newDocChapters.map((newDocChapter: ChapterObject) => {
      if (newDocChapter.subchapters.length == 0) {
        newDocChapter.sections = []

        return
      }
      newDocChapter.sections = []

      deleteSectionsDFS(newDocChapter.subchapters)
    })
  }
}

export function fetchVersionNumberByCountry(id: string, country: Country) {
  return async function fetchVersionNumberByCountry(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    if (id && country) {
      axios
        .post(`${config.apiGateway.URL}/documents/getVersionNumberByCountry`, {
          id,
          country,
        })
        .then(function (response) {
          let attributes = {
            id,
            version: response.data.message as string,
            country,
          }
          dispatch({
            type: setDocumentByCountryVersions.type,
            payload: attributes,
          })
        })
        .catch(function (error) {
          //
        })
    }
  }
}

export function fetchVersions(isTemplate: boolean, id: string) {
  return async function fetchVersions(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    axios
      .post(`${config.apiGateway.URL}/documents/getVersions`, {
        id,
        templateDocument: isTemplate,
      })
      .then(function (response) {
        let attributes = response.data.message as DocumentVersion[]
        attributes = attributes.sort((a, b) =>
          a.latestVersion > b.latestVersion ? -1 : 1
        )

        dispatch({ type: setDocumentVersions.type, payload: attributes })
      })
      .catch(function (error) {
        //
      })
  }
}

export function fetchVersionsAndChanges(isTemplate: boolean, id: string) {
  return async function fetchVersions(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setAddDocumentChangeStatus.type, payload: Status.loading })
    dispatch({ type: setAddDocumentChangeMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/documents/getVersions`, {
        id,
        templateDocument: isTemplate,
      })
      .then(function (response) {
        let attributes = response.data.message as DocumentVersion[]
        attributes = attributes.sort((a, b) =>
          a.latestVersion > b.latestVersion ? -1 : 1
        )

        dispatch({ type: setDocumentVersions.type, payload: attributes })
        attributes.map((version) => {
          //version.changes.map((change) => {
          let change = version.changes[version.changes.length - 1]
          axios
            .post(`${config.apiGateway.URL}/documents/get_change_by_id`, {
              id: change,
            })
            .then(function (response) {
              let attribute = response.data.message as DocumentChange
              dispatch({
                type: editChangeOnVersion.type,
                payload: { versionId: version.id, change: attribute },
              })
            })
          //})
        })

        dispatch({
          type: setAddDocumentChangeStatus.type,
          payload: Status.success,
        })
        dispatch({ type: setAddDocumentChangeMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({
          type: setAddDocumentChangeStatus.type,
          payload: Status.failed,
        })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setAddDocumentChangeMessage.type, payload: error })
          }
        } else dispatch({ type: setAddDocumentChangeMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({
          type: setAddDocumentChangeStatus.type,
          payload: Status.idle,
        })
        dispatch({ type: setAddDocumentChangeMessage.type, payload: "" })
      })
  }
}

export function fetchChanges(versionId: string) {
  return async function fetchChanges(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    axios
      .post(`${config.apiGateway.URL}/documents/getChanges`, {
        id: versionId,
      })
      .then(function (response) {
        let attributes = response.data.message as DocumentChange[]
        attributes = attributes.sort(
          (a, b) =>
            new Date(b.lastUpdated).getTime() -
            new Date(a.lastUpdated).getTime()
        )

        dispatch({ type: setDocumentChanges.type, payload: attributes })
      })
      .catch(function (error) {
        //
      })
  }
}

export function fetchChangesV2(versionId: string) {
  return async function fetchChangesV2(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    axios
      .post(`${config.apiGateway.URL}/documents/getChanges`, {
        id: versionId,
      })
      .then(function (response) {
        let attributes = response.data.message as DocumentChange[]
        attributes = attributes.sort(
          (a, b) =>
            new Date(b.lastUpdated).getTime() -
            new Date(a.lastUpdated).getTime()
        )

        dispatch({
          type: editChangesOnVersion.type,
          payload: { versionId, changes: attributes },
        })
      })
      .catch(function (error) {
        //
      })
  }
}

export function fetchChangeById(versionId: string, changeId: string) {
  return async function fetchChangeById(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    axios
      .post(`${config.apiGateway.URL}/documents/get_change_by_id`, {
        id: changeId,
      })
      .then(function (response) {
        let attribute = response.data.message as DocumentChange

        dispatch({
          type: editChangeOnVersion.type,
          payload: { versionId, change: attribute },
        })
      })
      .catch(function (error) {
        //
      })
  }
}
