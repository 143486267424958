import { create } from "zustand"

export enum SnackType {
  SnackInfo = "info",
  SnackError = "error",
  SnackSuccess = "success",
  SnackWarning = "warning",
}

type SnackBarState = {
  isOpen: boolean
  message: string
  messageSeverity: SnackType
}

type SnackBarAction = {
  onOpen: () => void
  onClose: () => void
  setMessage: (message: string) => void
  setMessageSeverity: (messageSeverity: SnackType) => void
}

const useSnackBar = create<SnackBarState & SnackBarAction>((set) => ({
  isOpen: false,
  message: "",
  messageSeverity: SnackType.SnackInfo,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setMessage: (message) => set(() => ({ message })),
  setMessageSeverity: (messageSeverity) => set(() => ({ messageSeverity })),
}))

export default useSnackBar
