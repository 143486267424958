import { create } from "zustand"

type RemoveChapterDialogState = {
    isOpen: boolean
}

type RemoveChapterDialogActions = {
    onOpen: () => void
    onClose: () => void
}

const useRemoveChapterDialogV1 = create<
    RemoveChapterDialogState & RemoveChapterDialogActions
>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
}))

export default useRemoveChapterDialogV1
