import React from "react"
import SearchIcon from "@material-ui/icons/Search"
import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"

type SearchBarProp = {
  search?: string
  setSearch?: (search: string) => void
}

const SearchBarV1: React.FC<SearchBarProp> = ({ search, setSearch }) => {
  return (
    <FormControl style={{ width: "100%" }} size="small">
      <OutlinedInput
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        placeholder="Search..."
        value={search}
        onChange={(event) => setSearch(event.target.value as string)}
      />
    </FormControl>
  )
}

export default SearchBarV1
