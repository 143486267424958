// ==================== External Imports ==================== //
import {
  Paper,
  Select,
  Table,
  Tooltip,
  TableRow,
  TableBody,
  MenuItem,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { cloneDeep } from "lodash"
import { Skeleton } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import InfoIcon from "@material-ui/icons/InfoOutlined"
import { makeStyles, Theme } from "@material-ui/core/styles"

// ==================== Local Imports ==================== //
import {
  checkDisabledRole,
  checkFilteredRoles,
  getUpdatedRoles,
} from "util/helper"
import useAppState from "hooksV1/useAppState"
import { RootState } from "../../redux/store"
import { ProjectRole } from "../../shared/types/project"
import { fetchProjectRoles } from "../../redux/thunks/projectRolesThunk"
import { useGetDocumentVersionAccessRightsV1Query } from "redux/services"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  skeleton: {
    width: "100%",
    padding: 0,
  },
  disabledSelect: {
    "&.Mui-disabled": {
      color: "black !important",
      opacity: ".5 !important",
    },
  },
  accessRightsHead: {
    display: "flex",
    alignItems: "end",
  },
  table: {
    width: "100%",
  },
  paperCard: {
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
  },
  infoIcon: {
    marginRight: 5,
  },
  descriptionTitle: {
    letterSpacing: 1,
  },
  description: {
    fontSize: 12,
  },
}))

const GLOBAL = "global"

const ProjectManageRoles: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    activeDocumentVersion,
    activeDocument,
    documentRoles,
    setDocumentRoles,
    activeProjectUser,
    activeCountry,
  } = useAppState()

  const { data: documentVersionData } =
    useGetDocumentVersionAccessRightsV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  useEffect(() => {
    if (roles?.length === 0) {
      const rolesThunk = fetchProjectRoles()
      dispatch(rolesThunk)
    }
  }, [])

  useEffect(() => {
    if (documentVersionData) {
      let { access } = documentVersionData.data.getDocumentVersion

      access = getUpdatedRoles(access)

      setDocumentRoles(access)
    }
  }, [documentVersionData])

  // ==================== Variables ==================== //
  const roles: ProjectRole[] = useSelector((state: RootState) =>
    state.projectRoles.roles.filter((role) => role.role_name !== "None")
  )

  const accessRightsDescription = (
    <>
      <h2 className={classes.descriptionTitle}>Maintain</h2>
      <p className={classes.description}>user has read & write access</p>

      <h2 className={classes.descriptionTitle}>Maintain DOA</h2>
      <p className={classes.description}>
        user has read & write access on depending on chapter authorization
      </p>

      <h2 className={classes.descriptionTitle}>Consult</h2>
      <p className={classes.description}>user has read access</p>

      <h2 className={classes.descriptionTitle}>No Access</h2>
      <p className={classes.description}>
        user does not have read or write access
      </p>
    </>
  )

  // ==================== Functions ==================== //
  const sortRoles = (roles: ProjectRole[]): ProjectRole[] => {
    const roleOrder = [
      "Super User",
      "Project Manager",
      "Lead Consultant",
      "Implementation Consultant",
      "AMS Consultant",
      "Client User",
      "Client Project Manager",
    ].map((role) => role.toLowerCase())

    const sortedRoles = [...roles]

    sortedRoles.sort((a, b) => {
      const roleAIndex = roleOrder.indexOf(a.role_name.toLowerCase())
      const roleBIndex = roleOrder.indexOf(b.role_name.toLowerCase())

      if (roleAIndex === -1) {
        return 1
      } else if (roleBIndex === -1) {
        return -1
      }

      return roleAIndex - roleBIndex
    })

    return sortedRoles
  }

  const handleChange = (index: string, value: string) => {
    const newRoles = cloneDeep(documentRoles)
    newRoles[parseInt(index)] = value
    setDocumentRoles(newRoles)
  }

  const TableHeadings = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1" color="primary" component="h1">
              Roles
            </Typography>
          </TableCell>
          <TableCell align="right" className={classes.accessRightsHead}>
            <Tooltip title={accessRightsDescription} placement="bottom-start">
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
            <Typography
              variant="body1"
              color="primary"
              component="h1"
              className={classes.infoIcon}
            >
              Access Rights
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const isDisabled = (roleName: string): boolean => {
    return (
      !activeProjectUser?.role
        .filter(
          (role) =>
            role.countryId === GLOBAL || role.countryId === activeCountry?.id
        )
        .map((roleEntry) => roleEntry.role_name)
        .includes("Project Manager" || "AMS Consultant") ||
      checkDisabledRole(roleName)
    )
  }

  const TableContent: React.FC = () => {
    return (
      <TableBody>
        {sortRoles(roles)
          .filter((role) => checkFilteredRoles(role.role_name))
          .map((role, i) => (
            <TableRow key={role.role_name}>
              <TableCell>{role.role_name}</TableCell>
              <TableCell align="right">
                <Select
                  defaultValue={"none"}
                  className={
                    isDisabled(role.role_name) ? classes.disabledSelect : ""
                  }
                  disabled={isDisabled(role.role_name)}
                  onChange={(event) =>
                    handleChange(role.id, event.target.value as string)
                  }
                  value={documentRoles[parseInt(role.id)]}
                >
                  <MenuItem value={"maintain"}>Maintain</MenuItem>
                  <MenuItem value={"maintain_doa"}>Maintain DOA</MenuItem>
                  <MenuItem value={"consult"}>Consult</MenuItem>
                  <MenuItem value={"none"}>No Access</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    )
  }

  const showSkeleton = () => {
    return (
      <TableBody style={{ height: "100%" }}>
        {Array.from({ length: 4 }, (_, index) => (
          <TableRow key={index}>
            <TableCell className={classes.skeleton}>
              <Skeleton
                variant="text"
                height={"40px"}
                animation="wave"
                style={{ borderRadius: "8px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="text"
                height={"40px"}
                animation="wave"
                style={{ borderRadius: "8px" }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paperCard}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHeadings />
            {documentRoles?.length === 0 ? showSkeleton() : <TableContent />}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default ProjectManageRoles
