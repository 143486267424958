// ==================== External Imports ==================== //
import {
  Theme,
  Table,
  Tooltip,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  TableContainer,
  Button,
  TablePagination,
} from "@material-ui/core"
import { RootState } from "redux/store"
import { Skeleton } from "@material-ui/lab"
import * as lookup from "country-code-lookup"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import MoreVertIcon from "@mui/icons-material/MoreVert"

// ==================== Local Imports ==================== //
import { User } from "shared/types/user"
import { Status } from "shared/types/status"
import SearchField from "components/SearchField"
import { fetchUsers } from "redux/thunks/usersSliceThunk"
import { Project, ProjectRole, ProjectUserRole } from "shared/types/project"
import useEditProjectState from "hooksV1/useEditProjectState"
import { isMatch, checkFilteredRolesObject } from "util/helper"
import { fetchProjectRoles } from "redux/thunks/projectRolesThunk"
import {
  CreateChip,
  TableHeadings,
} from "components/UserManagement/UserTableBody"
import useEditProjectCountryRolesDialogV1 from "hooksV1/useEditProjectCountryRolesDialogV1"
import EditProjectCountryRolesDialogV1 from "dialogsV1/EditProjectCountryRolesDialogV1"

const useStyles = makeStyles((theme: Theme) => ({
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flex: "0 1 15px",
    [theme.breakpoints.down("sm")]: {
      flex: "1 1 25ch",
    },
  },
  searchField: {
    width: "45ch",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  skeleton: {
    width: "100%",
    height: 75,
    flex: 1,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
  },
  chips: {
    gap: "0.5rem",
    display: "flex",
    maxWidth: "15vw",
    flexWrap: "wrap",
    overflow: "hidden",
  },
}))

type TableContentProps = {
  users: ProjectUserRole[]
  page: number
  rowsPerPage: number
}

const getCountryFlag = (role: ProjectRole): string => {
  if (!role.countryId || !role.countryName) return ""

  if (role.countryId === "global") return "global"

  return lookup.byCountry(role?.countryName)?.iso2 ?? ""
}

const TableContent: React.FC<TableContentProps> = ({
  users,
  page,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const useDialog = useEditProjectCountryRolesDialogV1()

  return (
    <TableBody>
      {users.length > 0 &&
        users
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((user, i) => {
            return (
              <TableRow key={user.user.id}>
                <TableCell component="th" scope="row">
                  {user.user.firstname}
                </TableCell>
                <TableCell component="th" scope="row">
                  {user.user.surname}
                </TableCell>
                <TableCell>{user.user.email}</TableCell>
                <TableCell component="th" style={{ overflow: "hidden" }}>
                  <div className={classes.chips}>
                    {user.role.map((role, index) => {
                      const code = getCountryFlag(role)

                      return (
                        <CreateChip
                          code={code}
                          key={role.id}
                          message={role.role_name}
                          outlined={false}
                        />
                      )
                    })}
                  </div>
                </TableCell>
                <TableCell component="th">
                  <Tooltip title="Edit Country Access Rights">
                    <Button
                      onClick={() => {
                        useDialog.setActiveUser(user)
                        useDialog.onOpen()
                      }}
                    >
                      <MoreVertIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })}
    </TableBody>
  )
}

const EditProjectDialogUsers: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const dispatch = useDispatch()
  const useDialog = useEditProjectCountryRolesDialogV1()
  const { activeProject, setProjectUsers } = useEditProjectState()

  // ==================== Variables ==================== //
  const users = useSelector((state: RootState) => state.users.users)

  const fetchingUsersStatus = useSelector(
    (state: RootState) => state.users.getUserStatus
  )

  const roles: ProjectRole[] = useSelector(
    (state: RootState) => state.projectRoles.roles
  )

  // ==================== Functions ==================== /

  const handleSave = (value: ProjectUserRole) => {
    let rowIndex = userRows.findIndex((row) => row.user.id === value.user.id)

    if (rowIndex === -1) return

    const tempRows = [...userRows]
    tempRows[rowIndex] = value
    setUserRows(tempRows)
    setProjectUsers(tempRows)
  }

  const createProjectUser = (user: User, project: Project) => {
    if (!project.users || project.users?.length === 0) {
      const result: ProjectUserRole = {
        user,
        role: [],
      }

      return result
    }

    const projectUser = project.users?.find(
      (projectUser) => projectUser.user.id === user.id
    )

    if (projectUser) {
      return projectUser
    }

    return {
      user,
      role: [],
    }
  }

  const setUsers = () => {
    let rows = userRows

    if (searchText !== "") {
      rows = rows.filter((row) => {
        if (
          isMatch(row.user.firstname, searchText) ||
          isMatch(row.user.surname, searchText) ||
          isMatch(row.user.email, searchText) ||
          isMatch(row.user.role_id.role_name, searchText)
        )
          return row
      })
    }

    setFilteredUsers(rows)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  // ==================== Use Hooks ==================== //
  const [searchText, setSearchText] = useState("")
  const [userRows, setUserRows] = useState<ProjectUserRole[]>([])
  const [filteredUsers, setFilteredUsers] = useState<ProjectUserRole[]>([])
  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    if (users.length === 0) {
      const fetchUsersThunk = fetchUsers()
      dispatch(fetchUsersThunk)
    }

    if (roles.length === 0) {
      const rolesThunk = fetchProjectRoles()
      dispatch(rolesThunk)
    }
  }, [])

  useEffect(() => {
    setUsers()
  }, [userRows, searchText])

  useEffect(() => {
    const tempUsers = users
      .map((user) => {
        return createProjectUser(user, activeProject)
      })
      .filter((t): t is ProjectUserRole => !!t)
      .sort((a, b) => (a.user.firstname < b.user.firstname ? -1 : 1))

    setUserRows(tempUsers)
  }, [users])

  return (
    <>
      {fetchingUsersStatus === Status.loading ? (
        <Skeleton
          className={classes.skeleton}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      ) : (
        <>
          <SearchField searchText={searchText} setSearchText={setSearchText} />
          <TableContainer style={{ minHeight: "25vw" }}>
            <Table size="small" aria-label="a dense table">
              <TableHeadings />
              <TableContent
                users={filteredUsers}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={users.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </>
      )}

      {useDialog.isOpen && (
        <EditProjectCountryRolesDialogV1
          handleSave={handleSave}
          projectId={activeProject.id}
          roles={checkFilteredRolesObject(roles)}
        />
      )}
    </>
  )
}

export default EditProjectDialogUsers
