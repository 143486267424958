import { cloneDeep } from "lodash"
import {
  Theme,
  Button,
  Dialog,
  Tooltip,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { Skeleton } from "@mui/material"
import Grid from "@material-ui/core/Grid"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"

import {
  Document,
  Operations,
  ChapterObject,
  ChapterSection,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  transformArray,
  invalidateAll,
  hasChapterChangedNameOrChapterOrder,
  createAuditTrailObject,
} from "util/helper"
import {
  useSyncStructureOperationMutation,
  useListDocumentsByCountryNameAndNoProjectIdForSyncQuery,
  useGetDocumentForSyncAllAndStructureQuery,
  useCreateAuditTrailMutation,
} from "redux/services"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { SyncStructureModel } from "shared/types-exp/sync"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useSyncStructureDialogV1 from "hooksV1/useSyncStructureDialogV1"
import { createAuditTrail } from "util/batchHook"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "40vw",
    maxHeight: "60vh",
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  contentContainer: {
    display: "flex",
  },
  column: {
    flex: "1",
    border: "1px solid #ccc",
    padding: "20px",
  },
  chaptersContainer: {
    listStyle: "none",
  },
  subChapterItem: {
    paddingTop: "1rem",
    borderLeft: "1px dashed #ddd",
    margin: "0 !important",
  },
  structureSkeletons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
  },
}))

const SyncStructureDialogV1: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    chapterObjects,
    chapterObjectsList,
    setChapterObjects,
    activeDocumentVersion,
    setChapterObjectsList,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const syncStructureDialog = useSyncStructureDialogV1()

  const [partialDoc, setPartialDoc] = useState<Partial<Document>>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [, setNewSectionList] = useState<ChapterSection[]>([])
  const [syncStructureModel, setSyncStructureModel] =
    useState<SyncStructureModel>(null)

  const [projectRefIdMap, setProjectRefIdMap] = useState<
    Map<string, ChapterObject>
  >(new Map())

  const [templateRefIdMap, setTemplateRefIdMap] = useState<
    Map<string, Partial<ChapterObject>>
  >(new Map())

  const [chaptersFromTemplate, setChaptersFromTemplate] = useState<
    Partial<ChapterObject>[]
  >([])

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [syncStructureApi] = useSyncStructureOperationMutation()

  const { data: result, isLoading: isFetchingCountries } =
    useListDocumentsByCountryNameAndNoProjectIdForSyncQuery(
      {
        countryName: activeCountry?.country_name,
        projectRefId: "",
      },
      {
        skip: !chapterObjects || !activeCountry || !activeDocumentVersion,
      }
    )

  const { data: response, isLoading: isFetchingDocument } =
    useGetDocumentForSyncAllAndStructureQuery(
      {
        id: partialDoc?.id,
        countryId: partialDoc?.countryId,
      },
      {
        skip: !partialDoc,
      }
    )

  useEffect(() => {
    if (!result || partialDoc) return

    const documents = result?.data?.listCountries?.items[0].documents.items

    const filteredDocument = documents.find(
      (doc) => activeDocument?.refId === doc.refId
    )

    if (!filteredDocument) {
      snackBar.setMessage(
        `${activeDocument.name} not found in template. Please sync documents.`
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      handleClose()

      return
    }

    setPartialDoc(filteredDocument)
  }, [result])

  useEffect(() => {
    if (!result || !response) return

    mapQueryResultObject(response.data.getDocument)
  }, [response])

  const mapQueryResultObject = (filteredDocument: any) => {
    const activeVersion = filteredDocument.documentVersions.items[0]
    const newSectionsList = cloneDeep(activeVersion.sections)

    activeDocumentVersion?.sections?.forEach((section) => {
      if (section.isProject) newSectionsList.push(section)
    })

    const chaptersFromTemplate = activeVersion.chapters
      .items as Partial<ChapterObject>[]

    if (chaptersFromTemplate.length === 0) {
      snackBar.setMessage(
        `The ${activeDocument.name} does not have any chapters from template.`
      )
      snackBar.setMessageSeverity(SnackType.SnackInfo)
      snackBar.onOpen()
      handleClose()

      return
    }

    const syncStructureModel: SyncStructureModel = {
      sections: newSectionsList,
      templateDocumentVersionId: activeVersion.id,
      projectDocumentVersionId: activeDocumentVersion.id,
      documentId: activeDocument.id,
      isIncludedInWorkflow: activeDocument?.isIncludedInWorkflow,
    }

    setTemplateRefIdMap(
      new Map(chaptersFromTemplate.map((chapter) => [chapter.refId, chapter]))
    )
    setProjectRefIdMap(
      new Map(chapterObjectsList.map((chapter) => [chapter.refId, chapter]))
    )
    setNewSectionList(newSectionsList)
    setSyncStructureModel(syncStructureModel)
    setChaptersFromTemplate(
      transformArray(chaptersFromTemplate as ChapterObject[])
    )
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSync()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    syncStructureDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleSync = async () => {
    try {
      setIsLoading(true)

      const response: any = await syncStructureApi(syncStructureModel)

      if (
        response.error ||
        (response.error?.status && response.error.status.toString() !== "200")
      ) {
        throw Error(
          `${JSON.stringify(
            response.error.data
          )}, Operation: [Upload Attachments], Data Passed: ${JSON.stringify(
            syncStructureModel
          )}`
        )
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.SYNC,
        Operations.SYNC_STRUCTURE,
        `Executed the Sync Structure operation in ${activeCountry?.country_name}, in the ${activeDocument?.name} document`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)

      const responseChapters = response.data?.body.chapters || []
      const transformedChapters = transformArray(responseChapters)

      setChapterObjects(transformedChapters)
      setChapterObjectsList(responseChapters)
      setActiveChapterObject(transformedChapters[0])
      invalidateAll(dispatch)

      snackBar.setMessage("Sync Structure Completed Successful")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("SyncStructureDialog", "handleSync", error)

      snackBar.setMessage("Error occurred while syncing structure")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const renderSyncChapters = (
    chapters: ChapterObject[],
    isCurrent = false,
    depth = 0
  ) => {
    let chapterCounter = 0

    const getChapterStyle = (
      isCurrent: boolean,
      chapter: ChapterObject,
      chapterToUpdate: ChapterObject
    ) => {
      let baseStyle = {
        paddingTop: "0.4rem",
        paddingBottom: "0.4rem",
        paddingLeft: "0.75rem",
        paddingRight: "0.75rem",
        borderRadius: "1rem",
        marginLeft: "0.25rem",
        cursor: "pointer",
        color: "",
        backgroundColor: "",
      }

      if (isCurrent) {
        baseStyle.backgroundColor =
          !chapterToUpdate && !chapter.isProject
            ? "red"
            : chapter.isProject
            ? "purple"
            : hasChapterChangedNameOrChapterOrder(chapter, chapterToUpdate)
            ? "orange"
            : "lightgrey"
        baseStyle.color = !chapterToUpdate || chapter.isProject ? "white" : ""
      } else {
        baseStyle.backgroundColor = !chapterToUpdate ? "green" : "lightgrey"
        baseStyle.color = !chapterToUpdate || chapter.isProject ? "white" : ""
      }

      return baseStyle
    }

    const getCurrentTooltip = (
      isCurrent,
      chapter: ChapterObject,
      chapterToUpdate: ChapterObject
    ) => {
      if (isCurrent) {
        return !chapterToUpdate
          ? "Chapter to Remove"
          : chapter.isProject
          ? "Project Only Chapter"
          : hasChapterChangedNameOrChapterOrder(chapter, chapterToUpdate)
          ? "Chapter name, order or sub-chapter allowed has changed"
          : "Unchanged Chapter"
      }

      return !chapterToUpdate ? "New Chapter Added" : "Unchanged Chapter"
    }

    return chapters?.map((chapter) => {
      chapterCounter++
      const currentChapterNumber = chapterCounter

      const renderedSubChapters = chapter.subchapters
        ? renderSyncChapters(chapter.subchapters, isCurrent, depth + 1)
        : null

      let chapterToUpdate = null

      if (chapter.isProject) {
        chapterToUpdate = chapter
      } else {
        chapterToUpdate = isCurrent
          ? templateRefIdMap.get(chapter.refId)
          : projectRefIdMap.get(chapter.refId)
      }

      const chapterStyle = getChapterStyle(isCurrent, chapter, chapterToUpdate)
      const currentTooltip = getCurrentTooltip(
        isCurrent,
        chapter,
        chapterToUpdate
      )

      return (
        <li key={chapter.id} className={classes.chaptersContainer}>
          <Tooltip title={currentTooltip} placement="bottom">
            <span style={chapterStyle}>
              {`${currentChapterNumber}. ${chapter.name}`}
            </span>
          </Tooltip>
          {renderedSubChapters && (
            <ul className={chapter.subchapters ? classes.subChapterItem : ""}>
              {renderedSubChapters}
            </ul>
          )}
        </li>
      )
    })
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={syncStructureDialog.isOpen}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Sync Selection</DialogTitle>
      <DialogContent className={classes.container} dividers>
        {isLoading || isFetchingDocument || isFetchingCountries ? (
          <>
            <Skeleton
              variant="text"
              width={"70%"}
              sx={{ fontSize: "2rem" }}
              animation="wave"
            />
            <div className={classes.structureSkeletons}>
              <Skeleton
                variant="rounded"
                width={"48%"}
                height={100}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width={"48%"}
                height={100}
                animation="wave"
              />
            </div>
          </>
        ) : (
          <>
            <h3>Syncing: {activeDocument?.name} - Structure with Template</h3>
            <Grid container>
              <Grid item xs={6}>
                <h3>Current Chapters</h3>
                {renderSyncChapters(chapterObjects, true)}
              </Grid>
              <Grid item xs={6}>
                <h3>Template Chapters</h3>
                {renderSyncChapters(
                  chaptersFromTemplate as ChapterObject[],
                  false
                )}
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSync}
          disabled={isLoading || isFetchingCountries || isFetchingDocument}
        >
          Sync
        </Button>
      </DialogActions>

      {(isLoading || isFetchingCountries || isFetchingDocument) && (
        <Loader open={true} />
      )}
    </Dialog>
  )
}

export default SyncStructureDialogV1
