// ==================== External Imports ==================== //
import {
  Radio,
  Theme,
  FormLabel,
  makeStyles,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core"
import React, { useState } from "react"

// ==================== Local Imports ==================== //
import useEditUserState from "hooksV1/useEditUserState"

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    minWidth: "552px",
  },
  radioContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  spacer: {
    padding: theme.spacing(2, 3, 0, 3),
  },
}))

const AccessManagement: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { newRole, setNewRole } = useEditUserState()

  // ==================== Use Hooks ==================== //
  const [value, setValue] = useState(newRole?.role_name)

  // ==================== Functions ==================== //
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)

    if ((event.target as HTMLInputElement).value === "Guest") {
      setNewRole({
        can_create: false,
        id: "1",
        role_name: "Guest",
      })
    } else if ((event.target as HTMLInputElement).value === "Administrator") {
      setNewRole({
        can_create: true,
        id: "2",
        role_name: "Administrator",
      })
    }
  }

  return (
    <FormControl component="fieldset" className={classes.formContainer}>
      <FormLabel component="legend">
        User's role in template environment:
      </FormLabel>
      <div className={classes.spacer} />
      <RadioGroup
        aria-label="role"
        name="role"
        value={value}
        onChange={handleChange}
        className={classes.radioContainer}
      >
        <div className={classes.radioGroup}>
          <FormControlLabel
            value="Guest"
            control={<Radio color="primary" />}
            label="Guest"
          />
          <FormHelperText>{"Cannot create templates"}</FormHelperText>
        </div>
        <div className={classes.spacer} />
        <div className={classes.radioGroup}>
          <FormControlLabel
            value="Administrator"
            control={<Radio color="primary" />}
            label="Administrator"
          />
          <FormHelperText>{"Can create templates"}</FormHelperText>
        </div>
      </RadioGroup>
    </FormControl>
  )
}

export default AccessManagement
