// ==================== External Imports ==================== //
import React from "react"
import SaveIcon from "@material-ui/icons/Save"
import { IconButton, Tooltip, makeStyles, Theme } from "@material-ui/core"

import {
  useGetDocumentVersionWithChaptersV1Query,
  useUpdateDocumentVersionMutation,
} from "redux/services"
import { useDispatch } from "react-redux"
import { invalidateAll } from "util/helper"
import useAppState from "hooksV1/useAppState"
import { DocumentVersion } from "shared/types-exp"
import { updateDocumentVersion } from "util/batchHook"

// ==================== Local Imports ==================== //

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  iconButton: {
    color: "primary",
  },
}))

const ManageRolesButton: React.FC = () => {
  // ==================== Hooks ==================== //
  const dispatch = useDispatch()
  const classes = useStyles()
  const { activeDocumentVersion, activeDocument, documentRoles } = useAppState()
  const [updateDocumentVersionAPI, { isLoading }] =
    useUpdateDocumentVersionMutation()

  const { isLoading: isLoadingDocumentsVersion } =
    useGetDocumentVersionWithChaptersV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      {
        skip: !activeDocument || !activeDocumentVersion,
      }
    )

  const handleClick = async () => {
    const updateActiveDocumentVersion: DocumentVersion = {
      ...activeDocumentVersion,
      documentId: activeDocument?.id,
      access: documentRoles,
    }
    await updateDocumentVersion(
      updateDocumentVersionAPI,
      updateActiveDocumentVersion
    )
    invalidateAll(dispatch)
  }

  return (
    <>
      <div className={classes.container}>
        <Tooltip title="Save Role Changes">
          <IconButton
            color="primary"
            onClick={handleClick}
            className={classes.iconButton}
            disabled={
              isLoadingDocumentsVersion ||
              documentRoles.length === 0 ||
              isLoading
            }
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  )
}

export default ManageRolesButton
