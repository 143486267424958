// Need to use the React-specific entry point to allow generating React hooks
import config from "config"
import { DeleteModel } from "shared/types-exp/delete"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const baseUrl =
  "DELETE_URL" in config.apiGateway
    ? `${config.apiGateway.DELETE_URL}`
    : `${config.apiGateway.URL}/delete/`

// Define a service using a base URL and expected endpoints d
export const deleteApi = createApi({
  reducerPath: "deleteApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token")

      if (token) {
        headers.set("Authorization", `${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    deleteCountryOperation: builder.mutation<any, DeleteModel>({
      query: (deleteModel) => ({
        url: "country",
        method: "DELETE",
        body: deleteModel,
      }),
    }),
    deleteDocumentOperation: builder.mutation<any, DeleteModel>({
      query: (deleteModel) => ({
        url: "document",
        method: "DELETE",
        body: deleteModel,
      }),
    }),
    deleteProjectOperation: builder.mutation<any, DeleteModel>({
      query: (deleteModel) => ({
        url: "project",
        method: "DELETE",
        body: deleteModel,
      }),
    }),
  }),
})

export const {
  useDeleteCountryOperationMutation,
  useDeleteDocumentOperationMutation,
  useDeleteProjectOperationMutation,
} = deleteApi
