// ==================== External Imports ==================== //
import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
  Theme,
  Table,
  Paper,
  MenuItem,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  makeStyles,
  Typography,
  TableContainer,
  Tooltip,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
//PHASE 2
//--------------------
// import StatusIcon from "@material-ui/icons/DonutLarge"
import DeleteIcon from "@material-ui/icons/Delete"

// ==================== Local Imports ==================== //
import Loader from "components/Loading/Loader"
import { Project } from "shared/types/project"
import { projectsHeadCells } from "util/constants"
import useEditProjectState from "hooksV1/useEditProjectState"
import EditProjectDialogV1 from "dialogsV1/EditProjectDialogV1"
import useEditProjectDialogV1 from "hooksV1/useEditProjectDialogV1"
import useDeleteProjectDialogV1 from "hooksV1/useDeleteProjectDialogV1"
import { TableActionMenu } from "components/TableActionMenu/TableActionMenu"

type ProjectTableContentProps = {
  page: number
  projects: Project[]
  rowsPerPage: number
}

type ProjectTableBodyProps = {
  page: number
  projects: Project[]
  isLoading: boolean
  rowsPerPage: number
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: "auto",
  },
  tableContainer: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    height: "100%",
  },
  skeleton: {
    width: "100%",
    height: "100%",
  },
}))

export const ProjectTableHeadings: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        {projectsHeadCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "actions" || headCell.id === "delete"
                  ? "right"
                  : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              <Typography variant="body1" color="primary" component="h1">
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const TableContent: React.FC<ProjectTableContentProps> = ({
  projects = [],
  page,
  rowsPerPage,
}) => {
  const useEditProject = useEditProjectDialogV1()
  const useDeleteProject = useDeleteProjectDialogV1()
  const useEditProjectStateHook = useEditProjectState()

  const [menuOpen, setMenuOpen] = React.useState(false)

  const handleManageProjectClick = (project: Project) => {
    useEditProjectStateHook.setActiveProject(project)
    useEditProjectStateHook.setNewAuthorName(project.author)
    useEditProjectStateHook.setNewProjectName(project.name)
    useEditProject.onOpen()
    setMenuOpen(false)
  }

  const handleDeleteProjectClick = (project: Project) => {
    useEditProjectStateHook.setActiveProject(project)
    useDeleteProject.onOpen()
    setMenuOpen(false)
  }

  return (
    <TableBody>
      {projects.length > 0 &&
        projects
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" align="left">
                  {row.name}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  <TableActionMenu
                    tooltip="Manage Project"
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  >
                    <Tooltip
                      title="View/Edit Project Name and Assigned Users"
                      placement="right-end"
                    >
                      <MenuItem onClick={() => handleManageProjectClick(row)}>
                        <EditIcon /> &nbsp;Manage Project
                      </MenuItem>
                    </Tooltip>

                    <Tooltip title="Remove this Project" placement="right-end">
                      <MenuItem onClick={() => handleDeleteProjectClick(row)}>
                        <DeleteIcon /> &nbsp;Delete Project
                      </MenuItem>
                    </Tooltip>
                  </TableActionMenu>
                </TableCell>
              </TableRow>
            )
          })}
    </TableBody>
  )
}

const ProjectTableBody: React.FC<ProjectTableBodyProps> = ({
  page,
  projects,
  isLoading,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const useEditProject = useEditProjectDialogV1()

  if (isLoading) {
    return (
      <Paper className={classes.tableContainer}>
        <Skeleton
          className={classes.skeleton}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      </Paper>
    )
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table" className={classes.table}>
          <ProjectTableHeadings />
          <TableContent
            projects={projects}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
        <Loader open={isLoading} />
      </TableContainer>

      {useEditProject.isOpen && <EditProjectDialogV1 />}
    </>
  )
}

export default ProjectTableBody
