import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import React, { useState, useEffect } from "react"

import {
  Version,
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { createAuditTrailObject } from "util/helper"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import useExportVersionDialogV1 from "hooksV1/useExportVersionDialogV1"
import { useGetVersionQuery, useCreateAuditTrailMutation } from "redux/services"
import { downloadDocument } from "redux/thunks/documentsThunk"
import { createAuditTrail } from "util/batchHook"

type ExportVersionsProps = {
  fileType: string
  version: Version
}

const ExportVersionDialogV1: React.FC<ExportVersionsProps> = ({
  version,
  fileType,
}) => {
  const snackBar = useSnackBar()
  const { activeCountry, activeDocument, activeDocumentVersion } = useAppState()
  const exportVersion = useExportVersionDialogV1()

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const { data: getVersionsData, isLoading: isVersionsLoading } =
    useGetVersionQuery(
      { id: version.id, countryId: version.countryId },
      {
        skip: !version || !version?.id,
      }
    )

  const [isLoading, setIsLoading] = useState(false)
  const [_, setCurrentVersion] = useState<Version>(null)

  useEffect(() => {
    if (getVersionsData) {
      const tempVersion: Version = getVersionsData.data.getVersion
      setCurrentVersion(tempVersion)
    }
  }, [getVersionsData])

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await handleSave()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    exportVersion.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.EXPORT,
        Operations.VERSION,
        `The version (${version.activeVersion}) was exported as a type ${fileType} , in the ${version?.name} document, in ${activeCountry?.country_name}.`
      )

      if (activeDocumentVersion.latestVersion === version.activeVersion) {
        await downloadDocument(
          activeDocumentVersion.id,
          fileType,
          version.name,
          version.activeVersion
        )
      } else {
        await downloadDocument(
          version.id,
          fileType,
          activeDocument.name,
          version.activeVersion,
          activeCountry.id,
          false
        )
      }

      await createAuditTrail(createAuditTrailAPI, auditTrail)

      snackBar.setMessage("Working version successfully saved")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("ExportVersionDialogV1", "handleSave", error)

      snackBar.setMessage(
        "Working version save operation failed. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={exportVersion.isOpen}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Export Version</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to export this version as a type {fileType}?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isLoading || isVersionsLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isLoading || isVersionsLoading}
        >
          Export
        </Button>
      </DialogActions>

      {(isLoading || isVersionsLoading) && <Loader open={true} />}
    </Dialog>
  )
}

export default ExportVersionDialogV1
