import axios from "axios"
import { cloneDeep } from "lodash"
import {
  fetchSpecficDocuments,
  setGetDocumentMessage,
  setGetDocumentStatus,
} from "redux/slices/documents"
import {
  addDocumentToProject,
  createAllProjects,
  createProjects,
  removeProject,
  setAddProjectMessage,
  setAddProjectStatus,
  setCurrentProjectUserRole,
  setDeleteProjectMessage,
  setDeleteProjectStatus,
  setEditDocumentMessage,
  setEditDocumentStatus,
  setGetProjectMessage,
  setGetProjectStatus,
  setPublishDocsMessage,
  setPublishDocsStatus,
  setSaveToNewVersionMessage,
  setSaveToNewVersionStatus,
  setSyncNewTemplateChapterContent,
  setSyncNewTemplateChapterStructure,
  setSyncNewTemplateDocs,
  setUpdateProjectMessage,
  setUpdateProjectStatus,
  // updateDocumentOnProject,
} from "redux/slices/projects"
import {
  findDFSByTreeIndexAndUpdateChapterWasChanged,
  reIndexChapters,
} from "util/treeUtils"
import { Country } from "shared/types/country"
import {
  ChapterObject,
  ChapterSection,
  Document,
  DocumentChange,
  DocumentVersion,
} from "shared/types/document"
import { Project, ProjectUserRole } from "shared/types/project"
import { Status } from "shared/types/status"
import "../../config"
import config from "../../config"
import type { EPIDispatch, RootState } from "../store"
import { createChapterSection } from "./documentsThunk"

// Define a type for the slice state

type responseProject = {
  last_updated: string
  project_name: string
  project_value: string
  project_user: ProjectUserRole[]
  countries: Country[]
  id: string
  author: string
  documents: responseDocument[]
}

type responseDocument = {
  id: string
  refId: string
  document_name: string
  author: string
  latestVersion: number
  lastUpdated: string
  documentVersions: Array<DocumentVersion>
  enabled: boolean
}

export function copyDFSForBlueprint(
  localStateDocument: DocumentChange,
  doc: DocumentChange
) {
  let newDocumentChapters: ChapterObject[]

  newDocumentChapters = cloneDeep(localStateDocument.chapters)
  deleteSectionsDFS(newDocumentChapters)
  //CLONE BUSINESS BLUEPRINT STRUCTURE OVER TO NEW DOCUMENT AND KEEP EXISTING DOCUMENT CONTENT
  const chaptersToCopy = cloneDeep(doc.chapters)
  const sectionsToCopy = cloneDeep(doc.sections)
  const countriesToCopy = cloneDeep(doc.countries)

  copyDFS(newDocumentChapters, chaptersToCopy, sectionsToCopy, countriesToCopy)

  doc.chapters = cloneDeep(newDocumentChapters)
}

export function copyDFS(
  newDocChapters: ChapterObject[],
  originalDocChapters: ChapterObject[],
  sectionTemplates: ChapterSection[],
  countries: Country[]
) {
  newDocChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
  originalDocChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)

  if (newDocChapters) {
    newDocChapters.map((newDocChapter: ChapterObject, index: number) => {
      let matchingOriginalChapter = originalDocChapters.find(
        (originalChapter) => originalChapter.sharedId === newDocChapter.sharedId
      )

      doCopy(
        matchingOriginalChapter,
        index,
        sectionTemplates,
        newDocChapter,
        countries
      )

      if (newDocChapter.subchapters.length == 0) {
        return
      }

      if (
        newDocChapter.subchapters &&
        matchingOriginalChapter &&
        matchingOriginalChapter.subchapters
      ) {
        copyDFS(
          newDocChapter.subchapters,
          originalDocChapters[index].subchapters,
          sectionTemplates,
          countries
        )
      }
    })
  }
}

function doCopy(
  originalDocChapter: ChapterObject,
  index: number,
  sectionTemplates: ChapterSection[],
  newDocChapter: ChapterObject,
  countries: Country[]
) {
  let updatedSectionContent = originalDocChapter && originalDocChapter.sections

  //UPDATED SECTION
  if (originalDocChapter && updatedSectionContent) {
    updateExistingContentInChapter(
      sectionTemplates,
      originalDocChapter,
      newDocChapter,
      index,
      countries
    )
  }
  //NEW SECTION CONTENT
  else {
    createNewContentFromTemplates(sectionTemplates, newDocChapter, countries)
  }
}

function updateExistingContentInChapter(
  sectionTemplates: ChapterSection[],
  originalDocChapter: ChapterObject,
  newDocChapter: ChapterObject,
  index: number,
  countries: Country[]
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate, sectionTemplateIndex) => {
    let originalDocSectionsIndex = sectionTemplateIndex
    let hasChangedChapterName = originalDocChapter && originalDocChapter.name

    let newChapterName = hasChangedChapterName
      ? originalDocChapter.name
      : newDocChapter.name
    originalDocChapter.sections.map((originalSection, index) => {
      if (sectionTemplate.name === originalSection.name) {
        originalDocSectionsIndex = index
      }
    })
    let syncOriginalDocument =
      originalDocChapter.sections[originalDocSectionsIndex]
    let createdSection: ChapterSection

    if (syncOriginalDocument) {
      let globalContent = syncOriginalDocument.chapterGlobalContent
      let countryContent = syncOriginalDocument.countrySpecificContent
      createdSection = createChapterSection(
        newChapterName,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        globalContent,
        countries
      )

      if (countryContent.length > 0) {
        countryContent.map((countryCont) => {
          createdSection.countrySpecificContent.map(
            (countrySpecificCont, index) => {
              if (
                countryCont.country.country_name ===
                countrySpecificCont.country.country_name
              ) {
                createdSection.countrySpecificContent[index] = countryCont
              }
            }
          )
        })
      }
    } else {
      createdSection = createChapterSection(
        newDocChapter.name,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        sectionTemplate.chapterGlobalContent,
        countries
      )
    }
    createdSections.push(createdSection)
  })
  newDocChapter.sections = createdSections
  newDocChapter.name = originalDocChapter.name
}

function updateExistingContentInChapterForManualSync(
  sectionTemplates: ChapterSection[],
  originalDocChapter: ChapterObject,
  newDocChapter: ChapterObject,
  index: number,
  countries: Country[],
  o: ChapterObject
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate, sectionTemplateIndex) => {
    let originalDocSectionsIndex = sectionTemplateIndex
    let hasChangedChapterName = originalDocChapter && originalDocChapter.name

    let newChapterName = hasChangedChapterName
      ? originalDocChapter.name
      : newDocChapter.name
    originalDocChapter.sections.map((originalSection, index) => {
      if (sectionTemplate.name === originalSection.name) {
        originalDocSectionsIndex = index
      }
    })
    let syncOriginalDocument =
      originalDocChapter.sections[originalDocSectionsIndex]
    let createdSection: ChapterSection

    if (syncOriginalDocument) {
      let globalContent = sectionTemplate.chapterGlobalContent
      let countryContent = syncOriginalDocument.countrySpecificContent
      createdSection = createChapterSection(
        newChapterName,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        globalContent,
        countries
      )

      if (countryContent.length > 0) {
        countryContent.map((countryCont) => {
          createdSection.countrySpecificContent.map(
            (countrySpecificCont, index) => {
              if (
                countryCont.country.country_name ===
                countrySpecificCont.country.country_name
              ) {
                createdSection.countrySpecificContent[index] = countryCont
              }
            }
          )
        })
      }

      countries.map((country) => {
        let index = createdSection.countrySpecificContent.findIndex(
          (csc) => csc.country.country_name === country.country_name
        )

        if (index !== -1) {
          createdSection.countrySpecificContent[index].nonClientContent =
            sectionTemplate.countrySpecificContent.find(
              (stcsc) => stcsc.country.country_name === country.country_name
            ).nonClientContent
        }
      })
    } else {
      createdSection = createChapterSection(
        newDocChapter.name,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        sectionTemplate.chapterGlobalContent,
        countries
      )
    }
    createdSections.push(createdSection)
  })
  o.sections = createdSections
  o.name = originalDocChapter.name
}

function createNewContentFromTemplates(
  sectionTemplates,
  newDocChapter,
  countries
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate) => {
    let createdSection: ChapterSection
    createdSection = createChapterSection(
      newDocChapter.name,
      sectionTemplate.name,
      sectionTemplate.isGlobal,
      sectionTemplate.isClientEditable,
      sectionTemplate.chapterGlobalContent,
      countries
    )
    createdSections.push(createdSection)
  })
  newDocChapter.sections = createdSections
}

function deleteSectionsDFS(newDocChapters: ChapterObject[]) {
  if (newDocChapters) {
    newDocChapters.map((newDocChapter: ChapterObject) => {
      //FOUND MY OBJECT
      if (newDocChapter.subchapters.length == 0) {
        newDocChapter.sections = []

        return
      }
      newDocChapter.sections = []

      deleteSectionsDFS(newDocChapter.subchapters)
    })
  }
}

export function fetchProjects() {
  return async function fetchProjects(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetProjectStatus.type, payload: Status.loading })
    dispatch({ type: setGetProjectMessage.type, payload: "" })
    axios
      .get(`${config.apiGateway.URL}/projects/get_all_projects`)
      .then(function (response) {
        const attributes = response.data.message as responseProject[]
        let projects: Project[] = []
        attributes.map((res) => {
          projects.push({
            author: res.author,
            id: res.id,
            lastUpdated: res.last_updated.replace(
              "+0000 (Coordinated Universal Time)",
              ""
            ),
            name: res.project_name,
            value: res.project_value,
            countries: res.countries,
            documents: res.documents as unknown as Document[],
            users: res.project_user,
          })
        })
        dispatch({ type: createAllProjects.type, payload: projects })
        dispatch({ type: setGetProjectStatus.type, payload: Status.success })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({ type: setGetProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setGetProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetProjectStatus.type, payload: Status.idle })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
  }
}

export function fetchProjectsByUser(id: string) {
  return async function fetchProjects(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetProjectStatus.type, payload: Status.loading })
    dispatch({ type: setGetProjectMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/projects/get_by_user`, {
        id,
      })
      .then(function (response) {
        const attributes = response.data.message as responseProject[]
        let projects: Project[] = []
        attributes.map((res) => {
          let projectDocuments: Document[] = []
          res.documents.map((resDoc) => {
            resDoc.documentVersions.map((docVersion) => {
              if (!docVersion.country) {
                docVersion.country = null
              }
            })
            projectDocuments.push({
              author: resDoc.author,
              documentVersions: resDoc.documentVersions,
              id: resDoc.id,
              refId: resDoc.refId,
              lastUpdated: resDoc.lastUpdated.replace(
                "+0000 (Coordinated Universal Time)",
                ""
              ),
              activeVersion: resDoc.latestVersion,
              name: resDoc.document_name,
              enabled: resDoc.enabled,
            })
          })
          projectDocuments.sort((a, b) => (a.name < b.name ? -1 : 1))
          projects.push({
            author: res.author,
            id: res.id,
            lastUpdated: res.last_updated.replace(
              "+0000 (Coordinated Universal Time)",
              ""
            ),
            name: res.project_name,
            value: res.project_value,
            countries: res.countries,
            documents: projectDocuments,
            users: res.project_user,
          })
        })
        dispatch({ type: createProjects.type, payload: projects })
        dispatch({ type: setGetProjectStatus.type, payload: Status.success })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({ type: setGetProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setGetProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetProjectStatus.type, payload: Status.idle })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
  }
}

export function fetchProjectsByUserV2(id: string) {
  return async function fetchProjectsByUserV2(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetProjectStatus.type, payload: Status.loading })
    dispatch({ type: setGetProjectMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/projects/get_projects_by_user`, {
        id,
      })
      .then(function (response) {
        const attributes = response.data.message as responseProject[]
        let projects: Project[] = []
        attributes.map((res) => {
          projects.push({
            author: res.author,
            id: res.id,
            lastUpdated: res.last_updated.replace(
              "+0000 (Coordinated Universal Time)",
              ""
            ),
            name: res.project_name,
            value: res.project_value,
            countries: res.countries,
            documents: res.documents as unknown as Document[],
            users: res.project_user,
          })
        })
        projects.sort((a, b) => (a.name < b.name ? -1 : 1))
        dispatch({ type: createProjects.type, payload: projects })
        dispatch({ type: setGetProjectStatus.type, payload: Status.success })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
      .catch(function (error) {
        dispatch({ type: setGetProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setGetProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetProjectStatus.type, payload: Status.idle })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
  }
}

export function fetchDocumentOnProject(id: string, projectId: string) {
  return async function fetchDocumentOnProject(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setGetProjectStatus.type, payload: Status.loading })
    dispatch({ type: setGetProjectMessage.type, payload: "" })

    axios
      .post(`${config.apiGateway.URL}/projects/get_project_document`, {
        id,
      })
      .then(function (response) {
        const attributes = response.data.message as responseDocument
        attributes.documentVersions.map((docVersion) => {
          if (!docVersion.country) {
            docVersion.country = null
          }
        })

        let document: Document = {
          id: attributes.id,
          refId: attributes.refId,
          name: attributes.document_name,
          author: attributes.author,
          lastUpdated: attributes.lastUpdated.replace(
            "+0000 (Coordinated Universal Time)",
            ""
          ),
          documentVersions: attributes.documentVersions,
          activeVersion: attributes.latestVersion,
          enabled: attributes.enabled !== undefined ? attributes.enabled : true,
        }

        let version =
          document.documentVersions[document.documentVersions.length - 1]
        let change = version.changes[version.changes.length - 1]

        findDFSByTreeIndexAndUpdateChapterWasChanged(change.chapters)
        reIndexChapters(change.chapters, 0, "")
        version.changes = [change]
        document.documentVersions = [version]

        dispatch({
          type: addDocumentToProject.type,
          payload: { doc: document, projectId },
        })
      })
      .catch(function (error) {
        dispatch({ type: setGetProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setGetProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setGetProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setGetProjectStatus.type, payload: Status.idle })
        dispatch({ type: setGetProjectMessage.type, payload: "" })
      })
  }
}

export function addProjectOnDB(project: Project) {
  return async function addProjectOnDB(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setAddProjectStatus.type, payload: Status.loading })
    dispatch({ type: setAddProjectMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/projects/add`, {
        id: project.id,
        project_name: project.name,
        author: project.author,
        countries: project.countries,
      })
      .then(function (response) {
        dispatch({
          type: setAddProjectMessage.type,
          payload: "Project created successfully",
        })
        dispatch({ type: setAddProjectStatus.type, payload: Status.success })
      })
      .catch(function (error) {
        dispatch({ type: setAddProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setAddProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setAddProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setAddProjectStatus.type, payload: Status.idle })
        dispatch({ type: setAddProjectMessage.type, payload: "" })
      })
  }
}

export function editProjectOnDB(project: Project) {
  return async function editProjectonDB(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setUpdateProjectStatus.type, payload: Status.loading })
    dispatch({ type: setUpdateProjectMessage.type, payload: "" })
    let finalDocuments: Document[] = []
    const localDocuments = project.documents

    if (localDocuments && localDocuments.length > 0) {
      finalDocuments = localDocuments
    } else {
      finalDocuments = project.documents
    }

    let requestDocuments: responseDocument[] =
      mapProjectDocumentsToResponseProjectDocuments(finalDocuments)
    axios
      .put(`${config.apiGateway.URL}/projects/update`, {
        author: project.author,
        id: project.id,
        last_updated: project.lastUpdated,
        project_name: project.name,
        countries: project.countries,
        project_user: project.users,
        documents: requestDocuments,
      })
      .then(function (response) {
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.success })
        dispatch({
          type: setUpdateProjectMessage.type,
          payload: "Project updated successfully",
        })
      })
      .catch(function (error) {
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setUpdateProjectMessage.type, payload: error })
          }
        } else
          dispatch({
            type: setUpdateProjectMessage.type,
            payload: "Unknown Error",
          })
      })
      .finally(() => {
        const newDocuments: Document[] = cloneDeep(project.documents)
        const updatedDocuments: Document[] = []
        newDocuments
          .filter((document) => {
            let docVersion =
              document.documentVersions[document.documentVersions.length - 1]
            let docChange = docVersion.changes[docVersion.changes.length - 1]

            if (docChange.id === null) {
              return true
            }

            return false
          })
          .map((document) => {
            let id = document.id
            axios
              .post(`${config.apiGateway.URL}/projects/get_project_document`, {
                id,
              })
              .then(function (response) {
                const attributes = response.data.message as responseDocument
                attributes.documentVersions.map((docVersion) => {
                  if (!docVersion.country) {
                    docVersion.country = null
                  }
                })

                let document: Document = {
                  id: attributes.id,
                  refId: attributes.refId,
                  name: attributes.document_name,
                  author: attributes.author,
                  lastUpdated: attributes.lastUpdated.replace(
                    "+0000 (Coordinated Universal Time)",
                    ""
                  ),
                  documentVersions: attributes.documentVersions,
                  activeVersion: attributes.latestVersion,
                  enabled:
                    attributes.enabled !== undefined
                      ? attributes.enabled
                      : true,
                }

                let version =
                  document.documentVersions[
                    document.documentVersions.length - 1
                  ]
                let change = version.changes[version.changes.length - 1]

                findDFSByTreeIndexAndUpdateChapterWasChanged(change.chapters)
                reIndexChapters(change.chapters, 0, "")
                version.changes = [change]
                document.documentVersions = [version]

                updatedDocuments.push(document)
              })
              .catch(function (error) {
                dispatch({
                  type: setGetProjectStatus.type,
                  payload: Status.failed,
                })

                if (error.response !== undefined) {
                  if (error.response.data !== undefined) {
                    dispatch({
                      type: setGetProjectMessage.type,
                      payload: error,
                    })
                  }
                } else dispatch({ type: setGetProjectMessage.type, payload: "Unknown error" })
              })
              .finally(() => {
                dispatch({
                  type: setGetProjectStatus.type,
                  payload: Status.idle,
                })
                dispatch({ type: setGetProjectMessage.type, payload: "" })
              })
          })
      })
  }
}

export function editProjectInfoOnDB(project: Project) {
  return async function editProjectInfoonDB(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setUpdateProjectStatus.type, payload: Status.loading })
    dispatch({ type: setUpdateProjectMessage.type, payload: "" })
    axios
      .put(`${config.apiGateway.URL}/projects/update_project_info`, {
        author: project.author,
        id: project.id,
        last_updated: project.lastUpdated,
        project_name: project.name,
        countries: project.countries,
        project_user: project.users,
        documents: project.documents,
      })
      .then(function (response) {
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.success })
        dispatch({
          type: setUpdateProjectMessage.type,
          payload: "Project updated successfully",
        })
      })
      .catch(function (error) {
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setUpdateProjectMessage.type, payload: error })
          }
        } else
          dispatch({
            type: setUpdateProjectMessage.type,
            payload: "Unknown Error",
          })
      })
      .finally(() => {
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
        dispatch({ type: setUpdateProjectStatus.type, payload: "" })
      })
  }
}

export function removeProjectOnDB(project: Project) {
  return async function removeProjectOnDB(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setDeleteProjectStatus.type, payload: Status.loading })
    axios
      .delete(`${config.apiGateway.URL}/projects/delete`, {
        data: JSON.stringify(project.id),
      })
      .then(function (response) {
        dispatch({ type: removeProject.type, payload: project })
        dispatch({ type: setDeleteProjectStatus.type, payload: Status.success })
        dispatch({
          type: setDeleteProjectMessage.type,
          payload: response.data.message,
        })
      })
      .catch(function (error) {
        dispatch({ type: setDeleteProjectStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setDeleteProjectMessage.type, payload: error })
          }
        } else dispatch({ type: setDeleteProjectMessage.type, payload: "Unknown error" })
      })
      .finally(() => {
        dispatch({ type: setDeleteProjectStatus.type, payload: Status.idle })
        dispatch({ type: setDeleteProjectMessage.type, payload: "" })
      })
  }
}

export function publishDocumentsToProjectsOnDB(documents: Document[]) {
  return async function publishDocumentsToProjectsOnDB(
    dispatch: EPIDispatch,
    getState
  ) {
    dispatch({ type: setPublishDocsStatus.type, payload: Status.loading })
    dispatch({ type: setPublishDocsMessage.type, payload: "" })
    const newDocuments: Document[] = cloneDeep(documents)
    const updatedDocuments: Document[] = []
    const filteredDocuments: Document[] = newDocuments.filter((document) => {
      let docVersion =
        document.documentVersions[document.documentVersions.length - 1]
      let docChange = docVersion.changes[docVersion.changes.length - 1]

      if (docChange.id === null) {
        return true
      }

      return false
    })

    filteredDocuments.map((document, index) => {
      const latestChange =
        document.documentVersions[document.documentVersions.length - 1].changes[
          document.documentVersions[document.documentVersions.length - 1]
            .changes.length - 1
        ]
      latestChange.id && delete latestChange.id

      axios
        .put(`${config.apiGateway.URL}/documents/update`, {
          id: document.id,
          document_name: document.name,
          author: document.author,
          documentVersions: document.documentVersions,
          lastUpdated: document.lastUpdated,
          latestVersion: document.activeVersion,
          enabled: document.enabled,
        })
        .then(function (response) {
          if (index === filteredDocuments.length - 1) {
            dispatch({
              type: setPublishDocsStatus.type,
              payload: Status.success,
            })
            dispatch({
              type: setPublishDocsMessage.type,
              payload: "Documents were successfully published",
            })
          }
        })
        .catch(function (error) {
          dispatch({
            type: setPublishDocsStatus.type,
            payload: Status.failed,
          })

          if (error.response !== undefined) {
            if (error.response.data !== undefined) {
              dispatch({ type: setPublishDocsMessage.type, payload: error })
            }
          } else dispatch({ type: setPublishDocsMessage.type, payload: "Unknown error" })
        })
        .finally(() => {
          let id = document.id
          axios
            .post(`${config.apiGateway.URL}/documents/get_document`, {
              id,
            })
            .then(function (response) {
              const attribute = response.data.message as responseDocument

              attribute.documentVersions.map((docVersion) => {
                if (!docVersion.country) {
                  docVersion.country = null
                }
              })
              let document: Document = {
                id: attribute.id,
                refId: attribute.refId,
                name: attribute.document_name,
                author: attribute.author,
                lastUpdated: attribute.lastUpdated.replace(
                  "+0000 (Coordinated Universal Time)",
                  ""
                ),
                documentVersions: attribute.documentVersions,
                activeVersion: attribute.latestVersion,
                enabled:
                  attribute.enabled !== undefined ? attribute.enabled : true,
              }

              let version =
                document.documentVersions[document.documentVersions.length - 1]
              let change = version.changes[version.changes.length - 1]

              findDFSByTreeIndexAndUpdateChapterWasChanged(change.chapters)
              reIndexChapters(change.chapters, 0, "")
              version.changes = [change]
              document.documentVersions = [version]
              updatedDocuments.push(document)
              dispatch({
                type: fetchSpecficDocuments.type,
                payload: JSON.parse(JSON.stringify(updatedDocuments)),
              })
            })
            .catch(function (error) {
              dispatch({
                type: setGetDocumentStatus.type,
                payload: Status.failed,
              })

              if (error.response !== undefined) {
                if (error.response.data !== undefined) {
                  dispatch({
                    type: setGetDocumentMessage.type,
                    payload: error,
                  })
                }
              } else dispatch({ type: setGetDocumentMessage.type, payload: "Unknown error" })
            })
            .finally(() => {
              dispatch({
                type: setGetDocumentStatus.type,
                payload: Status.idle,
              })
              dispatch({ type: setGetDocumentMessage.type, payload: "" })
            })
        })
    })

    dispatch({
      type: setGetDocumentStatus.type,
      payload: Status.success,
    })
    dispatch({ type: setGetDocumentMessage.type, payload: "" })
  }
}

export function updateDocsOnProject(project: Project) {
  return async function updateDocsOnProject(dispatch: EPIDispatch, getState) {
    dispatch({ type: setUpdateProjectStatus.type, payload: Status.loading })
    let businessBlueprintDoc = (project.documents as Document[]).find(
      (document) => document.name === "Business Blueprint"
    )

    let latestVersionLocalStateDocument: DocumentVersion =
      businessBlueprintDoc.documentVersions[
        businessBlueprintDoc.documentVersions.length - 1
      ]

    let latestChangeLocalStateDoccument: DocumentChange = cloneDeep(
      latestVersionLocalStateDocument.changes[
        latestVersionLocalStateDocument.changes.length - 1
      ]
    )

    copyDFSForBlueprint(
      latestChangeLocalStateDoccument,
      latestChangeLocalStateDoccument
    )
    delete latestChangeLocalStateDoccument.id
    latestVersionLocalStateDocument.changes.push(
      latestChangeLocalStateDoccument
    )

    project.documents
      .filter((document) => document.name !== "Business Blueprint")
      .map((doc: Document) => {
        //CLEAR CHANGES MADE TO SECTIONS BY COPYDFS
        let latestVersionDoc =
          doc.documentVersions[doc.documentVersions.length - 1]
        let latestVersionChange: DocumentChange = cloneDeep(
          latestVersionDoc.changes[latestVersionDoc.changes.length - 1]
        )
        latestVersionChange.countries =
          latestChangeLocalStateDoccument.countries
        copyDFSForBlueprint(
          latestChangeLocalStateDoccument,
          latestVersionChange
        )
        delete latestVersionChange.id
        latestVersionDoc.changes.push(latestChangeLocalStateDoccument)
      })
    let responseDocs = mapProjectDocumentsToResponseProjectDocuments(
      project.documents
    )
    axios
      .put(`${config.apiGateway.URL}/projects/update`, {
        author: project.author,
        id: project.id,
        last_updated: project.lastUpdated,
        project_name: project.name,
        countries: project.countries,
        project_user: project.users,
        documents: responseDocs,
      })
      .then(function (response) {
        // dispatch({ type: editProject.type, payload: project })
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.success })
        dispatch({
          type: setUpdateProjectMessage.type,
          payload: "Project updated successfully",
        })
      })
      .catch(function (error) {
        dispatch({ type: setUpdateProjectStatus.type, payload: Status.failed })

        if (error && error.message) {
          dispatch({
            type: setUpdateProjectMessage.type,
            payload: error.message,
          })
        } else {
          dispatch({
            type: setUpdateProjectMessage.type,
            payload: "Project failed to update",
          })
        }
      })
  }
}

function mapProjectDocumentsToResponseProjectDocuments(
  documents: Document[]
): responseDocument[] {
  let responseDocuments: responseDocument[] = []

  documents && documents.length > 0
    ? documents.forEach((document) => {
        let newResponseDocument: responseDocument = {
          id: document.id,
          refId: document.refId,
          document_name: document.name,
          author: document.author,
          lastUpdated: document.lastUpdated,
          latestVersion: document.activeVersion,
          documentVersions: document.documentVersions,
          enabled: document.enabled,
        }
        responseDocuments.push(newResponseDocument)
      })
    : []

  return responseDocuments
}

export function buildRoleStr(userProjectRoles: ProjectUserRole) {
  return async function buildRoleStr(dispatch: EPIDispatch, getState) {
    let finalRoleStr = ""

    if (userProjectRoles) {
      let buildStr: string[] =
        userProjectRoles &&
        userProjectRoles !== null &&
        userProjectRoles.role &&
        userProjectRoles.role !== null &&
        userProjectRoles.role.length > 0
          ? userProjectRoles.role.map((r) => r.role_name)
          : []

      if (buildStr && buildStr.length > 0) {
        finalRoleStr = buildStr.toString()
      }
    }
    sessionStorage.setItem("project_roles", finalRoleStr)
    dispatch({ type: setCurrentProjectUserRole.type, payload: finalRoleStr })
  }
}

export function getAndSetNewSyncTemplateDocs(project: Project) {
  return async function getAndSetNewSyncTemplateDocs(
    dispatch: EPIDispatch,
    getState
  ) {
    let templateDocs: Document[] = getState().documents.Documents
    let projectDocs: Document[] = project.documents
    let newProjectDocs: Document[] = []

    if (templateDocs) {
      templateDocs.forEach((templateDoc) => {
        if (
          projectDocs.find(
            (projectDoc) => projectDoc.refId === templateDoc.id
          ) === undefined
        ) {
          if (templateDoc.enabled) {
            newProjectDocs.push(templateDoc)
          }
        } else {
          let projectDocument = projectDocs.find(
            (projectDoc) => projectDoc.refId === templateDoc.id
          )

          if (projectDocument.enabled && !templateDoc.enabled) {
            newProjectDocs.push(templateDoc)
          }

          if (!projectDocument.enabled && templateDoc.enabled) {
            newProjectDocs.push(templateDoc)
          }
        }
      })
    }

    dispatch({ type: setSyncNewTemplateDocs.type, payload: newProjectDocs })
  }
}

export function getAndSetNewSyncChapterStructure(projectDoc: Document) {
  return async function getAndSetNewSyncChapterStructure(
    dispatch: EPIDispatch,
    getState
  ) {
    let templateDocs: Document[] = getState().documents.Documents
    let templateDoc = templateDocs.find((doc) => doc.name === projectDoc.name)
    let compareDocChanges: DocumentChange = null

    if (templateDoc) {
      let templateDocChange =
        templateDoc.documentVersions[templateDoc.documentVersions.length - 1]
          .changes[
          templateDoc.documentVersions[templateDoc.documentVersions.length - 1]
            .changes.length - 1
        ]
      compareDocChanges = templateDocChange
    }

    dispatch({
      type: setSyncNewTemplateChapterStructure.type,
      payload: compareDocChanges,
    })
  }
}

export function getAndSetNewSyncChapterContent(
  projectChapter: ChapterObject,
  projectDoc: Document
) {
  return async function getAndSetNewSyncChapterStructure(
    dispatch: EPIDispatch,
    getState
  ) {
    let templateDocs: Document[] = getState().documents.Documents
    let templateDoc = templateDocs.find((doc) => doc.name === projectDoc.name)
    let templateChapter: ChapterObject = null

    if (templateDoc) {
      let templateDocChange =
        templateDoc.documentVersions[templateDoc.documentVersions.length - 1]
          .changes[
          templateDoc.documentVersions[templateDoc.documentVersions.length - 1]
            .changes.length - 1
        ]
      templateChapter = findDFSByTreeIndex(
        templateDocChange.chapters,
        projectChapter.treeIndex
      )
    }

    dispatch({
      type: setSyncNewTemplateChapterContent.type,
      payload: templateChapter,
    })
  }
}

export function syncDocuments(projectId: string) {
  return async function syncDocuments(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setEditDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setEditDocumentMessage.type, payload: "" })
    axios
      .put(`${config.apiGateway.URL}/projects/sync_documents`, {
        id: projectId,
      })
      .then(function (response) {
        dispatch({ type: setEditDocumentStatus.type, payload: Status.success })
        dispatch({
          type: setEditDocumentMessage.type,
          payload: "New Documents Synced Successfully",
        })
      })
      .catch(function (error) {
        //
      })
      .finally(() => {
        dispatch({ type: setEditDocumentStatus.type, payload: Status.idle })
        dispatch({ type: setEditDocumentMessage.type, payload: "" })
      })
  }
}

export function syncChapterStructure(projectId: string, documentId: string) {
  return async function syncChapterStructure(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    //
    dispatch({ type: setEditDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setEditDocumentMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/projects/sync_structure`, {
        projectId,
        documentId,
      })
      .then(function (response) {
        dispatch({ type: setEditDocumentStatus.type, payload: Status.success })
        dispatch({
          type: setEditDocumentMessage.type,
          payload: "Chapter Structure Synced Successfully",
        })
      })
      .catch(function (error) {
        //
      })
  }
}

export function syncChapterContent(
  projectId: string,
  documentId: string,
  versionId: string,
  changeId: string,
  chapterId: string
) {
  return async function syncChapterStructure(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    //
    dispatch({ type: setEditDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setEditDocumentMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/projects/sync_content`, {
        projectId,
        documentId,
        versionId,
        changeId,
        chapterId,
      })
      .then(function (response) {
        dispatch({ type: setEditDocumentStatus.type, payload: Status.success })
        dispatch({
          type: setEditDocumentMessage.type,
          payload: "Chapter Content Synced Successfully",
        })
      })
      .catch(function (error) {
        //
      })
  }
}

function findDFSByTreeIndex(objects: any, treeIndex: number) {
  for (let [_, o] of objects.entries() || []) {
    if (o.treeIndex == treeIndex) {
      return o
    }

    if (o.subchapters.length > 0) {
      const o_ = findDFSByTreeIndex(o.subchapters, treeIndex)

      if (o_) {
        return o_
      }
    }
  }
}

function findDFSByTreeIndex2(
  objects: ChapterObject[],
  treeIndex: number,
  templateChapter: ChapterObject,
  projectChapter: ChapterObject,
  countries: Country[]
) {
  objects.map((o) => {
    if (o.treeIndex == treeIndex) {
      updateExistingContentInChapterForManualSync(
        cloneDeep(templateChapter.sections),
        projectChapter,
        templateChapter,
        0,
        countries,
        o
      )

      return
    }

    if (o.subchapters.length > 0) {
      return findDFSByTreeIndex2(
        o.subchapters,
        treeIndex,
        templateChapter,
        projectChapter,
        countries
      )
    }
  })
}

export function syncChapterContentManually(
  project: Project,
  document: Document,
  templateChapter: ChapterObject,
  projectChapter: ChapterObject,
  documentChapters: ChapterObject[]
) {
  return async function syncChapterStructure(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    dispatch({ type: setEditDocumentStatus.type, payload: Status.loading })
    dispatch({ type: setEditDocumentMessage.type, payload: "" })
    let documentVersion =
      document.documentVersions[document.documentVersions.length - 1]
    let documentChange =
      documentVersion.changes[documentVersion.changes.length - 1]
    let newChapter: ChapterObject = cloneDeep(templateChapter)
    let docChapters: ChapterObject[] = cloneDeep(documentChapters)
    findDFSByTreeIndex2(
      docChapters,
      newChapter.treeIndex,
      newChapter,
      projectChapter,
      documentChange.countries
    )
    let activeDocVersion: DocumentVersion =
      document.documentVersions[document.documentVersions.length - 1]

    let activeDocumentChange: DocumentChange =
      activeDocVersion.changes[activeDocVersion.changes.length - 1]

    let newReduxDocumentChange: DocumentChange = {
      ...activeDocumentChange,
      chapters: docChapters,
      version: activeDocVersion.latestVersion + 1,
      lastUpdated: new Date().toISOString(),
    }
    delete newReduxDocumentChange.id
    let activeDocDocumentVersions: DocumentVersion[] = cloneDeep(
      document.documentVersions
    )

    let activeDocumentVersion: DocumentVersion =
      activeDocDocumentVersions[activeDocDocumentVersions.length - 1]

    let activeDocDocumentChanges: DocumentChange[] =
      activeDocumentVersion.changes

    activeDocumentVersion.lastUpdated = new Date().toISOString()
    activeDocDocumentChanges.push(newReduxDocumentChange)

    let newDocument: Document = {
      ...document,
      documentVersions: activeDocDocumentVersions,
      lastUpdated: new Date().toISOString(),
    }
    let docs = cloneDeep(project.documents)
    let newDocs: Document[] = cloneDeep(docs)

    let index = docs.findIndex((d) => d.id === document.id)

    if (index !== -1) {
      newDocs[index] = newDocument
    }

    let newReduxObj: Project = {
      ...project,
      documents: newDocs,
      lastUpdated: new Date().toISOString(),
    }

    let requestDocuments: responseDocument[] =
      mapProjectDocumentsToResponseProjectDocuments(newReduxObj.documents)
    axios
      .put(`${config.apiGateway.URL}/projects/update`, {
        author: newReduxObj.author,
        id: newReduxObj.id,
        last_updated: newReduxObj.lastUpdated,
        project_name: newReduxObj.name,
        countries: newReduxObj.countries,
        project_user: newReduxObj.users,
        documents: requestDocuments,
      })
      .then(function (response) {
        dispatch({ type: setEditDocumentStatus.type, payload: Status.success })
        dispatch({
          type: setEditDocumentMessage.type,
          payload: "Chapter Content Synced Successfully",
        })
      })
      .catch(function (error) {
        dispatch({ type: setEditDocumentStatus.type, payload: Status.failed })

        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            dispatch({ type: setEditDocumentMessage.type, payload: error })
          }
        } else dispatch({ type: setEditDocumentMessage.type, payload: "Unknown Error" })
      })
  }
}

export function saveToNewVersion(
  documentId: string,
  versionId: string,
  country: Country
) {
  return async function saveToNewVersion(
    dispatch: EPIDispatch,
    getState: RootState
  ) {
    //
    dispatch({ type: setSaveToNewVersionStatus.type, payload: Status.loading })
    dispatch({ type: setSaveToNewVersionMessage.type, payload: "" })
    axios
      .post(`${config.apiGateway.URL}/projects/save_version`, {
        documentId,
        versionId,
        country,
      })
      .then(function (response) {
        dispatch({
          type: setSaveToNewVersionStatus.type,
          payload: Status.success,
        })
        dispatch({
          type: setSaveToNewVersionMessage.type,
          payload: "Saved to Version Successfully",
        })
      })
      .catch(function (error) {
        //
      })
  }
}
