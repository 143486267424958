import DescriptionIcon from "@material-ui/icons/Description"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import CountryIcon from "@material-ui/icons/Language"
import PeopleIcon from "@material-ui/icons/People"
import SettingsIcon from "@material-ui/icons/Settings"
import DocumentIcon from "@material-ui/icons/Assignment"
import SectionsIcon from "@material-ui/icons/ViewHeadline"
import ExitIcon from "@material-ui/icons/ExitToApp"
import HomeIcon from "@material-ui/icons/Home"
import GlobalIcon from "@material-ui/icons/Language"
import ManageHistoryIcon from "@mui/icons-material/ManageHistory"

type SideNavLinks = {
  id: string
  title: string
  path: string
  icon: React.ElementType
  canAccess?: string[]
}

export enum PROJECT_ROLE_NAMES {
  NONE = "None",
  // SUPER_USER = "Super User",
  LEAD_CONSULTANT = "Lead Consultant",
  IMPLEMENTATION_CONSULTANT = "Implementation Consultant",
  CLIENT_PROJECT_MANAGER = "Client Project Manager",
  PROJECT_MANAGER = "Project Manager",
  AMS_CONSULTANT = "AMS Consultant",
  CLIENT_USER = "Client User",
}

const templateHomeLinks: SideNavLinks[] = [
  {
    id: "template_documents_link",
    title: "Documents Management",
    path: "/template",
    icon: DescriptionIcon,
  },
  {
    id: "template_users_link",
    title: "Users Management",
    path: "users",
    icon: SupervisorAccountIcon,
  },
  {
    id: "template_projects_link",
    title: "Project Management",
    path: "projects",
    icon: BusinessCenterIcon,
  },
]

const projectHomeLinks: SideNavLinks[] = [
  {
    id: "projects_documents_link",
    title: "Project Home",
    path: "home",
    icon: HomeIcon,
    canAccess: ["all"],
  },
  {
    id: "projects_users_link",
    title: "Manage Users",
    path: "users",
    icon: SupervisorAccountIcon,
    canAccess: [
      // PROJECT_ROLE_NAMES.SUPER_USER,
      PROJECT_ROLE_NAMES.PROJECT_MANAGER,
      PROJECT_ROLE_NAMES.AMS_CONSULTANT,
    ],
  },
  {
    id: "projects_countries_link",
    title: "Manage Countries",
    path: "countries",
    icon: GlobalIcon,
    canAccess: [
      // PROJECT_ROLE_NAMES.SUPER_USER,
      PROJECT_ROLE_NAMES.PROJECT_MANAGER,
      PROJECT_ROLE_NAMES.AMS_CONSULTANT,
    ],
  },
  {
    id: "projects_leave_project_link",
    title: "Leave Project",
    path: "/project",
    icon: ExitIcon,
    canAccess: ["all"],
  },
]

const templateDocumentLinks: SideNavLinks[] = [
  {
    id: "template_content_link",
    title: "Manage Content",
    path: "content",
    icon: DocumentIcon,
  },
  {
    id: "template_sections_link",
    title: "Manage Sections",
    path: "sections",
    icon: SectionsIcon,
  },
  {
    id: "template_countries_link",
    title: "Manage Countries",
    path: "countries",
    icon: CountryIcon,
  },
  {
    id: "template_roles_link",
    title: "Manage Roles",
    path: "roles",
    icon: PeopleIcon,
  },
  {
    id: "template_settings_link",
    title: "Document Settings",
    path: "settings",
    icon: SettingsIcon,
  },
  {
    id: "template_leave_document_link",
    title: "Leave Document",
    path: "/template",
    icon: ExitIcon,
  },
]

const projectDocumentLinks: SideNavLinks[] = [
  {
    id: "projects_content_link",
    title: "Manage Content",
    path: "content",
    icon: DocumentIcon,
    canAccess: ["all"],
  },
  {
    id: "projects_sections_link",
    title: "Manage Sections",
    path: "sections",
    icon: SectionsIcon,
    canAccess: ["all"],
  },
  {
    id: "projects_roles_link",
    title: "Manage Roles",
    path: "roles",
    icon: PeopleIcon,
    canAccess: ["all"],
  },
  {
    id: "projects_versions_link",
    title: "Manage Versions",
    path: "versions",
    icon: ManageHistoryIcon,
    canAccess: ["all"],
  },
  {
    id: "projects_settings_link",
    title: "Document Settings",
    path: "settings",
    icon: SettingsIcon,
    canAccess: ["all"],
  },
  {
    id: "projects_leave_document_link",
    title: "Leave Document",
    path: "",
    icon: ExitIcon,
    canAccess: ["all"],
  },
]

const documentsHeadCells = [
  { id: "name", disablePadding: false, label: "Document Name" },
  { id: "author", disablePadding: false, label: "Author" },
  { id: "lastUpdated", disablePadding: false, label: "Last Updated" },
  { id: "enabled", disablePadding: false, label: "Active" },
  { id: "manageVersions", disablePadding: false, label: "Actions" },
]

const usersHeadCells = [
  { id: "firstname", disablePadding: false, label: "First name" },
  { id: "surname", disablePadding: false, label: "Last name" },
  { id: "email", disablePadding: false, label: "Email" },
  // { id: "company", disablePadding: false, label: "Company" },
  { id: "role_name", disablePadding: false, label: "Roles" },
  { id: "actions", disablePadding: false, label: "Actions" },
]

const projectUsersHeadCells = [
  { id: "firstname", disablePadding: false, label: "First name" },
  { id: "surname", disablePadding: false, label: "Last name" },
  { id: "email", disablePadding: false, label: "Email" },
  { id: "role_name", disablePadding: false, label: "Roles" },
  { id: "user_activation", disablePadding: false, label: "Actions" },
]

const projectsHeadCells = [
  { id: "name", disablePadding: false, label: "Project Name" },
  // { id: "lead", disablePadding: false, label: "Project Lead" },
  { id: "actions", disablePadding: false, label: "Actions" },
]

const userProjectsHeadCells = [
  { id: "name", disablePadding: false, label: "Name" },
  { id: "author", disablePadding: false, label: "Author" },
  { id: "lastUpdated", disablePadding: false, label: "Last Updated" },
  { id: "roles", disablePadding: false, label: "Roles" },
]

const userActivityLogHeadCells = [
  { id: "date", disablePadding: false, label: "Date" },
  { id: "time", disablePadding: false, label: "Time" },
  { id: "user", disablePadding: false, label: "User" },
  { id: "event", disablePadding: false, label: "Event" },
  { id: "description", disablePadding: false, label: "Description" },
]

const statusAndColor = new Map([
  ["To be started", "#CCCCCC"],
  ["In Progress (being updated)", "#FFFF00"],
  ["In Progress (waiting for client information)", "#FFA500"],
  ["Released for Client Review", "#CC00CC"],
  ["Rejected with Comments", "#FF0000"],
  ["Approved by Client", "#0092ff"],
  ["Completed", "#00FF00"],
])

const defaultAccess = [
  "none",
  "maintain",
  "none",
  "none",
  "none",
  "maintain",
  "none",
  "none",
]

export {
  defaultAccess,
  statusAndColor,
  projectHomeLinks,
  templateHomeLinks,
  documentsHeadCells,
  templateDocumentLinks,
  projectDocumentLinks,
  usersHeadCells,
  projectUsersHeadCells,
  projectsHeadCells,
  userProjectsHeadCells,
  userActivityLogHeadCells,
}
