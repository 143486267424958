import { create } from "zustand"

import { Project, ProjectUserRole } from "shared/types/project"

type EditProjectState = {
  activeProject: Project
  authorError: boolean
  projectNameError: boolean
  authorHelperText: string
  projectNameHelperText: string
  newAuthorName: string
  newProjectName: string
  projectUsers: ProjectUserRole[]
}

type EditProjectActions = {
  setActiveProject: (activeProject: Project) => void
  setAuthorError: (authorError: boolean) => void
  setProjectNameError: (projectNameError: boolean) => void
  setAuthorHelperText: (authorHelperText: string) => void
  setProjectNameHelperText: (projectNameHelperText: string) => void
  setNewAuthorName: (newAuthorName: string) => void
  setNewProjectName: (newProjectName: string) => void
  setProjectUsers: (projectUsers: ProjectUserRole[]) => void
}

const useEditProjectState = create<EditProjectState & EditProjectActions>(
  (set) => ({
    activeProject: null,
    authorError: false,
    projectNameError: false,
    authorHelperText: "",
    projectNameHelperText: "",
    newAuthorName: "",
    newProjectName: "",
    projectUsers: [] as ProjectUserRole[],
    setActiveProject: (activeProject) => set(() => ({ activeProject })),
    setAuthorError: (authorError) => set(() => ({ authorError })),
    setProjectNameError: (projectNameError) =>
      set(() => ({ projectNameError })),
    setAuthorHelperText: (authorHelperText) =>
      set(() => ({ authorHelperText })),
    setProjectNameHelperText: (projectNameHelperText) =>
      set(() => ({ projectNameHelperText })),
    setNewAuthorName: (newAuthorName) => set(() => ({ newAuthorName })),
    setNewProjectName: (newProjectName) => set(() => ({ newProjectName })),
    setProjectUsers: (projectUsers) => set(() => ({ projectUsers })),
  })
)

export default useEditProjectState
