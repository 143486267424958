import React from "react"
import { Skeleton } from "@material-ui/lab"
import { makeStyles, Theme } from "@material-ui/core/styles"

import useAppState from "hooksV1/useAppState"
import { checkEnvironment } from "util/environment"
import useBooleanState from "hooksV1/useBooleanStates"
import { Document } from "shared/types-exp"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  skeletonContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "1rem",
    margin: theme.spacing(1, 0, 1, 0),
  },
}))

const isInWorkflow = (activeDocument: Document, clientStatus: string) => {
  if (!activeDocument) return null

  if (!activeDocument?.isIncludedInWorkflow) return null

  if (clientStatus === "Approved") return <>(Approved)</>

  return <>(Not Approved)</>
}

const ChapterHeaderDetails: React.FC = () => {
  const { activeChapterObject, activeDocument } = useAppState()

  const clientStatus = activeChapterObject?.client_status?.status || ""

  return (
    <span>
      {" "}
      - {activeChapterObject?.name || "N/A"} &nbsp;
      {isInWorkflow(activeDocument, clientStatus)}
    </span>
  )
}

const getHeaderTitle = (section: string, isProjectEnvironment: boolean) => {
  if (section === "sections") {
    return "Manage Sections"
  } else if (section === "settings") {
    return "Document Settings"
  } else if (section === "countries") {
    return "Manage Countries"
  } else if (section === "versions") {
    return "Version Management"
  } else if (section === "roles") {
    return isProjectEnvironment
      ? "Chapter Role Access Management"
      : "Document Role Access Management"
  }

  return ""
}

const ChapterHeaderV1: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { isChaptersLoading } = useBooleanState()
  const { isProjectEnvironment } = checkEnvironment()
  const { activeDocument, activeChapterObject } = useAppState()

  // ==================== Variables ==================== //
  let pathArray = location.pathname.split("/")
  let section = pathArray[pathArray.length - 1]

  if (isChaptersLoading || !activeDocument) {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton
          width="50%"
          height={40}
          variant="text"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      </div>
    )
  }

  if (section !== "content") {
    const title = getHeaderTitle(section, isProjectEnvironment)

    return (
      <div className={classes.container}>
        <h3>{title}</h3>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <h3>
        Editing: {activeDocument?.name}
        {activeChapterObject && <ChapterHeaderDetails />}
      </h3>
    </div>
  )
}

export default ChapterHeaderV1
