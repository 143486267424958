import {
  Theme,
  Table,
  Dialog,
  Button,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  makeStyles,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  DialogContentText,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import { cloneDeep } from "lodash"
import { Skeleton } from "@material-ui/lab"
import * as lookup from "country-code-lookup"
import ReactCountryFlag from "react-country-flag"
import React, { useEffect, useState } from "react"
import GlobalIcon from "@material-ui/icons/Language"

import { logger } from "util/logger"
import { Country } from "shared/types-exp"
import { checkFilteredRolesObject } from "util/helper"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import { ProjectRole, ProjectUserRole } from "shared/types/project"
import { useListCountriesWithProjectRefIdV1Query } from "redux/services"
import useEditProjectCountryRolesDialogV1 from "hooksV1/useEditProjectCountryRolesDialogV1"
import { checkEnvironment } from "util/environment"
import useAppState from "hooksV1/useAppState"

const GLOBAL = "global"
const AMS_CONSULTANT = "AMS Consultant"
const PROJECT_MANAGER = "Project Manager"

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    width: "100%",
    height: 75,
    flex: 1,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
  },
  container: {
    padding: theme.spacing(5, 5, 5, 5),
  },
  button: {
    paddingLeft: "1rem",
  },
  dialogTitle: { padding: theme.spacing(2.5, 0, 0, 5) },
  dialogContentText: { padding: theme.spacing(0, 0, 0, 2) },
  dialogActions: { padding: theme.spacing(0, 5, 2.5, 5) },
}))

export type EditProjectCountryRolesDialogV1Props = {
  projectId: string
  isLoading?: boolean
  roles: ProjectRole[]
  handleSave: (value: ProjectUserRole) => void
}

export const TableHeadings: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="country" padding={"normal"}>
          <Typography variant="body1" color="primary" component="h1">
            Country
          </Typography>
        </TableCell>
        <TableCell align="left" key="role_name" padding={"normal"}>
          <Typography variant="body1" color="primary" component="h1">
            Roles
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

type TableContentProps = {
  countries: any[]
  roles: ProjectRole[]
  activeUser: ProjectUserRole
  setActiveUser: (activeUser: ProjectUserRole) => void
}

const TableContent: React.FC<TableContentProps> = ({
  roles,
  countries,
  activeUser,
  setActiveUser,
}) => {
  const [isGlobalSet, setIsGlobalSet] = useState(false)
  const { isTemplateEnvironment } = checkEnvironment()
  const { activeCountry, activeProjectUser } = useAppState()

  const isAmsConsultantOrProjectManager = (roleName: string): boolean => {
    return roleName === AMS_CONSULTANT || roleName === PROJECT_MANAGER
  }

  const filterGlobalObject = (arr) => {
    const hasGlobal = arr.some((item) => item.countryId === "global")

    if (hasGlobal) {
      return arr.filter((item) => item.countryId === "global")
    }

    return arr
  }

  const handleOnRoleChange = (
    role: string,
    countryId: string,
    countryName: string
  ) => {
    const activeUserClone = cloneDeep(activeUser)

    const roleIndex = activeUserClone.role.findIndex(
      (currRole) => currRole.countryId === countryId
    )

    if (role === "None" && roleIndex !== -1) {
      activeUserClone.role = activeUserClone.role.filter(
        (role) => role.countryId !== countryId
      )

      if (countryId === "global") setIsGlobalSet(false)

      setActiveUser(activeUserClone)

      return
    }

    const { id, role_name, description } = roles.find(
      (roleObj) => role === roleObj.role_name
    )

    if (roleIndex === -1) {
      activeUserClone.role.push({
        id,
        role_name,
        description,
        countryId,
        countryName,
      })
    } else {
      activeUserClone.role[roleIndex] = {
        id,
        role_name,
        description,
        countryId,
        countryName,
      }
    }

    activeUserClone.role = filterGlobalObject(activeUserClone.role)

    setActiveUser(activeUserClone)
  }

  const isSelectDisabled = (role: ProjectRole) => {
    if (isTemplateEnvironment) return false

    if (
      activeProjectUser?.role.length === 1 &&
      activeProjectUser?.role[0].countryId === GLOBAL &&
      isAmsConsultantOrProjectManager(activeProjectUser?.role[0].role_name)
    )
      return false

    if (!role || !activeCountry) return true

    if (role?.countryId === "global") return false

    return role?.countryId !== activeCountry?.id
  }

  return (
    <TableBody>
      {countries.map((row) => {
        const countryCode = lookup.byCountry(row.country_name)?.iso2 ?? ""

        const role = activeUser?.role.find((role) => role.countryId === row.id)

        return (
          <TableRow key={`${row.country_name}_${uuidv4()}`}>
            <TableCell component="th" scope="row" key={uuidv4()}>
              <>
                {row.global ? (
                  <GlobalIcon
                    fontSize="small"
                    style={{
                      verticalAlign: "middle",
                    }}
                  />
                ) : (
                  <ReactCountryFlag countryCode={countryCode} svg />
                )}
                &nbsp;{row.country_name}
              </>
            </TableCell>
            <TableCell
              align="left"
              key={uuidv4()}
              style={{ width: "200px", whiteSpace: "nowrap" }}
            >
              <Select
                value={role?.role_name || "None"}
                onChange={(event) => {
                  handleOnRoleChange(
                    event.target.value as string,
                    row.id,
                    row.country_name
                  )
                }}
                inputProps={{
                  name: "age",
                  id: "age-native-simple",
                }}
                disabled={isSelectDisabled(role)}
                style={{ width: "230px", whiteSpace: "nowrap" }}
              >
                {roles.map((role) => (
                  <MenuItem
                    key={`${role.id}_${uuidv4()}`}
                    value={role.role_name}
                    disabled={row.countryId !== "global" && isGlobalSet}
                  >
                    <Tooltip title={role.description} placement="bottom-end">
                      <ListItemText primary={role.role_name} />
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

const EditProjectCountryRolesDialogV1: React.FC<
  EditProjectCountryRolesDialogV1Props
> = ({ projectId, isLoading = false, roles, handleSave }) => {
  const classes = useStyles()
  const snackBar = useSnackBar()
  const { isTemplateEnvironment } = checkEnvironment()
  const useDialog = useEditProjectCountryRolesDialogV1()

  const { data: projectCountries, isLoading: isFetching } =
    useListCountriesWithProjectRefIdV1Query(projectId)

  const [countries, setCountries] = useState([])

  useEffect(() => {
    if (!projectCountries) return

    try {
      if (projectCountries.errors) {
        throw Error(
          `Operation: [Get All Countries With Project Ref Id, ${projectId}]`
        )
      }

      const countriesList: any[] = projectCountries?.data?.listCountries?.items

      const sortedCountriesList = [...countriesList].sort((a, b) => {
        const nameA = a.country_name.toUpperCase() // Convert names to uppercase for case-insensitive comparison
        const nameB = b.country_name.toUpperCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0 // Names are equal
      })

      const globalCountry: Partial<Country> = {
        active_status: true,
        country_name: "Global",
        global: true,
        id: "global",
        projectRefId: "",
      }

      sortedCountriesList.unshift(globalCountry)

      setCountries(sortedCountriesList)
    } catch (error) {
      logger("CountrySelectorV1", "useEffect", error)

      snackBar.setMessage(
        "An error occurred while fetching countries. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [projectCountries])

  const handleClose = () => {
    useDialog.onClose()
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleClose()
    }
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return

    handleClose()
  }

  const displayContent = () => {
    if (isFetching || !useDialog?.activeUser) {
      return (
        <Skeleton
          className={classes.skeleton}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      )
    }

    if (!isFetching && countries.length === 0) {
      return (
        <div
          className={classes.container}
          style={{
            height: 60,
            fontSize: 16,
          }}
        >
          No Countries Available
        </div>
      )
    }

    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHeadings />
          <TableContent
            countries={countries}
            activeUser={useDialog?.activeUser}
            setActiveUser={useDialog?.setActiveUser}
            roles={checkFilteredRolesObject(roles)}
          />
        </Table>
      </TableContainer>
    )
  }

  const saveAccessRights = () => {
    handleSave(useDialog?.activeUser)

    if (isTemplateEnvironment) useDialog.onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={useDialog.isOpen}
      onClose={handleCloseDialog}
      className={classes.container}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle className={classes.dialogTitle}>
        Project Country Access Rights/Roles
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          View/Change The Access Rights/Roles of a User For Each Country
        </DialogContentText>
        {displayContent()}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="text"
          color="primary"
          size="large"
          disabled={isLoading}
          onClick={useDialog.onClose}
        >
          Cancel
        </Button>
        <span className={classes.button} />
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={isLoading || isFetching}
          onClick={saveAccessRights}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProjectCountryRolesDialogV1
