import {
  Paper,
  Theme,
  Button,
  Tooltip,
  Divider,
  IconButton,
  makeStyles,
  TablePagination,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { Skeleton } from "@material-ui/lab"
import CompareIcon from "@mui/icons-material/CompareArrows"
import React, { useState, useEffect } from "react"

import {
  Version,
  Document,
  ChapterObject,
  PartialVersion,
  DocumentVersion,
  PartialChapterObject,
  PartialDocumentVersion,
} from "shared/types-exp"
import {
  useGetDocumentQuery,
  useListVersionsByCountryIdAndRefIdQuery,
} from "redux/services"
import VersionsTable from "./VersionsTable"
import useAppState from "hooksV1/useAppState"
import NoDataMessage from "components/NoDataMessage"
import SaveWorkingVersionDialogV1 from "dialogsV1/SaveWorkingVersionDialogV1"
import useSaveWorkingVersionDialogV1 from "hooksV1/useSaveWorkingVersionDialogV1"
import CompareVersionDialog from "../../dialogsV1/CompareVersionDialog"
import useCompareVersionDialog from "../../hooksV1/useCompareVersionDialog"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  workingVersionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  workingVersion: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "0.7rem",
    paddingLeft: "2rem",
  },
  saveButton: {
    marginRight: "1rem",
    marginLeft: "0.5rem",
  },
  tableContainer: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    height: "100%",
    marginLeft: "0.5rem",
  },
  paginationContainer: {
    height: "auto",
    display: "flex",
    marginLeft: "0.5rem",
    justifyContent: "center",
  },
  skeleton: {
    height: "100%",
    marginTop: "0.5rem",
    marginLeft: "1rem",
    borderRadius: "0.5rem",
  },
}))

const ProjectManageVersion: React.FC = () => {
  const classes = useStyles()
  const { activeCountry, activeDocument } = useAppState()
  const saveWorkingVersion = useSaveWorkingVersionDialogV1()
  const compareVersionDialog = useCompareVersionDialog()

  const docId = activeDocument?.id ?? ""
  const refId = activeDocument?.refId ?? ""
  const countryId = activeCountry?.id ?? ""

  const { data: getDocumentData, isLoading: isDocumentLoading } =
    useGetDocumentQuery(
      { countryId, id: docId },
      { skip: !countryId || !docId }
    )

  const { data: getVersionsData, isLoading: isVersionsLoading } =
    useListVersionsByCountryIdAndRefIdQuery(
      {
        countryId,
        refId,
      },
      {
        skip: !countryId,
      }
    )

  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [versions, setVersions] = useState<Version[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentVersion, setCurrentVersion] = useState<PartialVersion>(null)
  const [currentDocument, setCurrentDocument] = useState<Document>(null)
  const [currentActiveDocumentVersion, setCurrentActiveDocumentVersion] =
    useState<DocumentVersion>(null)
  const [currentDocumentChapters, setCurrentDocumentChapters] = useState<
    ChapterObject[]
  >([])

  useEffect(() => {
    if (getVersionsData) {
      const tempVersions: Version[] =
        getVersionsData.data?.listVersions?.items || []

      const sortedVersionList: Version[] = sortArrayByActiveVersionDescending([
        ...tempVersions,
      ])
      setVersions(sortedVersionList)
    }
  }, [getVersionsData])

  useEffect(() => {
    if (getDocumentData) {
      const document = getDocumentData.data.getDocument
      setCurrentDocument(document)
      const activeVersion = document.documentVersions.items[0]
      setCurrentActiveDocumentVersion(activeVersion)
      const chapters = activeVersion.chapters.items
      setCurrentDocumentChapters(chapters)
    }
  }, [getDocumentData])

  useEffect(() => {
    if (currentDocument && currentActiveDocumentVersion) {
      const partialDocumentChapters: PartialChapterObject[] =
        currentDocumentChapters

      const clonedDocVersion = cloneDeep(currentActiveDocumentVersion)
      delete clonedDocVersion.chapters

      const partialDocumentVersion: PartialDocumentVersion = {
        ...clonedDocVersion,
      }

      const version: PartialVersion = {
        name: currentDocument.name,
        refId: currentDocument.refId,
        author: currentDocument.author,
        enabled: currentDocument.enabled,
        chapters: partialDocumentChapters,
        lastUpdated: new Date().toISOString(),
        countryId: currentDocument.countryId,
        documentVersion: partialDocumentVersion,
        activeVersion: currentDocument.activeVersion,
      }

      setCurrentVersion(version)
    }
  }, [currentDocument, currentActiveDocumentVersion, currentDocumentChapters])

  useEffect(() => {
    setIsLoading(isDocumentLoading || isVersionsLoading)
  }, [isDocumentLoading, isVersionsLoading])

  const sortArrayByActiveVersionDescending = (array: Version[]): Version[] => {
    return array.sort((a, b) => b.activeVersion - a.activeVersion)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  if (isLoading) {
    return <Skeleton className={classes.skeleton} variant="rect" />
  }

  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.tableContainer}>
          <div className={classes.workingVersionContainer}>
            <div className={classes.workingVersion}>
              <h2>{activeDocument?.name} Versions</h2>
            </div>
            {versions.length > 0 && (
              <Tooltip title="Compare Versions">
                <IconButton
                  color="primary"
                  onClick={compareVersionDialog.onOpen}
                >
                  <CompareIcon />
                </IconButton>
              </Tooltip>
            )}
            <Button
              variant="outlined"
              className={classes.saveButton}
              color="primary"
              disabled={isLoading}
              onClick={saveWorkingVersion.onOpen}
            >
              Create Version
            </Button>
          </div>
          <Divider />
          {versions.length > 0 ? (
            <VersionsTable
              page={page}
              currentVersion={currentVersion}
              versions={versions}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <NoDataMessage text={"No Versions Saved"} />
          )}
        </Paper>

        <Paper className={classes.paginationContainer}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={versions.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </div>

      {saveWorkingVersion.isOpen && (
        <SaveWorkingVersionDialogV1
          version={currentVersion}
          activeDocument={currentDocument}
          activeDocumentVersion={currentActiveDocumentVersion}
        />
      )}

      {compareVersionDialog.isOpen && (
        <CompareVersionDialog
          versions={[{ ...currentVersion, id: "" }, ...versions]}
        />
      )}
    </>
  )
}

export default ProjectManageVersion
