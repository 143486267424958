/* eslint-disable @typescript-eslint/no-extra-semi */
// ==================== External Imports ==================== //
import React from "react"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import SyncIcon from "@material-ui/icons/Refresh"
import DeleteIcon from "@material-ui/icons/Delete"
import { Menu, MenuItem } from "@material-ui/core"
import ReorderIcon from "@mui/icons-material/Reorder"
import EditNoteIcon from "@mui/icons-material/EditNote"
// ==================== Local Imports ==================== //
import useAppState from "hooksV1/useAppState"
import { PROJECT_ROLE_NAMES } from "util/constants"
import { checkEnvironment } from "util/environment"
import useReOrderDialogV1 from "hooksV1/useReOrderDialogV1"
import useAssignUserDialogV1 from "hooksV1/useAssignUserDialogV1"
import useSyncContentDialogV1 from "hooksV1/useSyncContentDialog"
import useEditChapterDialogV1 from "../../hooksV1/useEditChapterDialogV1"
import useAddSubChapterDialogV1 from "../../hooksV1/useAddSubChapterDialogV1"
import useRemoveChapterDialogV1 from "../../hooksV1/useRemoveChapterDialogV1"

type ChapterSettingsV1Props = {
  chapterSettingsMenu: boolean
  setChapterSettingsMenu: (value: boolean) => void
  anchorEl: null | HTMLElement
}

type MenuItemsProps = {
  icon: React.ElementType
  label: string
  onClick: () => void
  isDisabled: boolean
  isDisplayed: boolean
}

export const ChapterSettingsV1: React.FC<ChapterSettingsV1Props> = ({
  chapterSettingsMenu,
  setChapterSettingsMenu,
  anchorEl,
}) => {
  // ==================== Hooks ==================== //
  const { activeCountry, activeChapterObject, activeProjectUser } =
    useAppState()

  const assignUserDialog = useAssignUserDialogV1()
  const reOrderChapterDialog = useReOrderDialogV1()
  const syncContentDialog = useSyncContentDialogV1()
  const editChapterDialog = useEditChapterDialogV1()
  const addSubChapterDialog = useAddSubChapterDialogV1()
  const removeChapterDialog = useRemoveChapterDialogV1()
  const { isTemplateEnvironment, isProjectEnvironment } = checkEnvironment()

  // ==================== Functions ==================== //

  const canDisplay = () => {
    return (
      (isTemplateEnvironment &&
        activeCountry?.global &&
        activeChapterObject?.isGlobal) ||
      (isTemplateEnvironment &&
        !activeCountry?.global &&
        activeChapterObject?.isCountry) ||
      (isProjectEnvironment && activeChapterObject?.isProject)
    )
  }

  const isAssignUserVisible = () => {
    const rolesArray = [
      PROJECT_ROLE_NAMES.PROJECT_MANAGER as string,
      PROJECT_ROLE_NAMES.AMS_CONSULTANT as string,
    ]

    const isProjectManager = activeProjectUser?.role
      .map((roleEntry) => roleEntry.role_name)
      .some((roleName) => rolesArray.includes(roleName))

    return isProjectEnvironment && isProjectManager
  }

  // ==================== Variables ==================== //
  const menuItems: MenuItemsProps[] = [
    {
      icon: ReorderIcon,
      label: "Reorder Chapters",
      onClick: () => {
        reOrderChapterDialog.onOpen()
        setChapterSettingsMenu(false)
      },
      isDisabled: false,
      isDisplayed: canDisplay(),
    },
    {
      icon: AddIcon,
      label: "Add Subchapters",
      onClick: () => {
        addSubChapterDialog.onOpen()
        setChapterSettingsMenu(false)
      },
      isDisabled: false,
      isDisplayed: activeChapterObject?.subchapterCreateAllowed || activeCountry.global,
    },
    {
      icon: EditIcon,
      label: "Edit Chapter",
      onClick: () => {
        editChapterDialog.onOpen()
        setChapterSettingsMenu(false)
      },
      isDisabled: false,
      isDisplayed: canDisplay(),
    },
    {
      icon: EditNoteIcon,
      label: "Assign User",
      onClick: () => {
        assignUserDialog.onOpen()
        setChapterSettingsMenu(false)
      },
      isDisabled: false,
      isDisplayed: isAssignUserVisible(),
    },
    {
      icon: DeleteIcon,
      label: "Remove Chapter",
      onClick: () => {
        removeChapterDialog.onOpen()
        setChapterSettingsMenu(false)
      },
      isDisabled: false,
      isDisplayed: canDisplay(),
    },
    {
      icon: SyncIcon,
      label: "Sync Content",
      onClick: () => {
        syncContentDialog.onOpen()
        setChapterSettingsMenu(false)
      },
      isDisabled: false,
      isDisplayed: isProjectEnvironment && !activeChapterObject?.isProject,
    },
  ]

  return (
    <>
      {chapterSettingsMenu && (
        <Menu
          open={chapterSettingsMenu}
          anchorEl={anchorEl}
          onClose={() => setChapterSettingsMenu(false)}
          style={{ marginLeft: "2rem", marginTop: "0.5rem" }}
        >
          {menuItems.map(
            (item, index) =>
              item.isDisplayed && (
                <MenuItem
                  disabled={item.isDisabled}
                  key={index}
                  onClick={item.onClick}
                >
                  <item.icon /> &nbsp;{item.label}
                </MenuItem>
              )
          )}
        </Menu>
      )}
    </>
  )
}
