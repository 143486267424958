// ==================== External Imports ==================== //
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import InfoIcon from "@material-ui/icons/InfoOutlined"
import { makeStyles, Theme } from "@material-ui/core/styles"
// ==================== Local Imports ==================== //
import {
  checkDisabledRole,
  checkFilteredRoles,
  getUpdatedRoles,
} from "util/helper"
import useAppState from "hooksV1/useAppState"
import { RootState } from "../../redux/store"
import Loading from "components/Loading/Loading"
import useBooleanState from "hooksV1/useBooleanStates"
import { ProjectRole } from "../../shared/types/project"
import { fetchProjectRoles } from "../../redux/thunks/projectRolesThunk"
import { useGetDocumentVersionAccessRightsV1Query } from "redux/services"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.45, 0, 1),
  },
  disabledSelect: {
    "&.Mui-disabled": {
      color: "black !important",
      opacity: ".5 !important",
    },
  },
  skeletonContainer: {
    width: "100%",
    height: "100%",
    margin: theme.spacing(1, 0, 0, 0),
  },
  accessRightsHead: {
    display: "flex",
    alignItems: "end",
  },
  table: {
    width: "100%",
  },
  paperCard: {
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1, 0, 0, 0),
  },
  infoIcon: {
    marginRight: 5,
  },
  descriptionTitle: {
    letterSpacing: 1,
  },
  description: {
    fontSize: 12,
  },
}))

const ManageRoles: React.FC = () => {
  // ==================== Hooks ==================== //
  const {
    documentRoles,
    activeDocument,
    setDocumentRoles,
    activeDocumentVersion,
  } = useAppState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { setIsChaptersLoading, isChaptersLoading } = useBooleanState()

  const { data: documentVersionData, isLoading } =
    useGetDocumentVersionAccessRightsV1Query(
      {
        documentId: activeDocument?.id,
        id: activeDocumentVersion?.id,
      },
      { skip: !activeDocument || !activeDocumentVersion }
    )

  useEffect(() => {
    if (isChaptersLoading) setIsChaptersLoading(false)
  }, [isChaptersLoading])

  useEffect(() => {
    if (documentVersionData) {
      let { access } = documentVersionData.data.getDocumentVersion

      access = getUpdatedRoles(access)

      setDocumentRoles(access)
    }
  }, [documentVersionData])

  useEffect(() => {
    if (roles.length === 0) {
      const rolesThunk = fetchProjectRoles()
      dispatch(rolesThunk)
    }
  }, [])

  // ==================== Variables ==================== //
  const roles: ProjectRole[] =
    useSelector((state: RootState) => state.projectRoles.roles).filter(
      (role) => role.role_name !== "None"
    ) ?? []

  const accessRightsDescription = (
    <>
      <h2 className={classes.descriptionTitle}>Maintain</h2>
      <p className={classes.description}>user has write access</p>

      <h2 className={classes.descriptionTitle}>Consult</h2>
      <p className={classes.description}>user has read access</p>

      <h2 className={classes.descriptionTitle}>No Access</h2>
      <p className={classes.description}>
        user does not have read or write access
      </p>
    </>
  )

  // ==================== Functions ==================== //
  const sortRoles = (roles: ProjectRole[]): ProjectRole[] => {
    const roleOrder = [
      "Super User",
      "Project Manager",
      "Lead Consultant",
      "Implementation Consultant",
      "AMS Consultant",
      "Client User",
      "Client Project Manager",
    ].map((role) => role.toLowerCase())

    const sortedRoles = [...roles]

    sortedRoles.sort((a, b) => {
      const roleAIndex = roleOrder.indexOf(a.role_name.toLowerCase())
      const roleBIndex = roleOrder.indexOf(b.role_name.toLowerCase())

      if (roleAIndex === -1) {
        return 1
      } else if (roleBIndex === -1) {
        return -1
      }

      return roleAIndex - roleBIndex
    })

    return sortedRoles
  }

  const handleChange = (index: string, value: string) => {
    const newRoles = cloneDeep(documentRoles)
    newRoles[parseInt(index)] = value
    setDocumentRoles(newRoles)
  }

  const TableHeadings = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1" color="primary" component="h1">
              Roles
            </Typography>
          </TableCell>
          <TableCell align="right" className={classes.accessRightsHead}>
            <Tooltip title={accessRightsDescription} placement="bottom-start">
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
            <Typography
              variant="body1"
              color="primary"
              component="h1"
              className={classes.infoIcon}
            >
              Access Rights
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const TableContent = () => {
    return (
      <TableBody>
        {sortRoles(roles)
          .filter((role) => checkFilteredRoles(role.role_name))
          .map((role, i) => (
            <TableRow key={role.role_name}>
              <TableCell>{role.role_name}</TableCell>
              <TableCell align="right">
                <Select
                  className={
                    checkDisabledRole(role.role_name)
                      ? classes.disabledSelect
                      : ""
                  }
                  disabled={checkDisabledRole(role.role_name)}
                  defaultValue={"none"}
                  onChange={(event) =>
                    handleChange(role.id, event.target.value as string)
                  }
                  value={documentRoles[parseInt(role.id)]}
                >
                  <MenuItem value={"maintain"}>Maintain</MenuItem>
                  <MenuItem value={"maintain_doa"}>Maintain DOA</MenuItem>
                  <MenuItem value={"consult"}>Consult</MenuItem>
                  <MenuItem value={"none"}>No Access</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    )
  }

  const displayBody = () => {
    if (documentRoles.length === 0 || isLoading) {
      return (
        <div className={classes.skeletonContainer}>
          <Loading />
        </div>
      )
    }

    return (
      <Paper className={classes.paperCard}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHeadings />
            <TableContent />
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  return <div className={classes.container}>{displayBody()}</div>
}

export default ManageRoles
