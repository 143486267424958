import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

import { sortDocuments } from "util/helper"
import useAppState from "hooksV1/useAppState"
import useBooleanState from "hooksV1/useBooleanStates"
import SideNaveV1 from "components/SideNav/SideNaveV1"
import DocumentsToolBar from "components/DocumentsToolBar"
import { useGetGlobalCountryWithDocumentsV1Query } from "redux/services"
import { useInitializeGlobalCountry } from "hooksV1/useInitializeGlobalCountry"
import useInitializeBusinessBlueprint from "hooksV1/useInitializeBusinessBlueprint"

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flexGrow: 1,
    padding: "0.75rem",
  },
  root: {
    flex: 1,
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "0.75rem",
  },
  sideNav: {
    width: "15%",
    height: "100%",
  },
  body: {
    flex: 1,
    height: "100%",
  },
}))

const TemplateHomeV1: React.FC = () => {
  const classes = useStyles()
  const appState = useAppState()
  const booleanState = useBooleanState()

  // Get global country and its documents
  const { data: globalCountryWithDocuments, isLoading } =
    useGetGlobalCountryWithDocumentsV1Query(null)

  useInitializeGlobalCountry()
  useInitializeBusinessBlueprint()

  useEffect(() => {
    if (!globalCountryWithDocuments) return

    const globalCountries =
      globalCountryWithDocuments?.data?.listCountries?.items

    if (!globalCountries || globalCountries.length === 0) return

    extractGlobal(globalCountries[0])
  }, [globalCountryWithDocuments])

  useEffect(() => {
    appState.setActiveProject(null)
    appState.setActiveCountry(null)
    appState.setActiveDocument(null)
    appState.setActiveChapterObject(null)
    appState.setActiveDocumentVersion(null)
  }, [])

  const extractGlobal = (global: any) => {
    const documents = sortDocuments(global.documents?.items || []) ?? []

    appState.setDocuments(documents)
    appState.setCountries([global])
    appState.setActiveCountry(global)
  }

  useEffect(() => {
    booleanState.setIsCountriesLoading(isLoading)
    booleanState.setIsDocumentsLoading(isLoading)
  }, [isLoading])

  return (
    <main className={classes.main}>
      <div className={classes.root}>
        <DocumentsToolBar />
        <div className={classes.container}>
          <div className={classes.sideNav}>
            <SideNaveV1 isTemplateHome />
          </div>
          <div className={classes.body}>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default TemplateHomeV1
