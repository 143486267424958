/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateCountry = /* GraphQL */ `subscription OnCreateCountry(
  $id: ID
  $documents: [ID]
  $country_name: String
  $active_status: Boolean
  $global: Boolean
) {
  onCreateCountry(
    id: $id
    documents: $documents
    country_name: $country_name
    active_status: $active_status
    global: $global
  ) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCountrySubscriptionVariables,
  APITypes.OnCreateCountrySubscription
>;
export const onUpdateCountry = /* GraphQL */ `subscription OnUpdateCountry(
  $id: ID
  $documents: [ID]
  $country_name: String
  $active_status: Boolean
  $global: Boolean
) {
  onUpdateCountry(
    id: $id
    documents: $documents
    country_name: $country_name
    active_status: $active_status
    global: $global
  ) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCountrySubscriptionVariables,
  APITypes.OnUpdateCountrySubscription
>;
export const onDeleteCountry = /* GraphQL */ `subscription OnDeleteCountry(
  $id: ID
  $documents: [ID]
  $country_name: String
  $active_status: Boolean
  $global: Boolean
) {
  onDeleteCountry(
    id: $id
    documents: $documents
    country_name: $country_name
    active_status: $active_status
    global: $global
  ) {
    id
    documents {
      items {
        countryId
        id
        refId
        name
        author
        activeVersion
        documentVersions {
          items {
            documentId
            id
            latestVersion
            users
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            chapters {
              nextToken
              __typename
            }
            isProject
            isCountry
            isGlobal
            documentRefId
            refId
            __typename
          }
          nextToken
          __typename
        }
        lastUpdated
        enabled
        isProject
        isIncludedInWorkflow
        openForChanges
        projectType
        __typename
      }
      nextToken
      __typename
    }
    country_name
    active_status
    global
    projectRefId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCountrySubscriptionVariables,
  APITypes.OnDeleteCountrySubscription
>;
export const onCreateDocument = /* GraphQL */ `subscription OnCreateDocument(
  $countryId: ID
  $id: ID
  $refId: ID
  $name: String
  $author: String
) {
  onCreateDocument(
    countryId: $countryId
    id: $id
    refId: $refId
    name: $name
    author: $author
  ) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDocumentSubscriptionVariables,
  APITypes.OnCreateDocumentSubscription
>;
export const onUpdateDocument = /* GraphQL */ `subscription OnUpdateDocument(
  $countryId: ID
  $id: ID
  $refId: ID
  $name: String
  $author: String
) {
  onUpdateDocument(
    countryId: $countryId
    id: $id
    refId: $refId
    name: $name
    author: $author
  ) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDocumentSubscriptionVariables,
  APITypes.OnUpdateDocumentSubscription
>;
export const onDeleteDocument = /* GraphQL */ `subscription OnDeleteDocument(
  $countryId: ID
  $id: ID
  $refId: ID
  $name: String
  $author: String
) {
  onDeleteDocument(
    countryId: $countryId
    id: $id
    refId: $refId
    name: $name
    author: $author
  ) {
    countryId
    id
    refId
    name
    author
    activeVersion
    documentVersions {
      items {
        documentId
        id
        latestVersion
        users
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        chapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        isProject
        isCountry
        isGlobal
        documentRefId
        refId
        __typename
      }
      nextToken
      __typename
    }
    lastUpdated
    enabled
    isProject
    isIncludedInWorkflow
    openForChanges
    projectType
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDocumentSubscriptionVariables,
  APITypes.OnDeleteDocumentSubscription
>;
export const onCreateDocumentVersion = /* GraphQL */ `subscription OnCreateDocumentVersion(
  $documentId: ID
  $id: ID
  $latestVersion: Int
  $lastUpdated: String
  $users: [String]
) {
  onCreateDocumentVersion(
    documentId: $documentId
    id: $id
    latestVersion: $latestVersion
    lastUpdated: $lastUpdated
    users: $users
  ) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDocumentVersionSubscriptionVariables,
  APITypes.OnCreateDocumentVersionSubscription
>;
export const onUpdateDocumentVersion = /* GraphQL */ `subscription OnUpdateDocumentVersion(
  $documentId: ID
  $id: ID
  $latestVersion: Int
  $lastUpdated: String
  $users: [String]
) {
  onUpdateDocumentVersion(
    documentId: $documentId
    id: $id
    latestVersion: $latestVersion
    lastUpdated: $lastUpdated
    users: $users
  ) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDocumentVersionSubscriptionVariables,
  APITypes.OnUpdateDocumentVersionSubscription
>;
export const onDeleteDocumentVersion = /* GraphQL */ `subscription OnDeleteDocumentVersion(
  $documentId: ID
  $id: ID
  $latestVersion: Int
  $lastUpdated: String
  $users: [String]
) {
  onDeleteDocumentVersion(
    documentId: $documentId
    id: $id
    latestVersion: $latestVersion
    lastUpdated: $lastUpdated
    users: $users
  ) {
    documentId
    id
    latestVersion
    users
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    chapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    isProject
    isCountry
    isGlobal
    documentRefId
    refId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDocumentVersionSubscriptionVariables,
  APITypes.OnDeleteDocumentVersionSubscription
>;
export const onCreateChapterObject = /* GraphQL */ `subscription OnCreateChapterObject(
  $documentVersionId: ID
  $id: ID
  $refId: ID
  $parentId: ID
  $sharedId: ID
) {
  onCreateChapterObject(
    documentVersionId: $documentVersionId
    id: $id
    refId: $refId
    parentId: $parentId
    sharedId: $sharedId
  ) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChapterObjectSubscriptionVariables,
  APITypes.OnCreateChapterObjectSubscription
>;
export const onUpdateChapterObject = /* GraphQL */ `subscription OnUpdateChapterObject(
  $documentVersionId: ID
  $id: ID
  $refId: ID
  $parentId: ID
  $sharedId: ID
) {
  onUpdateChapterObject(
    documentVersionId: $documentVersionId
    id: $id
    refId: $refId
    parentId: $parentId
    sharedId: $sharedId
  ) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChapterObjectSubscriptionVariables,
  APITypes.OnUpdateChapterObjectSubscription
>;
export const onDeleteChapterObject = /* GraphQL */ `subscription OnDeleteChapterObject(
  $documentVersionId: ID
  $id: ID
  $refId: ID
  $parentId: ID
  $sharedId: ID
) {
  onDeleteChapterObject(
    documentVersionId: $documentVersionId
    id: $id
    refId: $refId
    parentId: $parentId
    sharedId: $sharedId
  ) {
    documentVersionId
    id
    refId
    parentId
    sharedId
    name
    isGlobal
    isCountry
    isProject
    documentRefId
    subchapters {
      items {
        documentVersionId
        id
        refId
        parentId
        sharedId
        name
        isGlobal
        isCountry
        isProject
        documentRefId
        subchapters {
          items {
            documentVersionId
            id
            refId
            parentId
            sharedId
            name
            isGlobal
            isCountry
            isProject
            documentRefId
            subchapters {
              nextToken
              __typename
            }
            sections {
              id
              isGlobal
              isCountry
              isProject
              isClientEditable
              name
              type
              viewing
              refId
              __typename
            }
            access
            editing {
              email
              name
              surname
              isEditing
              __typename
            }
            epi_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            client_status {
              status
              lastUpdated
              userLastUpdated
              __typename
            }
            treeIndex
            assignedProjectUser
            chapterOrder
            isIncludedInWorkflow
            subchapterCreateAllowed
            __typename
          }
          nextToken
          __typename
        }
        sections {
          id
          content {
            uploadedFiles {
              id
              name
              type
              size
              content
              __typename
            }
            plainText
            __typename
          }
          isGlobal
          isCountry
          isProject
          isClientEditable
          name
          type
          viewing
          refId
          __typename
        }
        access
        editing {
          email
          name
          surname
          isEditing
          __typename
        }
        epi_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        client_status {
          status
          lastUpdated
          userLastUpdated
          comments {
            email
            comment
            lastName
            timeStamp
            firstName
            metadata
            __typename
          }
          __typename
        }
        treeIndex
        assignedProjectUser
        chapterOrder
        isIncludedInWorkflow
        subchapterCreateAllowed
        __typename
      }
      nextToken
      __typename
    }
    sections {
      id
      content {
        uploadedFiles {
          id
          name
          type
          size
          content
          __typename
        }
        plainText
        __typename
      }
      isGlobal
      isCountry
      isProject
      isClientEditable
      name
      type
      viewing
      refId
      __typename
    }
    access
    editing {
      email
      name
      surname
      isEditing
      __typename
    }
    epi_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    client_status {
      status
      lastUpdated
      userLastUpdated
      comments {
        email
        comment
        lastName
        timeStamp
        firstName
        metadata
        __typename
      }
      __typename
    }
    treeIndex
    assignedProjectUser
    chapterOrder
    isIncludedInWorkflow
    subchapterCreateAllowed
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChapterObjectSubscriptionVariables,
  APITypes.OnDeleteChapterObjectSubscription
>;
