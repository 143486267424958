import userSliceReducer from "./slices/authorization"
import usersSliceReducer from "./slices/usersSlice"
import registrationReducer from "./slices/registration"
import projectsReducer from "./slices/projects"
import documentsReducer from "./slices/documents"
import projectRolesReducer from "./slices/projectRoles"
import { combineReducers } from "@reduxjs/toolkit"
import {
  syncApi,
  deleteApi,
  versionsApi,
  documentsApi,
  countriesApi,
  auditTrailApi,
  attachmentsApi,
  chapterObjectsApi,
  documentVersionsApi,
} from "./services"

const RootReducer = combineReducers({
  users: usersSliceReducer,
  projects: projectsReducer,
  documents: documentsReducer,
  authorisation: userSliceReducer,
  registration: registrationReducer,
  projectRoles: projectRolesReducer,
  [syncApi.reducerPath]: syncApi.reducer,
  [deleteApi.reducerPath]: deleteApi.reducer,
  [versionsApi.reducerPath]: versionsApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [auditTrailApi.reducerPath]: auditTrailApi.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [chapterObjectsApi.reducerPath]: chapterObjectsApi.reducer,
  [documentVersionsApi.reducerPath]: documentVersionsApi.reducer,
})

export default RootReducer
