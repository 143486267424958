// ==================== External Imports ==================== //
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Typography,
  Paper,
  Switch,
  MenuItem,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import EditIcon from "@material-ui/icons/Edit"
import SettingsIcon from "@material-ui/icons/Settings"
import DeleteIcon from "@material-ui/icons/Delete"
import React, { useState, useEffect } from "react"
import { TableActionMenu } from "components/TableActionMenu/TableActionMenu"

// ==================== Local Imports ==================== //
import {
  countriesUtil,
  useUpdateDocumentMutation,
  useGetCountriesWithNoProjectIdQuery,
  useBatchUpdateDocumentsMutation,
  useCreateAuditTrailMutation,
} from "redux/services"
import {
  AuditTrailOperations,
  Document,
  Operations,
  PartialAuditTrail,
} from "shared/types-exp"
import useAppState from "hooksV1/useAppState"
import { documentsHeadCells } from "util/constants"
import useDeleteDocumentDialogV1 from "hooksV1/useDeleteDocumentV1"
import {
  createAuditTrailObject,
  invalidateAll,
  sortDocumentsOrder,
} from "util/helper"
import {
  batchUpdateDocuments,
  createAuditTrail,
  updateDocument,
} from "util/batchHook"

type DocumentsTableBodyV1Props = {
  documents: Document[]
  page: number
  rowsPerPage: number
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: "auto",
  },
  tableContainer: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    height: "100%",
  },
  skeleton: {
    width: "100%",
    height: "100%",
  },
}))

const TableHeadings: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        {documentsHeadCells.map((headCell) => {
          return (
            <TableCell
              align="left"
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              <Typography variant="body1" color="primary" component="h1">
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const TableContent: React.FC<DocumentsTableBodyV1Props> = ({
  documents,
  page,
  rowsPerPage,
}) => {
  // ==================== Hooks ==================== //
  const {
    activeCountry,
    setDocuments,
    setChapterObjects,
    setActiveDocument,
    setActiveDocumentVersion,
  } = useAppState()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const deleteDocumentDialog = useDeleteDocumentDialogV1()

  // ==================== Use Hooks ==================== //
  const [menuOpen, setMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentRow, setCurrentRow] = useState<Document>(null)

  const [updateDocumentAPI] = useUpdateDocumentMutation()
  const {
    data: documentsList,
    isLoading: isLoadingDocuments,
    refetch,
  } = useGetCountriesWithNoProjectIdQuery(null, { skip: !currentRow })
  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchDocumentMutationAPI] = useBatchUpdateDocumentsMutation()

  useEffect(() => {
    if (!documentsList || !currentRow || isLoadingDocuments) return

    const updateOtherDocs = async () => {
      const countries = documentsList.data.listCountries.items

      const listOfDocuments = []

      for (const doc of countries) {
        const updateDocument = doc.documents.items.filter(
          (tempDoc) =>
            tempDoc.refId === currentRow.refId && tempDoc.id !== currentRow.id
        )

        if (updateDocument[0]) {
          listOfDocuments.push({
            ...updateDocument[0],
            enabled: currentRow.enabled,
            lastUpdated: new Date().toISOString(),
          })
        }
      }

      if (listOfDocuments.length > 0) {
        await batchUpdateDocuments(batchDocumentMutationAPI, listOfDocuments)
        refetch()
      }

      setCurrentRow(null)
      dispatch(countriesUtil.invalidateTags(["Countries"]))
    }

    updateOtherDocs()
  }, [documentsList])

  useEffect(() => {
    setIsLoading(isLoadingDocuments)
  }, [isLoadingDocuments])

  // ==================== Functions ==================== //
  const openTemplateEditor = (id: string, tab: string) => {
    const country = activeCountry?.country_name ?? "Global"
    const activeDocument = documents?.find((doc) => doc.id === id)

    invalidateAll(dispatch)
    setChapterObjects(null)
    setActiveDocumentVersion(null)
    setActiveDocument(activeDocument)
    navigate(`/template/document/${id}/country/${country}/${tab}`)
  }

  const checkIsBusinessBlueprint = (document: Document) => {
    return document.name.toLocaleLowerCase() === "business blueprint"
  }

  const handleOnCheckedChange = async (document: any) => {
    setIsLoading(true)
    let tempRows = [...documents]
    let rowIndex = tempRows.findIndex((row) => row.id === document.id)

    if (rowIndex !== -1) {
      tempRows[rowIndex] = {
        ...tempRows[rowIndex],
        enabled: !tempRows[rowIndex].enabled,
      }
    }

    const auditTrail: PartialAuditTrail = createAuditTrailObject(
      AuditTrailOperations.UPDATE,
      Operations.DOCUMENT,
      `The document enabled setting for ${document?.name} document, were updated.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      updateDocument(updateDocumentAPI, {
        ...document,
        enabled: !document.enabled,
        lastUpdated: new Date().toISOString(),
      }),
    ])

    setDocuments(tempRows)
    setCurrentRow(tempRows[rowIndex])
    setIsLoading(false)
  }

  const handleDeleteUserClick = async (document: any) => {
    deleteDocumentDialog.setActiveDocument(document)
    deleteDocumentDialog.onOpen()
    setMenuOpen(false)
  }

  const sortedDocuments = sortDocumentsOrder(
    documents?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  )

  return (
    <TableBody>
      {documents.length > 0 &&
        sortedDocuments?.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th">{row.name}</TableCell>
            <TableCell component="th">{row.author}</TableCell>
            <TableCell component="th">{row.lastUpdated}</TableCell>
            <TableCell component="th">
              <Switch
                checked={row.enabled}
                disabled={checkIsBusinessBlueprint(row) || isLoading}
                onChange={() => handleOnCheckedChange(row)}
                name={row.name}
                color="primary"
              />
            </TableCell>
            <TableCell component="th">
              <TableActionMenu
                tooltip="Manage Document"
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
              >
                <Tooltip
                  title="View/Edit Content of Template"
                  placement="right-end"
                >
                  <MenuItem
                    onClick={() => openTemplateEditor(row.id, "content")}
                    disabled={!row.enabled}
                  >
                    <EditIcon /> &nbsp;Edit Content
                  </MenuItem>
                </Tooltip>

                <Tooltip
                  title="Edit Document Workflow, Status and Project Type"
                  placement="right-end"
                >
                  <MenuItem
                    onClick={() => openTemplateEditor(row.id, "settings")}
                    disabled={!row.enabled}
                  >
                    <SettingsIcon /> &nbsp;Edit Settings
                  </MenuItem>
                </Tooltip>

                <Tooltip title="Remove this Template" placement="right-end">
                  <MenuItem
                    onClick={() => handleDeleteUserClick(row)}
                    disabled={!row.enabled || checkIsBusinessBlueprint(row)}
                  >
                    <DeleteIcon /> &nbsp;Delete Document
                  </MenuItem>
                </Tooltip>
              </TableActionMenu>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  )
}

const DocumentsTableBodyV1: React.FC<DocumentsTableBodyV1Props> = ({
  page,
  documents,
  rowsPerPage,
}) => {
  const classes = useStyles()

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="collapsible table" className={classes.table}>
        <TableHeadings />
        <TableContent
          page={page}
          documents={documents}
          rowsPerPage={rowsPerPage}
        />
      </Table>
    </TableContainer>
  )
}

export default DocumentsTableBodyV1
