// ==================== External Imports ==================== //
import React from "react"
import {
  Theme,
  Button,
  ListItem,
  makeStyles,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core"
import * as lookup from "country-code-lookup"
import ReactCountryFlag from "react-country-flag"
import DeleteIcon from "@material-ui/icons/Delete"

// ==================== Local Imports ==================== //
import { Country } from "shared/types-exp"
import useDeleteCountryState from "hooksV1/useDeleteCountryState"
import useDeleteCountryDialogV1 from "hooksV1/useDeleteCountryDialogV1"
import useAppState from "hooksV1/useAppState"

type ManageCountriesListItemProps = {
  country: Country
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: "1em",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  button: {
    backgroundColor: "#f44336",
    color: "white",
    marginLeft: "0.25em",
  },
}))

const ManageCountriesListItem: React.FC<ManageCountriesListItemProps> = ({
  country,
}) => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const { activeCountry } = useAppState()
  const { setCountry } = useDeleteCountryState()
  const useDeleteCountry = useDeleteCountryDialogV1()

  // ==================== Functions ==================== //
  const onDeleteButtonClick = () => {
    setCountry(country)
    useDeleteCountry.onOpen()
  }

  // ==================== Variables ==================== //
  let countryCode = ""

  if (country.country_name !== "Global") {
    countryCode = lookup.byCountry(country.country_name)?.iso2 ?? ""
  }

  return (
    <>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <>
              <ReactCountryFlag countryCode={countryCode} svg />
              &nbsp;{country.country_name}
            </>
          }
        />
        {activeCountry?.global && (
          <ListItemSecondaryAction>
            <Button
              onClick={onDeleteButtonClick}
              variant="contained"
              className={classes.button}
            >
              <DeleteIcon />
              &nbsp;Delete
            </Button>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  )
}

export default ManageCountriesListItem
