import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"
import {
  copyDFSForBlueprint,
  isMasterBlueprintDocument,
} from "redux/thunks/documentsThunk"
import { Country } from "shared/types/country"
import type {
  ChapterObject,
  ChapterSection,
  Content,
  CountrySpecificContent,
  Document,
  DocumentChange,
  DocumentVersion,
} from "shared/types/document"
import { VersionNumber } from "shared/types/document"
import { Status } from "shared/types/status"
import "../../config"
import type { RootState } from "../store"
import { v4 as uuidv4 } from "uuid"
// Define a type for the slice state

type DocumentsState = {
  Documents: Document[]
  addDocumentResponseMessage: string
  addDocumentStatus: Status
  getDocumentResponseMessage: string
  getDocumentStatus: Status
  deleteDocumentResponseMessage: string
  deleteDocumentStatus: Status
  updateDocumentResponseMessage: string
  updateDocumentStatus: Status
  DocumentVersions: DocumentVersion[]
  DocumentByCountryVersions: VersionNumber[]
  DocumentChanges: DocumentChange[]
  DocumentVCID: string
  addDocumentChangeStatus: Status
  addDocumentChangeResponseMessage: string
}

export const createChapterSection = (
  chapterName: string,
  sectionName: string,
  isGlobal: boolean,
  isClientEditable: boolean,
  globalContent: Content,
  countrySpecificContent: Array<Country>
) => {
  const countrySpecificContentArray: Array<CountrySpecificContent> =
    countrySpecificContent.map((country) => {
      // const newGlobalContent: Content = {
      //   rawHTML: `This is ${country.country_name} content!`,
      // };
      const newnonClientContent: Content = {
        rawHTML: `This is ${country.country_name} content!`,
      }
      const newClientContent: Content = {
        rawHTML: `This is ${country.country_name} content!`,
      }

      return {
        country,
        clientContent: newClientContent,
        chapterCountrySpecificName: chapterName,
        nonClientContent: newnonClientContent,
        isChapterActiveInCountry: true,
      }
    })
  const templateObject: ChapterSection = {
    name: sectionName,
    isGlobal,
    isClientEditable,
    chapterGlobalContent: globalContent,
    viewing: false,
    wasChanged: false,
    countrySpecificContent: countrySpecificContentArray,
  }

  return templateObject
}

export const createChapterOrSubchapter = (
  chapterName: string,
  countries: Array<Country>,
  sections: Array<ChapterSection>,
  documentVersion: DocumentChange,
  parentChapter?: ChapterObject
) => {
  const newSections: Array<ChapterSection> = []

  if (sections && sections.length > 0)
    sections.map((section: any) => {
      newSections.push(
        createChapterSection(
          undefined,
          section.name,
          section.isGlobal,
          section.isClientEditable,
          {
            rawHTML: `This is Global content!`,
          },
          JSON.parse(JSON.stringify(countries))
        )
      )
    })

  if (parentChapter) {
    const newObject: ChapterObject = {
      id: Math.floor(Math.random() * Date.now()).toString(),
      refId: undefined,
      sharedId: uuidv4(),
      parentId: parentChapter.id,
      name: chapterName,
      subchapters: [],
      assignedProjectUser: [],
      sections: newSections,
      treeIndex: documentVersion.globalTreeIndexCounter + 1,
      chapterOrder: parentChapter.subchapters.length + 1,
    }

    parentChapter.subchapters.push(newObject)

    return newObject
  }

  const newChapter: ChapterObject = {
    id: Math.floor(Math.random() * Date.now()).toString(),
    name: chapterName,
    subchapters: [],
    sections: newSections,
    assignedProjectUser: [],
    treeIndex: documentVersion.globalTreeIndexCounter + 1,
    chapterOrder: documentVersion.chapters.length + 1,
    parentId: null,
    refId: null,
    sharedId: uuidv4(),
  }

  return newChapter
}

export const createBaseTemplateDoc = (
  newDoc: Document,
  initialVersion: DocumentVersion
) => {
  return {
    id: newDoc.id,
    name: newDoc.name,
    author: newDoc.author,
    activeVersion: newDoc.activeVersion,
    lastUpdated: newDoc.lastUpdated,
    documentVersions: [initialVersion],
  }
}

// Define the initial state using that type
const initialState: DocumentsState = {
  Documents: [],
  addDocumentResponseMessage: "",
  addDocumentStatus: Status.idle,
  addDocumentChangeStatus: Status.idle,
  addDocumentChangeResponseMessage: "",
  getDocumentResponseMessage: "",
  getDocumentStatus: Status.idle,
  deleteDocumentResponseMessage: "",
  deleteDocumentStatus: Status.idle,
  updateDocumentResponseMessage: "",
  updateDocumentStatus: Status.idle,
  DocumentChanges: [],
  DocumentVCID: "",
  DocumentVersions: [],
  DocumentByCountryVersions: [],
}

export const Documents = createSlice({
  name: "Documents",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createDocuments: (state, action: PayloadAction<Document[]>) => {
      if (action.payload) {
        state.Documents = action.payload.map((Document: Document) => Document)
      }
    },

    addDocument: (state, action: PayloadAction<Document>) => {
      if (action.payload.id === "") {
        action.payload.id = Date.now().toString()
      }
      state.Documents.push(action.payload)
    },

    removeDocument: (state, action: PayloadAction<Document>) => {
      state.Documents = state.Documents.filter(
        (Document) => Document.id !== action.payload.id
      )
    },

    setAddDocumentStatus: (state, action: PayloadAction<Status>) => {
      state.addDocumentStatus = action.payload
    },
    setAddDocumentMessage: (state, action: PayloadAction<string>) => {
      state.addDocumentResponseMessage = action.payload
    },
    setDeleteDocumentStatus: (state, action: PayloadAction<Status>) => {
      state.deleteDocumentStatus = action.payload
    },
    setDeleteDocumentMessage: (state, action: PayloadAction<string>) => {
      state.deleteDocumentResponseMessage = action.payload
    },
    setUpdateDocumentStatus: (state, action: PayloadAction<Status>) => {
      state.updateDocumentStatus = action.payload
    },
    setUpdateDocumentMessage: (state, action: PayloadAction<string>) => {
      state.updateDocumentResponseMessage = action.payload
    },
    setGetDocumentStatus: (state, action: PayloadAction<Status>) => {
      state.getDocumentStatus = action.payload
    },
    setGetDocumentMessage: (state, action: PayloadAction<string>) => {
      state.getDocumentResponseMessage = action.payload
    },

    editDocument: (state, action: PayloadAction<Document>) => {
      let localStateDocument: Document = cloneDeep(action.payload)
      let index = state.Documents.findIndex(
        (p) => p.id === localStateDocument.id
      )
      // let masterBlueprintDocument = state.Documents.find((doc) => doc.id === '0')

      if (!isMasterBlueprintDocument(localStateDocument)) {
        //UPDATE DOCUMENT IN REDUX

        let latestVersionLocalStateDocument =
          localStateDocument.documentVersions[
            localStateDocument.documentVersions.length - 1
          ]
        let latestChangeLocalStateDoccumentVersion: DocumentChange = cloneDeep(
          latestVersionLocalStateDocument.changes[
            latestVersionLocalStateDocument.changes.length - 1
          ]
        )
        //UPDATE STRUCTURE WITH NEW SECTIONS ON ALL CHAPTERS
        copyDFSForBlueprint(
          latestChangeLocalStateDoccumentVersion,
          latestChangeLocalStateDoccumentVersion
        )

        // delete latestChangeLocalStateDoccumentVersion.id
        if (latestChangeLocalStateDoccumentVersion.id !== null) {
          latestVersionLocalStateDocument.changes.push(
            latestChangeLocalStateDoccumentVersion
          )
        }

        // latestVersionLocalStateDocument.changes.push(latestChangeLocalStateDoccumentVersion)
        index === -1
          ? { ...state }
          : state.Documents.splice(index, 1, localStateDocument)
      } else {
        //UPDATE DOCUMENT IN REDUX

        //COPY MASTER BLUEPRINT STRUCTURE CHANGES TO ALL OTHER DOCUMENTS

        let latestVersionLocalStateDocument: DocumentVersion =
          localStateDocument.documentVersions[
            localStateDocument.documentVersions.length - 1
          ]

        let latestChangeLocalStateDoccumentVersion: DocumentChange = cloneDeep(
          latestVersionLocalStateDocument.changes[
            latestVersionLocalStateDocument.changes.length - 1
          ]
        )

        copyDFSForBlueprint(
          latestChangeLocalStateDoccumentVersion,
          latestChangeLocalStateDoccumentVersion
        )

        // delete latestChangeLocalStateDoccumentVersion.id
        if (latestChangeLocalStateDoccumentVersion.id !== null) {
          latestVersionLocalStateDocument.changes.push(
            latestChangeLocalStateDoccumentVersion
          )
        }

        // latestVersionLocalStateDocument.changes.push(latestChangeLocalStateDoccumentVersion)

        state.Documents.filter(
          (document) => !isMasterBlueprintDocument(document)
        ).map((doc: Document) => {
          //CLEAR CHANGES MADE TO SECTIONS BY COPYDFS
          let latestVersionDoc =
            doc.documentVersions[doc.documentVersions.length - 1]
          let latestVersionChange = cloneDeep(
            latestVersionDoc.changes[latestVersionDoc.changes.length - 1]
          )
          copyDFSForBlueprint(
            latestChangeLocalStateDoccumentVersion,
            latestVersionChange
          )
          // delete latestVersionChange.id
          latestVersionChange.id = null
          latestVersionDoc.changes.push(latestVersionChange)
        })
        index === -1
          ? { ...state }
          : state.Documents.splice(index, 1, localStateDocument)
      }
    },
    editContentOfSpecificDocument(state, action: PayloadAction<Document>) {
      let index = state.Documents.findIndex((p) => p.id === action.payload.id)

      index === -1
        ? { ...state }
        : state.Documents.splice(index, 1, action.payload)
    },
    setActiveDocument: (state, action: PayloadAction<string>) => {
      let index = state.Documents.findIndex((p) => p.id === action.payload)

      if (index !== -1) {
        // let doc = state.Documents[index];
      }
    },
    editDocumentV2: (state, action: PayloadAction<Document[]>) => {
      state.Documents = action.payload
    },
    setDocumentVersions: (state, action: PayloadAction<DocumentVersion[]>) => {
      state.DocumentVersions = action.payload
    },
    setDocumentByCountryVersions: (
      state,
      action: PayloadAction<VersionNumber>
    ) => {
      let versionByCountry = action.payload
      let tempVersionByCountries = JSON.parse(
        JSON.stringify(state.DocumentByCountryVersions)
      )

      if (
        tempVersionByCountries.find(
          (tempVersion) =>
            tempVersion.country.country_name ===
              versionByCountry.country.country_name &&
            tempVersion.id === versionByCountry.id
        ) === undefined
      ) {
        tempVersionByCountries.push(versionByCountry)
      } else {
        const objIndex = tempVersionByCountries.findIndex(
          (tempVersion) =>
            tempVersion.country.country_name ===
              versionByCountry.country.country_name &&
            tempVersion.id === versionByCountry.id
        )

        tempVersionByCountries[objIndex].version = versionByCountry.version
      }

      state.DocumentByCountryVersions = tempVersionByCountries
    },
    setDocumentChanges: (state, action: PayloadAction<DocumentChange[]>) => {
      state.DocumentChanges = action.payload
    },
    setDocumentVCID: (state, action: PayloadAction<string>) => {
      state.DocumentVCID = action.payload
    },
    editChangesOnVersion: (
      state,
      action: PayloadAction<{ versionId; changes }>
    ) => {
      let versionIndex = state.DocumentVersions.findIndex(
        (p) => p.id === action.payload.versionId
      )
      state.DocumentVersions[versionIndex].changes = action.payload.changes
    },
    setAddDocumentChangeStatus: (state, action: PayloadAction<Status>) => {
      state.addDocumentChangeStatus = action.payload
    },
    setAddDocumentChangeMessage: (state, action: PayloadAction<string>) => {
      state.addDocumentChangeResponseMessage = action.payload
    },
    editChangeOnVersion: (
      state,
      action: PayloadAction<{ versionId; change }>
    ) => {
      let versionIndex = state.DocumentVersions.findIndex(
        (p) => p.id === action.payload.versionId
      )
      let changeIndex = state.DocumentVersions[versionIndex].changes.findIndex(
        (change) => (change as unknown as string) === action.payload.change.id
      )
      state.DocumentVersions[versionIndex].changes[changeIndex] =
        action.payload.change
    },
    fetchSpecificDocument: (state, action: PayloadAction<Document>) => {
      let docIndex = state.Documents.findIndex(
        (p) => p.id === action.payload.id
      )
      state.Documents[docIndex] = action.payload
    },
    fetchSpecficDocuments: (state, action: PayloadAction<Document[]>) => {
      const existingState = JSON.parse(JSON.stringify(state))
      action.payload.map((doc) => {
        let docIndex = existingState.Documents.findIndex((p) => p.id === doc.id)
        existingState.Documents[docIndex] = doc
      })
      state = existingState
    },
  },
})

export const {
  createDocuments,
  removeDocument,
  editDocument,
  addDocument,
  setActiveDocument,
  editContentOfSpecificDocument,
  editDocumentV2,
  setAddDocumentMessage,
  setAddDocumentStatus,
  setDeleteDocumentMessage,
  setDeleteDocumentStatus,
  setUpdateDocumentMessage,
  setUpdateDocumentStatus,
  setGetDocumentMessage,
  setGetDocumentStatus,
  setDocumentChanges,
  setDocumentVCID,
  setDocumentVersions,
  setDocumentByCountryVersions,
  editChangeOnVersion,
  editChangesOnVersion,
  fetchSpecificDocument,
  fetchSpecficDocuments,
  setAddDocumentChangeStatus,
  setAddDocumentChangeMessage,
} = Documents.actions

// Other code such as selectors can use the imported `RootState` type
export const getDocuments = (state: RootState) => state.documents.Documents

export const getDocumentVersions = (state: RootState) => {
  let documentVersions: DocumentVersion[][] = []
  state.documents.Documents.forEach((doc) => {
    if (typeof doc.documentVersions[0] !== "string") {
      documentVersions.push(doc.documentVersions)
    }
  })

  return documentVersions
}

export default Documents.reducer
