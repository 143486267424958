import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core"
import { cloneDeep } from "lodash"
import { useDispatch } from "react-redux"
import React, { useState, useEffect, useRef } from "react"

import {
  Operations,
  ChapterObject,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  useCreateAuditTrailMutation,
  useBatchUpdateChapterObjectsMutation,
} from "redux/services"
import {
  invalidateAll,
  transformArray,
  createAuditTrailObject,
} from "util/helper"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { generateClient } from "aws-amplify/api"
import { checkEnvironment } from "util/environment"
import useReOrderDialogV1 from "hooksV1/useReOrderDialogV1"
import { listChaptersByProjectRefId } from "graphql/queries"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import ChapterList from "components/ReOrderChapters/ChapterList"
import { createAuditTrail, batchUpdateChapters } from "util/batchHook"

const client = generateClient()

type ReOrderDialogV1Props = {
  chaptersProp: ChapterObject[]
}

const ReOrderDialogV1: React.FC<ReOrderDialogV1Props> = ({ chaptersProp }) => {
  const {
    activeCountry,
    activeDocument,
    setChapterObjects,
    chapterObjectsList,
    activeChapterObject,
    setChapterObjectsList,
  } = useAppState()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const reOrderDialog = useReOrderDialogV1()

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()

  const fetchInitiated = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [chaptersIdMap, setChaptersIdMap] = useState<
    Map<string, ChapterObject>
  >(new Map())
  const [localChaptersIdMap, setLocalChaptersIdMap] = useState<
    Map<string, ChapterObject>
  >(new Map())

  const [chaptersData, setChaptersData] =
    useState<Map<string, ChapterObject>[]>()

  useEffect(() => {
    if (fetchInitiated.current) return

    const fetchListOfCountries = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: listChaptersByProjectRefId,
          variables: {
            filter: {
              projectRefId: {
                eq: "",
              },
            },
          },
        })

        const countriesData = result?.data?.listCountries?.items

        const docs = []
        countriesData.forEach((country) => {
          const result = country.documents.items.filter(
            (document) => document.refId === activeDocument?.refId
          )
          docs.push(...result)
        })

        const extractedChapters: Map<string, ChapterObject>[] = []

        docs.forEach((doc) => {
          const chapterMap = new Map<string, ChapterObject>()
          const version = doc.documentVersions.items[0]

          version.chapters.items
            .filter((chapter) => !chapter.isProject && !chapter.isCountry)
            .forEach((chapter) => {
              chapterMap.set(chapter.refId, {
                ...chapter,
              })
            })

          extractedChapters.push(chapterMap)
        })

        setChaptersData(extractedChapters)
      } catch (error) {
        logger("ReOrderDialogV1", "useEffect (fetchListOfCountries)", error)

        snackBar.setMessage(
          "An error occurred fetching the list of countries. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    if (activeCountry?.global) fetchListOfCountries()
  }, [])

  useEffect(() => {
    if (!chapterObjectsList) return

    const chapterMap: Map<string, ChapterObject> = new Map(
      chapterObjectsList.map((chapter) => [chapter.id, chapter])
    )

    if (!activeCountry?.global) setLocalChaptersIdMap(cloneDeep(chapterMap))

    setChaptersIdMap(cloneDeep(chapterMap))
  }, [chapterObjectsList])

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSubmit()
    }
  }

  const handleClose = () => {
    setIsLoading(false)

    reOrderDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleGlobalReOrder = (): ChapterObject[] => {
    const updatedChapters: ChapterObject[] = []

    chaptersData.forEach((chapterMap) => {
      chaptersIdMap.forEach((chapter) => {
        const refId = chapter.refId
        const chapterData = chapterMap.get(refId)

        if (chapterData && chapterData.chapterOrder !== chapter.chapterOrder) {
          const updatedChapter: ChapterObject = cloneDeep({
            ...chapterData,
            chapterOrder: chapter.chapterOrder,
            treeIndex: chapter.chapterOrder,
          })

          delete updatedChapter.subchapters

          updatedChapters.push(updatedChapter)
        }
      })
    })

    return updatedChapters
  }

  const handleLocalReOrder = (): ChapterObject[] => {
    const filteredArrayForUpdate: ChapterObject[] = Array.from(chaptersIdMap)
      .filter(
        ([_, value]) =>
          (value.isCountry || value.isProject) &&
          localChaptersIdMap.get(value.id).chapterOrder !== value.chapterOrder
      )
      .map(([_, chapter]) => {
        const updatedChapter: ChapterObject = {
          ...chapter,
          chapterOrder: chapter.chapterOrder,
          treeIndex: chapter.chapterOrder,
        }

        delete updatedChapter.subchapters

        return updatedChapter
      })

    return filteredArrayForUpdate
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      let chapterObjectsToUpdate: ChapterObject[] = []

      if (activeCountry?.global) chapterObjectsToUpdate = handleGlobalReOrder()
      else chapterObjectsToUpdate = handleLocalReOrder()

      const auditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.CHAPTER,
        `The chapter order for ${activeChapterObject?.name}, in the document ${activeDocument?.name}, in ${activeCountry?.country_name}, in the ${environment} environment`
      )

      await Promise.all([
        createAuditTrail(createAuditTrailAPI, auditTrail),
        batchUpdateChapters(
          batchUpdateChapterObjectsAPI,
          chapterObjectsToUpdate
        ),
      ])

      const newChapters = Array.from(chaptersIdMap.values()).map((value) =>
        cloneDeep(value)
      )

      const newChapterObjects = transformArray(newChapters)

      setChapterObjectsList(newChapters)
      setChapterObjects(newChapterObjects)

      invalidateAll(dispatch)

      snackBar.setMessage("Chapters successfully re-ordered.")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("ReOrderDialogV1", "handleSubmit", error)

      snackBar.setMessage(
        "An error occurred whilst reordering chapters. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  return (
    <Dialog
      open={reOrderDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Re-Order Chapters</DialogTitle>
      <DialogContent
        dividers
        style={{
          maxHeight: "70vh",
          overflow: "auto",
        }}
      >
        <ChapterList
          chaptersProp={chaptersProp}
          chaptersIdMap={chaptersIdMap}
          setChaptersIdMap={setChaptersIdMap}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          disabled={isLoading}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default ReOrderDialogV1
