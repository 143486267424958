import React from "react"
import { CSS } from "@dnd-kit/utilities"
import { useDraggable } from "@dnd-kit/core"
import { useSortable } from "@dnd-kit/sortable"
import GlobalIcon from "@material-ui/icons/Language"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Theme, Typography, makeStyles } from "@material-ui/core"

import ChapterList from "./ChapterList"
import { isValidCursor } from "util/checker"
import { ChapterObject } from "shared/types-exp"
import { checkEnvironment } from "util/environment"
import useAppState from "hooksV1/useAppState"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "70%",
    height: 50,
    cursor: "grab",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    transition: "background-color 0.2s ease",
  },
  dragging: {
    backgroundColor: "#f0f0f0",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
}))

type ChapterItemProps = {
  chapter: ChapterObject
  chaptersIdMap: Map<string, ChapterObject>
  setChaptersIdMap: React.Dispatch<
    React.SetStateAction<Map<string, ChapterObject>>
  >
}

const ChapterItem: React.FC<ChapterItemProps> = ({
  chapter,
  chaptersIdMap,
  setChaptersIdMap,
}) => {
  const classes = useStyles()
  const { activeCountry } = useAppState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const isDisabled = () => {
    if (isTemplateEnvironment && !activeCountry?.global && !chapter.isCountry)
      return true

    if (isProjectEnvironment && !chapter.isProject) return true

    return
  }

  const isDraggingDisabled = isDisabled()

  const {
    attributes: draggableAttributes,
    listeners: draggableListeners,
    setNodeRef: setDraggableNodeRef,
    transform: draggableTransform,
    isDragging: isDraggableDragging,
  } = useDraggable({
    id: chapter.id,
    disabled: isDisabled(),
  })

  const {
    attributes: sortableAttributes,
    listeners: sortableListeners,
    setNodeRef: setSortableNodeRef,
    transform: sortableTransform,
    transition: sortableTransition,
    isDragging: isSortableDragging,
  } = useSortable({
    id: chapter.id,
  })

  // Use draggable if disabled is true, otherwise use sortable
  const attributes = isDraggingDisabled
    ? draggableAttributes
    : sortableAttributes
  const listeners = isDraggingDisabled ? draggableListeners : sortableListeners
  const setNodeRef = isDraggingDisabled
    ? setDraggableNodeRef
    : setSortableNodeRef
  const transform = isDraggingDisabled ? draggableTransform : sortableTransform
  const transition = sortableTransition
  const isDragging = isDraggingDisabled
    ? isDraggableDragging
    : isSortableDragging

  const isGlobalChapter = (chapter: ChapterObject): boolean => {
    if (isTemplateEnvironment && !chapter.isCountry) return true

    if (isProjectEnvironment && chapter.isProject) return true

    return false
  }

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  const cursorStyle = "grab"

  if (!isValidCursor(cursorStyle)) {
    console.error(`Invalid cursor property: ${cursorStyle}`)
  }

  return (
    <div
      style={style}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      className={`${isDragging ? classes.dragging : ""}`}
    >
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <DragIndicatorIcon />
          <Typography variant="body1">{chapter.name}</Typography>
        </div>

        {isGlobalChapter(chapter) && (
          <GlobalIcon
            style={{
              color: "#001744",
              width: "20px",
              marginRight: "5px",
            }}
          />
        )}
      </div>
      {chapter.subchapters?.length > 0 && (
        <div className={classes.nested}>
          <ChapterList
            chaptersProp={chapter.subchapters}
            chaptersIdMap={chaptersIdMap}
            setChaptersIdMap={setChaptersIdMap}
          />
        </div>
      )}
    </div>
  )
}

export default ChapterItem
