import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import { z } from "zod"
import { cloneDeep } from "lodash"
import { useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"
import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import { toFormikValidationSchema } from "zod-formik-adapter"

import {
  Operations,
  ChapterObject,
  DocumentVersion,
  AuditTrailOperations,
} from "shared/types-exp"
import {
  findObjectById,
  transformArray,
  findObjectByRefId,
  updateChapterOrders,
  newChapterSectionsMap,
  createAuditTrailObject,
  invalidateAll,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useCreateChapterObjectMutation,
  useBatchUpdateChapterObjectsMutation,
  useListChaptersByProjectRefIdQuery,
} from "redux/services"
import {
  checkIfError,
  createAuditTrail,
  batchUpdateChapters,
} from "util/batchHook"
import useAppState from "hooksV1/useAppState"
import Loader from "../components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"

import { logger } from "util/logger"
import { defaultAccess } from "util/constants"
import useAddSubChapterDialogV1 from "../hooksV1/useAddSubChapterDialogV1"

const AddSubChapterDialogV1: React.FC = () => {
  const {
    activeCountry,
    activeDocument,
    chapterObjects,
    setChapterObjects,
    activeChapterObject,
    activeDocumentVersion,
    setActiveChapterObject,
  } = useAppState()

  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const { isProjectEnvironment } = checkEnvironment()
  const addSubChapterDialog = useAddSubChapterDialogV1()

  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [createChapterObjectAPI] = useCreateChapterObjectMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()

  const { data: countriesList, refetch } = useListChaptersByProjectRefIdQuery(
    null,
    {
      skip: !activeCountry?.global,
    }
  )

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (countriesList) refetch()
  }, [])

  const chapterNameExistsOnLevel = (name) => {
    const currentChapterObject = findObjectById(
      chapterObjects,
      activeChapterObject.id
    )

    if (
      !currentChapterObject.subchapters ||
      currentChapterObject.subchapters.length === 0
    )
      return false

    return currentChapterObject.subchapters.some(
      (subchapter) => subchapter.name === name
    )
  }

  const addSubChapterDialogSchema = z.object({
    subChapterName: z
      .string()
      .min(1, { message: "Sub chapter cannot be empty" })
      .refine((value) => !chapterNameExistsOnLevel(value), {
        message: "Chapter name already exists on this level",
      }),
  })

  const formik = useFormik({
    initialValues: {
      subChapterName: "",
    },
    validationSchema: toFormikValidationSchema(addSubChapterDialogSchema),
    onSubmit: async () => handleSubChapterAdd(),
  })

  const handleSubChapterAdd = async () => {
    try {
      setIsLoading(true)

      if (activeCountry?.global) {
        await handleGlobalSubChapterAdd()
      } else {
        await handleNonGlobalSubChapterAdd()
      }

      invalidateAll(dispatch)
      snackBar.setMessage("Subchapter Created Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()
      handleClose()
    } catch (error) {
      logger("AddSubChapterDialog", "useFormik", error)
      snackBar.setMessage("Subchapter Creation Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    } finally {
      setIsLoading(false)
    }
  }

  const handleNonGlobalSubChapterAdd = async () => {
    const clonedChapterObjects = cloneDeep(chapterObjects)

    const parentChapterObject = findObjectById(
      clonedChapterObjects,
      activeChapterObject.id
    )

    if (!parentChapterObject) return

    const subchapters = parentChapterObject.subchapters.sort(
      (a, b) => b.chapterOrder - a.chapterOrder
    )
    const [lastChapterObject] = subchapters

    const treeIndex = lastChapterObject ? lastChapterObject.treeIndex + 1 : 0
    const chapterOrder = lastChapterObject
      ? lastChapterObject.chapterOrder + 1
      : 0

    const newChapter: Partial<ChapterObject> = {
      documentVersionId: activeDocumentVersion.id,
      refId: uuidv4(),
      isProject: isProjectEnvironment,
      documentRefId: activeDocument.refId,
      isGlobal: false,
      isCountry: true,
      parentId: parentChapterObject.id,
      sharedId: uuidv4(),
      name: formik.values.subChapterName,
      editing: {
        email: "",
        name: "",
        surname: "",
        isEditing: false,
      },
      epi_status: {
        status: activeDocument?.isIncludedInWorkflow ? "To be started" : "",
        lastUpdated: new Date().toISOString(),
        userLastUpdated: "",
        comments: [],
      },
      client_status: {
        status: activeDocument?.isIncludedInWorkflow ? "To be started" : "",
        lastUpdated: new Date().toISOString(),
        userLastUpdated: "",
        comments: [],
      },
      sections: newChapterSectionsMap(activeDocumentVersion.sections),
      access:
        Array.isArray(activeDocumentVersion?.access) &&
        activeDocumentVersion.access.length > 0
          ? activeDocumentVersion.access
          : Array.isArray(defaultAccess)
          ? defaultAccess
          : [],
      treeIndex,
      chapterOrder,
      assignedProjectUser: "",
      isIncludedInWorkflow: activeDocument?.isIncludedInWorkflow || false,
      subchapterCreateAllowed: true,
    }

    const response: any = await createChapterObjectAPI(newChapter)

    checkIfError(response, newChapter, "[Create Chapter]")

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.CREATE,
      Operations.SUBCHAPTER,
      `A sub-chapter with the name ${formik.values.subChapterName}, was created under the ${parentChapterObject?.name}, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await createAuditTrail(createAuditTrailAPI, auditTrail)

    const createdChapterObject = response.data.data.createChapterObject
    const createdChapterObjectWithSubchapters = {
      ...createdChapterObject,
      subchapters: [],
    }

    parentChapterObject.subchapters.push(createdChapterObjectWithSubchapters)

    setChapterObjects(clonedChapterObjects)
    setActiveChapterObject(createdChapterObjectWithSubchapters)
  }

  const handleGlobalSubChapterAdd = async () => {
    if (!countriesList) return

    let newActiveChapter = null
    const refId = uuidv4()
    let parentChapterObject: ChapterObject = null
    const chaptersToUpdate = []

    let newListOfChapters = []

    const countries = countriesList.data.listCountries.items

    for (const country of countries) {
      const documents = country.documents.items

      for (const doc of documents) {
        if (doc.refId !== activeDocument.refId) continue

        const activeVersion = doc.documentVersions.items[0]
        const chapters = activeVersion.chapters.items as ChapterObject[]
        const transformedChapters = transformArray(chapters)

        parentChapterObject = findObjectByRefId(
          transformedChapters,
          activeChapterObject.refId
        )

        if (!parentChapterObject) continue

        let newChapter: Partial<ChapterObject> = null
        const subchapters = parentChapterObject.subchapters

        if (activeDocument.id === doc.id) {
          const lastChapterObject = subchapters[subchapters.length - 1]
          const treeIndex = lastChapterObject
            ? lastChapterObject.treeIndex + 1
            : 0
          const chapterOrder = lastChapterObject
            ? lastChapterObject.chapterOrder + 1
            : 0

          newChapter = createNewSubChapterObject(
            activeVersion,
            formik.values.subChapterName,
            refId,
            parentChapterObject.id,
            treeIndex,
            chapterOrder
          )
        } else {
          const { newTreeIndex, newChapterOrder, updatedChapters } =
            updateChapterOrders(subchapters)
          chaptersToUpdate.push(...updatedChapters)
          newChapter = createNewSubChapterObject(
            activeVersion,
            formik.values.subChapterName,
            refId,
            parentChapterObject.id,
            newTreeIndex,
            newChapterOrder
          )
        }

        const response: any = await createChapterObjectAPI(newChapter)

        checkIfError(response, newChapter, "[Create Chapter]")

        if (activeDocument.id === doc.id) {
          newActiveChapter = response.data.data.createChapterObject
          parentChapterObject.subchapters.push(newActiveChapter)

          newListOfChapters = transformedChapters
        }
      }
    }

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.CREATE,
      Operations.SUBCHAPTER,
      `A sub-chapter with the name ${formik.values.subChapterName}, was created under the ${parentChapterObject?.name}, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
    ])

    const createdChapterObjectWithSubchapters = {
      ...newActiveChapter,
      subchapters: [],
    }

    setChapterObjects(newListOfChapters)
    setActiveChapterObject(createdChapterObjectWithSubchapters)
  }

  const createNewSubChapterObject = (
    activeVersion: DocumentVersion,
    subChapterName: string,
    refId: string,
    parentId: string,
    newTreeIndex: number,
    newChapterOrder: number
  ): Partial<ChapterObject> => {
    return {
      documentVersionId: activeVersion.id,
      refId,
      parentId,
      documentRefId: activeDocument.refId,
      isCountry: !activeCountry.global,
      isGlobal: activeCountry.global,
      isProject: isProjectEnvironment,
      sharedId: uuidv4(),
      name: subChapterName,
      access:
        Array.isArray(activeDocumentVersion?.access) &&
        activeDocumentVersion.access.length > 0
          ? activeDocumentVersion.access
          : defaultAccess || [],
      epi_status: {
        status: "",
        lastUpdated: "",
        userLastUpdated: "",
        comments: [],
      },
      client_status: {
        status: "",
        lastUpdated: "",
        userLastUpdated: "",
        comments: [],
      },
      editing: {
        email: "",
        name: "",
        surname: "",
        isEditing: false,
      },
      treeIndex: newTreeIndex,
      assignedProjectUser: "",
      subchapterCreateAllowed: true,
      chapterOrder: newChapterOrder,
      sections: newChapterSectionsMap(activeVersion.sections),
      isIncludedInWorkflow: activeDocument?.isIncludedInWorkflow || false,
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit()
    }
  }

  const handleClose = () => {
    formik.resetForm()
    addSubChapterDialog.onClose()
    setIsLoading(false)
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  return (
    <Dialog
      open={addSubChapterDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="xs"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>
        Add Subchapters to <b>{activeChapterObject?.name}</b>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          id="name"
          label="Chapter Name"
          name="subChapterName"
          type="text"
          fullWidth
          value={formik.values.subChapterName}
          error={
            formik.touched.subChapterName &&
            Boolean(formik.errors.subChapterName)
          }
          helperText={
            formik.touched.subChapterName && formik.errors.subChapterName
          }
          onChange={formik.handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={(e) => formik.handleSubmit()}
        >
          Add
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default AddSubChapterDialogV1
