import { IconButton, InputAdornment } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles, Theme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import * as EmailValidator from "email-validator"
import image from "media/logo.svg"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setResetPasswordStatus } from "redux/slices/authorization"
import { RootState } from "redux/store"
import { resetPassword } from "redux/thunks/authorizationThunk"
import Loader from "components/Loading/Loader"
import { Status } from "shared/types/status"
import styled from "styled-components"

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 a100%",
  },
  mainDiv: {
    display: "flex",
    flex: 1,
    padding: "2em",
  },
  paperContainer: {
    display: "flex",
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: "center",
  },
  centeredFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: theme.spacing(3, 0),
  },
  formRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: theme.spacing(3),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    "& > *": {
      paddingLeft: "0 !important",
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: "none !important",
    color: theme.palette.primary.main,
  },
}))

const PasswordReset: React.FC = () => {
  const [emailInput, setEmailInput] = React.useState<string>("")
  const [passwordInput, setPasswordInput] = React.useState<string>("")
  const [confirmPasswordInput, setConfirmPasswordInput] =
    React.useState<string>("")
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    React.useState<boolean>(false)
  const [passwordValidation, setPasswordValidation] =
    React.useState<boolean>(false)
  const [emailValidation, setEmailValidation] = React.useState<boolean>(false)
  const [emailHelperText, setEmailHelperText] = React.useState<string>("")
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
    React.useState<string>("")
  const [passwordHelperText, setPasswordHelperText] = React.useState<string>("")

  const dispatch = useDispatch()
  // const errorMessage = useSelector(
  //   (state: RootState) => state.authorisation.resetPasswordResponseErrorMessage
  // );
  const theResetPasswordStatus = useSelector(
    (state: RootState) => state.authorisation.resetPasswordStatus
  )

  React.useEffect(() => {
    if (theResetPasswordStatus === Status.success) {
      navigate("/signin")
    }
  }, [theResetPasswordStatus])

  const sendDetailsToServer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let formValidation = true
    setEmailHelperText("")
    setPasswordHelperText("")
    setConfirmPasswordHelperText("")

    if (emailInput === "") {
      setEmailHelperText("Email can not be empty")
      setEmailValidation(true)
      formValidation = false
    } else {
      if (EmailValidator.validate(emailInput)) {
        setEmailValidation(false)
      } else {
        setEmailHelperText("Invalid email")
        setEmailValidation(true)
        formValidation = false
      }
    }

    if (passwordInput === "") {
      //log error
      setPasswordHelperText("Password can not be empty")
      setPasswordValidation(true)
      formValidation = false
    }

    if (confirmPasswordInput === "") {
      setConfirmPasswordHelperText("Password can not be empty")
      setConfirmPasswordValidation(true)
      formValidation = false
    } else {
      if (passwordInput !== confirmPasswordInput) {
        setConfirmPasswordHelperText("Passwords do not match")
        setConfirmPasswordValidation(true)
        formValidation = false
      }
    }

    //do post with axios
    // wait for response
    // if success store in redux
    //if fail show error pop up
    if (formValidation) {
      const passwordResetThunk = resetPassword(emailInput, passwordInput)
      dispatch(passwordResetThunk)
      dispatch({ type: setResetPasswordStatus.type, payload: Status.loading })
      /* navigate('/signin') */
    }
  }

  const classes = useStyles()
  let navigate = useNavigate()

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="xs" component="main">
              <CssBaseline />
              <div className={classes.centeredFlex}>
                <div className={classes.logoContainer}>
                  <LogoImg src={image} alt="" />
                </div>
                <Typography
                  style={{ textAlign: "center" }}
                  component="h1"
                  variant="h5"
                  color="textPrimary"
                >
                  Enter your new password
                </Typography>
                <form
                  noValidate
                  className={classes.form}
                  onSubmit={sendDetailsToServer}
                >
                  <div className={classes.formFields}>
                    <TextField
                      autoComplete="email"
                      id="email"
                      label="Email address"
                      margin="normal"
                      name="email"
                      variant="outlined"
                      autoFocus
                      fullWidth
                      required
                      error={emailValidation}
                      helperText={emailHelperText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setEmailInput(event.target.value)
                      }
                    />
                  </div>
                  <div className={classes.formFields}>
                    <TextField
                      required
                      id="password"
                      label="Password"
                      name="password"
                      autoComplete="current-password"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      type={passwordShown ? "text" : "password"}
                      error={passwordValidation}
                      helperText={passwordHelperText}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setPasswordShown(!passwordShown)}
                            >
                              {passwordShown ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setPasswordInput(event.target.value)
                      }
                    />
                  </div>

                  <div className={classes.formFields}>
                    <TextField
                      required
                      id="confirmPassword"
                      label="Confirm Password"
                      name="password"
                      autoComplete="current-password"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      type={confirmPasswordShown ? "text" : "password"}
                      error={confirmPasswordValidation}
                      helperText={confirmPasswordHelperText}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setConfirmPasswordShown(!confirmPasswordShown)
                              }
                            >
                              {confirmPasswordShown ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setConfirmPasswordInput(event.target.value)
                      }
                    />
                  </div>

                  <div className={classes.formFields}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      // onClick={() => {
                      //     navigate('/home')
                      // }}
                    >
                      Reset Password
                    </Button>
                  </div>
                </form>
              </div>
              <Loader open={theResetPasswordStatus === Status.loading} />
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default PasswordReset

const LogoImg = styled.img<{ src: string; alt: string }>`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`
