import { create } from "zustand"

type BooleanState = {
  showDisabled: boolean
  isEditingBlocked: boolean
  isChaptersLoading: boolean
  isDocumentsLoading: boolean
  isCountriesLoading: boolean
  isDocumentVersionLoading: boolean
}

type BooleanActions = {
  setShowDisabled: (showDisabled: boolean) => void
  setIsEditingBlocked: (isEditingBlocked: boolean) => void
  setIsChaptersLoading: (isChaptersLoading: boolean) => void
  setIsDocumentsLoading: (isDocumentsLoading: boolean) => void
  setIsCountriesLoading: (isCountriesLoading: boolean) => void
  setIsDocumentVersionLoading: (isDocumentVersionLoading: boolean) => void
}

const useBooleanState = create<BooleanState & BooleanActions>((set) => ({
  showDisabled: true,
  isEditingBlocked: false,
  isChaptersLoading: false,
  isDocumentsLoading: false,
  isCountriesLoading: false,
  isDocumentVersionLoading: false,
  setShowDisabled: (showDisabled) => set(() => ({ showDisabled })),
  setIsEditingBlocked: (isEditingBlocked) => set(() => ({ isEditingBlocked })),
  setIsChaptersLoading: (isChaptersLoading) =>
    set(() => ({ isChaptersLoading })),
  setIsDocumentVersionLoading: (isDocumentVersionLoading) =>
    set(() => ({ isDocumentVersionLoading })),
  setIsDocumentsLoading: (isDocumentsLoading) =>
    set(() => ({ isDocumentsLoading })),
  setIsCountriesLoading: (isCountriesLoading) =>
    set(() => ({ isCountriesLoading })),
}))

export default useBooleanState
