import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import {
  ListItem,
  ListItemText,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { Skeleton } from "@mui/material"
import { makeStyles, Theme } from "@material-ui/core/styles"

import {
  useCreateAuditTrailMutation,
  useSyncDocumentOperationMutation,
} from "redux/services"

import {
  Country,
  Document,
  Operations,
  PartialAuditTrail,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import useAppState from "hooksV1/useAppState"
import Loader from "components/Loading/Loader"
import { generateClient } from "aws-amplify/api"
import { createAuditTrail } from "util/batchHook"
import { SyncDocumentsModel } from "shared/types-exp/sync"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import { createAuditTrailObject, invalidateAll } from "util/helper"
import useSyncTemplateDocDialogV1 from "hooksV1/useSyncTemplateDocDialogV1"
import { listCountriesAndDocuments } from "graphql/queries"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "30vw",
    maxHeight: "60vh",
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  listItem: {
    padding: "1em",
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  },
  listItemText: {
    display: "inline-block",
  },
  typographyInline: {
    display: "inline",
  },
}))

const client = generateClient()

const SyncNewTemplateDocsDialogV1: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackBar = useSnackBar()
  const syncDocDialog = useSyncTemplateDocDialogV1()
  const { activeCountry, activeProject } = useAppState()

  const pathArray = location.pathname.split("/")
  const projectId = pathArray[2]

  const [syncDocumentsModel, setSyncDocumentsModel] =
    useState<SyncDocumentsModel | null>(null)

  // =============== Country Sync =============

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [syncDocumentsApi] = useSyncDocumentOperationMutation()

  const fetchInitiated = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [documentsToAdd, setDocumentsToAdd] = useState<Document[]>([])
  const [templateCountry, setTemplateCountry] = useState<Country>(null)
  const [projectCountry, setProjectCountry] = useState<Country>(null)
  const [documentsToUpdate, setDocumentsToUpdate] = useState<Document[]>([])
  const [documentsToDelete, setDocumentsToDelete] = useState<Document[]>([])

  useEffect(() => {
    if (!projectCountry || !templateCountry || fetchInitiated.current) return

    const { docsToDelete, docsToUpdate, docsToAdd } =
      findDocumentsToDeleteUpdateAndAdd(
        projectCountry.documents,
        templateCountry.documents
      )

    const request: SyncDocumentsModel = {
      docsToAdd,
      docsToDelete,
      docsToUpdate,
      isCountryBeingDeleted: templateCountry.documents.length === 0,
      countryId: activeCountry.id,
    }

    setSyncDocumentsModel(request)

    setDocumentsToAdd(docsToAdd)
    setDocumentsToUpdate(docsToUpdate)
    setDocumentsToDelete(docsToDelete)
  }, [templateCountry, projectCountry])

  useEffect(() => {
    if (fetchInitiated.current) return

    const fetchProjectCountries = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: listCountriesAndDocuments,
          variables: {
            filter: {
              projectRefId: {
                eq: projectId,
              },
              country_name: {
                eq: activeCountry?.country_name,
              },
            },
          },
        })

        const country = result?.data?.listCountries?.items[0] || null

        setProjectCountry({
          ...country,
          documents: (country.documents?.items as Document[]) || [],
        })
      } catch (error) {
        logger(
          "SyncNewTemplateDocsDialogV1",
          "useEffect (fetchProjectCountries)",
          error
        )

        snackBar.setMessage(
          "An error occurred fetching the list of countries. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    const fetchTemplateCountries = async () => {
      try {
        setIsLoading(true)
        fetchInitiated.current = true

        const result: any = await client.graphql({
          query: listCountriesAndDocuments,
          variables: {
            filter: {
              projectRefId: {
                eq: "",
              },
              country_name: {
                eq: activeCountry?.country_name,
              },
            },
          },
        })

        const country = result?.data?.listCountries?.items[0] || null

        if (!country || country.length === 0) {
          snackBar.setMessage(
            `${activeCountry.country_name} does not have any documents to sync.`
          )
          snackBar.setMessageSeverity(SnackType.SnackInfo)
          snackBar.onOpen()
          handleClose()

          return
        }

        setTemplateCountry({
          ...country,
          documents: country.documents?.items || [],
        })
      } catch (error) {
        logger(
          "SyncNewTemplateDocsDialogV1",
          "useEffect (fetchTemplateCountries)",
          error
        )

        snackBar.setMessage(
          "An error occurred fetching the list of countries. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setIsLoading(false)
        fetchInitiated.current = false
      }
    }

    if (activeProject && activeCountry && !fetchInitiated.current) {
      fetchProjectCountries()
      fetchTemplateCountries()
    }
  }, [activeProject, activeCountry])

  const findDocumentsToDeleteUpdateAndAdd = (
    docsFromCountry: Document[],
    docsFromGlobal: Document[]
  ): {
    docsToDelete: Document[]
    docsToUpdate: Document[]
    docsToAdd: Document[]
  } => {
    const docsToDelete: Document[] = []
    const docsToUpdate: Document[] = []
    const docsToAdd: Document[] = []

    // Find documents in global that are not in country
    for (const globalDoc of docsFromGlobal) {
      const foundInCountry = docsFromCountry.find((countryDoc) =>
        countryDoc.refId.includes(globalDoc.refId)
      )

      if (!foundInCountry) {
        docsToAdd.push(globalDoc)
      } else {
        // Compare properties to check for updates
        if (!compareDocuments(globalDoc, foundInCountry)) {
          const doc = {
            ...foundInCountry,
            name: globalDoc.name,
            enabled: globalDoc.enabled,
            lastUpdated: new Date().toISOString(),
          }

          delete doc.documentVersions

          docsToUpdate.push(doc)
        }
      }
    }

    // Find documents in country that are not in global
    for (const countryDoc of docsFromCountry) {
      const foundInGlobal = docsFromGlobal.find((globalDoc) =>
        countryDoc.refId.includes(globalDoc.refId)
      )

      if (!foundInGlobal) docsToDelete.push(countryDoc)
    }

    return { docsToDelete, docsToUpdate, docsToAdd }
  }

  // Function to compare if two documents are identical
  const compareDocuments = (doc1: Document, doc2: Document): boolean => {
    return doc1.enabled === doc2.enabled && doc1.name === doc2.name
  }

  // ============== Delete Functions ==========

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleOnSubmit()
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    syncDocDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleOnSubmit = async () => {
    try {
      setIsLoading(true)

      const response: any = await syncDocumentsApi(syncDocumentsModel)

      if (
        response.error ||
        (response.error?.status && response.error.status.toString() !== "200")
      ) {
        throw Error(
          `${JSON.stringify(
            response.error.data
          )}, Operation: [Sync Documents], Data Passed: ${JSON.stringify(
            syncDocumentsModel
          )}`
        )
      }

      const auditTrail: PartialAuditTrail = createAuditTrailObject(
        AuditTrailOperations.SYNC,
        Operations.SYNC_COUNTRY_DOCUMENT,
        `Executed the sync by country content operation, for ${activeCountry?.country_name}, in the project environment.`
      )

      await createAuditTrail(createAuditTrailAPI, auditTrail)

      invalidateAll(dispatch)

      snackBar.setMessage("Country Documents Sync Successful")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()

      handleClose()
    } catch (error) {
      logger("SyncNewTemplateDocsDialogV1", "syncCountryDocuments", error)

      snackBar.setMessage(`Error occurred while syncing documents`)
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()

      setIsLoading(false)
    }
  }

  const canCountrySync = () => {
    return (
      documentsToAdd.length !== 0 ||
      documentsToDelete.length !== 0 ||
      documentsToUpdate.length !== 0
    )
  }

  const showContent = () => {
    if (isLoading) {
      return (
        <Skeleton
          style={{
            margin: 0,
            borderRadius: "8px",
          }}
          height={40}
          variant="rounded"
          width={"100%"}
          animation="wave"
        />
      )
    }

    if (!canCountrySync())
      return <Typography>No new documents to sync</Typography>

    return (
      <>
        {documentsToUpdate.map((doc) => {
          return (
            <ListItem className={classes.listItem} key={doc.id}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <>
                    {doc.name}
                    &nbsp;
                    <Typography
                      className={classes.typographyInline}
                      color="primary"
                    >
                      (Updating)
                    </Typography>
                  </>
                }
              />
            </ListItem>
          )
        })}
        {documentsToAdd.map((doc) => {
          return (
            <ListItem className={classes.listItem} key={doc.id}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <>
                    {doc.name}
                    &nbsp;
                    <Typography
                      className={classes.typographyInline}
                      style={{ color: "green" }}
                    >
                      (Adding)
                    </Typography>
                  </>
                }
              />
            </ListItem>
          )
        })}
        {documentsToDelete.map((doc) => {
          return (
            <ListItem className={classes.listItem} key={doc.id}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <>
                    {doc.name}
                    &nbsp;
                    <Typography
                      className={classes.typographyInline}
                      color="error"
                    >
                      (Deleting)
                    </Typography>
                  </>
                }
              />
            </ListItem>
          )
        })}
      </>
    )
  }

  return (
    <Dialog
      open={syncDocDialog.isOpen}
      onClose={handleCloseDialog}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Sync Template Documents</DialogTitle>
      <DialogContent className={classes.container} dividers>
        {showContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
          disabled={isLoading || !canCountrySync()}
        >
          Sync
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default SyncNewTemplateDocsDialogV1
