import { configureStore } from "@reduxjs/toolkit"

import RootReducer from "./rootReducer"
import thunkMiddleware from "redux-thunk"
import {
  syncApi,
  deleteApi,
  versionsApi,
  documentsApi,
  countriesApi,
  auditTrailApi,
  attachmentsApi,
  chapterObjectsApi,
  documentVersionsApi,
} from "./services"

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(thunkMiddleware)
      .concat(syncApi.middleware)
      .concat(deleteApi.middleware)
      .concat(versionsApi.middleware)
      .concat(documentsApi.middleware)
      .concat(countriesApi.middleware)
      .concat(auditTrailApi.middleware)
      .concat(attachmentsApi.middleware)
      .concat(chapterObjectsApi.middleware)
      .concat(documentVersionsApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>

export type EPIDispatch = typeof store.dispatch

export default store
