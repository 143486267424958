import { create } from "zustand"

type AddChapterDialogState = {
  isOpen: boolean
}

type AddChapterDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useAddChapterDialogV1 = create<
  AddChapterDialogState & AddChapterDialogActions
>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

export default useAddChapterDialogV1
