// ==================== External Imports ==================== //
import {
  Chip,
  Theme,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  makeStyles,
  TableContainer,
  Typography,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// ==================== Local Imports ==================== //
import { RootState } from "redux/store"
import { User } from "shared/types/user"
import { Status } from "shared/types/status"
import { userProjectsHeadCells } from "util/constants"
import NoDataMessage from "../NoDataMessage"
import SearchField from "components/SearchField"
import useEditUserState from "hooksV1/useEditUserState"
import { fetchProjects } from "redux/thunks/projectsThunk"
import { Project, ProjectRole } from "shared/types/project"

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    width: "100%",
    height: 75,
    flex: 1,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
  },
}))

type ProjectRow = {
  project: Project
  role?: ProjectRole
}

type TableContentProps = {
  user: User
  projects: ProjectRow[]
}

type CreateChipProp = {
  message: string
  outlined: boolean
}

const CreateChip: React.FC<CreateChipProp> = ({ message, outlined }) => {
  return (
    <span style={{ marginRight: `4px !important` }}>
      <Chip
        label={message}
        variant={outlined ? "outlined" : "outlined"}
        color="primary"
        size="small"
      />
    </span>
  )
}

const TableHeadings: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        {userProjectsHeadCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "actions" || headCell.id === "delete"
                  ? "right"
                  : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              <Typography variant="body1" color="primary" component="h1">
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const TableContent: React.FC<TableContentProps> = ({ projects, user }) => {
  return (
    <TableBody>
      {projects
        .filter((project) =>
          project.project.users.find((usr) => usr.user.id === user.id)
        )
        .map((row, i) => {
          return (
            <TableRow key={row.project.id}>
              <TableCell component="th" scope="row">
                {row.project.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.project.author}
              </TableCell>
              <TableCell>{row.project.lastUpdated}</TableCell>
              <TableCell>
                {row.role?.role_name &&
                  CreateChip({
                    message: row.role?.role_name,
                    outlined: false,
                  })}
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

const UserProjects: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeUser } = useEditUserState()

  // ==================== Variables ==================== //
  const fetchingProjectsStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  )

  const projects = useSelector((state: RootState) => state.projects.allProjects)

  // ==================== Use Hooks ==================== //
  const [searchText, setSearchText] = useState("")
  const [projectRows, setProjectsRows] = useState<ProjectRow[]>([])
  const [filteredProjects, setFilteredProjects] = useState<ProjectRow[]>([])

  useEffect(() => {
    if (projects.length === 0) {
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
    }
  }, [])

  useEffect(() => {
    setUsers()
  }, [projectRows, searchText])

  useEffect(() => {
    const updatedRows = projects
      .map((project) => {
        return createUserProject(activeUser, project)
      })
      .filter((t): t is ProjectRow => !!t)
      .sort((a, b) => (a.project.name < b.project.name ? -1 : 1))

    setProjectsRows(updatedRows)
  }, [projects])

  // ==================== Functions ==================== //
  const setUsers = () => {
    let rows = projectRows

    if (searchText !== "") {
      rows = rows.filter((row) => {
        if (
          row.project.name.toLowerCase().includes(searchText.toLowerCase()) ||
          row.project.author.toLowerCase().includes(searchText.toLowerCase())
        )
          return row
      })
    }

    setFilteredProjects(rows)
  }

  const createUserProject = (user: User, project: Project) => {
    if (!project || !project.users) {
      const result: ProjectRow = {
        project,
        role: undefined,
      }

      return result
    }

    const projectUsers = project.users?.filter(
      (u) => u.user.email === user.email
    )
    const checked = projectUsers.length > 0 ? true : false

    if (checked) {
      const projectRole: ProjectRole = projectUsers[0].role[0]
      const result: ProjectRow = {
        project,
        role: projectRole,
      }

      return result
    }

    const result: ProjectRow = {
      project,
      role: undefined,
    }

    return result
  }

  return (
    <>
      {fetchingProjectsStatus === Status.loading ? (
        <Skeleton
          className={classes.skeleton}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      ) : (
        <>
          <SearchField searchText={searchText} setSearchText={setSearchText} />
          <TableContainer>
            {filteredProjects.length > 0 ? (
              <Table size="small" aria-label="a dense table">
                <TableHeadings />
                <TableContent projects={filteredProjects} user={activeUser} />
              </Table>
            ) : (
              <NoDataMessage text={"No Projects Found"} />
            )}
          </TableContainer>
        </>
      )}
    </>
  )
}

export default UserProjects
