import React from "react"
import { useLocation } from "react-router-dom"
import { makeStyles, Theme } from "@material-ui/core/styles"

import UserTable from "./UserTable"
import TemplatePageToolbar from "../PageToolBar/TemplatePageToolbar"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}))

const UserManagement: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()

  const [search, setSearch] = React.useState<string>("")

  let pathArray = location.pathname.split("/")
  const managementPage = pathArray[pathArray.length - 1] === "users"

  return (
    <div className={classes.container}>
      <TemplatePageToolbar
        isUserManagement={managementPage}
        search={search}
        setSearch={setSearch}
      />
      <UserTable searchText={search} />
    </div>
  )
}

export default UserManagement
