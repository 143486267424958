// ==================== External Imports ==================== //
import {
  Theme,
  Select,
  MenuItem,
  FormControl,
  makeStyles,
} from "@material-ui/core"
import { useDispatch } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import * as lookup from "country-code-lookup"
import ReactCountryFlag from "react-country-flag"
import React, { useEffect, useState, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import useSnackBar, { SnackType } from "../../hooksV1/useSnackBar"

// ==================== Local Imports ==================== //

import {
  useGetAllocatedCountriesQuery,
  useGetAllCountriesWithProjectRefIdV1Query,
} from "redux/services"
import { logger } from "util/logger"
import { containsPath } from "util/helper"
import { invalidateAll } from "util/helper"
import useAppState from "hooksV1/useAppState"
import { sortDocumentsOrder } from "../../util/helper"
import useBooleanState from "hooksV1/useBooleanStates"
import { AuthHelper } from "util/authHelper"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  skeletonContainer: {
    width: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "1rem",
    margin: theme.spacing(1, 0, 1, 0),
  },
}))

const ProjectCountrySelectorV1: React.FC = () => {
  // ==================== Hooks ==================== //
  const {
    activeCountry,
    activeProject,
    activeDocument,
    activeProjectUser,
    setCountries,
    setDocuments,
    setActiveCountry,
    setChapterObjects,
    setChapterObjectsList,
    setActiveChapterObject,
    setActiveDocumentVersion,
  } = useAppState()

  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const snackBar = useSnackBar()
  const booleanState = useBooleanState()

  const pathArray = location.pathname.split("/")
  const projectId = pathArray[2]

  const getAllocatedCountries = (): string[] => {
    const currentUser = activeProject?.users.find(
      (u) => u.user.id === sessionStorage.getItem("id")
    )

    if (!currentUser) return []

    const ids = currentUser.role
      .filter((role) => role.countryId)
      .map((role) => role.countryId)

    return ids
  }

  const isGlobalUser = () => {
    if (activeProjectUser?.role.length !== 1) return false

    if (activeProjectUser?.role[0].countryId !== "global") return false

    return true
  }

  const { data: response, isLoading } = useGetAllocatedCountriesQuery(
    getAllocatedCountries(),
    {
      skip:
        !activeProject ||
        isGlobalUser() ||
        getAllocatedCountries().length === 0,
    }
  )

  const { data: globalCountries, isLoading: isLoadingGlobal } =
    useGetAllCountriesWithProjectRefIdV1Query(activeProject?.id, {
      skip: !activeProject || !isGlobalUser(),
    })
  // ==================== Use Hooks ==================== //
  const [countries, setCountriesSelector] = useState([])

  useEffect(() => {
    if (!response) return

    try {
      if (response.errors) {
        throw Error(
          `Operation: [Get All Allocated Countries With Country Ids, ${getAllocatedCountries()}]`,
          response.errors
        )
      }

      const countriesList: any[] =
        response?.data?.listCountriesMultiple?.items.filter(
          (country) => country
        )

      if (!countriesList) return

      processCountries(countriesList)
    } catch (error) {
      logger("ProjectCountrySelectorV1", "useEffect", error)

      snackBar.setMessage(
        "An error occurred while fetching countries. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [response, activeDocument, activeProjectUser])

  useEffect(() => {
    if (!globalCountries) return

    try {
      if (globalCountries.errors) {
        throw Error(
          `Operation: [Get All  Countries as Global User, ${getAllocatedCountries()}]`
        )
      }

      const countriesList: any[] =
        globalCountries?.data?.listCountries?.items?.filter(
          (country) => country
        )

      if (!countriesList) return

      processCountries(countriesList)
    } catch (error) {
      logger("ProjectCountrySelectorV1", "useEffect", error)

      snackBar.setMessage(
        "An error occurred while fetching countries. Please try agin."
      )
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    }
  }, [globalCountries, activeDocument, activeProjectUser])

  useEffect(() => {
    booleanState.setIsDocumentsLoading(isLoading || isLoadingGlobal)
    booleanState.setIsDocumentVersionLoading(isLoading || isLoadingGlobal)
  }, [isLoading, isLoadingGlobal])

  const processCountries = useCallback(
    (countriesList) => {
      const pathName = pathArray[3]
      let uniqueCountries: any[] = []

      if (pathName === "document") {
        const activeDocumentId = pathArray[4]

        const tempCountry = countriesList.filter(
          (country) =>
            !!country.documents.items.find(
              (document) => document["id"] === activeDocumentId
            )
        )

        const activeDocument = tempCountry[0].documents.items.find(
          (doc) => doc.id === activeDocumentId
        )

        const countriesListData = countriesList.filter(
          (country) =>
            !!country.documents.items.find(
              (document) => document["refId"] === activeDocument.refId
            )
        )

        uniqueCountries = countriesListData ?? []
      } else {
        uniqueCountries = countriesList
      }

      const sortedCountriesList = [...uniqueCountries].sort((a, b) => {
        const nameA = a.country_name.toUpperCase() // Convert names to uppercase for case-insensitive comparison
        const nameB = b.country_name.toUpperCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0 // Names are equal
      })

      const filteredCountriesList = sortedCountriesList.filter(
        (country) => country.active_status
      )

      // Check if there are any active countries
      const hasActiveCountries = filteredCountriesList.length > 0

      if (!hasActiveCountries) {
        setActiveCountry(null)
        setCountriesSelector([])
        setCountries(sortedCountriesList)

        return
      }

      if (pathName === "document") {
        const activeCountryName =
          pathName === "document"
            ? pathArray[6]?.replace(/%20/g, " ")
            : pathArray[4]?.replace(/%20/g, " ")

        let activeCountry = filteredCountriesList.find(
          (country) => country.country_name === activeCountryName
        )

        setActiveCountry(activeCountry)
      } else if (pathName === "country") {
        const countryName = pathArray[4]?.replace(/%20/g, " ")

        let activeCountry = filteredCountriesList.find(
          (country) => country.country_name === countryName
        )

        setActiveCountry(activeCountry)
        const sortedDocuments = sortDocumentsOrder(
          (activeCountry.documents?.items || [])?.filter(
            (document) =>
              document.enabled &&
              AuthHelper.canViewDocument(
                activeCountry?.id,
                document.documentVersions["items"][0].access,
                activeProjectUser
              )
          )
        )

        setDocuments(sortedDocuments)
      }

      setCountries(sortedCountriesList)
      setCountriesSelector(filteredCountriesList)
    },
    [pathArray]
  )

  // ==================== Functions ==================== //

  const handleCountryChange = useCallback(
    (event: any) => {
      clearState()

      if (event.target.value === "") {
        setActiveCountry(null)
        setDocuments([])

        navigate(`/project/${projectId}/home`)

        return
      }

      const newActiveCountry = countries.find(
        (country) => country.id === event.target.value
      )

      let activeCountryName = newActiveCountry.country_name
      const currentPath = pathArray[pathArray.length - 1]
      let activeDocumentId = activeDocument?.id ?? ""

      booleanState.setIsDocumentsLoading(true)

      setActiveCountry(newActiveCountry)

      const sortedDocuments = sortDocumentsOrder(
        (newActiveCountry.documents?.items || [])?.filter(
          (document) =>
            document.enabled &&
            AuthHelper.canViewDocument(
              newActiveCountry?.id,
              document.documentVersions["items"][0].access,
              activeProjectUser
            )
        )
      )

      setDocuments(sortedDocuments)

      if (!pathArray.includes("document") && !containsPath(currentPath)) {
        navigate(`/project/${projectId}/country/${activeCountryName}`)
      } else if (!pathArray.includes("document") && containsPath(currentPath)) {
        navigate(
          `/project/${projectId}/country/${activeCountryName}/${currentPath}`
        )
      } else if (!containsPath(currentPath)) {
        navigate(
          `/project/${projectId}/document/${activeDocumentId}/country/${activeCountryName}`
        )
      } else {
        navigate(
          `/project/${projectId}/document/${activeDocumentId}/country/${activeCountryName}/${currentPath}`
        )
      }
    },
    [countries]
  )

  const clearState = useCallback(() => {
    invalidateAll(dispatch)

    setChapterObjects([])
    setChapterObjectsList([])
    setActiveChapterObject(null)
    setActiveDocumentVersion(null)
  }, [])

  if (isLoading || isLoadingGlobal) {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton
          width={125}
          height={30}
          variant="rect"
          animation="wave"
          style={{ borderRadius: "8px" }}
        />
      </div>
    )
  }

  if (!(isLoading || isLoadingGlobal) && countries.length > 0) {
    return (
      <div className={classes.container}>
        <FormControl>
          <Select
            value={activeCountry?.id || ""}
            onChange={handleCountryChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select a country</em>
            </MenuItem>

            {countries.map((country) => {
              const countryCode =
                lookup.byCountry(country?.country_name)?.iso2 ?? ""

              return (
                <MenuItem
                  key={country.id}
                  value={country.id}
                  disabled={
                    !country.active_status || booleanState?.isEditingBlocked
                  }
                >
                  <ReactCountryFlag countryCode={countryCode} svg />
                  &nbsp;
                  {country.country_name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  return (
    <div
      className={classes.container}
      style={{
        height: 60,
        fontSize: 16,
      }}
    >
      No Countries Available
    </div>
  )
}

export default ProjectCountrySelectorV1
