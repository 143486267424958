// ==================== External Imports ==================== //
import { cloneDeep, debounce } from "lodash"
import { Editor } from "@tinymce/tinymce-react"
import { generateClient } from "aws-amplify/api"
import * as subscriptions from "graphql/subscriptions"
import CircularProgress from "@mui/material/CircularProgress"
import { Typography, Paper, makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"

// ==================== Local Imports ==================== //
import {
  setEditingState,
  getUpdatedRoles,
  mapToChapterObject,
  createAuditTrailObject,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useUploadAttachmentsMutation,
} from "redux/services"
import {
  createAuditTrail,
  updateChapterObjectAmplify,
  batchCreateImageDataAmplify,
  updateImageDataAmplify,
} from "util/batchHook"
import {
  ImageData,
  Operations,
  UploadedFile,
  ChapterObject,
  ChapterSection,
  ImageRemoveProp,
  AuditTrailOperations,
} from "shared/types-exp"
import { logger } from "util/logger"
import { AuthHelper } from "util/authHelper"
import useAppState from "hooksV1/useAppState"
import { defaultAccess } from "util/constants"
import UneditableEditor from "./UneditableEditor"
import { checkEnvironment } from "util/environment"
import useBooleanState from "hooksV1/useBooleanStates"
import { OnUpdateChapterObjectSubscription } from "API"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import {
  fetchBlobInfoAndParse,
  updateMetadataWithContent,
  updateImgSrc,
  hasSingleBase64ImgTag,
  haveImagesChanged,
  getChangedImageSrc,
} from "util/textEditorHelper"

const client = generateClient()

type TextEditorSectionV1Props = {
  chapterSection: ChapterSection
  chapterObject: ChapterObject
  chapterObjectsToUpdate: ChapterObject[]
  setChapterObjectsToUpdate: React.Dispatch<React.SetStateAction<any[]>>
}

type TextEditorProp = {
  value: string
  loading: boolean
  disabled: boolean
  isAbleToEdit: boolean
  onChange: (value: string) => void
  setLoading: (value: React.SetStateAction<boolean>) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
  },
  headerContainer: {
    width: "100%",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
  },
  unEditableContainer: {
    width: "100%",
    minHeight: 150,
    display: "flex",
    position: "relative",
    alignItems: "center",
    backgroundColor: "#f1f1f1",
    padding: theme.spacing(1.0, 1.0, 1.0, 1.0),
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 10,
    border: "1px solid #ccc", // Add a solid border with color #ccc
    borderRadius: "8px",
    position: "absolute",
  },
}))

const TextEditor: React.FC<TextEditorProp> = React.memo(
  ({ value, onChange, disabled, loading, isAbleToEdit, setLoading }) => {
    const classes = useStyles()
    const editorRef = useRef(null)
    const pasteBuffer = useRef([]) // Buffer to store images during paste

    const handleEditorInit = useCallback((evt, editor) => {
      editorRef.current = editor

      editor.on("keydown", function (e) {
        const selection = editor.selection
        const selectedNode = selection.getNode()

        if (e.keyCode === 8 || e.keyCode === 46) {
          const childNode = selectedNode.childNodes[0]

          if (childNode && childNode.nodeName === "IMG") {
            e.preventDefault()
          }
        }
      })
      setLoading(false)
    }, [])

    const formatLinks = useCallback((html) => {
      const div = document.createElement("div")
      div.innerHTML = html

      const links = div.getElementsByTagName("a")

      for (let link of links) {
        link.setAttribute("target", "_blank")
        link.setAttribute("rel", "noopener noreferrer")

        const href = link.getAttribute("href")

        if (!href.startsWith("http://") && !href.startsWith("https://")) {
          link.setAttribute("href", `https://${href}`)
        }
      }

      return div.innerHTML
    }, [])

    const handleEditorChange = useCallback(
      (newState) => {
        const text = newState === "" ? " " : newState

        if (pasteBuffer.current.length > 0) return

        const formattedState = formatLinks(text)
        onChange(formattedState)
      },
      [formatLinks, onChange]
    )

    const processBufferedImages = useCallback(() => {
      if (pasteBuffer.current.length > 0) {
        const editor = editorRef.current

        // Get the current selection (cursor position)
        const bookmark = editor.selection.getBookmark(2)

        // Join the buffered content into a single string
        const bufferedContent = pasteBuffer.current.join("")

        // Move to the saved cursor position
        editor.selection.moveToBookmark(bookmark)

        // Insert the buffered content (blob image)
        editor.execCommand("mceInsertContent", false, bufferedContent)

        // Get the updated content after insertion
        const updatedContent = editor.getContent()

        // Format the updated content to preserve image src attributes
        const formattedState = formatLinks(updatedContent)

        // Pass the formatted content to onChange handler
        onChange(formattedState)

        // Clear the buffer after processing
        pasteBuffer.current = []
      }
    }, [formatLinks, onChange])

    // Debounce the processBufferedImages function to wait for a short period after the last image is pasted
    const debouncedProcessBufferedImages = useCallback(
      debounce(processBufferedImages, 200), // Adjust the delay as necessary
      [processBufferedImages]
    )

    const handleImagePaste = useCallback(
      (content) => {
        const div = document.createElement("div")
        div.innerHTML = content

        const images = div.querySelectorAll("img")

        images.forEach((img) => {
          const src = img.getAttribute("src")

          // Handle base64 images
          if (src && src.startsWith("data:image")) {
            const base64Strings = src.split(",")

            base64Strings.forEach((base64String, index) => {
              if (index > 0) {
                // Skip the first part before the comma
                const newImg = document.createElement("img")
                newImg.setAttribute(
                  "src",
                  `${base64Strings[0]},${base64String}`
                )
                div.appendChild(newImg)
              }
            })

            // Remove the original image
            img.remove()
          }
        })

        // Add the processed content to the paste buffer
        pasteBuffer.current.push(div.innerHTML)

        // Trigger the debounced function to process the buffer
        debouncedProcessBufferedImages()
      },
      [debouncedProcessBufferedImages]
    )

    if (!isAbleToEdit && disabled) return <UneditableEditor content={value} />

    return (
      <div style={{ position: "relative", minHeight: 200, width: "100%" }}>
        {loading && (
          <div className={classes.progressContainer}>
            <CircularProgress color="primary" size={25} />
          </div>
        )}

        <Editor
          apiKey={process.env.REACT_APP_EDITOR_APIKEY}
          value={value}
          onInit={handleEditorInit}
          disabled={disabled}
          init={{
            height: "auto",
            width: "100%",
            min_height: 200,
            resize: "both",
            menubar: false,
            paste_data_images: true,
            paste_merge_formats: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
              "autoresize",
              "image",
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist outdent indent | removeformat | help | " +
              "table tabledelete | tableprops tablerowprops tablecellprops | " +
              "tableinsertrowbefore tableinsertrowafter tabledeleterow | " +
              "tableinsertcolbefore tableinsertcolafter tabledeletecol | image | " +
              "paste",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            paste_preprocess: (editor, args) => {
              handleImagePaste(args.content)
              args.preventDefault() // Prevent default handling of paste
            },
            file_picker_types: "image",
            file_picker_callback: () => {
              return false
            },
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
    )
  }
)

const TextEditorSection: React.FC<TextEditorSectionV1Props> = ({
  chapterObject,
  chapterSection,
  chapterObjectsToUpdate,
  setChapterObjectsToUpdate,
}) => {
  const {
    activeCountry,
    activeDocument,
    documentRoles,
    activeProjectUser,
    setCurrentSectionEditing,
    setActiveChapterObject,
  } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()
  const { isEditingBlocked, setIsEditingBlocked } = useBooleanState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [uploadAttachmentsAPI] = useUploadAttachmentsMutation()

  const [loading, setLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [text, setText] = useState(chapterSection?.content?.plainText || "")

  const latestChapterObject = useRef(chapterObject)

  const isAbleToEdit = useMemo(() => {
    const isTemplateGlobal =
      isTemplateEnvironment && chapterSection.isGlobal && activeCountry?.global

    const isTemplateNonGlobal =
      isTemplateEnvironment &&
      !chapterSection.isGlobal &&
      !activeCountry?.global

    const isProjectClientEditable =
      isProjectEnvironment && chapterSection.isClientEditable

    return isTemplateGlobal || isTemplateNonGlobal || isProjectClientEditable
  }, [
    isTemplateEnvironment,
    isProjectEnvironment,
    chapterSection.isGlobal,
    chapterSection.isClientEditable,
    activeCountry,
  ])

  const isEditingDisabled = useMemo(() => {
    if (isProjectEnvironment) {
      const [{ id }] = activeProjectUser.role
      const access = getUpdatedRoles(chapterObject.access, documentRoles)
      const isProjectEditDisabled = access[id] !== "maintain"
      const isAbleToEdit =
        activeProjectUser.user.id === chapterObject.assignedProjectUser &&
        access[id] === "maintain_doa"

      if (isAbleToEdit) return false

      if (isProjectEditDisabled) return true
    }

    const isEditingByAnotherUser =
      chapterObject?.editing?.isEditing &&
      chapterObject?.editing.email &&
      chapterObject.editing.email.trim() !== localStorage.getItem("email")

    return isEditingByAnotherUser
  }, [isProjectEnvironment, documentRoles, activeProjectUser, chapterObject])

  const canEditDocument = AuthHelper.canEditDocument(
    chapterObject?.assignedProjectUser,
    activeCountry?.id,
    activeDocument?.documentVersions["items"][0].access,
    isProjectEnvironment,
    activeProjectUser
  )

  const clearEditingState = useCallback(async () => {
    const { id, editing, documentVersionId, isIncludedInWorkflow } =
      latestChapterObject.current

    const userEmail = localStorage.getItem("email")

    if (!editing.isEditing || editing.email !== userEmail) return

    const chapterCopy = cloneDeep({
      ...latestChapterObject.current,
      editing: {
        name: "",
        email: "",
        surname: "",
        isEditing: false,
      },
    })

    setActiveChapterObject(chapterCopy)

    await updateChapterObjectAmplify(chapterCopy, {
      id,
      documentVersionId,
      isIncludedInWorkflow,
      editing: {
        name: "",
        email: "",
        surname: "",
        isEditing: false,
      },
    })
  }, [latestChapterObject.current])

  const clearEditingOnUnMount = useCallback(async () => {
    const { editing } = latestChapterObject.current
    const userEmail = localStorage.getItem("email")

    if (editing.email === userEmail) await clearEditingState()
  }, [latestChapterObject.current])

  const setToTrue = useCallback(() => {
    setIsUpdating(true)
    setIsEditingBlocked(true)
    setCurrentSectionEditing(chapterSection.id)
  }, [chapterSection.id])

  const setToFalse = useCallback(() => {
    setIsUpdating(false)
    setIsEditingBlocked(false)
    setCurrentSectionEditing("")
  }, [])

  const updateChapterSection = useCallback(
    (
      chapter: ChapterObject,
      sectionId: string,
      newText: string
    ): ChapterObject => {
      const updatedChapter = cloneDeep(chapter)
      updatedChapter.access = updatedChapter.access || defaultAccess

      updatedChapter.sections.forEach((section) => {
        if (section.id === sectionId) {
          section.content.plainText = newText
        }
      })

      return updatedChapter
    },
    []
  )

  const handleGlobalUpdates = useCallback(
    (sectionId: string, newText: string) => {
      const updatableChapters = chapterObjectsToUpdate.map(
        (chapterObjectToUpdate) => {
          let chapterCopy = cloneDeep(chapterObjectToUpdate)

          chapterCopy.sections.forEach((section) => {
            if (section.id === sectionId && section.isGlobal) {
              section.content.plainText = newText
            }
          })

          delete chapterCopy.subchapters

          return chapterCopy
        }
      )

      return updatableChapters
    },
    [chapterObjectsToUpdate]
  )

  const updateSectionInDB = useCallback(
    async (text: string) => {
      let chapterCopy = updateChapterSection(
        chapterObject,
        chapterSection.id,
        text
      )

      chapterCopy = setEditingState(chapterCopy)

      let updatableChapters = []

      const auditTrail = createAuditTrailObject(
        AuditTrailOperations.UPDATE,
        Operations.SECTION,
        `Updated the section ${chapterSection.name}, in the chapter ${chapterObject?.name}, in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
      )

      delete chapterCopy.subchapters

      if (activeCountry?.global) {
        updatableChapters = handleGlobalUpdates(chapterSection.id, text)

        await Promise.all([
          createAuditTrail(createAuditTrailAPI, auditTrail),
          updateAllChapters([...updatableChapters, chapterCopy]),
        ])

        setChapterObjectsToUpdate(updatableChapters)
      } else {
        await Promise.all([
          createAuditTrail(createAuditTrailAPI, auditTrail),
          updateChapterObjectAmplify(chapterCopy, {
            id: chapterCopy.id,
            documentVersionId: chapterCopy.documentVersionId,
            isIncludedInWorkflow: chapterCopy.isIncludedInWorkflow,
            editing: chapterCopy.editing,
            sections: chapterCopy.sections,
          }),
        ])
      }

      setActiveChapterObject(chapterCopy)
    },
    [chapterSection]
  )

  const updateAllChapters = async (chapters: ChapterObject[]) => {
    return chapters.map((chapter) =>
      updateChapterObjectAmplify(chapter, {
        id: chapter.id,
        documentVersionId: chapter.documentVersionId,
        isIncludedInWorkflow: chapter.isIncludedInWorkflow,
        editing: chapter.editing,
        sections: chapter.sections,
      })
    )
  }

  const handleTextChange = useCallback(
    async (text: string) => {
      try {
        setToTrue()

        await updateSectionInDB(text)
      } catch (error) {
        logger(
          "TextEditorSection",
          "handleTextChange (Updating Text Section)",
          error
        )

        snackBar.setMessage(
          "An error occurred updating the section. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setToFalse()
      }
    },
    [chapterSection]
  )

  const uploadImages = useCallback(
    async (images: UploadedFile[]): Promise<UploadedFile[]> => {
      const result: any = await uploadAttachmentsAPI({
        files: images,
      })

      if (result.errors) {
        throw Error(
          `${JSON.stringify(
            result.data?.body
          )}, Operation: [Upload Image in TextEditor], Data Passed: ${JSON.stringify(
            [images]
          )}`
        )
      }

      const uploadedFiles: UploadedFile[] = result.data.body

      return uploadedFiles
    },
    []
  )

  const handleUploadPastedImages = useCallback(async (text: string) => {
    try {
      setToTrue()

      const extractedImages = await fetchBlobInfoAndParse(text)

      if (!extractedImages) return

      const result = await uploadImages(extractedImages.images)

      const newUpdatedResult = updateMetadataWithContent(
        result,
        extractedImages
      )

      const newText = updateImgSrc(text, newUpdatedResult.metadata)

      // Convert the size from MB to bytes (rounded to an integer)
      const imageData: ImageData[] = result.map((image) => ({
        ...image,
        size: Math.round(image.size * 1024 * 1024), // Convert size from MB to bytes for Amplify Size
        isDeleted: false,
      }))

      await Promise.all([
        updateSectionInDB(newText),
        batchCreateImageDataAmplify(imageData),
      ])
    } catch (error) {
      logger(
        "TextEditorSection",
        "handleUploadPastedImages (Updating Text Section Via Base64 Image Upload)",
        error
      )

      let message = "An error occurred adding the image. Please try again."

      if (error === "File too large.")
        message = "File too large. Must be 4MB or less."

      if (error === "Unsupported file type")
        message = "Unsupported file type. Only png, jpg and jpeg allowed"

      snackBar.setMessage(message)
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
    } finally {
      setToFalse()
    }
  }, [])

  const softDeleteImage = useCallback(
    async (text: string, imageRemove: ImageRemoveProp) => {
      try {
        setToTrue()

        if (!imageRemove.id) await updateSectionInDB(text)
        else {
          await Promise.all([
            updateSectionInDB(text),
            updateImageDataAmplify({
              id: imageRemove.id,
              content: imageRemove.content,
              isDeleted: imageRemove.isRemoved,
            }),
          ])
        }
      } catch (error) {
        logger(
          "TextEditorSection",
          "softDeleteImage (Soft Deleting Image)",
          error
        )

        snackBar.setMessage(
          "An error occurred adding the image. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()
      } finally {
        setToFalse()
      }
    },
    []
  )

  useEffect(() => {
    latestChapterObject.current = chapterObject

    if (chapterSection?.content?.plainText !== text) {
      setText(chapterSection?.content?.plainText)
    }
  }, [chapterObject])

  useEffect(() => {
    if (chapterSection?.content?.plainText === text || text === "") return

    let handleTextChangeTimer: ReturnType<typeof setTimeout> | null = null
    let clearEditingStateTimer: ReturnType<typeof setTimeout> | null = null
    const processTextChange = async () => {
      if (hasSingleBase64ImgTag(text)) {
        await handleUploadPastedImages(text)
      } else if (haveImagesChanged(chapterSection?.content?.plainText, text)) {
        const result = getChangedImageSrc(
          chapterSection?.content?.plainText,
          text
        )

        if (result) await softDeleteImage(text, result)
      } else {
        handleTextChangeTimer = setTimeout(() => handleTextChange(text), 3000)
      }

      clearEditingStateTimer = setTimeout(async () => {
        await clearEditingState()
      }, 15000)
    }

    processTextChange()

    // Cleanup function to clear timers and prevent memory leaks
    return () => {
      if (handleTextChangeTimer) clearTimeout(handleTextChangeTimer)

      if (clearEditingStateTimer) clearTimeout(clearEditingStateTimer)
    }
  }, [text])

  useEffect(() => {
    const updateSub = client
      .graphql({
        query: subscriptions.onUpdateChapterObject,
        variables: {
          refId: chapterObject?.refId,
        },
      })
      .subscribe({
        next: ({ data }) => updateSectionInformation(data),
        error: (error) =>
          logger("TextEditorSection", "useEffect (Subscribe)", error),
      })

    return () => {
      clearEditingOnUnMount()
      updateSub.unsubscribe()
    }
  }, [])

  const updateSectionInformation = (
    data: OnUpdateChapterObjectSubscription
  ) => {
    const updatedChapterObject = data.onUpdateChapterObject

    if (chapterObject.id !== updatedChapterObject.id) return

    const { editing } = updatedChapterObject

    const userEmail = localStorage.getItem("email")

    if (editing.email === userEmail) return

    const section = updatedChapterObject.sections.find(
      (section) => section.id === chapterSection.id
    )

    if (!section) return

    if (section.content.plainText === text) return

    const newChapter = mapToChapterObject(updatedChapterObject)

    setActiveChapterObject(newChapter)
    setText(section.content.plainText)
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <h2>{chapterSection.name}</h2>
          {isUpdating && <Typography>Saving...</Typography>}
        </div>
        <>
          {isAbleToEdit && canEditDocument ? (
            <TextEditor
              value={text}
              loading={loading}
              onChange={setText}
              isAbleToEdit={true}
              setLoading={setLoading}
              disabled={isEditingDisabled || isEditingBlocked}
            />
          ) : (
            <Paper className={classes.unEditableContainer}>
              <TextEditor
                value={text}
                onChange={null}
                disabled={true}
                loading={loading}
                setLoading={setLoading}
                isAbleToEdit={false}
              />
            </Paper>
          )}
        </>
      </div>
    </>
  )
}

export default TextEditorSection
