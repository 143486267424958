import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@material-ui/core"
import { z } from "zod"
import { cloneDeep } from "lodash"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import React, { useState, FormEvent } from "react"
import { toFormikValidationSchema } from "zod-formik-adapter"

import {
  transformArray,
  findObjectByRefId,
  createAuditTrailObject,
  invalidateAll,
} from "util/helper"
import {
  useCreateAuditTrailMutation,
  useUpdateChapterObjectMutation,
  useBatchUpdateChapterObjectsMutation,
  useListChaptersByProjectRefIdQuery,
} from "redux/services"

import { Operations, AuditTrailOperations } from "shared/types-exp"
import useAppState from "hooksV1/useAppState"
import useEditChapterDialogV1 from "../hooksV1/useEditChapterDialogV1"

import { logger } from "util/logger"
import Loader from "../components/Loading/Loader"
import { checkEnvironment } from "util/environment"
import useSnackBar, { SnackType } from "../hooksV1/useSnackBar"
import {
  createAuditTrail,
  updateChapterObjectFunc,
  batchUpdateChapters,
} from "util/batchHook"
import { flattenChapters } from "helpers/ChapterObjectHelpers"

const EditChapterDialogV1: React.FC = () => {
  const dispatch = useDispatch()
  const editChapterDialog = useEditChapterDialogV1()

  const {
    activeCountry,
    chapterObjects,
    activeDocument,
    activeChapterObject,
    setActiveChapterObject,
    setChapterObjects,
  } = useAppState()
  const snackBar = useSnackBar()

  const { isProjectEnvironment } = checkEnvironment()
  const environment: string = isProjectEnvironment ? "Project" : "Template"

  const [isLoading, setIsLoading] = useState(false)

  const { data: countriesList } = useListChaptersByProjectRefIdQuery(null, {
    skip: !activeCountry?.global,
  })

  const chapterNameExistsOnLevel = (name) => {
    return chapterObjects.some(
      (chapter) =>
        chapter.name.trim().toLowerCase() === name.toLowerCase() &&
        chapter.id !== activeChapterObject?.id
    )
  }

  const editChapterSchema = z.object({
    name: z
      .string()
      .min(3, { message: "Chapter name has to be at least 3 characters long" })
      .refine((value) => !chapterNameExistsOnLevel(value), {
        message: "Chapter name already exists on this level",
      }),
    subChapterAllowed: z.boolean(),
  })

  const formik = useFormik({
    initialValues: {
      name: activeChapterObject?.name,
      subChapterAllowed: activeChapterObject?.subchapterCreateAllowed,
    },
    validationSchema: toFormikValidationSchema(editChapterSchema),
    onSubmit: async (values) => handleSubmit(values),
  })

  const [createAuditTrailAPI] = useCreateAuditTrailMutation()
  const [updateChapterObjectAPI] = useUpdateChapterObjectMutation()
  const [batchUpdateChapterObjectsAPI] = useBatchUpdateChapterObjectsMutation()

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    editChapterDialog.onClose()
  }

  const handleCloseDialog = (
    event: any,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return

    handleClose()
  }

  const handleSubmit = async (values: {
    name: string
    subChapterAllowed: boolean
  }) => {
    setIsLoading(true)

    try {
      if (activeCountry?.global) {
        if (!countriesList) return

        await handleGlobalChapterEdit(values)
      } else {
        await handleLocalChapterEdit(values)
      }

      invalidateAll(dispatch)

      snackBar.setMessage("Chapter Edited Successfully")
      snackBar.setMessageSeverity(SnackType.SnackSuccess)
      snackBar.onOpen()
      handleClose()
    } catch (error) {
      logger("EditChapterDialogV1", "useFormik", error)

      snackBar.setMessage("Chapter Edit Failed. Please try again.")
      snackBar.setMessageSeverity(SnackType.SnackError)
      snackBar.onOpen()
      setIsLoading(false)
    }
  }

  const handleLocalChapterEdit = async (values: {
    name: string
    subChapterAllowed: boolean
  }) => {
    const tempChapterObjects = cloneDeep(chapterObjects)

    const indexOfObjectToUpdate = tempChapterObjects.findIndex(
      (obj) => obj.id === activeChapterObject?.id
    )

    if (indexOfObjectToUpdate === -1) return

    tempChapterObjects[indexOfObjectToUpdate].name = values.name
    tempChapterObjects[indexOfObjectToUpdate].subchapterCreateAllowed =
      values.subChapterAllowed

    delete tempChapterObjects[indexOfObjectToUpdate].subchapters

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.UPDATE,
      Operations.CHAPTER,
      `A chapter with the name ${activeChapterObject.name}, was updated in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      updateChapterObjectFunc(
        updateChapterObjectAPI,
        tempChapterObjects[indexOfObjectToUpdate]
      ),
    ])

    setChapterObjects(tempChapterObjects)
    setActiveChapterObject(tempChapterObjects[indexOfObjectToUpdate])
  }

  const handleGlobalChapterEdit = async (values: {
    name: string
    subChapterAllowed: boolean
  }) => {
    let newActiveChapter = null
    let newChapterObjects = null
    const chaptersToUpdate = []

    const countries = countriesList?.data?.listCountries?.items || []

    for (const country of countries) {
      for (const doc of country.documents.items) {
        if (doc.refId !== activeDocument.refId) {
          continue
        }

        const activeVersion = doc.documentVersions.items[0]
        const chapters = transformArray(activeVersion.chapters.items)

        const activeChapter = findObjectByRefId(
          chapters,
          activeChapterObject.refId
        )

        if (!activeChapter) {
          continue
        }
        activeChapter.name = values.name
        activeChapter.subchapterCreateAllowed = values.subChapterAllowed
        const clonedActiveChapter = cloneDeep(activeChapter) // Needed to prevent altering active chapter
        delete clonedActiveChapter.subchapters
        chaptersToUpdate.push(clonedActiveChapter)

        if (activeDocument?.id === doc.id) {
          const foundChapter = flattenChapters(chapters).find(
            (chap) => chap.id === activeChapterObject?.id
          ) // Make one array of Chapters and subchapters, then find the chapter

          foundChapter.name = values.name
          foundChapter.subchapterCreateAllowed = values.subChapterAllowed

          newChapterObjects = chapters
          newActiveChapter = foundChapter
        }
      }
    }

    const auditTrail = createAuditTrailObject(
      AuditTrailOperations.UPDATE,
      Operations.CHAPTER,
      `A chapter with the name ${activeChapterObject.name}, was updated in the ${activeDocument?.name} document, in ${activeCountry?.country_name}, in the ${environment} environment.`
    )

    await Promise.all([
      createAuditTrail(createAuditTrailAPI, auditTrail),
      batchUpdateChapters(batchUpdateChapterObjectsAPI, chaptersToUpdate),
    ])

    setActiveChapterObject(newActiveChapter)
    setChapterObjects(newChapterObjects)
  }

  return (
    <Dialog
      open={editChapterDialog.isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="xs"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Chapter Settings</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          id="name"
          label="Chapter Name"
          type="text"
          name="name"
          fullWidth
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          style={{ marginBottom: "1.5rem" }}
        />

        <FormControl component="fieldset">
          <FormLabel component="legend">Subchapter Settings</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="subChapterAllowed"
                  checked={formik.values.subChapterAllowed}
                  onChange={formik.handleChange}
                />
              }
              label="Allow Sub-Chapter Creation"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) =>
            formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)
          }
          disabled={
            isLoading ||
            !formik.isValid ||
            (formik.initialValues.name === formik.values.name &&
              formik.initialValues.subChapterAllowed ===
                formik.values.subChapterAllowed)
          }
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && <Loader open={true} />}
    </Dialog>
  )
}

export default EditChapterDialogV1
