// ==================== External Imports ==================== //
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppBar, Button, Toolbar, makeStyles, Theme } from "@material-ui/core"
import LayersIcon from "@material-ui/icons/Layers"

// ==================== Local Imports ==================== //
import Logo from "components/Logo"
import ProfileMenu from "./ProfileMenu"
import useAppState from "../../hooksV1/useAppState"
import EnvironmentSelector from "../EnvironmentSelector"
import { checkEnvironment } from "../../util/environment"

const useStyles = makeStyles((theme: Theme) => ({
  signInButton: {
    color: "#111B43",
  },
  projectHeader: {
    display: "flex",
    alignItems: "center",
    marginRight: "12rem",
  },
  projectTitle: {
    fontSize: 20,
    letterSpacing: 2,
    color: "white",
  },
  projectIcon: {
    fontSize: 25,
    color: "white",
    marginTop: 4,
    marginRight: 5,
  },
}))

const getFromStorage = (storage: Storage, type: string) => {
  return storage.getItem(type)
}

const MainNavV1: React.FC = () => {
  // ==================== Hooks ==================== //
  const classes = useStyles()
  let navigate = useNavigate()
  const { activeProject } = useAppState()
  const { isProjectEnvironment, isTemplateEnvironment } = checkEnvironment()

  const [hasStorageToken, setHasStorageToken] = useState<boolean>(() => {
    // Check both localStorage and sessionStorage for token initially
    return Boolean(
      getFromStorage(localStorage, "token") ||
        getFromStorage(sessionStorage, "token")
    )
  })

  useEffect(() => {
    // Event listener for storage changes
    const handleStorageChange = () => {
      const tokenInLocal = getFromStorage(localStorage, "token")
      const tokenInSession = getFromStorage(sessionStorage, "token")

      // If token exists in either storage, update state
      if (tokenInLocal || tokenInSession) {
        setHasStorageToken(true)
      } else {
        setHasStorageToken(false)
      }
    }

    // Add event listener for 'storage' event
    window.addEventListener("storage", handleStorageChange)

    // Clean up listener when component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  // ==================== Functions ==================== //
  const getBackgroundColor = () => {
    switch (true) {
      case isProjectEnvironment:
        return "#111B43"
      case isTemplateEnvironment:
        return "#212121"
      default:
        return "white"
    }
  }

  const showProjectName = (path) => {
    return activeProject && isProjectEnvironment && path.length > 2
  }

  // ==================== Variables ==================== //

  const backgroundColor = getBackgroundColor()
  const pathArray = location.pathname.split("/")

  const isSignInPage = pathArray[1] === "signin"

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <AppBar
        color="transparent"
        elevation={0}
        style={{
          width: "100%",
          padding: 0,
        }}
        position="static"
      >
        <Toolbar
          style={{
            backgroundColor,
            width: "100%",
            display: "flex",
            padding: 0,
            paddingRight: 10,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Logo />
            <EnvironmentSelector hasStorageToken={hasStorageToken} />
          </div>
          <div className={classes.projectHeader}>
            {showProjectName(pathArray) && (
              <>
                <LayersIcon className={classes.projectIcon} />
                <span className={classes.projectTitle}>
                  {activeProject?.name}
                </span>
              </>
            )}
          </div>
          {hasStorageToken ? (
            <ProfileMenu hasStorageToken={hasStorageToken} />
          ) : !isSignInPage ? (
            <Button
              className={classes.signInButton}
              onClick={() => navigate("/signin")}
            >
              Sign In
            </Button>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default MainNavV1
