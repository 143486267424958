import React from "react"
import { Navigate, Outlet } from "react-router-dom"

const PrivateRoute: React.FC = () => {
  const isAdmin = () => {
    return localStorage.getItem("role_name") === "Administrator"
  }

  return isAdmin() ? <Outlet /> : <Navigate to="/project" replace />
}

export default PrivateRoute
