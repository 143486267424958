// ==================== External Imports ==================== //
import React from "react"
import { Paper, makeStyles, Theme } from "@material-ui/core"

// ==================== Local Imports ==================== //
import PageTitle from "./PageTitle"
import SearchBarV1 from "./SearchBarV1"
import ToolbarButtons from "./ToolbarButtons"
import ProjectCountrySelectorV1 from "components/CountrySelector/ProjectCountrySelectorV1"

type PageToolBarProps = {
  isProjectDocuments?: boolean
  isUserManagement?: boolean
  isCountryManagement?: boolean
  isRoleManagement?: boolean
  isSettingsManagement?: boolean
  isVersionManagement?: boolean
  search?: string
  setSearch?: (search: string) => void
  role?: string
  setRole?: (role: string) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: "1rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },

  gridItem: {
    flex: 1,
    marginRight: "1rem",
  },
}))

const getToolbarDetails = (
  isProjectDocuments: boolean,
  isUserManagement: boolean,
  isCountryManagement: boolean,
  isRoleManagement: boolean,
  isSettingsManagement: boolean,
  isVersionManagement: boolean
) => {
  switch (true) {
    case isProjectDocuments:
      return {
        title: "Project Documents",
        subTitle: "Edit documents of the Project",
        buttonDetails: "isProjectDocuments",
      }
    case isUserManagement:
      return {
        title: "User Management",
        subTitle: "Invite users to the project and configure user roles",
        buttonDetails: "isUserManagement",
      }
    case isCountryManagement:
      return {
        title: "Country Management",
        subTitle: "Maintain active countries for the Project",
        buttonDetails: "isCountryManagement",
      }
    case isRoleManagement:
      return {
        title: "Role Management",
        subTitle: "Maintain Role Access Rights for the Project",
        buttonDetails: "isRoleManagement",
      }
    case isSettingsManagement:
      return {
        title: "Document Settings",
        subTitle: "Manage Settings for the Document",
        buttonDetails: "isSettingsManagement",
      }
    case isVersionManagement:
      return {
        title: "Version Management",
        subTitle: "Manage Versions for the Document",
        buttonDetails: "isVersionManagement",
      }
  }
}

const ProjectPageToolbar: React.FC<PageToolBarProps> = ({
  isProjectDocuments,
  isUserManagement,
  isCountryManagement,
  isRoleManagement,
  isSettingsManagement,
  isVersionManagement,
  search,
  setSearch,
  role,
  setRole,
}) => {
  const classes = useStyles()

  const {
    title: title,
    subTitle: subTitle,
    buttonDetails: buttonDetails,
  } = getToolbarDetails(
    isProjectDocuments,
    isUserManagement,
    isCountryManagement,
    isRoleManagement,
    isSettingsManagement,
    isVersionManagement
  )

  return (
    <Paper className={classes.container}>
      <div style={{ flex: 1 }}>
        <PageTitle title={title} subTitle={subTitle} />
      </div>
      {isUserManagement && (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SearchBarV1 search={search} setSearch={setSearch} />
        </div>
      )}
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        {isProjectDocuments && <ProjectCountrySelectorV1 />}
        {(isCountryManagement || isUserManagement) && (
          <div style={{ visibility: "hidden" }}>
            <ProjectCountrySelectorV1 />
          </div>
        )}
        <ToolbarButtons buttonsDetails={buttonDetails} />
      </div>
    </Paper>
  )
}

export default ProjectPageToolbar
