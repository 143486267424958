import { create } from "zustand"

type SyncDialogState = {
  isOpen: boolean
}

type SyncDialogActions = {
  onOpen: () => void
  onClose: () => void
}

const useSyncTemplateDocDialogV1 = create<SyncDialogState & SyncDialogActions>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export default useSyncTemplateDocDialogV1
