import { create } from "zustand"

type CreateProjectDialogState = {
    isOpen: boolean
}

type CreateProjectDialogActions = {
    onOpen: () => void
    onClose: () => void
}

const useCreateProjectDialogV1 = create<
    CreateProjectDialogState & CreateProjectDialogActions
>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
}))

export default useCreateProjectDialogV1
