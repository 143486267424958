// ==================== External Imports ==================== //
import { makeStyles, Theme } from "@material-ui/core"
import React, { useState, useEffect, useRef } from "react"

// ==================== Local Imports ==================== //
import { logger } from "util/logger"
import Loading from "../Loading/Loading"
import { findObjectByChapterIndex, transformArray } from "util/helper"
import useAppState from "hooksV1/useAppState"
import { generateClient } from "aws-amplify/api"
import useBooleanState from "hooksV1/useBooleanStates"
import { getDocumentVersionLimited } from "graphql/queries"
import useSnackBar, { SnackType } from "hooksV1/useSnackBar"
import useChapterNavigationV1 from "hooksV1/useChapterNavigation"
import DocumentSectionsV1 from "../DocumentSection/DocumentSectionsV1"
import ChapterNavigationV1 from "../ChapterNavigation/ChapterNavigationV1"

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  documentSection: {
    width: "100%",
    height: "99%",
    margin: theme.spacing(1, 0, 1, 1),
    backgroundColor: "white",
    borderRadius: "10px",
  },
  chapterNavigationVisible: {
    opacity: 1,
    width: "15vw",
    height: "100%",
    transition: "opacity 0.25s ease-in-out",
  },
  chapterNavigationHidden: {
    opacity: 0,
    width: "0%",
    transition: "opacity 0s ease-in-out",
    pointerEvents: "none",
  },
}))

const client = generateClient()

const ProjectManageContent: React.FC = () => {
  // ==================== Hooks ==================== //
  const {
    activeDocument,
    setChapterObjects,
    setChapterObjectsList,
    activeDocumentVersion,
    setActiveChapterObject,
    setActiveDocumentVersion,
  } = useAppState()
  const classes = useStyles()
  const snackBar = useSnackBar()
  const chapterNavHook = useChapterNavigationV1()
  const { setIsChaptersLoading, isDocumentVersionLoading } = useBooleanState()

  const fetchInitiated = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [documentVersion, setDocumentVersionData] = useState(null)

  useEffect(() => {
    if (!documentVersion) return

    const chapters = documentVersion.chapters["items"]

    const transformedArray = transformArray(chapters)

    let newActiveDocument = null

    transformedArray.some((chapter, index) => {
      newActiveDocument = findObjectByChapterIndex(
        chapter,
        (index + 1).toString()
      )

      return newActiveDocument !== null
    })

    setChapterObjectsList(chapters)
    setChapterObjects(transformedArray)
    setActiveDocumentVersion({ ...documentVersion, chapters })
    setActiveChapterObject(newActiveDocument || transformedArray[0])

    setIsLoading(false)
    setIsChaptersLoading(false)
    fetchInitiated.current = false
  }, [documentVersion])

  useEffect(() => {
    if (
      !activeDocument ||
      !activeDocumentVersion ||
      fetchInitiated.current ||
      documentVersion?.id === activeDocumentVersion?.id
    ) {
      return
    }

    fetchInitiated.current = true

    const fetchDocumentVersion = async () => {
      try {
        setIsLoading(true)
        setIsChaptersLoading(true)

        const result = await client.graphql({
          query: getDocumentVersionLimited,
          variables: {
            id: activeDocumentVersion?.id,
            documentId: activeDocumentVersion?.documentId,
          },
        })

        setDocumentVersionData(result?.data?.getDocumentVersion)
      } catch (error) {
        logger("ProjectManageContent", "fetchDocumentVersion", error)
        snackBar.setMessage(
          "An error occurred fetching the document version. Please try again."
        )
        snackBar.setMessageSeverity(SnackType.SnackError)
        snackBar.onOpen()

        setIsLoading(false)
        setIsChaptersLoading(false)
        fetchInitiated.current = false
      }
    }

    fetchDocumentVersion()
  }, [activeDocument, activeDocumentVersion])

  // ==================== Functions ==================== //

  if (
    isLoading ||
    !activeDocument ||
    !documentVersion ||
    !activeDocumentVersion ||
    isDocumentVersionLoading
  ) {
    return (
      <div style={{ padding: "0.5em", width: "100%", height: "100%" }}>
        <Loading />
      </div>
    )
  }

  return (
    <div className={classes.paperContainer}>
      <div
        className={
          chapterNavHook.isOpen
            ? classes.chapterNavigationVisible
            : classes.chapterNavigationHidden
        }
      >
        <ChapterNavigationV1 />
      </div>
      <div className={classes.documentSection}>
        <DocumentSectionsV1 />
      </div>
    </div>
  )
}

export default ProjectManageContent
